import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../service/storage.service';
import { WorkerService } from '../service/worker.service';

@Injectable({
  providedIn: 'root'
})
export class OnlyAdminGuard implements CanActivate {

  constructor(
    private storage: StorageService,
    private worker: WorkerService,
    private router: Router
  ) {}
    
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.storage.user.data.user_id) {
        // 근로자
        if(this.storage.user.data.user_type == 'USER'){
          this.storage.user.clear();
          this.worker.clearWorker();
          setTimeout(() => {
            this.router.navigate(['/admin-web/login'], {replaceUrl: true});
          },300);
        }
        return true
      } else return true;
  }
  
}
