import { DateService } from "../../service/date.service";
import { NumberService } from "../../service/number.service";

//작업허가서
export class Daily_Plan_Data {
  date = new DateService(new NumberService());
  constructor() {}
  dp_id = 0;
  dp_date = this.date.today();
  dp_end_date = this.date.today();
  dp_construction_name = '기타';
  dp_construction_name_etc = '';
  company_id = 0;
  dp_worker_no = '1';
  dp_worker_no_etc = '';
  dp_equipment = [];
  dp_work_text = '';
  dp_work_area = 0;
  dp_etc = '';
  ra_ids = [];
  checker_user_ids = [];
  default_state = false;
  dp_time = '';
  dp_start_time = '';
  dp_end_time = '';
  today_data = {
    daily_plan_today: [] as Daily_Plan_Today_Data[]
  }
  file_list = [];
  file_json = {file:[]}
}
export class Daily_Plan_Edit_Data extends Daily_Plan_Data {
  
}
export class Daily_Plan_Today_Data {
  occupation_name:string = '';
  occupation_name_etc:string = '';
  today_text:string = '';
}

//위험성평가
export class Ra_List_Item {
  approval_comment = "";
  approval_user_data = {risk_assessment_approval: []};
  checker_users = {checker_user_ids: [], checker_user_data: []};
  cnt_approval_state = ""; // 현재상태
  code_level_one = "";
  company_duty_name = "";
  company_id = 0; // 업체ID
  company_name = ""; // 업체명
  construction_name = ""; // 공종\
  ctgo_construction_id = 0;
  ctgo_occupation_id = 0;
  evaluation_detail = ""; // 평가내용
  index = 0;
  occupation_name = "";
  ra_end_date = ""; // 기간
  ra_id = 0; // 위험성평가ID
  ra_scene = ""; // 현장
  ra_start_date = ""; // 기간
  reg_date = ""; // 등록일
  risk_assessment = {risk_assessment_construction: []};
  risk_assessment_cmt = "";
  user_id = 0; // 작성자ID
  user_name = "";
  ctgo_construction_name = ""; //	공종명				
}
export class Ra_Risk {
  ra_id = 0;
  construction_name = "";
  risk:Risk[] = [];
}
export class Risk {
  constructor(
      _risk_assessment:Risk_Assessment[] = [new Risk_Assessment()]
  ) {
      this.risk_assessment = _risk_assessment;
  }
  code_level_one = "";
  code_level_two = "";
  ra_construction_name = ""; // 세부공종
  risk_assessment:Risk_Assessment[] = [];
}
export class Risk_Assessment {
  constructor(
      _risk_assessment_factor:Risk_Assessment_Factor[] = [new Risk_Assessment_Factor()]
  ) {
      this.risk_assessment_factor = _risk_assessment_factor;
  }
  ra_construction_name = ""; // 세부공종
  ra_workstep = ""; // 작업단계
  ra_equipment = ""; // 사용장비
  ra_equipment_no = "1"; // 장비수
  ra_equipment_no_manual = ""; //장비수 직접입력
  ra_work_start_date = ""; // 2020-01-01
  ra_work_end_date = ""; // 2021-01-01
  ra_work_area_detail = ""; // 작업위치상세
  ra_work_area = ""; // 작업위치카테고리로불러오는거
  ra_worker_no = "1"; // 인원
  ra_worker_manual = ""; //인원 직업입력
  code_level_one = "";
  code_level_two = "";
  code_level_three = "";
  risk_assessment_factor:Risk_Assessment_Factor[] = [];
}
export class Risk_Assessment_Factor {
  ra_risk_factor = ""; //위험요인
  ra_damage_form = ""; //피해형태
  ra_frequency = "1"; //빈도
  ra_strength = "1"; //강도
  ra_rating = ""; //등급
  ra_management_method = ""; //관리방안
  ra_admin_comment = ""; //관리자코멘트
  ra_admin_comment_visible:boolean = false;
  code_level_four = "";
}
 export class SOSManage_ITEM{
  index = 0;
  reg_date="";	//일자			
  reg_time="";	//시간			
  user_name="";	//이름			
  user_phone="";	//휴대폰번호			
  construction_name="";	//공종			
  occupation_name="";	//직종			
  company_name="";	//업체명			
 }

 export class Work_Manage_ITEM{
  auth_id: ""
  auth_type: 1
  bad_push:false
  company_duty_name: ""
  company_duty_name_etc: ""
  company_id: 0
  company_name: ""
  company_position_name: ""
  company_position_name_etc: ""
  company_type_name: ""
  construction_name: ""
  construction_name_etc: ""
  edu_new_count: 0
  index: number
  occupation_name: ""
  occupation_name_etc: ""
  reg_date: ""
  update_date: ""
  user_address: ""
  user_address_detail: ""
  user_birthday: ""
  user_email: ""
  user_foreigner_no: ""
  user_id: 0
  user_level: 0
  user_name: ""
  user_nationality: ""
  user_phone: ""
  user_profile_file: ""
  user_safe_file: null
  user_scene: ""
  user_sign_file: ""
  user_sos_family: ""
  user_sos_name: ""
  user_sos_phone: ""
  user_type: ""
}

//월별 공정 사진
export interface MonthlyData {
  monthly_id:number,
  monthly_list: MonthlyImgItem[],
  monthly_month: string,
  monthly_title: string,
  monthly_year: string,
  reg_date: string,
  result_data_count: number,
  user_data: {
    auth_id: string,
    user_name: string
  }
}
export interface MonthlyImgItem {
  monthly_filelist: MonthlyFileItem[],
  monthly_fileSelected?:MonthlyFileItem,
  monthly_id: number,
  monthly_month: string,
  monthly_year: string
}
export interface MonthlyFileItem {
  seq_no:number,
  order_no:number,
  monthly_id:number,
  monthly_file:string,
  checked?:boolean
}

//인원배치표
export interface Organization_Item {
  organization_id:number,//	id
  user_id:number,//	유저id
  organization_title:string,//	제목
  organization_file:string,//	파일
  user_data:{ auth_id:string, user_name:string },
  reg_date:string,//	등록일	
  rep_state:boolean
}
//근로자 이동기록 현황
export interface UserMoveNfcItem {
  index:number,
  data_state:boolean,
  open:boolean,
  sub_data:UserMoveNfcDetailItem[],
  user_id:number,//유저ID			
  nfc_state:NfcState,	//입장 OR 퇴장			
  area_name:string,	//구역명			
  enter_datetime:string,	//시간			
  user_name:string,	//유저명			
  construction_name:string,	//공종			
  occupation_name:string,	//직종			
  company_name:string	//업체명
}
export interface UserMoveNfcDetailItem {
  area_name:string,
  enter_datetime:string,
  enter_time:string,
  nfc_state:NfcState,
  nfc_type:string,
  seq_no:number,
  user_id:number
}
export type NfcState = "입장" | "퇴장";
