import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { BluetoothService } from 'src/app/service/bluetooth.service';
import { NfcService } from 'src/app/service/nfc.service';
import { PushService } from 'src/app/service/push.service';
import { BiconRssiComponent } from '../bicon-rssi/bicon-rssi.component';

@Component({
  selector: 'app-native-list',
  templateUrl: './native-list.component.html',
  styleUrls: ['./native-list.component.scss'],
})
export class NativeListComponent implements OnInit {

  constructor(
    public toast: ToastController,
    public push: PushService,
    public nfc: NfcService,
    public bluetooth: BluetoothService,
    private modal: ModalController
  ) {
    
  }

  ngOnInit() {
    // this.bluetooth.bluetoothScan();
  }
  ionViewWillEnter() {
    console.log(this.bluetooth.activated);
  }
  checkValid() {
    let total = 0;
    this.push.activated ? total++ : null;
    this.nfc.activated ? total++ : null;
    this.bluetooth.activated ? total++ : null;
    return total;
  }
  async nav1() {
    const res = await this.promiseTimeout(3000, this.push.initPush());
    if(res) {
      const toast = await this.toast.create({
        message: '알림서비스가 활성화 되어있습니다.',
        duration: 1500,
        color: 'primary'
      });
      toast.present();
    } else {
      const toast = await this.toast.create({
        message: '알림서비스가 활성화에 실패했습니다.',
        duration: 1500,
        color: 'danger'
      });
      toast.present();
    }
  }
  async nav2() {
    const res = await this.promiseTimeout(3000, this.nfc.readerMode());
    if(res) {
      const toast = await this.toast.create({
        message: 'NFC서비스가 활성화 되어있습니다.',
        duration: 1500,
        color: 'primary'
      });
      toast.present();
    } else {
      const toast = await this.toast.create({
        message: 'NFC서비스 활성화에 실패했습니다.',
        duration: 1500,
        color: 'danger'
      });
      toast.present();
    }
  }
  async nav3() {
    const res = await this.promiseTimeout(10000, this.bluetooth.startScan());
    console.log('blue state - ', res);
    if(res) {
      const toast = await this.toast.create({
        message: '비콘서비스가 활성화 되어있습니다.',
        duration: 1500,
        color: 'primary'
      });
      toast.present();
    } else {
      const toast = await this.toast.create({
        message: '비콘서비스 활성화에 실패했습니다.',
        duration: 1500,
        color: 'danger'
      });
      // this.nav3();
      // toast.present();
    }
  }
  promiseTimeout = (ms:number, promise:Promise<unknown>) => {

    // Create a promise that rejects in <ms> milliseconds
    let timeout = new Promise((resolve, reject) => {
      let id = setTimeout(() => {
        clearTimeout(id);
        resolve(false)
      }, ms)
    });
  
    // Returns a race between our timeout and the passed in promise
    return Promise.race([
      promise,
      timeout
    ]);
  }
  async biconRssi(ev) {
    ev.stopPropagation(); 
    console.log('테스트 1 ----------');
    window.dispatchEvent(new CustomEvent('app:biconRssi'));
    console.log('테스트 2 ----------');
  }
}
