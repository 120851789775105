import { Injectable } from '@angular/core';
import { ConnectService } from './connect.service';
import { StorageService } from './storage.service';


class Worker_Data {
  user_id = 0; // 유저ID			
  user_type = 'USER'; // ADMIN - 관리자 / USER - 유저			
  user_level = 1; // 0 마스터 / 1 일반			
  auth_type = 1; // 1고정			
  auth_id = ''; // 아이디			
  user_name = ''; // 이름			
  user_phone = ''; // 연락처			
  user_email = ''; // 이메일			
  user_career = '';
  user_scene = ''; // 현장명			
  user_birthday = ''; // 생년월일			
  user_address = ''; // 주소			
  user_address_detail = ''; // 상세주소			
  user_nationality = ''; // 국적			
  user_foreigner_no = ''; // 외국인번호			
  user_sos_name = ''; // 긴급연락명			
  user_sos_phone = ''; // 긴급연락처			
  user_sos_family = ''; // 긴급연락가족관계			
  company_id = 0; // 업체ID			
  company_name = ''; // 업체명			
  company_type_name = ''; // 업체구분			
  company_duty_name = ''; // 직책			
  company_position_name = ''; // 직위			
  construction_name = ''; // 공종			
  occupation_name = ''; // 직종			
  company_duty_name_etc = ''; // 직책 기타입력			
  company_position_name_etc = ''; // 직종 기타입력			
  construction_name_etc = ''; // 공종 기타입력			
  occupation_name_etc = ''; // 직종 기타입력			
  user_safe_file = ''; // 안전교육			
  user_sign_file = ''; // 싸인			
  user_license_no = '';
  user_license_state = false;
  user_profile_file = ''; // 사진			
  work_permission = false; // true - 오늘 작업허가요청함			
  health_com_date = ''; // 안전교육이수일자			
  bicon_rssi = ''; // 비콘센서 값		
  bicon_state = false; // true - 오늘 작업허가 요청 후 비콘 활성화 함 	
  push_state = 'OFF' as 'ON' | 'OFF' | 'SILENT' ;
  alarm_data = {
    alarm_count: 0,
    notice_count: 0
  }
}
@Injectable({
  providedIn: 'root'
})
export class WorkerService {

  worker = new Worker_Data();

  constructor(
    private connect: ConnectService,
    private storage: StorageService
  ) {
    this.getWorker(true);
  }
  
  async getWorker(update:boolean = false) {
    if(update) {
      const res = await this.connect.run('Get_UserData', {edit_user_id:this.storage.user.data.user_id});
      switch(res.code) {
        case 0:
          this.worker = {
            ...this.worker,
            ...res.data,
            // work_one_etc: res.data.work_id_one ? '' : res.data.work_nm_one
          }
          return;
        default:
          return;
      }
    }
  }
  clearWorker() {
    this.worker = new Worker_Data();
  }
}
