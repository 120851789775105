import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nfc-tag',
  templateUrl: './nfc-tag.component.html',
  styleUrls: ['./nfc-tag.component.scss'],
})
export class NfcTagComponent implements OnInit {

  @Input('item') item = {
    nfc_id: 0, // nfc_id			
    area_id: 0, // area_id			
    nfc_type: '', // IN / OUT			
    nfc_state: '', // 현장 / 작업 / 중장비			
    area_name: '', // 작업구간이름			
  };

  constructor() { }

  ngOnInit() {
    // alert(JSON.stringify(this.item));
  }

}
