import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/core';
import { ModalController, AlertController, LoadingController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ConnectService } from 'src/app/service/connect.service';
import { FileService } from 'src/app/service/file.service';
import { EquipPreCheckTwoComponent } from '../equip-pre-check-two/equip-pre-check-two.component';

@Component({
  selector: 'app-equip-pre-check-one',
  templateUrl: './equip-pre-check-one.component.html',
  styleUrls: ['./equip-pre-check-one.component.scss'],
})
export class EquipPreCheckOneComponent implements OnInit {
  @Input('item') item = {
    user_id: 0, // user_id			
    equip_id: 0, // equip_id			
    company_id: 0, // 업체ID			
    rental_company_name: '', // 임대업체			
    equip_type: '', // 장비대분류			
    equip_sub_type: '', // 장비소분류			
    equip_regist_no: '', // 등록번호			
    equip_area_standard: '', // 규격			
    equip_start_date: '', // 검사일			
    equip_end_date: '', // 검사일			
    insur_start_date: '', // 보험일			
    insur_end_date: '', // 보험일			
    equip_user_id: '', // 운전원			
    equip_user_phone: '', // 연락처			
    equip_license_no: '', // 면허번호			
    equip_sub_user_id: 0, // 운전원 교대			
    reg_date: '', // 등록일			
    equip_user_name: '', // 운전원 명			
    equip_sub_user_name: '', // 교대 운전원 명			
    equip_file_data: [], // [{"seq_no":1,"equip_file":"http://54.180.53.189/kunyoung/Data/Daily/Safty/2/2131@@!![]}{)(!@!~`-복사본.txt.jpg","order_no":1},{"seq_no":2,"equip_file":"http://54.180.53.189/kunyoung/Data/Daily/Safty/2/2131@@!![]}{)(!@!~`-복사본.txt1.jpg","order_no":2},{"seq_no":3,"equip_file":"http://54.180.53.189/kunyoung/Data/Daily/Safty/2/2131@@!![]}{)(!@!~`-복사본.txt2.jpg","order_no":3}]			
    equip_question_data: {
      question_data: [],
      ctgo_question_data: []
    }, // {"question_data":[1,2],"ctgo_question_data":[{"ctgo_equip_question_id":1,"ctgo_equip_question_file":"http://54.180.53.189/kunyoung/Data/Equip/Question/c01_1.jpg","ctgo_equip_question_name":"버켓상태 확인 및 퀵커플러 안전핀을 사용하고 있는가?"},{"ctgo_equip_question_id":2,"ctgo_equip_question_file":"http://54.180.53.189/kunyoung/Data/Equip/Question/c01_2.png","ctgo_equip_question_name":"후크해지장치는 설치되어 있으며, 안전하게 사용될 수 있는가?"}]}			
    company_name: '', // 업체명			
    today_inspection: false, // true - 오늘 사용전점검함			
  };

  @Input('message') message:string = '';
  constructor(
    private _modal: ModalController,
    private alert: AlertController,
    private router: Router,
    private connect: ConnectService,
    private loading: LoadingController,
    private file: FileService,
    private changeDetector: ChangeDetectorRef,
    private plt: Platform
  ) { }

  ngOnInit() {}

  back_sub:Subscription;
  ionViewWillEnter() {
    this.back_sub = this.plt.backButton.subscribeWithPriority(10, async() => {
      let alert = await this.alert.create({
        header: '알림',
        subHeader: '사용전 점검을 완료해주셔야합니다.',
        backdropDismiss: false,
        buttons: [{text: '확인',role: 'cancel',}]
      });
      alert.present();
    });
  }
  ionViewWillLeave() {
    this.back_sub.unsubscribe();
  }

  dismiss() {
    this._modal.dismiss();
  }
  async submit() {
    const modal_2 = await this._modal.create({
      component: EquipPreCheckTwoComponent,
      componentProps:{
        item: this.item,
        message: this.message,
        dis_modal: this._modal
      }
    });
    await modal_2.present();
    const { data } = await modal_2.onDidDismiss();
    if(data)
      console.log('submit ----------');
      console.log(this._modal);
      this._modal.dismiss();
  }

  nameTrans(item:string){
    return item.split('/')[item.split('/').length-1];
  }
}
