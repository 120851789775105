import { Injectable } from '@angular/core';
import { NumberService } from './number.service';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(
    private number: NumberService
  ) { }

  today(opts:{year?, month?, date?} = {year: 0, month: 0, date: 0}, type: 'DATE' | 'TIME' = 'DATE') {
    const resDate = new Date();
    if (opts.year) resDate.setFullYear(resDate.getFullYear() + opts.year);
    if (opts.month) resDate.setMonth(resDate.getMonth() + opts.month);
    if (opts.date) resDate.setDate(resDate.getDate() + opts.date);
    switch(type) {
        case 'DATE':
            return resDate.getFullYear() + '-' + this.number.XX(resDate.getMonth() + 1) + '-' + this.number.XX(resDate.getDate());
        case 'TIME':
            return new Date().toISOString();
    }
  }
  year() {
    return new Date().getFullYear();
  }
  string(date:Date | string) {
    const ins_date = typeof date === 'string' ? new Date(date) : date;
    return ins_date.getFullYear() + '-' + this.number.XX(ins_date.getMonth() + 1) + '-' + this.number.XX(ins_date.getDate());
  }
  koreanText(date:Date | string) {
    const ins_date = typeof date === 'string' ? new Date(date) : date;
    return ins_date.getFullYear() + '년 ' + this.number.XX(ins_date.getMonth() + 1) + '월 ' + this.number.XX(ins_date.getDate()) + '일';
  }
  koreanText_week(date:Date | string) {
    const ins_date = typeof date === 'string' ? new Date(date) : date;

    var week = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
    var dayOfWeek = week[new Date(date).getDay()];
    // return dayOfWeek;

    return ins_date.getFullYear() + '년 ' + this.number.XX(ins_date.getMonth() + 1) + '월 ' + this.number.XX(ins_date.getDate()) + '일 '+ dayOfWeek;
  }
  getWeek(date:Date | string){
    const ins_date = typeof date === 'string' ? new Date(date) : date;

    var week = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
    var dayOfWeek = week[new Date(date).getDay()];
    // return dayOfWeek;

    return dayOfWeek;
  }
  age(date:Date | string) {
    const ins_date = typeof date === 'string' ? new Date(date) : date;
    return new Date().getFullYear() - ins_date.getFullYear() + 1;
  }
  dirrerence(date1:string, date2:string, type:'Year'|'Month'|'Date'|'HOUR'="Date") {
    const date_1 = new Date(date1);
    const date_2 = new Date(date2);
    const def_time = date_2.getTime() - date_1.getTime();
    const def_days = def_time / (1000 * 3600 * 24);
    const def_hours = def_time / (1000 * 3600);
    switch(type) {
        case 'Year':
            return Math.floor(def_days / 365);
        case 'Date':
            return def_days;
        case 'HOUR':
            return def_hours;
    }
  }
}
