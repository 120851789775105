import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { ConnectService } from '../service/connect.service';

@Injectable({
  providedIn: 'root'
})
export class OrientGuardGuard implements CanActivate {
  constructor(private screenOrientation: ScreenOrientation,private connect: ConnectService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.connect.isApp()) this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
    return true;
  }
}
