import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NFC, Ndef } from '@ionic-native/nfc/ngx';
import { AlertController, ModalController, NavController, NavParams, Platform, ToastController } from '@ionic/angular';
import { EquipPreCheckOneComponent } from '../app-component/equip-pre-check-one/equip-pre-check-one.component';
import { NfcTagComponent } from '../app-component/nfc-tag/nfc-tag.component';
import { ConnectService } from './connect.service';

@Injectable({
  providedIn: 'root'
})
export class NfcService {

  activated: boolean = false;

  constructor(
    private nfc: NFC,
    private plt: Platform,
    private ndef: Ndef,
    private alert: AlertController,
    private toast: ToastController,
    private connect: ConnectService,
    private modal: ModalController,
    public router: Router,
    private nav: NavController
  ) {

  }

  private initNFC() {
    console.log('INIT NFC');
    return new Promise(res => {
      const roofCall = () => {
        this.nfc.enabled()
          .then(data => {
            console.log('NFC ENABLED');
            res(true);
          })
          .catch(err => {
            console.log('NFC ENABLED FAIL', err);
            this.nfc.showSettings().then(async () => {
              const alert = await this.alert.create({
                header: 'NFC 사용 요청',
                message: '이 어플리케이션을 사용하기 위해서는 NFC기능이 필요합니다. NFC 기능을 켜주세요.',
                buttons: [
                  {
                    text: '확인'
                  }
                ]
              });
              alert.present();
              await alert.onDidDismiss();
              roofCall();
            })
              .catch(async (err) => {
                console.log('nfc showSettings fail', err);
                const alert = await this.alert.create({
                  header: 'NFC 사용 요청 불가',
                  message: typeof err === 'object' ? JSON.stringify(err) : err,
                  buttons: [
                    {
                      text: '확인'
                    }
                  ]
                });
                alert.present();
                await alert.onDidDismiss();
                res(true);
              });
          });
      }
      roofCall();
    });
  }
  async readerMode() {
    return new Promise(async (res) => {
      await this.initNFC();
      if (this.plt.is('android')) {
        const flags = this.nfc.FLAG_READER_NFC_A | this.nfc.FLAG_READER_NFC_V;
        const reader = this.nfc.readerMode(flags).subscribe(
          async (tag) => {
            const message = await this.getNdefMessage(tag);
            // alert(JSON.stringify(message));
            if (message) {
              if (location.href.indexOf('nfc-edu1') > -1) this.checkEdu(message);
              else this.NFCTypeChecker(message);
            } else {
              const toast = await this.toast.create({
                color: 'primary',
                message: 'NFC를 다시 인식해주세요.',
                duration: 3000
              });
              toast.present();
            }
          },
          async (err) => {
            const alert = await this.alert.create({
              header: 'NFC 리더모드 실패',
              message: 'NFC에 문제가 발생했습니다. 앱을 다시 실행해주세요.',
            })
            alert.present();
            console.log('Error reading tag', err)
          }
        );
        console.log('NFC READER', reader);
        if (reader) { this.activated = true; res(true); }
        else res(true);
      } else {
        this.activated = true;
        res(true);
      }
    });
  }
  async scanNdef() {
    try {
      let tag = await this.nfc.scanNdef();
      const message = await this.getNdefMessage(tag);
      if (message) {
        if (location.href.indexOf('nfc-edu1') > -1) this.checkEdu(message);
        else this.NFCTypeChecker(message);
      } else {
        const toast = await this.toast.create({
          color: 'primary',
          message: 'NFC를 다시 인식해주세요.',
          duration: 3000
        });
        toast.present();
      }
    } catch (err) {
      console.log('Error reading tag', err);
      const toast = await this.toast.create({
        color: 'primary',
        message: 'NFC를 다시 스캔해주세요.',
        duration: 3000
      });
      toast.present();
    }
  }
  private getNdefMessage(tag) {
    return new Promise(res => {
      if (tag.ndefMessage) {
        if (tag.ndefMessage[0]) {
          const message = JSON.parse(JSON.stringify(this.nfc.bytesToString(tag.ndefMessage[0].payload)).replace('\\u0002', ''));
          res(message);
        } else {
          res(true);
        }
      } else {
        res(true);
      }
    });
  }
  async NFCTypeChecker(message){
    const res = await this.connect.run('Get_NfcType', {
      nfc_uq_id: message
    });

    console.log('check ----------');
    console.log(JSON.stringify(res.data));
    console.log('check ----------');
    if(res.data.nfc_type == '중장비'){
      if (location.href.indexOf('equip-pre-check-nfc') > -1) this.checkNFC_equip(message);
    } else {
      if (location.href.indexOf('equip-pre-check-nfc') == -1 && location.href.indexOf('nfc-edu1') == -1) this.checkNFC(message);
    }
  }
  async checkNFC_equip(message) {
    const res = await this.connect.run('Get_UserNFC', {
      nfc_uq_id: message
    });
    switch (res.code) {
      case 0:
      case 3030:
        const top_modal = await this.modal.getTop();
        if (top_modal) {
          top_modal.dismiss();
        }
        
        let alert_1 = this.alert.create({
          header: '중장비',
          subHeader: '안전하게 작업하세요^^',
          backdropDismiss: false
        });
        (await alert_1).present();

        setTimeout(async() => {
          await (await alert_1).dismiss().then(() => {this.checkEquip(res, message);});
        },3000 );
        return true;
      case 3019:
        let alert_2 = this.alert.create({
          subHeader: '해당 중장비의 작업을 종료하시겠습니까?',
          buttons: [
            {text: '아니요'},
            {
              text: '예',
              handler: async () => { this.checkNFC_fail(message); }
            }
          ]
        });
        (await alert_2).present();
        return false;
      default:
        this.connect.error('NFC 정보 전송실패', res);
        return false;
    }
  }

  async checkNFC(message) {
    const res = await this.connect.run('Get_UserNFC', {
      nfc_uq_id: message
    });
    switch (res.code) {
      case 0:
      case 1:
      case 3030:
        const top_modal = await this.modal.getTop();
        if (top_modal) {
          top_modal.dismiss();
        }
        if (location.href.indexOf('equip-pre-check-nfc') > -1) {
          let alert_1 = this.alert.create({
            header: '중장비',
            subHeader: '안전하게 작업하세요^^',
            backdropDismiss: false
          });
          (await alert_1).present();

          setTimeout(async() => {
            await (await alert_1).dismiss().then(() => {this.checkEquip(res, message);});
          },3000 );

          // this.checkEquip(res, message); // 사용전 장비점검
        } else {
          console.log('중장비 ----------');
          console.log(res.data.nfc_type);
          console.log('중장비 ----------');
          if(res.data.nfc_type != '중장비'){
            const modal = await this.modal.create({
              component: NfcTagComponent,
              componentProps: {
                item: res.data,
                id: 'modal'
              }
            });
            await modal.present();
            const { data } = await modal.onDidDismiss();
            if (data) this.modal.dismiss('Y');
          }
        }
        return true;
      case 3019:
        if (location.href.indexOf('equip-pre-check-nfc') > -1) {
          let alert_1 = this.alert.create({
            subHeader: '해당 중장비의 작업을 종료하시겠습니까?',
            buttons: [
              {text: '아니요'},
              {
                text: '예',
                handler: async () => { this.checkNFC_fail(message); }
              }
            ]
          });
          (await alert_1).present();
        }
        return false;
      default:
        this.connect.error('NFC 정보 전송실패', res);
        return false;
    }
  }

  // 실패시 3019
  async checkNFC_fail(message) {
    const res = await this.connect.run('Check_OutNFCHeavy_Out', {
      nfc_uq_id: message
    }, { loading: '작업종료중...' });
    switch (res.code) {
      case 0:
        console.log(JSON.stringify(res.data));
        return true;
      default:
        this.connect.error('작업종료', res);
        return false;
    }
  }

  // 사용전 장비점검
  async checkEquip(item, message) {
    this.modal.dismiss();
    const res = await this.connect.run('Get_EquipData_Work', {
      nfc_uq_id: message
    });
    switch (res.code) {
      case 0:
        const modal = await this.modal.create({
          component: EquipPreCheckOneComponent,
          componentProps: {
            item: res.data,
            message: message
          }
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
          this.nav.back();
          setTimeout(() => {this.nav.back();}, 200);
        }
        return true;
      default:
        this.connect.error('NFC 정보 전송실패', res);
        return false;
    }
  }

  // 교육 참여
  async checkEdu(message) {
    let item = this.getQueryStringObject();
    const res = await this.connect.run('Get_EduNfcData', {
      edu_target_id: item.edu_target_id,
      edu_nfc_uq_id: message,
      edu_nfc_type: decodeURI(item.edu_nfc_type),
    });
    switch (res.code) {
      case 0:
        // const modal = await this.modal.create({
        //   component: EquipPreCheckOneComponent,
        //   componentProps: { 
        //     item: res.data,
        //     message: message
        //   }
        // });
        // modal.present();
        let toast: any = await this.toast.create({
          message: '교육참여가 완료되었습니다.',
          duration: 1500
        });
        await toast.present();
        return true;
      default:
        this.connect.error('NFC 정보 전송실패', res);
        return false;
    }
  }

  getQueryStringObject() {
    let arr_1 = location.pathname.split(';');
    let arr_string: string = '';
    let res = null;
    for (let i = 0; i < arr_1.length; i++) {
      if (i > 0) {
        if ((i + 1) == arr_1.length) arr_string += `"${arr_1[i].split('=')[0]}":"${arr_1[i].split('=')[1]}"`;
        else arr_string += `"${arr_1[i].split('=')[0]}":"${arr_1[i].split('=')[1]}",`;
      }
    }

    arr_string = `{${arr_string}}`;
    res = JSON.parse(arr_string);

    return res;
  }
}