import { Injectable } from '@angular/core';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { ImgPreviewComponent } from '../app-component/img-preview/img-preview.component';
import { FileService } from './file.service';
import { Plugins, CameraOptions, CameraResultType, CameraSource } from '@capacitor/core';

const { Camera } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  constructor(
    private file: FileService,
    private action: ActionSheetController,
    private modal: ModalController
  ) { }

  async present() {
    let blob = null;
    const options: CameraOptions = {
      quality: 75,
      //allowEditing: true,
      width: 1024,
      height: 1024,
      resultType: CameraResultType.DataUrl
    }
    const action = await this.action.create({
      buttons: [
        {
          text: '이미지 불러오기',
          handler: async() => {
            options.source = CameraSource.Photos;
            const img = await Camera.getPhoto(options);
            //console.log(img);
            blob = this.file.dataURItoBlob(img.dataUrl);
          }
        },
        {
          text: '촬영하기',
          handler: async() => {
            options.source = CameraSource.Camera;
            const img = await Camera.getPhoto(options);
            //console.log(img);
            blob = this.file.dataURItoBlob(img.dataUrl);
          }
        },
        {
          text: '취소',
          role: 'cancel'
        }
      ]
    });
    action.present();
    await action.onDidDismiss();
    return blob;
  }
  async preview(imgs) {
    const modal = await this.modal.create({
      component: ImgPreviewComponent,
      componentProps: {
        item: imgs
      }
    });
    modal.present();
  }
}
