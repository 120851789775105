import { Injectable } from '@angular/core';
import { BluetoothLE, ScanParams, ScanStatus } from '@ionic-native/bluetooth-le/ngx';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { ConnectService } from './connect.service';
import { Subject, Subscription } from 'rxjs';
import { PromiseService } from './promise.service';
import { StorageService } from './storage.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
// import { async } from '@angular/core/testing';

@Injectable({
  providedIn: 'root'
})
export class BluetoothService {

  bicon_rssi = 0;
  bicon_list = [];

  end_res:any;
  end_promise = () => {
    return new Promise(res => {
      this.end_res = res;
    });
  }

  activated:boolean = false;

  $scan = new Subject<ScanStatus>();

  constructor(
    private alert: AlertController,
    private bluetoothle: BluetoothLE,
    private connect: ConnectService,
    private plt: Platform,
    private toast: ToastController,
    private promise: PromiseService,
    private storage: StorageService,
    private androidPermissions: AndroidPermissions
  ) {
    
  }
  // async initBLE(){
  //   await this.requestGPS().then(() => {});
  //   await this.requestPermission().then(() => {}); 

  //   // this.bluetoothle.initialize()

  //   const initializeBluetooth = this.bluetoothle.initialize().subscribe(async(ble) => {
  //     console.log('ble initialize', JSON.stringify(ble));
  //     if(ble.status === 'disabled') await this.bluetoothle.enable();
  //     if(ble.status === 'enabled') {
  //       await this.scanStart();
  //     }
  //     // initializeBluetooth.unsubscribe(); 
  //   });
  // }

  // async scanStart(){
  //   this.bluetoothle.startScan({}).subscribe((res) => {
  //     console.log('test mode - ',JSON.stringify(res));
  //   },async(err) => {
  //     console.error('err - ', JSON.stringify(err));
  //     // await this.scanStart();
  //   });
  // }

  async startScan() {
    console.log('INIT BLUETOOTH');
    await this.getUserBiconRssi();
    await this.initBlueTooth();
    console.log('******************0');
    this.bluetoothScan();
    console.log('******************1');
    return await this.end_promise();
  }
  async getUserBiconRssi() {
    const res = await this.connect.run('Get_UserData',{edit_user_id:this.storage.user.data.user_id});
    switch(res.code) {
      case 0:
        this.bicon_rssi = res.data.bicon_rssi;
        return true;
      default:
        this.connect.error('유저비콘계수실패', res);
        return false;
    }
  }
  async resetBluetooth() {
    console.log('reset bluetooth');
    this.bluetoothle.disable();
    await this.promise.wait(2000);
    this.bluetoothle.enable();
  }
  async initBlueTooth() { 
    let state_1 = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION);
    let state_2 = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION);
    // if(!state_1.hasPermission || !state_2.hasPermission) alert('이 앱은 앱이 종료되었거나 사용 중이 아닐 때도 위치 데이터를 수집하여 백그라운드 모드 기능을 사용 설정합니다');

    await this.requestPermission().then(() => {}); 
    await this.requestGPS().then(() => {});
    return new Promise(res => {
      console.log('ble initialize before');
      const initializeBluetooth = this.bluetoothle.initialize().subscribe(async(ble) => {
        console.log('ble initialize', JSON.stringify(ble));  

        // if(ble.status === 'enabled') await this.resetBluetooth();
        // else this.bluetoothle.enable(); 

        if(ble.status === 'disabled') await this.bluetoothle.enable();

        initializeBluetooth.unsubscribe();
        res(true);
      });
    });
  }
  async requestPermission() {
    return new Promise(async res => { 
      const roofCall = async() => { 
        if(this.plt.is('android')) {
          await this.bluetoothle.requestPermission().then(async(permission) => {
            if(!permission.requestPermission) { 
              const alert = await this.alert.create({
                header: '위치서비스 동의',
                message: '이 어플리케이션을 사용하기 위해서는 위치서비스 동의가 필요합니다. 위치서비스에 동의해주세요.',
                buttons: [ 
                  {text: '확인'}
                ]
              });
              alert.present();
              await alert.onDidDismiss();
              roofCall();
            } else {
              res(true);
            }
          });
        } else {
          res(true);
        }
      }
      roofCall();
    });
  }
  async requestGPS() {
    return new Promise(async res => {
      console.log('isLocationEnabled roof start');
      const roofCall = async() => {
        if(this.plt.is('android')) {
          await this.bluetoothle.isLocationEnabled().then(async(data) => {
            console.log('isLocationEnabled', data);
            if(!data.isLocationEnabled) {
              this.bluetoothle.requestLocation().then(async(data) => {
                console.log('requestLocation', data);
                const alert = await this.alert.create({
                  header: '위치 사용 요청',
                  message: '이 어플리케이션을 사용하기 위해서는 위치기능이 필요합니다. 위치를 켜주세요.',
                  buttons: [
                    {text: '확인'}
                  ]
                });
                alert.present();
                await alert.onDidDismiss();
                roofCall();
              });
            } else {
              res(true);
            }
          });
        } else {
          res(true);
        }
      }
      roofCall();
    });
  }
  // async requestPermissionPop(){
  //   const alert_ctrl = await this.alert.create({
  //     header: '백그라운드 위치서비스 동의',
  //     message: '이 앱은 앱이 종료되었거나 사용 중이 아닐 때도 위치 데이터를 수집하여 백그라운드 모드 기능을 사용 설정합니다',
  //     buttons: [
  //       {text: '확인'}
  //     ]
  //   });
  //   await alert_ctrl.present();
  // }


  bluetoothScan() {
    // this.bluetoothle.isEnabled().then((ena) => {
      // console.log('bluetoothScan flag ---------- ',JSON.stringify(ena));
      // let tesss = [];
      return new Promise(resolv => {
        const roof = () => {
          this.bluetoothle.startScan({}).subscribe((res) => {
            console.log('bluetoothScan - T1 : ',res.name+' + '+res.address);
            console.log('user_id - ', this.storage.user.data.user_id);
            if(!this.storage.user.data.user_id) {
              console.log('scanning 1 ----------');
              // blu_sub_1.unsubscribe();
              // console.log('scanning 1 ----------');
              // await this.bluetoothle.stopScan();
            } else {
              if(res.status === 'scanStarted') {
                console.log('start line 1 ----------');
                // if(this.connect.isiOS()){
                  // setTimeout(async() => {
                    // await blu_sub_1.unsubscribe();
                    // roof();
                    // await this.bluetoothle.stopScan().then(() => {
                      
                    // });
                    
                    // await this.bluetoothScan();
  
                    // blu_sub_1.unsubscribe();
                    // await this.bluetoothScan();
                  // }, 6000);
                // }
                console.log('start line 2 ----------');
              } else if(res.status === 'scanResult') {
                console.log('start line 3 ----------');
                this.activated = true; 
                this.end_res(true);
                if(res.name && (res.name.startsWith('B2') || res.name.startsWith('TK') || res.name.startsWith('MBeacon') || res.name === 'GSIL') ) {
                  // console.log('bluetoothScan : ',JSON.stringify(res));
                  console.log('bluetoothScan - T2: ',res.name+' + '+res.address);
                    this.$scan.next(res);
                    console.log('bluetoothScan - T12: ',res.rssi+' : '+this.bicon_rssi);
                  if(res.rssi >= this.bicon_rssi-20) {
                    console.log('bluetoothScan - T3: ',res.name+' + '+res.address);
                    console.log('bluetoothScan - T0: ',this.bicon_rssi);
                    // console.log('bluetoothScan - T2 : ',res.name+' + '+res.address);
                    const index = this.bicon_list.findIndex(x => x.address === res.address);
                    if(index > -1) {
                      this.bicon_list[index] = res;
                    } else {
                      this.bicon_list.push(res);
                    }
                    this.bluetoothConnect(res); 
                  }
                }
              }
            }

          }, async(err) => {
            console.log('start line 4 ----------', JSON.stringify(err));
            if(err.message === 'Scanning already in progress'){
              console.log('scanning 2 ----------');
              // await blu_sub_1.unsubscribe();
              console.log('scanning 2 ----------');
              this.bluetoothle.stopScan().then((stop_item) => {
                roof();
              });
            } else {
              const alert = await this.alert.create({
                header: '블루투스 스캔 실패',
                message: '블루투스가 스캔에 실패하였습니다. 폰을 재부팅해보시고, 문제가 지속되면 관리자에게 문의해주세요.',
                buttons: [
                  {text: '확인'}
                ]
              });
              alert.present();
              this.end_res(false);
            }
            // if(err.message === 'Scanning already in progress') {
            //   await blu_sub_1.unsubscribe();
            //   roof();
            // } else {
            //   console.log('start line 5 ----------');
            //   this.activated = false;
            //   console.log('false state 2 - ', JSON.stringify(err)); // Application registration failed
            //   this.end_res(false);
            // }
            
          });
          
        }
        roof();
      });
  }

  resetBiconList() {
    this.bicon_list = [];
  }
  strongest_bluetooth:any = { rssi: -100, address: '' };
  updateDisable:boolean = false;
  async bluetoothConnect(bluetooth) {
    console.log('bluetoothScan - T4: ',this.strongest_bluetooth.address);
    console.log('bluetoothScan - T5: ',JSON.stringify(bluetooth));
    console.log('bluetoothScan - T6: ',bluetooth.rssi+' : '+this.strongest_bluetooth.rssi);
    console.log('bluetoothScan - T7: ',bluetooth.rssi > this.strongest_bluetooth.rssi);
    //가장 강한 비콘을 계속해서 갱신한다.
    //3초마다 가장 강한 비콘 신호 1개를 올리는 것이 목표

    
    if(bluetooth.rssi > this.strongest_bluetooth.rssi) this.strongest_bluetooth = bluetooth;
    console.log('bluetoothScan - T8: ',JSON.stringify(this.strongest_bluetooth));

    if(!this.strongest_bluetooth.address) return;
    console.log('bluetoothScan - T9: ',JSON.stringify(this.strongest_bluetooth));
    console.log('bluetoothScan - T10: ',this.updateDisable);
    if(this.updateDisable) return; 
    //3초에 한번씩만 업데이트를 할 것이기 때문에, 신호가 한번들어오면 통신끝난 후 3초동안 통신을 막는다.
    this.updateDisable = true;

    //통신
    let test:any = await this.connect.run('Get_UserBicon', { // Insert_EnterBicon
      bicon_uq_id: this.strongest_bluetooth.address,
      rssi: this.strongest_bluetooth.rssi
    });
    console.log('bluetoothScan - T11: ',JSON.stringify(test));
    console.log(JSON.stringify(test));
    this.strongest_bluetooth = { rssi: -100, address: '' };


    // this.updateDisable = false;
    setTimeout(async() => {
      //끝나고 3초 후 다시 통신을 활성화 한다.
      this.strongest_bluetooth = { rssi: -100, address: '' };
      this.updateDisable = false;
    }, 1500);
  }
}
// 6E400001-B5A3-F393-E0A9-E50E24DCCA9E
// D0363C37-6DBA-9621-DFD3-A8427827EB81

// {
//   "status":"scanResult",
//   "advertisement":{
//     "serviceUuids":[
//       "6E400001-B5A3-F393-E0A9-E50E24DCCA9E"
//     ],
//     "manufacturerData":"zasRX556RAADAPsAPgAy",
//     "overflowServiceUuids":[],
//     "isConnectable":0,
//     "solicitedServiceUuids":[],
//     "serviceData":{},
//     "localName":"GSIL"
//   },
//   "rssi":-46,
//   "name":"GSIL",
//   "address":"D0363C37-6DBA-9621-DFD3-A8427827EB81"
// }