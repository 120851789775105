import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BluetoothService } from 'src/app/service/bluetooth.service';
import { ConnectService } from 'src/app/service/connect.service';

@Component({
  selector: 'app-bicon-rssi',
  templateUrl: './bicon-rssi.component.html',
  styleUrls: ['./bicon-rssi.component.scss']
})
export class BiconRssiComponent implements OnInit {

  @Input() editable:boolean = false;

  cash = {
    bicon_rssi: 0
  }

  constructor(
    public bluetooth: BluetoothService,
    private connect: ConnectService,
    private modal: ModalController, 
    private changeDetector: ChangeDetectorRef
  ) { }

  async ionViewWillEnter() {
    const res = await this.bluetooth.getUserBiconRssi();
    this.cash.bicon_rssi = this.bluetooth.bicon_rssi;
    this.changeDetector.detectChanges();
  }
  ionViewWillLeave() {
    this.bluetooth.bicon_rssi = this.cash.bicon_rssi;
  }
  ngOnInit() {}
  refresh() {
    // this.bluetooth.$scan.subscribe(() => {
    //   //sdakaskdfpoasdfkapodf
    // });
    this.bluetooth.resetBiconList();
  }
  minus() {
    if(this.bluetooth.bicon_rssi > -100) {
      this.bluetooth.bicon_rssi--;
    } else {
      this.bluetooth.bicon_rssi = -100;
    }
    this.bluetooth.resetBiconList();
  }
  plus() {
    if(this.bluetooth.bicon_rssi < 0) {
      this.bluetooth.bicon_rssi++;
    } else {
      this.bluetooth.bicon_rssi = 0;
    }
    this.bluetooth.resetBiconList();
  }
  async submit() {
    const res = await this.connect.run('Insert_User_BiconRssi', {
      bicon_rssi: this.bluetooth.bicon_rssi
    }, {loading: '신호세기 민감도 저장'});
    switch(res.code) {
      case 0:
        this.cash.bicon_rssi = this.bluetooth.bicon_rssi;
        this.modal.dismiss();
        break;
      default:
        this.connect.error('신호세기 민감도 저장 실패', res);
        break;
    }
  }
}
