import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, AlertController, LoadingController, ToastController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CameraService } from 'src/app/service/camera.service';
import { ConnectService } from 'src/app/service/connect.service';
import { FileService } from 'src/app/service/file.service';

@Component({
  selector: 'app-equip-pre-check-two',
  templateUrl: './equip-pre-check-two.component.html',
  styleUrls: ['./equip-pre-check-two.component.scss'],
})
export class EquipPreCheckTwoComponent implements OnInit {
  @Input('item') item = {
    user_id: 0, // user_id			
    equip_id: 0, // equip_id			
    company_id: 0, // 업체ID			
    rental_company_name: '', // 임대업체			
    equip_type: '', // 장비대분류			
    equip_sub_type: '', // 장비소분류			
    equip_regist_no: '', // 등록번호			
    equip_area_standard: '', // 규격			
    equip_start_date: '', // 검사일			
    equip_end_date: '', // 검사일			
    insur_start_date: '', // 보험일			
    insur_end_date: '', // 보험일			
    equip_user_id: '', // 운전원			
    equip_user_phone: '', // 연락처			
    equip_license_no: '', // 면허번호			
    equip_sub_user_id: 0, // 운전원 교대			
    reg_date: '', // 등록일			
    equip_user_name: '', // 운전원 명			
    equip_sub_user_name: '', // 교대 운전원 명			
    equip_file_data: [], // [{"seq_no":1,"equip_file":"http://54.180.53.189/kunyoung/Data/Daily/Safty/2/2131@@!![]}{)(!@!~`-복사본.txt.jpg","order_no":1},{"seq_no":2,"equip_file":"http://54.180.53.189/kunyoung/Data/Daily/Safty/2/2131@@!![]}{)(!@!~`-복사본.txt1.jpg","order_no":2},{"seq_no":3,"equip_file":"http://54.180.53.189/kunyoung/Data/Daily/Safty/2/2131@@!![]}{)(!@!~`-복사본.txt2.jpg","order_no":3}]			
    equip_question_data: {
      question_data: [],
      ctgo_question_data: []
    }, // {"question_data":[1,2],"ctgo_question_data":[{"ctgo_equip_question_id":1,"ctgo_equip_question_file":"http://54.180.53.189/kunyoung/Data/Equip/Question/c01_1.jpg","ctgo_equip_question_name":"버켓상태 확인 및 퀵커플러 안전핀을 사용하고 있는가?"},{"ctgo_equip_question_id":2,"ctgo_equip_question_file":"http://54.180.53.189/kunyoung/Data/Equip/Question/c01_2.png","ctgo_equip_question_name":"후크해지장치는 설치되어 있으며, 안전하게 사용될 수 있는가?"}]}			
    company_name: '', // 업체명			
    today_inspection: false, // true - 오늘 사용전점검함			
  };

  @Input('message') message:string = '';
  @Input('dis_modal') dis_modal:any;

  form:any = {
    equip_id: 0,
    nfc_uq_id: 0, // 
    equip_question_answer_data: {
      answer_data: []
    }, // order_no는 해당하는 이미지의 order_no와 맞춰주세요. {"ctgo_equip_question_id":1, "inspection_state":"양호", "inspection_comment":null,"order_no":0}
    inspection_file: [], // 이미지리스트
    inspection_json: {file:[]} // {"file":[{"update_type":"INSERT", "order_no":"1"},{"update_type":"INSERT", "order_no":"2"},{"update_type":"INSERT", "order_no":"3"}]}
  }

  index_num:number = 0;

  fail_data:any = {
    text: '',
    pic: '',
    open_state: false
  }

  pic = '';

  constructor(
    private _modal_2: ModalController,
    private alert: AlertController,
    private router: Router,
    private camera: CameraService,
    private connect: ConnectService,
    private loading: LoadingController,
    private file: FileService,
    private changeDetector: ChangeDetectorRef,
    private toast: ToastController,
    private plt: Platform
  ) {}

  ngOnInit() {
    this.form.equip_id = this.item.equip_id;
    this.form.nfc_uq_id = this.message;
    console.log(this.dis_modal);
  }
  back_sub:Subscription;
  ionViewWillEnter() {
    this.back_sub = this.plt.backButton.subscribeWithPriority(10, async() => {
      let alert = await this.alert.create({
        header: '알림',
        subHeader: '사용전 점검을 완료해주셔야합니다.',
        backdropDismiss: false,
        buttons: [{text: '확인',role: 'cancel',}]
      });
      alert.present();
    });
  }
  ionViewWillLeave() {
    this.back_sub.unsubscribe();
  }
  async submit() {
    // Insert_UserEquipInspection
    const res = await this.connect.run('Insert_UserEquipInspection', this.form, {loading: '점검 완료중...'});
    switch(res.code) {
      case 0:
        let toast_1:any = await this.toast.create({
          message: '점검이 완료되었습니다.',
          duration: 1500
        });
        await toast_1.present();
        this.router.navigate[("/employee-app/main")];
        this.dis_modal.dismiss('Y').then(() => {
          this._modal_2.dismiss('Y');
        });
        return;
      default:
        this.connect.error('점검완료 실패', res);
        return;
    }
  }

  nextBtn_success(){
    this.resetFail();

    this.form.equip_question_answer_data.answer_data.push({
      ctgo_equip_question_id: this.item.equip_question_data.ctgo_question_data[this.index_num].ctgo_equip_question_id,
      inspection_state: '양호',
      inspection_comment: '',
      order_no: this.index_num+1
    });
    this.index_num++;
  }

  async nextBtn_fail(){
    this.form.equip_question_answer_data.answer_data.push({
      ctgo_equip_question_id: this.item.equip_question_data.ctgo_question_data[this.index_num].ctgo_equip_question_id,
      inspection_state: '불량',
      inspection_comment: this.fail_data.text,
      order_no: this.index_num+1
    });
    this.form.inspection_file.push(this.fail_data.pic);
    this.form.inspection_json.file.push({update_type:'INSERT', order_no: this.index_num+1});

    this.index_num++;
    this.resetFail();
  }

  nextBtn_nothing(){
    this.resetFail();
    
    this.form.equip_question_answer_data.answer_data.push({
      ctgo_equip_question_id: this.item.equip_question_data.ctgo_question_data[this.index_num].ctgo_equip_question_id,
      inspection_state: '해당없음',
      inspection_comment: '',
      order_no: this.index_num+1
    });
    this.index_num++;
  }

  dismiss() {
    this._modal_2.dismiss();
  }

  async openImage(event, name) {
    event.stopPropagation();
    const blob = await this.camera.present();
    this.fail_data[name] = blob;
    this[name] = this.file.createObjectURL(blob);
  }

  resetFail(){
    this.fail_data = {
      text: '',
      pic: '',
      open_state: false
    }
    this.pic = '';
  }
}
