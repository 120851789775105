import { Injectable } from '@angular/core';
import * as base64js from 'base64-js';
declare const pdfjsLib;
// declare const alasql:any;
import * as XLSX from '@sheet/image';
import { DateService } from './date.service';
import { ConnectService } from './connect.service';


// import base64js from 'base64-js';
// declare const pdfjsLib;
// import * as XLSX from '@sheet/image';
// import { DateService } from './date.service';
// import { map } from 'rxjs/operators';
import { NumberService } from './number.service';
import { Ra_List_Item, Ra_Risk, Risk, Risk_Assessment } from '../page/admin-web/interface';
// import { ConnectService } from './connect.service';
// import { Ra_List_Item, Ra_Risk, Risk, Risk_Assessment } from '../page/admin-web/interface';


export interface File_Item {
  selected:boolean,
  file: File,
  name: string,
  url: string,
  seq_no?: number
}
export class File_Json {
  constructor(
    _update_type: 'INSERT' | 'DELETE',
    _order_no:number,
    _seq_no:number
  ) {
    this.update_type = _update_type;
    this.order_no = _order_no;
    this.seq_no = _seq_no;
  }
  update_type = "INSERT";
  order_no = 0;
  seq_no = 0;
}

export type Excel_Data = Array<Sheet_Data>;
export interface Sheet_Data {
  name: string,
  data: Array<Array<{
    text?: string | number,
    rowspan?: number,
    colspan?: number,
    style?: Sheet_style
  }>>
};
export interface Sheet_style {
  color?: CSSStyleDeclaration["color"],
  background?: CSSStyleDeclaration["background"],
  backgroundColor?: CSSStyleDeclaration["backgroundColor"],
  textAlign?: CSSStyleDeclaration["textAlign"],
  border?: CSSStyleDeclaration["border"],
  borderTop?: CSSStyleDeclaration["borderTop"],
  borderRight?: CSSStyleDeclaration["borderRight"],
  borderLeft?: CSSStyleDeclaration["borderLeft"],
  borderBottom?: CSSStyleDeclaration["borderBottom"],
  verticalAlign?: CSSStyleDeclaration["verticalAlign"],
  width?: number | 'auto',
  height?: number | 'auto',
  fontSize?: number,
  fontWeight?: 'normal' | 'bold'
}
@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private date: DateService,
    private number: NumberService,
    private connect: ConnectService
  ) { }

  createObjectURL(blob:File | Blob) {
    return (window.URL ? URL : window['webkitURL']).createObjectURL(blob);
  }
  dataURItoBlob(dataURI) {
    //console.log(dataURI);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = base64js.toByteArray(dataURI.split(',')[1]);
    var blob: any = new Blob([ab], {
      type: mimeString
    });
    blob.lastModifiedDate = new Date();
    return blob;
  }
  checkMimeType(url:string, type = 'img') {
    const mimeType = url.substring(url.lastIndexOf('.'), url.length);
    switch(type) {
      case 'img':
        return mimeType === '.png' 
        || mimeType === '.jpeg' 
        || mimeType === '.jpg' 
        || mimeType === '.gif'
        || mimeType === '.svg';
    }
  }

  async getPdfDoc(pdf_url) {
    const doc = await pdfjsLib.getDocument({url: pdf_url});
    return doc;
  }
  getPdfPage(pdf_doc, page_num):Promise<string> {
    return new Promise(async(res) => {
      const page = await pdf_doc.getPage(page_num);
      const viewport = page.getViewport(1);
      const c = document.createElement('canvas');
      c.height = viewport.height;
      c.width = viewport.width;
      const render_contenxt = {
        canvasContext: c.getContext('2d'),
        viewport
      }
      await page.render(render_contenxt);
      c.toBlob(blob => {
        res(this.createObjectURL(blob));
        c.remove();
      });
    });
  }

  readExcel(event):Promise<any[]> {
    return new Promise((res, rej) => {
      const file = event.target.files[0];
      var reader = new FileReader();
  
      reader.onload = function(e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, {
          type: 'binary',
          cellStyles: true
        });
        console.log(workbook);
        const xlsx_data = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
        const arr = xlsx_data.split('\n');
        let rows = [];
        arr.map((item, i) => {
          const row = item.split(',');
          let new_row = [];
          let quotes_start = false; 
          row.map((col:string) => {
            if(!quotes_start) {
              new_row.push(col);
            } else {
              new_row[new_row.length-1] += col;
            }
            if(col.startsWith('"')) {
              quotes_start = true;
              new_row[new_row.length-1] = new_row[new_row.length-1].substring(1, new_row[new_row.length-1].length);
            }
            if(col.endsWith('"')) {
              quotes_start = false;
              new_row[new_row.length-1] = new_row[new_row.length-1].substring(0, new_row[new_row.length-1].length-1);
            }
          });
          rows.push(new_row);
        });
        res(rows);
      };
  
      reader.onerror = (ex) => {
        console.log(ex);
        rej(ex);
      };
  
      reader.readAsBinaryString(file);
    });
  }
  downloadExcel(url, filename, data_arr:any[] = [], start_number:number = 1) {
    return new Promise(async(res) => {
      var req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        var data = new Uint8Array(req.response);
        
        var workbook = XLSX.read(data, {type:"array", cellStyles: true});
        const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG'];
        const ref = `A1:${alphabet[data_arr[0].length-1]}${start_number + data_arr.length-1}`;
        data_arr.map((row, i) => {
          row.map((v, j) => {
            const key = alphabet[j] + String(start_number + i);
            const value = v !== null ? v : '';
            workbook.Sheets[workbook.SheetNames[0]][key] = {
              t: "s", v: value, r: `<t>${value}</t>`, h: value, w: value
            }
          });
        });
        workbook.Sheets[workbook.SheetNames[0]]['!ref'] = ref;
        console.log(workbook.Sheets[workbook.SheetNames[0]]);
        XLSX.writeFile(workbook, filename);
        res(e);
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }
  makeExcel(excel:Excel_Data, name) {
    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: name,
      Subject: name,
      Author: "Dev.monter",
      CreatedDate: new Date()
    };

    const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI'];
    
    excel.forEach(sheet => {
      //each sheet

      let sheet_width = 0;
      const sheet_height = sheet.data.length;
      const ws = {};
      let merge_data:Array<{s:{c:number, r:number}, e:{c:number, r:number}}> = [];
      sheet.data.forEach((row, ri) => {
        const cur_ri = ri;
        let cur_ci = -1;
        row.forEach((col) => {
          cur_ci++;
          for(let mi = 0; mi < merge_data.length; mi++) {
            const merge = merge_data[mi];
            if(
               (merge.s.c <= cur_ci && cur_ci <= merge.e.c)
            && (merge.s.r <= cur_ri && cur_ri <= merge.e.r)
            ) {
              cur_ci += merge.e.c - cur_ci + 1;
            }
          }
          const cr = alphabet[cur_ci] + String(cur_ri+1);
          if(!ws[cr]) ws[cr] = { t: "s", v: col.text || '', s: {} };
          for(let styleKey in col.style) {
            switch(styleKey) {
              case 'backgroundColor':
                ws[cr].s["fgColor"] = { rgb: col.style[styleKey].replace('#', '') }
                break;
              case 'border':
                const border_arr = col.style[styleKey].split(' ');
                let borderWidth = '';
                let borderColor = border_arr[2].replace('#', '');
                if(border_arr[0] === '1px') {
                  borderWidth = 'thin';
                } else if(border_arr[0] === '2px') {
                  borderWidth = 'medium';
                } else {
                  borderWidth = 'thick';
                }
                ws[cr].s["top"] = { style: borderWidth, color: { rgb: borderColor } };
                ws[cr].s["bottom"] = { style: borderWidth, color: { rgb: borderColor } };
                ws[cr].s["left"] = { style: borderWidth, color: { rgb: borderColor } };
                ws[cr].s["right"] = { style: borderWidth, color: { rgb: borderColor } };
                for(let x = 1; x < col.colspan; x++) {
                  const merged_cr = alphabet[cur_ci + x] + String(cur_ri+1);
                  if(!ws[merged_cr]) ws[merged_cr] = { t: "s", s: {} };
                  ws[merged_cr].s["top"] = { style: borderWidth, color: { rgb: borderColor } };
                  ws[merged_cr].s["bottom"] = { style: borderWidth, color: { rgb: borderColor } };
                  ws[merged_cr].s["left"] = { style: borderWidth, color: { rgb: borderColor } };
                  ws[merged_cr].s["right"] = { style: borderWidth, color: { rgb: borderColor } };
                }
                for(let y = 1; y < col.rowspan; y++) {
                  const merged_cr = alphabet[cur_ci] + String(cur_ri + 1 + y);
                  if(!ws[merged_cr]) ws[merged_cr] = { t: "s", s: {} };
                  ws[merged_cr].s["top"] = { style: borderWidth, color: { rgb: borderColor } };
                  ws[merged_cr].s["bottom"] = { style: borderWidth, color: { rgb: borderColor } };
                  ws[merged_cr].s["left"] = { style: borderWidth, color: { rgb: borderColor } };
                  ws[merged_cr].s["right"] = { style: borderWidth, color: { rgb: borderColor } };
                }
                break;
              case 'borderTop':
              case 'borderLeft':
              case 'borderBottom':
              case 'borderRight':
                let side = '';
                if(styleKey === 'borderTop') side = 'top';
                else if(styleKey === 'borderLeft') side = 'left';
                else if(styleKey === 'borderBottom') side = 'bottom';
                else if(styleKey === 'borderRight') side = 'right';
                const borderSide_arr = col.style[styleKey].split(' ');
                let borderSideWidth = '';
                let borderSideColor = borderSide_arr[2].replace('#', '');
                if(borderSide_arr[0] === '1px') {
                  borderSideWidth = 'thin';
                } else if(borderSide_arr[0] === '2px') {
                  borderSideWidth = 'medium';
                } else {
                  borderSideWidth = 'thick';
                }
                ws[cr].s[side] = { style: borderSideWidth, color: { rgb: borderSideColor } };
                if(styleKey === 'borderTop' || styleKey === 'borderBottom') {
                  for(let x = 1; x < col.colspan; x++) {
                    const merged_cr = alphabet[cur_ci + x] + String(cur_ri+1);
                    if(!ws[merged_cr]) ws[merged_cr] = { t: "s", s: {}, v: "undefinded", z: "General" };
                    ws[merged_cr].s[side] = { style: borderSideWidth, color: { rgb: borderSideColor } };
                  }
                }
                if(styleKey === 'borderLeft' || styleKey === 'borderRight') {
                  for(let y = 1; y < col.rowspan; y++) {
                    const merged_cr = alphabet[cur_ci] + String(cur_ri + 1 + y);
                    if(!ws[merged_cr]) ws[merged_cr] = { t: "s", s: {}, v: "undefinded", z: "General" };
                    ws[merged_cr].s[side] = { style: borderSideWidth, color: { rgb: borderSideColor } };
                  }
                }
                break;
              case 'textAlign':
                if(!ws[cr].s["alignment"]) ws[cr].s["alignment"] = {};
                ws[cr].s["alignment"]["horizontal"] = col.style[styleKey];
                break;
              case 'verticalAlign':
                if(!ws[cr].s["alignment"]) ws[cr].s["alignment"] = {};
                switch(col.style[styleKey]) {
                  case 'middle':
                    ws[cr].s["alignment"]["vertical"] = "center";
                    break;
                  default:
                    ws[cr].s["alignment"]["vertical"] = col.style[styleKey];
                    break;
                }
                break;
              case 'width':
                if(!ws['!cols']) ws['!cols'] = [];
                if(typeof col.style[styleKey] === 'number') ws['!cols'][cur_ci] = { width: col.style[styleKey] };
                else if(col.style[styleKey] === 'auto') ws['!cols'][cur_ci] = { auto: 1 };
                break;
              case 'height':
                if(!ws['!rows']) ws['!rows'] = [];
                if(typeof col.style[styleKey] === 'number') ws['!rows'][cur_ri] = { height: col.style[styleKey] };
                else if(col.style[styleKey] === 'auto') ws['!rows'][cur_ri] = { auto: 1 };
                break;
              case 'fontSize':
                ws[cr].s.sz = col.style[styleKey];
                break;
              case 'fontWeight':
                if(col.style[styleKey] === 'bold') {
                  ws[cr].s.bold = 1;
                } else {}
                break;
            }
          }
          if(col.colspan || col.rowspan) {
            const s = { c: cur_ci, r: cur_ri };
            const e = { 
              c: col.colspan ? cur_ci + col.colspan - 1 : cur_ci,
              r: col.rowspan ? cur_ri + col.rowspan - 1 : cur_ri
            }
            merge_data.push({ s, e });
          }
        });
        if(sheet_width < cur_ci) sheet_width = cur_ci;
      });
      const ref = `A1:${alphabet[sheet_width]}${sheet_height}`;
      ws['!ref'] = ref;
      ws['!merges'] = merge_data;
      console.log(ws);
      wb.SheetNames.push(sheet.name);
      wb.Sheets[sheet.name] = ws;
    });
    
    XLSX.writeFile(wb, name + ".xlsx", { cellStyles: true, bookSST: false });
  }
  testExcel(url, filename) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        console.log(workbook);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        // console.log(ws['!images']);
        // ws['!images'].push({
        //   "!pos": { x: 100, y: 100, w: 300, h: 300 },
        //   "!datatype": "binary",
        //   "!data": ws['!images'][0]['!data']
        // });
        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true, bookSST: true});
        // res(ws['!images'][0]['!data']);
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 위험성평가서 엑셀 다운로드
  ex_download_1(url, filename, item) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        const data = new Uint8Array(req.response);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        const ws = workbook.Sheets[workbook.SheetNames[0]];

        let obj:any = item.risk_assessment.risk_assessment_construction;
        

        // 현장명
        ws.B1.t = 's';
        ws.B1.v = this.connect.area;
        ws.B1.r = '<t>'+this.connect.area+'</t>';
        ws.B1.h = this.connect.area;
        ws.B1.w = this.connect.area;
        ws.B1.s.sz = 10;

        // 협력업체
        ws.B3.t = 's';
        ws.B3.v = item.company_name;
        ws.B3.r = '<t>'+item.company_name+'</t>';
        ws.B3.h = item.company_name;
        ws.B3.w = item.company_name;
        ws.B3.s.sz = 10;
        
        // 작업기간
        ws.B5.t = 's';
        ws.B5.v = item.ra_start_date+'~'+item.ra_end_date;
        ws.B5.r = '<t>'+item.ra_start_date+'~'+item.ra_end_date+'</t>';
        ws.B5.h = item.ra_start_date+'~'+item.ra_end_date;;
        ws.B5.w = item.ra_start_date+'~'+item.ra_end_date;;
        ws.B5.s.sz = 10;
        
        
        // 공종
        ws.B7.t = 's';
        ws.B7.v = item.construction_name;
        ws.B7.r = '<t>'+item.construction_name+'</t>';
        ws.B7.h = item.construction_name;
        ws.B7.w = item.construction_name;
        ws.B7.s.sz = 10;

        let length_flag:number = 10;
        let merge_obj:any = [];

        let option_default:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 10,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }
        // 아이템 넣기
        console.log(obj);
        for(let i = 0; i < obj.length; i++){
          // 아이템 - merge: s(시작),e(끝) r(세로), c(가로)
          if(obj.length > 1){
            merge_obj.push({s: {r: length_flag, c: 0}, e: {r: length_flag+obj[i].risk_assessment_factor.length-1, c: 0}});
            merge_obj.push({s: {r: length_flag, c: 1}, e: {r: length_flag+obj[i].risk_assessment_factor.length-1, c: 1}});
            merge_obj.push({s: {r: length_flag, c: 2}, e: {r: length_flag+obj[i].risk_assessment_factor.length-1, c: 2}});
            merge_obj.push({s: {r: length_flag, c: 3}, e: {r: length_flag+obj[i].risk_assessment_factor.length-1, c: 3}});
            merge_obj.push({s: {r: length_flag, c: 4}, e: {r: length_flag+obj[i].risk_assessment_factor.length-1, c: 4}});
            merge_obj.push({s: {r: length_flag, c: 5}, e: {r: length_flag+obj[i].risk_assessment_factor.length-1, c: 5}});
          }

          // console.log(res.item_list[i]);
          for(let x = 0; x < obj[i].risk_assessment_factor.length; x++){
            // 데이터 7 

            // 앞에 병합되는 데이터
            ws[`A${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].ra_construction_name,w: obj[i].ra_construction_name,h: obj[i].ra_construction_name,r: '<t>'+obj[i].ra_construction_name+'</t>',z: "General"};
            ws[`B${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].ra_workstep,w: obj[i].ra_workstep,h: obj[i].ra_workstep,r: '<t>'+obj[i].ra_workstep+'</t>',z: "General"};
            ws[`C${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].ra_equipment,w: obj[i].ra_equipment,h: obj[i].ra_equipment,r: '<t>'+obj[i].ra_equipment+'</t>',z: "General"};
            ws[`D${length_flag+1}`] = {s: option_default,t: "s",v: (obj[i].ra_work_start_date+'~'+obj[i].ra_work_end_date),w: (obj[i].ra_work_start_date+'~'+obj[i].ra_work_end_date),h: (obj[i].ra_work_start_date+'~'+obj[i].ra_work_end_date),r: '<t>'+(obj[i].ra_work_start_date+'~'+obj[i].ra_work_end_date)+'</t>',z: "General"};
            ws[`E${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].ra_work_area ? obj[i].ra_work_area : obj[i].ra_work_area_detail,w: obj[i].ra_work_area ? obj[i].ra_work_area : obj[i].ra_work_area_detail,h: obj[i].ra_work_area ? obj[i].ra_work_area : obj[i].ra_work_area_detail,r: '<t>'+obj[i].ra_work_area ? obj[i].ra_work_area : obj[i].ra_work_area_detail+'</t>',z: "General"};
            ws[`F${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].ra_worker_no,w: obj[i].ra_worker_no,h: obj[i].ra_worker_no,r: '<t>'+obj[i].ra_worker_no+'</t>',z: "General"};

            // 데이터 안에 데이터
            ws[`G${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].risk_assessment_factor[x].ra_risk_factor,w: obj[i].risk_assessment_factor[x].ra_risk_factor,h: obj[i].risk_assessment_factor[x].ra_risk_factor,r: '<t>'+obj[i].risk_assessment_factor[x].ra_risk_factor+'</t>',z: "General"};
            ws[`H${length_flag+1}`] = {s: option_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
            ws[`I${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].risk_assessment_factor[x].ra_damage_form,w: obj[i].risk_assessment_factor[x].ra_damage_form,h: obj[i].risk_assessment_factor[x].ra_damage_form,r: '<t>'+obj[i].risk_assessment_factor[x].ra_damage_form+'</t>',z: "General"};
            ws[`J${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].risk_assessment_factor[x].ra_frequency,w: obj[i].risk_assessment_factor[x].ra_frequency,h: obj[i].risk_assessment_factor[x].ra_frequency,r: '<t>'+obj[i].risk_assessment_factor[x].ra_frequency+'</t>',z: "General"};
            ws[`K${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].risk_assessment_factor[x].ra_strength,w: obj[i].risk_assessment_factor[x].ra_strength,h: obj[i].risk_assessment_factor[x].ra_strength,r: '<t>'+obj[i].risk_assessment_factor[x].ra_strength+'</t>',z: "General"};
            ws[`L${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].risk_assessment_factor[x].ra_rating,w: obj[i].risk_assessment_factor[x].ra_rating,h: obj[i].risk_assessment_factor[x].ra_rating,r: '<t>'+obj[i].risk_assessment_factor[x].ra_rating+'</t>',z: "General"};

            ws[`M${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].risk_assessment_factor[x].ra_management_method,w: obj[i].risk_assessment_factor[x].ra_management_method,h: obj[i].risk_assessment_factor[x].ra_management_method,r: '<t>'+obj[i].risk_assessment_factor[x].ra_management_method+'</t>',z: "General"};
            ws[`N${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].risk_assessment_factor[x].ra_management_method,w: obj[i].risk_assessment_factor[x].ra_management_method,h: obj[i].risk_assessment_factor[x].ra_management_method,r: '<t>'+obj[i].risk_assessment_factor[x].ra_management_method+'</t>',z: "General"};
            ws[`O${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].risk_assessment_factor[x].ra_management_method,w: obj[i].risk_assessment_factor[x].ra_management_method,h: obj[i].risk_assessment_factor[x].ra_management_method,r: '<t>'+obj[i].risk_assessment_factor[x].ra_management_method+'</t>',z: "General"};

            // 맨뒤에칸 병합 M ~ O
            // merge: s(시작),e(끝) r(세로), c(가로)
            merge_obj.push({s: {r: length_flag+1, c: 12}, e: {r: length_flag+1, c: 14}});

            length_flag++;
          }
        }

        // 셀 범위지정
        const ref = `A1:O${length_flag}`;
        ws['!ref'] = ref;
        ws['!merges'] = ws['!merges'].concat(merge_obj);
        
        let theme_col:any = [
          {width: 11.1640625, customwidth: "1", wpx: 78, wch: 10.43, MDW: 7},
          {width: 14.5, customwidth: "1", wpx: 101, wch: 13.71, MDW: 7},
          {width: 10.1640625, customwidth: "1", wpx: 71, wch: 9.43, MDW: 7},
          {width: 11.5, customwidth: "1", wpx: 80, wch: 10.71, MDW: 7},
          {width: 8.6640625, customwidth: "1", wpx: 61, wch: 8, MDW: 7},
          {width: 5.6640625, customwidth: "1", wpx: 40, wch: 5, MDW: 7},
          {width: 26, customwidth: "1", wpx: 182, wch: 25.29, MDW: 7},
          {width: 6.5, customwidth: "1", wpx: 45, wch: 5.71, MDW: 7},
          {width: 5.1640625, customwidth: "1", wpx: 36, wch: 4.43, MDW: 7},
          {width: 4.33203125, customwidth: "1", wpx: 30, wch: 3.57, MDW: 7},
          {width: 4.33203125, customwidth: "1", wpx: 30, wch: 3.57, MDW: 7},
          {width: 4.33203125, customwidth: "1", wpx: 30, wch: 3.57, MDW: 7},
          {width: 11.1640625, customwidth: "1", wpx: 78, wch: 10.43, MDW: 7},
          {width: 11.1640625, customwidth: "1", wpx: 78, wch: 10.43, MDW: 7},
          {width: 11.1640625, customwidth: "1", wpx: 78, wch: 10.43, MDW: 7},
          {width: 8.83203125, customwidth: "1", wpx: 62, wch: 8.14, MDW: 7}
        ]
        let theme_row:any = [];
        // {hpt: 42.5, hpx: 56.666666666666664}
        for(let i = 0; i < (length_flag-7); i++){
          theme_row.push({hpt: 42.5, hpx: 56.666666666666664});
        }

        // 넓이지정
        ws['!cols'] = theme_col;

        // 높이지정
        ws['!rows'] = ws['!rows'].concat(theme_row);

        // 이미지 크기 및 위치
        // r: 2, c: 9, x: 0, y: 0, w: 104, h: 44 - 현장소장
        // r: 2, c: 12, x: 0, y: 0, w: 90, h: 44 - 공사 책임자
        // r: 2, c: 13, x: 0, y: 0, w: 90, h: 44 - 현장소장
        // r: 2, c: 13, x: 0, y: 0, w: 90, h: 44 - 안전관리자

        // let img_data:any = this.createObjectURL('');
        // let img_data:any = this.UrlBuffer();
        
        // 이미지 넣기 - assets/img/theme-sign.png
        // ws['!images'] = [];
        // ws['!images'].push({
        //   "!pos": { r: 2, c: 9, x: 0, y: 0, w: 104, h: 44},
        //   "!datatype": "remote",
        //   "!link": "https://w7.pngwing.com/pngs/959/325/png-transparent-autograph-encapsulated-postscript-signature-miscellaneous-angle-text.png"
        // });

        const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU'];
        let duty_arr:any = item.approval_user_data.risk_assessment_approval;
        let length_flag_10:number = 9;
        let length_flag_11:number = 9;
        ws['!images'] = [];
        let option_duty:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 12,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        // for(let i = 0; i < duty_arr.length; i++){
          // ws[`${alphabet[length_flag_10]}2`] = {s: option_duty,t: "s",v: duty_arr[i].company_duty_name,w: duty_arr[i].company_duty_name,h: duty_arr[i].company_duty_name,r: '<t>'+duty_arr[i].company_duty_name+'</t>',z: "General"};
          // if(duty_arr[i].user_sign_file){
          //   ws['!images'].push({
          //     "!pos": { r: 2, c: length_flag_10, x: 0, y: 0, w: 104, h: 44},
          //     "!datatype": "remote",
          //     "!link": duty_arr[i].user_sign_file
          //   });
          // }

        //   length_flag_10 = length_flag_10+3;
        // }

        for(let i = 0; i < duty_arr.length; i++){
          if(i < 4){
            // ws[`${alphabet[length_flag_10]}1`] = {s: option_duty,t: "s",v: 'test_'+i,w: 'test_'+i,h: 'test_'+i,r: '<t>'+'test_'+i+'</t>',z: "General"};
            // ws['!images'].push({
            //   "!pos": { r: 1, c: length_flag_10, x: 0, y: 0, w: 88, h: 66},
            //   "!datatype": "remote",
            //   "!link": 'https://w7.pngwing.com/pngs/959/325/png-transparent-autograph-encapsulated-postscript-signature-miscellaneous-angle-text.png'
            // });

            console.log(duty_arr[i]);
            ws[`${alphabet[length_flag_10]}1`] = {s: option_duty,t: "s",v: duty_arr[i].company_duty_name,w: duty_arr[i].company_duty_name,h: duty_arr[i].company_duty_name,r: '<t>'+duty_arr[i].company_duty_name+'</t>',z: "General"};
            if(duty_arr[i].user_sign_file){
              ws['!images'].push({
                "!pos": { r: 1, c: length_flag_10, x: 0, y: 0, w: 88, h: 66},
                "!datatype": "remote",
                "!link": duty_arr[i].user_sign_file
              });
            }
  
            if(!i) length_flag_10 = length_flag_10+3;
            else length_flag_10++;
          } else {
            // ws[`${alphabet[length_flag_11]}5`] = {s: option_duty,t: "s",v: 'test_'+i,w: 'test_'+i,h: 'test_'+i,r: '<t>'+'test_'+i+'</t>',z: "General"};
            // ws['!images'].push({
            //   "!pos": { r: 5, c: length_flag_11, x: 0, y: 0, w: 88, h: 66},
            //   "!datatype": "remote",
            //   "!link": 'https://w7.pngwing.com/pngs/959/325/png-transparent-autograph-encapsulated-postscript-signature-miscellaneous-angle-text.png'
            // });

            ws[`${alphabet[length_flag_11]}5`] = {s: option_duty,t: "s",v: duty_arr[i].company_duty_name,w: duty_arr[i].company_duty_name,h: duty_arr[i].company_duty_name,r: '<t>'+duty_arr[i].company_duty_name+'</t>',z: "General"};
            if(duty_arr[i].user_sign_file){
              ws['!images'].push({
                "!pos": { r: 5, c: length_flag_11, x: 0, y: 0, w: 88, h: 66},
                "!datatype": "remote",
                "!link": duty_arr[i].user_sign_file
              });
            }
  
            if(i == 4) length_flag_11 = length_flag_11+3;
            else length_flag_11++;
          }
        }


        // https://w7.pngwing.com/pngs/959/325/png-transparent-autograph-encapsulated-postscript-signature-miscellaneous-angle-text.png
        // console.log(workbook);
        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true, bookSST: true});
        // res(ws['!images'][0]['!data']);
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 안전일일점검 엑셀 다운로드
  ex_download_2(url, filename, item) {
    console.log(item);
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        // console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        // console.log(workbook);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        // console.log(ws['!images']);
        // console.log(ws);

        

        // merge: s(시작),e(끝) r(세로), c(가로)
        let merge_obj:any = [];
        merge_obj.push({s: {r: 31, c: 1}, e: {r: 36, c: 4}});
        ws['!merges'] = ws['!merges'].concat(merge_obj);

        // 현장명
        ws.C5.t = 's';
        ws.C5.v = this.connect.area;
        ws.C5.r = '<t>'+this.connect.area+'</t>';
        ws.C5.h = this.connect.area;
        ws.C5.w = this.connect.area;
        ws.C5.s.sz = 11;

        // 점검일자
        ws.C6.t = 's';
        ws.C6.v = item.ds_date;
        ws.C6.r = '<t>'+item.ds_date+'</t>';
        ws.C6.h = item.ds_date;
        ws.C6.w = item.ds_date;
        ws.C6.s.sz = 11;
        
        // 금일 근로자 출역인원
        ws.E6.t = 's';
        ws.E6.v = item.ds_worker_no+'명';
        ws.E6.r = '<t>'+item.ds_worker_no+'명</t>';
        ws.E6.h = item.ds_worker_no+'명';
        ws.E6.w = item.ds_worker_no+'명';
        ws.E6.s.sz = 11;

        // 비고
        ws.B32.s.alignment = {vertical: "center", horizontal: "center", wrapText: true};
        ws.B32.t = 's';
        ws.B32.v = item.ds_etc;
        ws.B32.r = '<t>'+item.ds_etc+'</t>';
        ws.B32.h = item.ds_etc;
        ws.B32.w = item.ds_etc;
        ws.B32.s.sz = 11;
        
        // 이미지 넣기 - assets/img/theme-sign.png
        // 사진 한개 - 600 > 나머지 300
        if(item.ds_file_data){
          ws['!images'] = [];

          switch(item.ds_file_data.length){
            case 1:
              // for(let i = 0; i < item.ds_file_data.length; i++){
              ws['!images'].push({
                "!pos": { r: 10, c: 1, x: 0, y: 0, w: 670, h: 685},
                "!datatype": "remote",
                "!link": item.ds_file_data[0].ds_file
              });
              // }
              break;
            case 2:
              // for(let i = 0; i < item.ds_file_data.length; i++){
              ws['!images'].push({
                "!pos": { r: 10, c: 1, x: 0, y: 0, w: 300, h: 300},
                "!datatype": "remote",
                "!link": item.ds_file_data[0].ds_file
              });
              ws['!images'].push({
                "!pos": { r: 10, c: 3, x: 0, y: 0, w: 300, h: 300},
                "!datatype": "remote",
                "!link": item.ds_file_data[1].ds_file
              });
              // }
              break;
            case 3:
              ws['!images'].push({
                "!pos": { r: 10, c: 1, x: 0, y: 0, w: 300, h: 300},
                "!datatype": "remote",
                "!link": item.ds_file_data[0].ds_file
              });
              ws['!images'].push({
                "!pos": { r: 10, c: 3, x: 0, y: 0, w: 300, h: 300},
                "!datatype": "remote",
                "!link": item.ds_file_data[1].ds_file
              });
              ws['!images'].push({
                "!pos": { r: 19, c: 1, x: 0, y: 0, w: 300, h: 300},
                "!datatype": "remote",
                "!link": item.ds_file_data[2].ds_file
              });
              break;
            case 4:
              ws['!images'].push({
                "!pos": { r: 10, c: 1, x: 0, y: 0, w: 300, h: 300},
                "!datatype": "remote",
                "!link": item.ds_file_data[0].ds_file
              });
              ws['!images'].push({
                "!pos": { r: 10, c: 3, x: 0, y: 0, w: 300, h: 300},
                "!datatype": "remote",
                "!link": item.ds_file_data[1].ds_file
              });
              ws['!images'].push({
                "!pos": { r: 19, c: 1, x: 0, y: 0, w: 300, h: 300},
                "!datatype": "remote",
                "!link": item.ds_file_data[2].ds_file
              });
              ws['!images'].push({
                "!pos": { r: 19, c: 3, x: 0, y: 0, w: 300, h: 300},
                "!datatype": "remote",
                "!link": item.ds_file_data[3].ds_file
              });
              break;
            default:
              for(let i = 0; i < item.ds_file_data.length; i++){
                ws['!images'].push({
                  "!pos": { r: 10, c: 1, x: 0, y: 0, w: 300, h: 300},
                  "!datatype": "remote",
                  "!link": item.ds_file_data[i].ds_file
                });
              }
              break;
          }
        }

        // https://w7.pngwing.com/pngs/959/325/png-transparent-autograph-encapsulated-postscript-signature-miscellaneous-angle-text.png
        // console.log(workbook);
        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true, bookSST: true});
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  ra_list:Array<Ra_Risk> = [];
  // 작업허가서 엑셀 다운로드
  async ex_download_3(url, filename, item) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = async(e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        // console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        // console.log(workbook);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        // console.log(form_item);
        // console.log(item);
        // let day_state = new Date(form_item.search_year,form_item.search_month,0).getDate()

        let checker = [];

        /** @param dp_eq: 중장비 */
        let dp_eq = item.dp_equipment;

        /** @param approval: 결재선 */
        let approval = [];
        if(item.approval_user_data) if(item.approval_user_data.dp_approval.length) approval =item.approval_user_data.dp_approval;

        /** @param today_data: 금일 작업사항 데이터 */
        let today_data = [];
        if(item.today_data) if(item.today_data.daily_plan_today.length) today_data =item.today_data.daily_plan_today;

        //위험성평가 
        if(item.ra_ids && item.ra_ids.length) {
          const ra_await_list = item.ra_ids.map(ra_id => this.getRaData(ra_id));
          const ra_list = await Promise.all(ra_await_list);
          this.parseRaList({ ra_list });
        }
        console.log(this.ra_list)

        /** @param theme_checker: 더미 작업책임자 */
        let theme_checker:string = '';

        if(item.checker_users){
          if(item.checker_users.checker_user_data.length){
            checker = item.checker_users.checker_user_data;
            if(checker.length){
              for(let i = 0; i < checker.length; i++){
                if((i+1) == checker.length) theme_checker += checker[i].user_name;
                else theme_checker += checker[i].user_name+',';
              }
            }
          }
        }

        // 작성자
        ws.D2.t = 's';
        ws.D2.v = item.user_name;
        ws.D2.r = `<t>${item.user_name}</t>`;
        ws.D2.h = item.user_name;
        ws.D2.w = item.user_name;
        ws.D2.s.sz = 11;

        // 작성일자
        ws.D4.t = 's';
        ws.D4.v = item.reg_date;
        ws.D4.r = `<t>${item.reg_date}</t>`;
        ws.D4.h = item.reg_date;
        ws.D4.w = item.reg_date;
        ws.D4.s.sz = 11;

        // 작업책임자
        ws.D6.t = 's';
        ws.D6.v = theme_checker;
        ws.D6.r = `<t>${theme_checker}</t>`;
        ws.D6.h = theme_checker;
        ws.D6.w = theme_checker;
        ws.D6.s.sz = 11;

        // 작업업체
        ws.D8.t = 's';
        ws.D8.v = item.company_name;
        ws.D8.r = `<t>${item.company_name}</t>`;
        ws.D8.h = item.company_name;
        ws.D8.w = item.company_name;
        ws.D8.s.sz = 11;

        // 공사명
        ws.F11.t = 's';
        ws.F11.v = this.connect.area;
        ws.F11.r = `<t>`+this.connect.area+`</t>`;
        ws.F11.h = this.connect.area;
        ws.F11.w = this.connect.area;
        ws.F11.s.sz = 11;

        // 작업일자
        ws.F12.t = 's';
        ws.F12.v = item.dp_date+' ~ '+item.dp_end_date;
        ws.F12.r = `<t>${item.dp_date+' ~ '+item.dp_end_date}</t>`;
        ws.F12.h = item.dp_date+' ~ '+item.dp_end_date;
        ws.F12.w = item.dp_date+' ~ '+item.dp_end_date;
        ws.F12.s.sz = 11;

        // 공종
        ws.A16.t = 's';
        ws.A16.v = item.dp_construction_name ? item.dp_construction_name : item.dp_construction_name_etc;
        ws.A16.r = `<t>${item.dp_construction_name ? item.dp_construction_name : item.dp_construction_name}</t>`;
        ws.A16.h = item.dp_construction_name ? item.dp_construction_name : item.dp_construction_name;
        ws.A16.w = item.dp_construction_name ? item.dp_construction_name : item.dp_construction_name;
        ws.A16.s.sz = 11;

        // 작업위치
        ws.G16.t = 's';
        ws.G16.v = item.dp_work_area;
        ws.G16.r = `<t>${item.dp_work_area}</t>`;
        ws.G16.h = item.dp_work_area;
        ws.G16.w = item.dp_work_area;
        ws.G16.s.sz = 11;

        // 작업인원
        ws.M16.t = 's';
        ws.M16.v = item.dp_worker_no;
        ws.M16.r = `<t>${item.dp_worker_no}</t>`;
        ws.M16.h = item.dp_worker_no;
        ws.M16.w = item.dp_worker_no;
        ws.M16.s.sz = 11;


        // 디폴트 아이템 스타일.
        let style_default:any = {
          alignment: {vertical: "center", horizontal: "center"},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "solid",
          fgColor: { rgb: 'E7E6E6' },
          sz: 11,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        // 디폴트 아이템 스타일. - 색 없음
        let style_default_noColor:any = {
          alignment: {vertical: "center", horizontal: "center"},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 11,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }


        // 맨뒤에칸 병합 M ~ O
        // merge: s(시작),e(끝) r(세로), c(가로)
        let merge_obj = [];

        // 병합
        let merge_index = 17;
        let merge_index_1 = 17;
        const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'];
        if(dp_eq.length){
          merge_obj.push({s: {r: merge_index, c: 0}, e: {r: merge_index, c: 17}});
          alphabet.map((alphpa) => {ws[`${alphpa}${merge_index+1}`] = {s: style_default,t: "s",v: '중 장 비',w: '중 장 비',h: '중 장 비',r: '중 장 비',z: "General"};});
          merge_index++;
          merge_index_1++;
        }

        // 0~11 ,  12~17
        dp_eq.map((item) => {
          merge_obj.push({s: {r: merge_index, c: 0}, e: {r: merge_index, c: 11}});
          merge_obj.push({s: {r: merge_index, c: 12}, e: {r: merge_index, c: 17}});
          alphabet.map((alphpa) => {ws[`A${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.text,w: item.text,h: item.text,r: item.text,z: "General"};});
          alphabet.map((alphpa) => {ws[`B${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.text,w: item.text,h: item.text,r: item.text,z: "General"};});
          alphabet.map((alphpa) => {ws[`C${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.text,w: item.text,h: item.text,r: item.text,z: "General"};});
          alphabet.map((alphpa) => {ws[`D${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.text,w: item.text,h: item.text,r: item.text,z: "General"};});
          alphabet.map((alphpa) => {ws[`E${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.text,w: item.text,h: item.text,r: item.text,z: "General"};});
          alphabet.map((alphpa) => {ws[`F${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.text,w: item.text,h: item.text,r: item.text,z: "General"};});
          alphabet.map((alphpa) => {ws[`G${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.text,w: item.text,h: item.text,r: item.text,z: "General"};});
          alphabet.map((alphpa) => {ws[`H${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.text,w: item.text,h: item.text,r: item.text,z: "General"};});
          alphabet.map((alphpa) => {ws[`I${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.text,w: item.text,h: item.text,r: item.text,z: "General"};});
          alphabet.map((alphpa) => {ws[`J${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.text,w: item.text,h: item.text,r: item.text,z: "General"};});
          alphabet.map((alphpa) => {ws[`K${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.text,w: item.text,h: item.text,r: item.text,z: "General"};});
          alphabet.map((alphpa) => {ws[`L${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.text,w: item.text,h: item.text,r: item.text,z: "General"};});
          alphabet.map((alphpa) => {ws[`M${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.count,w: item.count,h: item.count,r: item.count,z: "General"};});
          alphabet.map((alphpa) => {ws[`N${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.count,w: item.count,h: item.count,r: item.count,z: "General"};});
          alphabet.map((alphpa) => {ws[`O${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.count,w: item.count,h: item.count,r: item.count,z: "General"};});
          alphabet.map((alphpa) => {ws[`P${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.count,w: item.count,h: item.count,r: item.count,z: "General"};});
          alphabet.map((alphpa) => {ws[`Q${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.count,w: item.count,h: item.count,r: item.count,z: "General"};});
          alphabet.map((alphpa) => {ws[`R${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.count,w: item.count,h: item.count,r: item.count,z: "General"};});
          merge_index++;
          merge_index_1++;
        });

        merge_index++;

        if(this.ra_list.length){
          merge_obj.push({s: {r: merge_index, c: 0}, e: {r: merge_index, c: 17}});
          alphabet.map((alphpa) => {ws[`${alphpa}${merge_index+1}`] = {s: style_default,t: "s",v: '위 험 성 평 가 서',w: '위 험 성 평 가 서',h: '위 험 성 평 가 서',r: '위 험 성 평 가 서',z: "General"};});
          merge_index++;
          merge_index_1++;
        }
        
        this.ra_list.map((item) => {
          merge_obj.push({s: {r: merge_index, c: 0}, e: {r: merge_index, c: 3}});
          merge_obj.push({s: {r: merge_index, c: 4}, e: {r: merge_index, c: 6}});
          merge_obj.push({s: {r: merge_index, c: 7}, e: {r: merge_index, c: 9}});
          merge_obj.push({s: {r: merge_index, c: 10}, e: {r: merge_index, c: 13}});
          merge_obj.push({s: {r: merge_index, c: 14}, e: {r: merge_index, c: 17}});
          alphabet.map((alphpa) => {ws[`A${merge_index+1}`] = {s: style_default,t: "s",v: '공종',w: '공종',h: '공종',r: '공종',z: "General"};});
          alphabet.map((alphpa) => {ws[`B${merge_index+1}`] = {s: style_default,t: "s",v: '공종',w: '공종',h: '공종',r: '공종',z: "General"};});
          alphabet.map((alphpa) => {ws[`C${merge_index+1}`] = {s: style_default,t: "s",v: '공종',w: '공종',h: '공종',r: '공종',z: "General"};});
          alphabet.map((alphpa) => {ws[`D${merge_index+1}`] = {s: style_default,t: "s",v: '공종',w: '공종',h: '공종',r: '공종',z: "General"};});
          alphabet.map((alphpa) => {ws[`E${merge_index+1}`] = {s: style_default,t: "s",v: '세부공종',w: '세부공종',h: '세부공종',r: '세부공종',z: "General"};});
          alphabet.map((alphpa) => {ws[`F${merge_index+1}`] = {s: style_default,t: "s",v: '세부공종',w: '세부공종',h: '세부공종',r: '세부공종',z: "General"};});
          alphabet.map((alphpa) => {ws[`G${merge_index+1}`] = {s: style_default,t: "s",v: '세부공종',w: '세부공종',h: '세부공종',r: '세부공종',z: "General"};});
          alphabet.map((alphpa) => {ws[`H${merge_index+1}`] = {s: style_default,t: "s",v: '중장비',w: '중장비',h: '중장비',r: '중장비',z: "General"};});
          alphabet.map((alphpa) => {ws[`I${merge_index+1}`] = {s: style_default,t: "s",v: '중장비',w: '중장비',h: '중장비',r: '중장비',z: "General"};});
          alphabet.map((alphpa) => {ws[`J${merge_index+1}`] = {s: style_default,t: "s",v: '중장비',w: '중장비',h: '중장비',r: '중장비',z: "General"};});
          alphabet.map((alphpa) => {ws[`K${merge_index+1}`] = {s: style_default,t: "s",v: '작업위치',w: '작업위치',h: '작업위치',r: '작업위치',z: "General"};});
          alphabet.map((alphpa) => {ws[`L${merge_index+1}`] = {s: style_default,t: "s",v: '작업위치',w: '작업위치',h: '작업위치',r: '작업위치',z: "General"};});
          alphabet.map((alphpa) => {ws[`M${merge_index+1}`] = {s: style_default,t: "s",v: '작업위치',w: '작업위치',h: '작업위치',r: '작업위치',z: "General"};});
          alphabet.map((alphpa) => {ws[`N${merge_index+1}`] = {s: style_default,t: "s",v: '작업위치',w: '작업위치',h: '작업위치',r: '작업위치',z: "General"};});
          alphabet.map((alphpa) => {ws[`O${merge_index+1}`] = {s: style_default,t: "s",v: '작업인원',w: '작업인원',h: '작업인원',r: '작업인원',z: "General"};});
          alphabet.map((alphpa) => {ws[`P${merge_index+1}`] = {s: style_default,t: "s",v: '작업인원',w: '작업인원',h: '작업인원',r: '작업인원',z: "General"};});
          alphabet.map((alphpa) => {ws[`Q${merge_index+1}`] = {s: style_default,t: "s",v: '작업인원',w: '작업인원',h: '작업인원',r: '작업인원',z: "General"};});
          alphabet.map((alphpa) => {ws[`R${merge_index+1}`] = {s: style_default,t: "s",v: '작업인원',w: '작업인원',h: '작업인원',r: '작업인원',z: "General"};});
          merge_index++;
          // console.log('1111:',this.get_rowspan_ra(item));
          
          merge_obj.push({s: {r: merge_index, c: 0}, e: {r: merge_index+this.get_rowspan_ra(item)-1, c: 3}});
          // alphabet.map((alphpa) => {ws[`A${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.construction_name,w: item.construction_name,h: item.construction_name,r: item.construction_name,z: "General"};});
          // alphabet.map((alphpa) => {ws[`B${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.construction_name,w: item.construction_name,h: item.construction_name,r: item.construction_name,z: "General"};});
          // alphabet.map((alphpa) => {ws[`C${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.construction_name,w: item.construction_name,h: item.construction_name,r: item.construction_name,z: "General"};});
          // alphabet.map((alphpa) => {ws[`D${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.construction_name,w: item.construction_name,h: item.construction_name,r: item.construction_name,z: "General"};});

          let theme_length = 0;
          item.risk.map((item_1) => {
            // console.log('2222:',this.get_rowspan_risk(item_1));
            merge_obj.push({s: {r: merge_index, c: 4}, e: {r: merge_index+this.get_rowspan_risk(item_1)-1, c: 6}});

            let theme_length_1 = 0;
            item_1.risk_assessment.map((item_2) => {
              merge_obj.push({s: {r: merge_index, c: 7}, e: {r: merge_index, c: 9}});
              alphabet.map((alphpa) => {ws[`H${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item_2.ra_equipment,w: item_2.ra_equipment,h: item_2.ra_equipment,r: item_2.ra_equipment,z: "General"};});
              alphabet.map((alphpa) => {ws[`I${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item_2.ra_equipment,w: item_2.ra_equipment,h: item_2.ra_equipment,r: item_2.ra_equipment,z: "General"};});
              alphabet.map((alphpa) => {ws[`J${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item_2.ra_equipment,w: item_2.ra_equipment,h: item_2.ra_equipment,r: item_2.ra_equipment,z: "General"};});
              merge_obj.push({s: {r: merge_index, c: 10}, e: {r: merge_index, c: 13}});
              alphabet.map((alphpa) => {ws[`K${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item_2.ra_work_area+' / '+item_2.ra_work_area_detail,w: item_2.ra_work_area+' / '+item_2.ra_work_area_detail,h: item_2.ra_work_area+' / '+item_2.ra_work_area_detail,r: item_2.ra_work_area+' / '+item_2.ra_work_area_detail,z: "General"};});
              alphabet.map((alphpa) => {ws[`L${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item_2.ra_work_area+' / '+item_2.ra_work_area_detail,w: item_2.ra_work_area+' / '+item_2.ra_work_area_detail,h: item_2.ra_work_area+' / '+item_2.ra_work_area_detail,r: item_2.ra_work_area+' / '+item_2.ra_work_area_detail,z: "General"};});
              alphabet.map((alphpa) => {ws[`M${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item_2.ra_work_area+' / '+item_2.ra_work_area_detail,w: item_2.ra_work_area+' / '+item_2.ra_work_area_detail,h: item_2.ra_work_area+' / '+item_2.ra_work_area_detail,r: item_2.ra_work_area+' / '+item_2.ra_work_area_detail,z: "General"};});
              alphabet.map((alphpa) => {ws[`N${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item_2.ra_work_area+' / '+item_2.ra_work_area_detail,w: item_2.ra_work_area+' / '+item_2.ra_work_area_detail,h: item_2.ra_work_area+' / '+item_2.ra_work_area_detail,r: item_2.ra_work_area+' / '+item_2.ra_work_area_detail,z: "General"};});
              merge_obj.push({s: {r: merge_index, c: 14}, e: {r: merge_index, c: 17}});
              alphabet.map((alphpa) => {ws[`O${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item_2.ra_worker_no,w: item_2.ra_worker_no,h: item_2.ra_worker_no,r: item_2.ra_worker_no,z: "General"};});
              alphabet.map((alphpa) => {ws[`P${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item_2.ra_worker_no,w: item_2.ra_worker_no,h: item_2.ra_worker_no,r: item_2.ra_worker_no,z: "General"};});
              alphabet.map((alphpa) => {ws[`Q${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item_2.ra_worker_no,w: item_2.ra_worker_no,h: item_2.ra_worker_no,r: item_2.ra_worker_no,z: "General"};});
              alphabet.map((alphpa) => {ws[`R${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item_2.ra_worker_no,w: item_2.ra_worker_no,h: item_2.ra_worker_no,r: item_2.ra_worker_no,z: "General"};});
              merge_index++;
              theme_length++;
              theme_length_1++;
            });

            for(let i = (merge_index-theme_length_1); i < merge_index; i++){
              alphabet.map((alphpa) => {ws[`E${i+1}`] = {s: style_default_noColor,t: "s",v: item_1.ra_construction_name,w: item_1.ra_construction_name,h: item_1.ra_construction_name,r: item_1.ra_construction_name,z: "General"};});
              alphabet.map((alphpa) => {ws[`F${i+1}`] = {s: style_default_noColor,t: "s",v: item_1.ra_construction_name,w: item_1.ra_construction_name,h: item_1.ra_construction_name,r: item_1.ra_construction_name,z: "General"};});
              alphabet.map((alphpa) => {ws[`G${i+1}`] = {s: style_default_noColor,t: "s",v: item_1.ra_construction_name,w: item_1.ra_construction_name,h: item_1.ra_construction_name,r: item_1.ra_construction_name,z: "General"};});
            }

            merge_index_1+=this.get_rowspan_risk(item_1);
          });

          for(let i = (merge_index-theme_length); i < merge_index; i++){
            alphabet.map((alphpa) => {ws[`A${i+1}`] = {s: style_default_noColor,t: "s",v: item.construction_name,w: item.construction_name,h: item.construction_name,r: item.construction_name,z: "General"};});
            alphabet.map((alphpa) => {ws[`B${i+1}`] = {s: style_default_noColor,t: "s",v: item.construction_name,w: item.construction_name,h: item.construction_name,r: item.construction_name,z: "General"};});
            alphabet.map((alphpa) => {ws[`C${i+1}`] = {s: style_default_noColor,t: "s",v: item.construction_name,w: item.construction_name,h: item.construction_name,r: item.construction_name,z: "General"};});
            alphabet.map((alphpa) => {ws[`D${i+1}`] = {s: style_default_noColor,t: "s",v: item.construction_name,w: item.construction_name,h: item.construction_name,r: item.construction_name,z: "General"};});
          }
        });

        merge_index++;
        if(today_data.length){
          merge_obj.push({s: {r: merge_index, c: 0}, e: {r: merge_index, c: 17}});
          merge_obj.push({s: {r: merge_index, c: 0}, e: {r: merge_index, c: 5}});
          merge_obj.push({s: {r: merge_index, c: 6}, e: {r: merge_index, c: 17}});
          alphabet.map((alphpa) => {ws[`${alphpa}${merge_index+1}`] = {s: style_default,t: "s",v: '금일작업 사항',w: '금일작업 사항',h: '금일작업 사항',r: '금일작업 사항',z: "General"};});
          merge_index++;
        }

        // today_data
        today_data.map((item) => {
          merge_obj.push({s: {r: merge_index, c: 0}, e: {r: merge_index, c: 5}});
          merge_obj.push({s: {r: merge_index, c: 6}, e: {r: merge_index, c: 17}});
          alphabet.map((alphpa) => {ws[`A${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.occupation_name ? item.occupation_name : item.occupation_name_etc,w: item.occupation_name ? item.occupation_name : item.occupation_name_etc,h: item.occupation_name ? item.occupation_name : item.occupation_name_etc,r: item.occupation_name ? item.occupation_name : item.occupation_name_etc,z: "General"};});
          alphabet.map((alphpa) => {ws[`B${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.occupation_name ? item.occupation_name : item.occupation_name_etc,w: item.occupation_name ? item.occupation_name : item.occupation_name_etc,h: item.occupation_name ? item.occupation_name : item.occupation_name_etc,r: item.occupation_name ? item.occupation_name : item.occupation_name_etc,z: "General"};});
          alphabet.map((alphpa) => {ws[`C${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.occupation_name ? item.occupation_name : item.occupation_name_etc,w: item.occupation_name ? item.occupation_name : item.occupation_name_etc,h: item.occupation_name ? item.occupation_name : item.occupation_name_etc,r: item.occupation_name ? item.occupation_name : item.occupation_name_etc,z: "General"};});
          alphabet.map((alphpa) => {ws[`D${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.occupation_name ? item.occupation_name : item.occupation_name_etc,w: item.occupation_name ? item.occupation_name : item.occupation_name_etc,h: item.occupation_name ? item.occupation_name : item.occupation_name_etc,r: item.occupation_name ? item.occupation_name : item.occupation_name_etc,z: "General"};});
          alphabet.map((alphpa) => {ws[`E${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.occupation_name ? item.occupation_name : item.occupation_name_etc,w: item.occupation_name ? item.occupation_name : item.occupation_name_etc,h: item.occupation_name ? item.occupation_name : item.occupation_name_etc,r: item.occupation_name ? item.occupation_name : item.occupation_name_etc,z: "General"};});
          alphabet.map((alphpa) => {ws[`F${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.occupation_name ? item.occupation_name : item.occupation_name_etc,w: item.occupation_name ? item.occupation_name : item.occupation_name_etc,h: item.occupation_name ? item.occupation_name : item.occupation_name_etc,r: item.occupation_name ? item.occupation_name : item.occupation_name_etc,z: "General"};});
          alphabet.map((alphpa) => {ws[`G${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.today_text,w: item.today_text,h: item.today_text,r: item.today_text,z: "General"};});
          alphabet.map((alphpa) => {ws[`H${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.today_text,w: item.today_text,h: item.today_text,r: item.today_text,z: "General"};});
          alphabet.map((alphpa) => {ws[`I${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.today_text,w: item.today_text,h: item.today_text,r: item.today_text,z: "General"};});
          alphabet.map((alphpa) => {ws[`J${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.today_text,w: item.today_text,h: item.today_text,r: item.today_text,z: "General"};});
          alphabet.map((alphpa) => {ws[`K${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.today_text,w: item.today_text,h: item.today_text,r: item.today_text,z: "General"};});
          alphabet.map((alphpa) => {ws[`L${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.today_text,w: item.today_text,h: item.today_text,r: item.today_text,z: "General"};});
          alphabet.map((alphpa) => {ws[`M${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.today_text,w: item.today_text,h: item.today_text,r: item.today_text,z: "General"};});
          alphabet.map((alphpa) => {ws[`N${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.today_text,w: item.today_text,h: item.today_text,r: item.today_text,z: "General"};});
          alphabet.map((alphpa) => {ws[`O${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.today_text,w: item.today_text,h: item.today_text,r: item.today_text,z: "General"};});
          alphabet.map((alphpa) => {ws[`P${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.today_text,w: item.today_text,h: item.today_text,r: item.today_text,z: "General"};});
          alphabet.map((alphpa) => {ws[`Q${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.today_text,w: item.today_text,h: item.today_text,r: item.today_text,z: "General"};});
          alphabet.map((alphpa) => {ws[`R${merge_index+1}`] = {s: style_default_noColor,t: "s",v: item.today_text,w: item.today_text,h: item.today_text,r: item.today_text,z: "General"};});
          merge_index++;
        });

        merge_index++;
        // 비고
        merge_obj.push({s: {r: merge_index, c: 0}, e: {r: merge_index, c: 17}});
        alphabet.map((alphpa) => {ws[`${alphpa}${merge_index+1}`] = {s: style_default,t: "s",v: '비고',w: '비고',h: '비고',r: '비고',z: "General"};});
        merge_index++;
        merge_obj.push({s: {r: merge_index, c: 0}, e: {r: merge_index+5, c: 17}});
        for(let i = 0; i < 6; i++) alphabet.map((alphpa) => {ws[`${alphpa}${merge_index+1+i}`] = {s: style_default_noColor,t: "s",v: item.dp_etc,w: item.dp_etc,h: item.dp_etc,r: item.dp_etc,z: "General"};});
        merge_index+=5;
  
        ws['!merges'] = ws['!merges'].concat(merge_obj);

        // // 스타일링
        // 1. 높이 맞추기
        ws['!rows'].splice(9,merge_index+1); // 원래 있던 높이 제거
        for(let i = 9; i < merge_index+1; i++){
          ws['!rows'].push({hpt:40, hpx:40});
        }

        // 4. A4 크기로 영역 지정하기 - s(시작),e(끝) r(세로), c(가로)
        // 가로 0 ~ 27까지, 세로 0 ~ 갯수만큼 - 위에있는 merge_index를 사용하면 됨
        ws['!print'].area = {s:{r:0,c:0},e:{r:merge_index+2,c:17}};

        // 결재선
        // if(res.user_sign_file){
        //   ws['!images'].push({
        //     "!pos": { r: image_index, c: 27, x: 0, y: 0, w: 80, h: 50},
        //     "!datatype": "remote",
        //     "!link": res.user_sign_file
        //   });
        // }

        // let duty_arr:any = item.approval_user_data.risk_assessment_approval;
        let length_flag_10:number = 13;
        let length_flag_11:number = 13;
        ws['!images'] = [];

        for(let i = 0; i < approval.length; i++){
          if(i < 4){
            ws[`${alphabet[length_flag_10]}2`].t = 's';
            ws[`${alphabet[length_flag_10]}2`].v = approval[i].user_name;
            ws[`${alphabet[length_flag_10]}2`].r = `<t>${approval[i].user_name}</t>`;
            ws[`${alphabet[length_flag_10]}2`].h = approval[i].user_name;
            ws[`${alphabet[length_flag_10]}2`].w = approval[i].user_name;
            ws[`${alphabet[length_flag_10]}2`].s.sz = 11;

            if(approval[i].approval_file){
              ws['!images'].push({
                "!pos": { r: 2, c: length_flag_10, x: 0, y: 0, w: 77, h: 60},
                "!datatype": "remote",
                "!link": approval[i].approval_file
              });
            }
  
            length_flag_10++;
          } else {
            ws[`${alphabet[length_flag_11]}6`].t = 's';
            ws[`${alphabet[length_flag_11]}6`].v = approval[i].user_name;
            ws[`${alphabet[length_flag_11]}6`].r = `<t>${approval[i].user_name}</t>`;
            ws[`${alphabet[length_flag_11]}6`].h = approval[i].user_name;
            ws[`${alphabet[length_flag_11]}6`].w = approval[i].user_name;
            ws[`${alphabet[length_flag_11]}6`].s.sz = 11;

            if(approval[i].approval_file){
              ws['!images'].push({
                "!pos": { r: 6, c: length_flag_11, x: 0, y: 0, w: 77, h: 60},
                "!datatype": "remote",
                "!link": approval[i].approval_file
              });
            }
  
            length_flag_11++;
          }
        }

        const ref = `A1:R${merge_index+2}`;
        ws['!ref'] = ref;


        console.log(ws);
        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }
  //작업허가서 - 데이터 가져오기
  async getRaData(ra_id) {
    const res = await this.connect.run('Get_RiskAssessmentData', {
      ra_id
    });
    switch(res.code) {
      case 0:
        return res.data;
      default:
        return null;
    }
  }
  //작업허가서 - 테이블 로우스폰
  get_rowspan_ra(ra_item:Ra_Risk) {
    let assessment_count = 0;
    ra_item.risk.forEach(risk_item => {
      assessment_count += risk_item.risk_assessment.length;
    });
    return assessment_count;
  }
  get_rowspan_risk(risk_item:Risk) {
    return risk_item.risk_assessment.length;
  }
  parseRaList(data) {
    let list:Ra_List_Item[] = data.ra_list;
    const ra_list = list.map(risk => {
      let ra_risk:Ra_Risk = new Ra_Risk();
      ra_risk.ra_id = risk.ra_id;
      ra_risk.construction_name = risk.construction_name;
      if(risk.risk_assessment){
        if(risk.risk_assessment.risk_assessment_construction.length) {
          risk.risk_assessment.risk_assessment_construction.forEach(item => {
            if(isNaN(item.ra_equipment_no) || Number(item.ra_equipment_no) > 99) {
              item.ra_equipment_no_manual = item.ra_equipment_no;
              item.ra_equipment_no = '직접입력';
            }
            if(isNaN(item.ra_worker_no) || Number(item.ra_worker_no) > 99) {
              item.ra_worker_manual = item.ra_worker_no;
              item.ra_worker_no = '직접입력';
            }
            if(!isNaN(item.ra_equipment_no) || Number(item.ra_equipment_no) < 100) item.ra_equipment_no = String(item.ra_equipment_no);
          });
        }
      }
      const risk_assessment:Risk_Assessment[] = risk.risk_assessment.risk_assessment_construction;
      risk_assessment.forEach(assessment => {
        assessment.risk_assessment_factor.forEach(factor => {
          factor.ra_admin_comment_visible = factor.ra_admin_comment ? true : false
        });
        const exist_risk = ra_risk.risk.find(x => x.code_level_two === assessment.code_level_two);
        if(!exist_risk) ra_risk.risk.push({
          code_level_one: assessment.code_level_one,
          code_level_two: assessment.code_level_two,
          ra_construction_name: assessment.ra_construction_name,
          risk_assessment: [assessment]
        });
        else exist_risk.risk_assessment.push(assessment);
      });
      return ra_risk;
    });
    console.log('ra_list', ra_list);
    this.ra_list = ra_list;
  }


  // 신규채용자 안전교육 이수 확인서 엑셀 다운로드
  ex_download_4(url, filename, item, state) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        console.log(workbook);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        // console.log(ws['!images']);
        console.log(ws);

        // merge: s(시작),e(끝) r(세로), c(가로)
        // let merge_obj:any = [];
        // merge_obj.push({s: {r: 13, c: 1}, e: {r: 18, c: 4}});
        // ws['!merges'] = ws['!merges'].concat(merge_obj);

        if(state){
          // 교육 이수시간 및 성명
          ws.D22.t = 's';
          ws.D22.v = `(교육시간: 1시간 , 강사 성명: ${item.edu_education_worker})`;
          ws.D22.r = `<t>(교육시간: 1시간 , 강사 성명: ${item.edu_education_worker})</t>`;
          ws.D22.h = `(교육시간: 1시간 , 강사 성명: ${item.edu_education_worker})`;
          ws.D22.w = `(교육시간: 1시간 , 강사 성명: ${item.edu_education_worker})`;
          ws.D22.s.sz = 11;
          
          // 서약일
          ws.E33.t = 's';
          ws.E33.v = item.edu_date;
          ws.E33.r = '<t>'+item.edu_date+'</t>';
          ws.E33.h = item.edu_date;
          ws.E33.w = item.edu_date;
          ws.E33.s.sz = 11;

          // 성명 밑 인
          ws.F35.t = 's';
          ws.F35.v = item.edu_education_worker+' (인)';
          ws.F35.r = '<t>'+item.edu_education_worker+' (인)</t>';
          ws.F35.h = item.edu_education_worker+' (인)';
          ws.F35.w = item.edu_education_worker+' (인)';
          ws.F35.s.sz = 11;

          // 주민등록번호
          ws.F37.t = 's';
          ws.F37.v = item.edu_regist_no;
          ws.F37.r = '<t>'+item.edu_regist_no+'</t>';
          ws.F37.h = item.edu_regist_no;
          ws.F37.w = item.edu_regist_no;
          ws.F37.s.sz = 11;

          // 업체명
          ws.F39.t = 's';
          ws.F39.v = item.company_name;
          ws.F39.r = '<t>'+item.company_name+'</t>';
          ws.F39.h = item.company_name;
          ws.F39.w = item.company_name;
          ws.F39.s.sz = 11;

          // 직종
          ws.F41.t = 's';
          ws.F41.v = item.occupation_name;
          ws.F41.r = '<t>'+item.occupation_name+'</t>';
          ws.F41.h = item.occupation_name;
          ws.F41.w = item.occupation_name;
          ws.F41.s.sz = 11;

          // 연락처
          ws.F43.t = 's';
          ws.F43.v = item.edu_phone;
          ws.F43.r = '<t>'+item.edu_phone+'</t>';
          ws.F43.h = item.edu_phone;
          ws.F43.w = item.edu_phone;
          ws.F43.s.sz = 11;

          ws['!images'] = [];

          if(item.user_sign_file){
            ws['!images'].push({
              "!pos": { r: 33, c: 5, x: 80, y: 0, w: 77, h: 60},
              "!datatype": "remote",
              "!link": item.user_sign_file
            });
          }
        } else {
          // 성명 밑 인
          ws.F35.t = 's';
          ws.F35.v = item.user_name+' (인)';
          ws.F35.r = '<t>'+item.user_name+' (인)</t>';
          ws.F35.h = item.user_name+' (인)';
          ws.F35.w = item.user_name+' (인)';
          ws.F35.s.sz = 11;

          // 업체명
          ws.F39.t = 's';
          ws.F39.v = item.company_name;
          ws.F39.r = '<t>'+item.company_name+'</t>';
          ws.F39.h = item.company_name;
          ws.F39.w = item.company_name;
          ws.F39.s.sz = 11;

          // 직종
          ws.F41.t = 's';
          ws.F41.v = item.occupation_name ? item.occupation_name : item.company_position_name_etc;
          ws.F41.r = '<t>'+item.occupation_name ? item.occupation_name : item.company_position_name_etc+'</t>';
          ws.F41.h = item.occupation_name ? item.occupation_name : item.company_position_name_etc;
          ws.F41.w = item.occupation_name ? item.occupation_name : item.company_position_name_etc;
          ws.F41.s.sz = 11;

          // 연락처
          ws.F43.t = 's';
          ws.F43.v = item.user_phone;
          ws.F43.r = '<t>'+item.user_phone+'</t>';
          ws.F43.h = item.user_phone;
          ws.F43.w = item.user_phone;
          ws.F43.s.sz = 11;
        }


        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true, bookSST: true});
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 안전보건협의체 회의록 엑셀 다운로드
  ex_download_5(no_url, filename, item) {
    return new Promise(async(res) => {
      let max_length:number = 0;
      let state_type:boolean = false;
      let more_length:number = 0;
      if(item.minutes_attend_construction.attend_list.length > item.minutes_attend_partner.attend_list.length){
        max_length = item.minutes_attend_construction.attend_list.length; // 원청사
        state_type = true;
        more_length = item.minutes_attend_construction.attend_list.length - item.minutes_attend_partner.attend_list.length;
      } else {
        max_length = item.minutes_attend_partner.attend_list.length; // 협력업체
        state_type = false;
        more_length = item.minutes_attend_partner.attend_list.length - item.minutes_attend_construction.attend_list.length;
      }

      let url:string = '';
      console.log('max_length : ' + max_length);
      if(max_length > 6) url = '../../../assets/excel_theme/layout_8_empty_test.xlsx';
      else url = '../../../assets/excel_theme/layout_8_empty.xlsx';
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        // console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        // console.log(workbook);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        const ws_2 = workbook.Sheets[workbook.SheetNames[1]];
        const ws_3 = workbook.Sheets[workbook.SheetNames[2]];

        console.log(ws);

        console.log('state: ' + state_type);
        
        

        // 현장명 1
        ws_2.B3.t = 's';
        ws_2.B3.v = '현장명 : '+this.connect.area;
        ws_2.B3.r = '<t>현장명 : '+this.connect.area+'</t>';
        ws_2.B3.h = '현장명 : '+this.connect.area;
        ws_2.B3.w = '현장명 : '+this.connect.area;
        ws_2.B3.s.sz = 12;

        // 현장명 2
        ws_3.C4.t = 's';
        ws_3.C4.v = this.connect.area;
        ws_3.C4.r = '<t>'+this.connect.area+'</t>';
        ws_3.C4.h = this.connect.area;
        ws_3.C4.w = this.connect.area;
        ws_3.C4.s.sz = 11;

        // 제목
        ws.B2.t = 's';
        ws.B2.v = item.minutes_type;
        ws.B2.r = '<t>'+item.minutes_type+'</t>';
        ws.B2.h = item.minutes_type;
        ws.B2.w = item.minutes_type;
        ws.B2.s.sz = 20;

        // 일자
        ws.C3.t = 's';
        ws.C3.v = this.date.koreanText_week(item.minutes_date) + '  시간: '+item.minutes_time;
        ws.C3.r = '<t>'+this.date.koreanText_week(item.minutes_date) + '  시간: '+item.minutes_time+'</t>';
        ws.C3.h = this.date.koreanText_week(item.minutes_date) + '  시간: '+item.minutes_time;
        ws.C3.w = this.date.koreanText_week(item.minutes_date) + '  시간: '+item.minutes_time;
        ws.C3.s.sz = 12;

        if(max_length < 6){
          // 회의록 내용
          ws.C12.t = 's';
          ws.C12.v = item.minutes_text;
          ws.C12.r = '<t>'+item.minutes_text+'</t>';
          ws.C12.h = item.minutes_text;
          ws.C12.w = item.minutes_text;
          ws.C12.s.sz = 11;
        }
        
        // 페이지 2 - 일자
        ws_2.D3.t = 's';
        ws_2.D3.v = this.date.koreanText(item.minutes_date);
        ws_2.D3.r = '<t>'+this.date.koreanText(item.minutes_date)+'</t>';
        ws_2.D3.h = this.date.koreanText(item.minutes_date);
        ws_2.D3.w = this.date.koreanText(item.minutes_date);
        ws_2.D3.s.sz = 11;

        // 페이지 3 - 일자
        ws_3.G4.t = 's';
        ws_3.G4.v = this.date.koreanText(item.minutes_date);
        ws_3.G4.r = '<t>'+this.date.koreanText(item.minutes_date)+'</t>';
        ws_3.G4.h = this.date.koreanText(item.minutes_date);
        ws_3.G4.w = this.date.koreanText(item.minutes_date);
        ws_3.G4.s.sz = 11;

        // minutes_attend_construction.attend_list - 원청사
        // minutes_attend_partner.attend_list - 협력업체

        // {hpt: 36, hpx: 48} - 열 높이 -> 원청사, 협력업체

        // 첫번째 열
        let style_first:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 12,
          right: {style: "thin", color: {rgb: "000000"}}
        }

        let style_first_bottom:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 12,
          right: {style: "thin", color: {rgb: "000000"}}
        }

        // 디폴트
        let style_default:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 12,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        // 아래쪽 보더
        let style_default_bottom:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 12,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        let one_arr:any = item.minutes_attend_construction.attend_list; // 원청사
        let two_arr:any = item.minutes_attend_partner.attend_list; // 협력업체

        // company_duty_name // 협력업체
        // user_name // 이름
        // user_sign // 서명


        if(max_length > 6){
          // 기본 셋팅
          let theme_row:any = [];
          theme_row.push({hpt: 34, hpx: 45.333333333333336});
          theme_row.push({hpt: 64.5, hpx: 85.33333333333333});
          theme_row.push({hpt: 36, hpx: 48});
          theme_row.push({hpt: 36, hpx: 48});
          theme_row.push({hpt: 36, hpx: 48});
          theme_row.push({hpt: 36, hpx: 48});
          theme_row.push({hpt: 36, hpx: 48});
          theme_row.push({hpt: 36, hpx: 48});
          theme_row.push({hpt: 36, hpx: 48});
          theme_row.push({hpt: 36, hpx: 48});
          theme_row.push({hpt: 36, hpx: 48});

          let rows_number:number = 26;

          // 원청사
          for(let i = 0; i < one_arr.length; i++){
            if(i < 6){
              ws[`C${i+6}`].t = 's';
              ws[`C${i+6}`].v = one_arr[i].company_duty_name;
              ws[`C${i+6}`].r = '<t>'+one_arr[i].company_duty_name+'</t>';
              ws[`C${i+6}`].h = one_arr[i].company_duty_name;
              ws[`C${i+6}`].w = one_arr[i].company_duty_name;
              ws[`C${i+6}`].s.sz = 12;

              ws[`D${i+6}`].t = 's';
              ws[`D${i+6}`].v = one_arr[i].user_name;
              ws[`D${i+6}`].r = '<t>'+one_arr[i].user_name+'</t>';
              ws[`D${i+6}`].h = one_arr[i].user_name;
              ws[`D${i+6}`].w = one_arr[i].user_name;
              ws[`D${i+6}`].s.sz = 12;

              ws[`E${i+6}`].t = 's';
              ws[`E${i+6}`].v = one_arr[i].user_sign;
              ws[`E${i+6}`].r = '<t>'+one_arr[i].user_sign+'</t>';
              ws[`E${i+6}`].h = one_arr[i].user_sign;
              ws[`E${i+6}`].w = one_arr[i].user_sign;
              ws[`E${i+6}`].s.sz = 12;

              rows_number++;
            } else if(i == one_arr.length-1){ // i == two_arr.length
              console.log('1-1');
              if(!state_type){
                console.log('1-2');
                for(let x = 0; x < more_length+1; x++){
                  console.log('1-3: '+ x);
                  if(x == 0){
                    ws[`B${i+6+x}`] = {s: style_first,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                    ws[`C${i+6+x}`] = {s: style_default_bottom,t: "s",v: one_arr[i].company_duty_name,w: one_arr[i].company_duty_name,h: one_arr[i].company_duty_name,r: '<t>'+(one_arr[i].company_duty_name)+'</t>',z: "General"};
                    ws[`D${i+6+x}`] = {s: style_default_bottom,t: "s",v: one_arr[i].user_name,w: one_arr[i].user_name,h: one_arr[i].user_name,r: '<t>'+(one_arr[i].user_name)+'</t>',z: "General"};
                    ws[`E${i+6+x}`] = {s: style_default_bottom,t: "s",v: one_arr[i].user_sign,w: one_arr[i].user_sign,h: one_arr[i].user_sign,r: '<t>'+(one_arr[i].user_sign)+'</t>',z: "General"};
                    rows_number++;
                  } else if(x == more_length){
                    ws[`B${i+6+x}`] = {s: style_first_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                    ws[`C${i+6+x}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                    ws[`D${i+6+x}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                    ws[`E${i+6+x}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                    rows_number++;
                  } else {
                    ws[`B${i+6+x}`] = {s: style_first,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                    ws[`C${i+6+x}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                    ws[`D${i+6+x}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                    ws[`E${i+6+x}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                    rows_number++;
                  }
                }
              } else {
                ws[`B${i+6}`] = {s: style_first_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                ws[`C${i+6}`] = {s: style_default_bottom,t: "s",v: one_arr[i].company_duty_name,w: one_arr[i].company_duty_name,h: one_arr[i].company_duty_name,r: '<t>'+(one_arr[i].company_duty_name)+'</t>',z: "General"};
                ws[`D${i+6}`] = {s: style_default_bottom,t: "s",v: one_arr[i].user_name,w: one_arr[i].user_name,h: one_arr[i].user_name,r: '<t>'+(one_arr[i].user_name)+'</t>',z: "General"};
                ws[`E${i+6}`] = {s: style_default_bottom,t: "s",v: one_arr[i].user_sign,w: one_arr[i].user_sign,h: one_arr[i].user_sign,r: '<t>'+(one_arr[i].user_sign)+'</t>',z: "General"};
                rows_number++;
              }

              console.log('1-4');
              // ws[`B${i+6}`] = {s: style_first_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
              // ws[`C${i+6}`] = {s: style_default_bottom,t: "s",v: one_arr[i].company_duty_name,w: one_arr[i].company_duty_name,h: one_arr[i].company_duty_name,r: '<t>'+(one_arr[i].company_duty_name)+'</t>',z: "General"};
              // ws[`D${i+6}`] = {s: style_default_bottom,t: "s",v: one_arr[i].user_name,w: one_arr[i].user_name,h: one_arr[i].user_name,r: '<t>'+(one_arr[i].user_name)+'</t>',z: "General"};
              // ws[`E${i+6}`] = {s: style_default_bottom,t: "s",v: one_arr[i].user_sign,w: one_arr[i].user_sign,h: one_arr[i].user_sign,r: '<t>'+(one_arr[i].user_sign)+'</t>',z: "General"};
              console.log('1-5');
              if(state_type) theme_row.push({hpt: 36, hpx: 48});
              console.log('1-6');
            } else {
              rows_number++;
              ws[`B${i+6}`] = {s: style_first,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
              ws[`C${i+6}`] = {s: style_default_bottom,t: "s",v: one_arr[i].company_duty_name,w: one_arr[i].company_duty_name,h: one_arr[i].company_duty_name,r: '<t>'+(one_arr[i].company_duty_name)+'</t>',z: "General"};
              ws[`D${i+6}`] = {s: style_default_bottom,t: "s",v: one_arr[i].user_name,w: one_arr[i].user_name,h: one_arr[i].user_name,r: '<t>'+(one_arr[i].user_name)+'</t>',z: "General"};
              ws[`E${i+6}`] = {s: style_default_bottom,t: "s",v: one_arr[i].user_sign,w: one_arr[i].user_sign,h: one_arr[i].user_sign,r: '<t>'+(one_arr[i].user_sign)+'</t>',z: "General"};
              theme_row.push({hpt: 36, hpx: 48});
            }
          }

          for(let i = 0; i < two_arr.length; i++){
            if(i < 6){
              ws[`F${i+6}`].t = 's';
              ws[`F${i+6}`].v = two_arr[i].company_duty_name;
              ws[`F${i+6}`].r = '<t>'+two_arr[i].company_duty_name+'</t>';
              ws[`F${i+6}`].h = two_arr[i].company_duty_name;
              ws[`F${i+6}`].w = two_arr[i].company_duty_name;
              ws[`F${i+6}`].s.sz = 12;

              ws[`G${i+6}`].t = 's';
              ws[`G${i+6}`].v = two_arr[i].user_name;
              ws[`G${i+6}`].r = '<t>'+two_arr[i].user_name+'</t>';
              ws[`G${i+6}`].h = two_arr[i].user_name;
              ws[`G${i+6}`].w = two_arr[i].user_name;
              ws[`G${i+6}`].s.sz = 12;

              ws[`H${i+6}`].t = 's';
              ws[`H${i+6}`].v = two_arr[i].user_sign;
              ws[`H${i+6}`].r = '<t>'+two_arr[i].user_sign+'</t>';
              ws[`H${i+6}`].h = two_arr[i].user_sign;
              ws[`H${i+6}`].w = two_arr[i].user_sign;
              ws[`H${i+6}`].s.sz = 12;
            } else if(i == two_arr.length-1){
              console.log('2-1');
              if(state_type){
                console.log('2-2');
                for(let x = 0; x < more_length+1; x++){
                  console.log('2-3: ' + x);
                  if(x == 0){
                    ws[`F${i+6}`] = {s: style_default_bottom,t: "s",v: two_arr[i].company_duty_name,w: two_arr[i].company_duty_name,h: two_arr[i].company_duty_name,r: '<t>'+(two_arr[i].company_duty_name)+'</t>',z: "General"};
                    ws[`G${i+6}`] = {s: style_default_bottom,t: "s",v: two_arr[i].user_name,w: two_arr[i].user_name,h: two_arr[i].user_name,r: '<t>'+(two_arr[i].user_name)+'</t>',z: "General"};
                    ws[`H${i+6}`] = {s: style_default_bottom,t: "s",v: two_arr[i].user_sign,w: two_arr[i].user_sign,h: two_arr[i].user_sign,r: '<t>'+(two_arr[i].user_sign)+'</t>',z: "General"};
                  } else {
                    ws[`F${i+6+x}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                    ws[`G${i+6+x}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                    ws[`H${i+6+x}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                  }
                }
              } else {
                ws[`F${i+6}`] = {s: style_default_bottom,t: "s",v: two_arr[i].company_duty_name,w: two_arr[i].company_duty_name,h: two_arr[i].company_duty_name,r: '<t>'+(two_arr[i].company_duty_name)+'</t>',z: "General"};
                ws[`G${i+6}`] = {s: style_default_bottom,t: "s",v: two_arr[i].user_name,w: two_arr[i].user_name,h: two_arr[i].user_name,r: '<t>'+(two_arr[i].user_name)+'</t>',z: "General"};
                ws[`H${i+6}`] = {s: style_default_bottom,t: "s",v: two_arr[i].user_sign,w: two_arr[i].user_sign,h: two_arr[i].user_sign,r: '<t>'+(two_arr[i].user_sign)+'</t>',z: "General"};
              }
              if(!state_type) theme_row.push({hpt: 36, hpx: 48});
            } else {
              ws[`F${i+6}`] = {s: style_default_bottom,t: "s",v: two_arr[i].company_duty_name,w: two_arr[i].company_duty_name,h: two_arr[i].company_duty_name,r: '<t>'+(two_arr[i].company_duty_name)+'</t>',z: "General"};
              ws[`G${i+6}`] = {s: style_default_bottom,t: "s",v: two_arr[i].user_name,w: two_arr[i].user_name,h: two_arr[i].user_name,r: '<t>'+(two_arr[i].user_name)+'</t>',z: "General"};
              ws[`H${i+6}`] = {s: style_default_bottom,t: "s",v: two_arr[i].user_sign,w: two_arr[i].user_sign,h: two_arr[i].user_sign,r: '<t>'+(two_arr[i].user_sign)+'</t>',z: "General"};
            }
          }

          ws["!rows"] = theme_row;

          ws['!print'].area = {s:{r:0,c:0},e:{r:rows_number,c:8}};

          for(let i = 0; i < 21; i++) theme_row.push({hpt: 18, hpx: 24});
          theme_row.push({hpt: 32, hpx: 42.666666666666664});

          console.log(rows_number-20); // 끝나는지점

          let merge_obj:any = [];
          merge_obj.push({s: {r: rows_number-21, c: 1}, e: {r: rows_number-1, c: 1}}); // 전
          merge_obj.push({s: {r: rows_number-21, c: 2}, e: {r: rows_number-1, c: 7}}); // 후
          ws['!merges'] = ws['!merges'].concat(merge_obj);

          // 기본 세팅
          for(let i = rows_number-20; i < rows_number+1; i++){
            ws[`B${i}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
            ws[`C${i}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
            ws[`D${i}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
            ws[`E${i}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
            ws[`F${i}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
            ws[`G${i}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
            ws[`H${i}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
          }

          ws[`B${rows_number-20}`].v = '회 의 내 용';
          ws[`B${rows_number-20}`].r = '<t>회 의 내 용</t>';
          ws[`B${rows_number-20}`].h = '회 의 내 용';
          ws[`B${rows_number-20}`].w = '회 의 내 용';
          ws[`B${rows_number-20}`].s.sz = 11;

          ws[`C${rows_number-20}`].v = item.minutes_text;
          ws[`C${rows_number-20}`].r = '<t>'+item.minutes_text+'</t>';
          ws[`C${rows_number-20}`].h = item.minutes_text;
          ws[`C${rows_number-20}`].w = item.minutes_text;
          ws[`C${rows_number-20}`].s.sz = 11;
          // ws[`B${rows_number-20}`] = {s: style_default_bottom,t: "s",v: '회 의 내 용',w: '회 의 내 용',h: '회 의 내 용',r: '<t>회 의 내 용</t>',z: "General"};
          // ws[`C${rows_number-20}`] = {s: style_default_bottom,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};

          // 11: {hpt: 18, hpx: 24} - row 1
          // 위 로우 하나를 21개 그리고 밑에꺼 하나 추가
          // 32: {hpt: 32, hpx: 42.666666666666664}
        } else {
          for(let i = 0; i < one_arr.length; i++){
            ws[`C${i+6}`].t = 's';
            ws[`C${i+6}`].v = one_arr[i].company_duty_name;
            ws[`C${i+6}`].r = '<t>'+one_arr[i].company_duty_name+'</t>';
            ws[`C${i+6}`].h = one_arr[i].company_duty_name;
            ws[`C${i+6}`].w = one_arr[i].company_duty_name;
            ws[`C${i+6}`].s.sz = 12;

            ws[`D${i+6}`].t = 's';
            ws[`D${i+6}`].v = one_arr[i].user_name;
            ws[`D${i+6}`].r = '<t>'+one_arr[i].user_name+'</t>';
            ws[`D${i+6}`].h = one_arr[i].user_name;
            ws[`D${i+6}`].w = one_arr[i].user_name;
            ws[`D${i+6}`].s.sz = 12;

            ws[`E${i+6}`].t = 's';
            ws[`E${i+6}`].v = one_arr[i].user_sign;
            ws[`E${i+6}`].r = '<t>'+one_arr[i].user_sign+'</t>';
            ws[`E${i+6}`].h = one_arr[i].user_sign;
            ws[`E${i+6}`].w = one_arr[i].user_sign;
            ws[`E${i+6}`].s.sz = 12;
          }
          for(let i = 0; i < two_arr.length; i++){
            ws[`F${i+6}`].t = 's';
            ws[`F${i+6}`].v = two_arr[i].company_duty_name;
            ws[`F${i+6}`].r = '<t>'+two_arr[i].company_duty_name+'</t>';
            ws[`F${i+6}`].h = two_arr[i].company_duty_name;
            ws[`F${i+6}`].w = two_arr[i].company_duty_name;
            ws[`F${i+6}`].s.sz = 12;

            ws[`G${i+6}`].t = 's';
            ws[`G${i+6}`].v = two_arr[i].user_name;
            ws[`G${i+6}`].r = '<t>'+two_arr[i].user_name+'</t>';
            ws[`G${i+6}`].h = two_arr[i].user_name;
            ws[`G${i+6}`].w = two_arr[i].user_name;
            ws[`G${i+6}`].s.sz = 12;

            ws[`H${i+6}`].t = 's';
            ws[`H${i+6}`].v = two_arr[i].user_sign;
            ws[`H${i+6}`].r = '<t>'+two_arr[i].user_sign+'</t>';
            ws[`H${i+6}`].h = two_arr[i].user_sign;
            ws[`H${i+6}`].w = two_arr[i].user_sign;
            ws[`H${i+6}`].s.sz = 12;
          }
        }



        let theme_arr:any = [];
        for(let i = 0; i < 11; i++){
          if(i == 0) theme_arr.push({width: 2.5});
          else if(i == 5) theme_arr.push({width: 2.5});
          else if(i == 10) theme_arr.push({width: 2.5});
          else theme_arr.push({width: 9});
        }
        ws_3['!cols'] = theme_arr;

        let theme_arr_2:any = [];
        for(let i = 0; i < 9; i++){
          if(i == 0) theme_arr_2.push({width: 5});
          else if(i == 8) theme_arr_2.push({width: 5});
          else theme_arr_2.push({width: 10});
        }
        ws['!cols'] = theme_arr_2;

        let theme_arr_3:any = [];
        for(let i = 0; i < 6; i++){
          if(i == 0) theme_arr_3.push({width: 4});
          else if(i == 5) theme_arr_3.push({width: 4});
          else theme_arr_3.push({width: 18});
        }
        ws_2['!cols'] = theme_arr_3;

        // 셀 범위지정
        const ref = `A1:K5000`;
        ws['!ref'] = ref;



        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // create_gap_rows(ws, nrows) {
  //   let ref:any = XLSX.utils.decode_range(ws["!ref"]);       // get original range
  //   ref.e.r += nrows;                                    // add to ending row
  //   ws["!ref"] = XLSX.utils.encode_range(ref);           // reassign row
  // }

  // 정기교육 엑셀 다운로드
  ex_download_6(url, filename, item) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        // console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        // console.log(workbook);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        const ws_2 = workbook.Sheets[workbook.SheetNames[1]];
        const ws_3 = workbook.Sheets[workbook.SheetNames[2]];
        
        // 현장명 1
        ws_2.B3.t = 's';
        ws_2.B3.v = '현장명 : '+this.connect.area;
        ws_2.B3.r = '<t>현장명 : '+this.connect.area+'</t>';
        ws_2.B3.h = '현장명 : '+this.connect.area;
        ws_2.B3.w = '현장명 : '+this.connect.area;
        ws_2.B3.s.sz = 12;

        // 제목
        ws.B4.t = 's';
        ws.B4.v = '제목 : '+item.edu_new_title;
        ws.B4.r = '<t>제목 : '+item.edu_new_title+'</t>';
        ws.B4.h = '제목 : '+item.edu_new_title;
        ws.B4.w = '제목 : '+item.edu_new_title;
        ws.B4.s.sz = 11;

        // 현장명 2
        ws_3.C4.t = 's';
        ws_3.C4.v = this.connect.area;
        ws_3.C4.r = '<t>'+this.connect.area+'</t>';
        ws_3.C4.h = this.connect.area;
        ws_3.C4.w = this.connect.area;
        ws_3.C4.s.sz = 11;

        // 일자
        ws.O6.t = 's';
        ws.O6.v = this.date.koreanText_week(item.edu_new_date);
        ws.O6.r = '<t>'+this.date.koreanText_week(item.edu_new_date)+'</t>';
        ws.O6.h = this.date.koreanText_week(item.edu_new_date);
        ws.O6.w = this.date.koreanText_week(item.edu_new_date);
        ws.O6.s.sz = 11;

        // 교육대상 근로자 남자
        ws.L11.t = 'n';
        ws.L11.v = item.target_man_count;
        ws.L11.r = '<t>'+item.target_man_count+'</t>';
        ws.L11.h = item.target_man_count;
        ws.L11.w = item.target_man_count;
        ws.L11.s.sz = 11;

        // 교육대상 근로자 여자
        ws.N11.t = 'n';
        ws.N11.v = item.target_woman_count;
        ws.N11.r = '<t>'+item.target_woman_count+'</t>';
        ws.N11.h = item.target_woman_count;
        ws.N11.w = item.target_woman_count;
        ws.N11.s.sz = 11;

        // 교육실시 근로자 남자
        ws.L12.t = 'n';
        ws.L12.v = item.practice_man_count;
        ws.L12.r = '<t>'+item.practice_man_count+'</t>';
        ws.L12.h = item.practice_man_count;
        ws.L12.w = item.practice_man_count;
        ws.L12.s.sz = 11;

        // 교육실시 근로자 여자
        ws.N12.t = 'n';
        ws.N12.v = item.practice_woman_count;
        ws.N12.r = '<t>'+item.practice_woman_count+'</t>';
        ws.N12.h = item.practice_woman_count;
        ws.N12.w = item.practice_woman_count;
        ws.N12.s.sz = 11;

        // 교육미실시 근로자 남자
        ws.L13.t = 'n';
        ws.L13.v = item.not_man_count;
        ws.L13.r = '<t>'+item.not_man_count+'</t>';
        ws.L13.h = item.not_man_count;
        ws.L13.w = item.not_man_count;
        ws.L13.s.sz = 11;

        // 교육미실시 근로자 여자
        ws.N13.t = 'n';
        ws.N13.v = item.not_woman_count;
        ws.N13.r = '<t>'+item.not_woman_count+'</t>';
        ws.N13.h = item.not_woman_count;
        ws.N13.w = item.not_woman_count;
        ws.N13.s.sz = 11;

        // 교육대상
        ws.P11.t = 's';
        ws.P11.v = item.edu_new_target;
        ws.P11.r = '<t>'+item.edu_new_target+'</t>';
        ws.P11.h = item.edu_new_target;
        ws.P11.w = item.edu_new_target;
        ws.P11.s.sz = 11;

        // 과목 또는 사항
        ws.D15.t = 's';
        ws.D15.v = item.edu_new_subject;
        ws.D15.r = '<t>'+item.edu_new_subject+'</t>';
        ws.D15.h = item.edu_new_subject;
        ws.D15.w = item.edu_new_subject;
        ws.D15.s.sz = 11;

        // 교육방법
        ws.I15.t = 's';
        ws.I15.v = item.edu_new_method;
        ws.I15.r = '<t>'+item.edu_new_method+'</t>';
        ws.I15.h = item.edu_new_method;
        ws.I15.w = item.edu_new_method;
        ws.I15.s.sz = 11;

        // 교육내용의 개요
        ws.L15.t = 's';
        ws.L15.v = item.edu_new_summary;
        ws.L15.r = '<t>'+item.edu_new_summary+'</t>';
        ws.L15.h = item.edu_new_summary;
        ws.L15.w = item.edu_new_summary;
        ws.L15.s.sz = 11;

        // 교육시간
        ws.S15.t = 's';
        ws.S15.v = item.edu_new_time;
        ws.S15.r = '<t>'+item.edu_new_time+'</t>';
        ws.S15.h = item.edu_new_time;
        ws.S15.w = item.edu_new_time;
        ws.S15.s.sz = 11;
        
        // 사용교재
        ws.U15.t = 's';
        ws.U15.v = item.edu_new_book;
        ws.U15.r = '<t>'+item.edu_new_book+'</t>';
        ws.U15.h = item.edu_new_book;
        ws.U15.w = item.edu_new_book;
        ws.U15.s.sz = 11;

        // 직급
        ws.D26.t = 's';
        ws.D26.v = item.educator_rank;
        ws.D26.r = '<t>'+item.educator_rank+'</t>';
        ws.D26.h = item.educator_rank;
        ws.D26.w = item.educator_rank;
        ws.D26.s.sz = 11;

        // 성명
        ws.H26.t = 's';
        ws.H26.v = item.educator_name;
        ws.H26.r = '<t>'+item.educator_name+'</t>';
        ws.H26.h = item.educator_name;
        ws.H26.w = item.educator_name;
        ws.H26.s.sz = 11;

        // 교육장소
        ws.K26.t = 's';
        ws.K26.v = item.educator_area;
        ws.K26.r = '<t>'+item.educator_area+'</t>';
        ws.K26.h = item.educator_area;
        ws.K26.w = item.educator_area;
        ws.K26.s.sz = 11;

        // 비고
        ws.P26.t = 's';
        ws.P26.v = item.educator_etc;
        ws.P26.r = '<t>'+item.educator_etc+'</t>';
        ws.P26.h = item.educator_etc;
        ws.P26.w = item.educator_etc;
        ws.P26.s.sz = 11;

        // 페이지 2 - 일자
        ws_2.D3.t = 's';
        ws_2.D3.v = this.date.koreanText(item.edu_new_date);
        ws_2.D3.r = '<t>'+this.date.koreanText(item.edu_new_date)+'</t>';
        ws_2.D3.h = this.date.koreanText(item.edu_new_date);
        ws_2.D3.w = this.date.koreanText(item.edu_new_date);
        ws_2.D3.s.sz = 11;

        // 페이지 3 - 일자
        ws_3.G4.t = 's';
        ws_3.G4.v = this.date.koreanText(item.edu_new_date);
        ws_3.G4.r = '<t>'+this.date.koreanText(item.edu_new_date)+'</t>';
        ws_3.G4.h = this.date.koreanText(item.edu_new_date);
        ws_3.G4.w = this.date.koreanText(item.edu_new_date);
        ws_3.G4.s.sz = 11;

        // 교육구분
        ws_3.C3.t = 's';
        ws_3.C3.v = item.educator_etc;
        ws_3.C3.r = '<t>'+item.educator_etc+'</t>';
        ws_3.C3.h = item.educator_etc;
        ws_3.C3.w = item.educator_etc;
        ws_3.C3.s.sz = 11;

        let length_1:number = 36;
        let length_2:number = 25;
        let length_3:number = 0;
        let moinor:number = 0;
        let degi_num:number = 0;
        let style_default:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 12,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        console.log('---------- aa');
        console.log(item);
        console.log('---------- aa');
        ws_3['!images'] = [];

        if(item.attendee_data){
          for(let i = 0; i < item.attendee_data.length; i++){
            if(i < 36){
              console.log('test 1');
              if (i%2 == 0){
                console.log('test 2');
                // 짝수
                ws_3[`B${i+7-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].company_name,w: item.attendee_data[i].company_name,h: item.attendee_data[i].company_name,r: '<t>'+item.attendee_data[i].company_name+'</t>',z: "General"};
                ws_3[`C${i+7-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,w: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,h: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,r: '<t>'+item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc+'</t>',z: "General"};
                ws_3[`D${i+7-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].user_name,w: item.attendee_data[i].user_name,h: item.attendee_data[i].user_name,r: '<t>'+item.attendee_data[i].user_name+'</t>',z: "General"};
                ws_3[`E${i+7-moinor}`] = {s: style_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};

                if(item.attendee_data[i].user_sign){
                  ws_3['!images'].push({
                    "!pos": { r: i+7-moinor-1, c: 4, x: 0, y: 0, w: 88, h: 45},
                    "!datatype": "remote",
                    "!link": item.attendee_data[i].user_sign
                  });
                }
              } else {
                console.log('test 3');
                // 홀수
                ws_3[`G${i+6-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].company_name,w: item.attendee_data[i].company_name,h: item.attendee_data[i].company_name,r: '<t>'+item.attendee_data[i].company_name+'</t>',z: "General"};
                ws_3[`H${i+6-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,w: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,h: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,r: '<t>'+item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc+'</t>',z: "General"};
                ws_3[`I${i+6-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].user_name,w: item.attendee_data[i].user_name,h: item.attendee_data[i].user_name,r: '<t>'+item.attendee_data[i].user_name+'</t>',z: "General"};
                ws_3[`J${i+6-moinor}`] = {s: style_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};

                if(item.attendee_data[i].user_sign){
                  ws_3['!images'].push({
                    "!pos": { r: i+6-moinor-1, c: 9, x: 0, y: 0, w: 88, h: 45},
                    "!datatype": "remote",
                    "!link": item.attendee_data[i].user_sign
                  });
                }
                moinor++;
              }
              
              // length_1 = length_1+3;
            } else {
              if(i > 36) degi_num = 1;
              if(i == length_1){
                console.log('test 4');
                length_1 = length_1+44;
                length_3 = length_3+2;
  
                // 열 높이 맞추기
                ws_3['!rows'].push({hpt:53, hpx:73});
                for(let i = 0; i < 22; i++) ws_3['!rows'].push({hpt:33, hpx:44});
                ws_3['!rows'].push({hpt:53, hpx:73});
  
                // A4크기로 영역 지정하기
                length_2 = length_2+23+degi_num;
                ws_3['!print'].area = {s:{r:0,c:0},e:{r:length_2,c:10}};
              }
  
              if (i%2 == 0){
                console.log('test 5');
                // 짝수
                ws_3[`B${((i+7)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].company_name,w: item.attendee_data[i].company_name,h: item.attendee_data[i].company_name,r: '<t>'+item.attendee_data[i].company_name+'</t>',z: "General"};
                ws_3[`C${((i+7)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,w: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,h: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,r: '<t>'+item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc+'</t>',z: "General"};
                ws_3[`D${((i+7)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].user_name,w: item.attendee_data[i].user_name,h: item.attendee_data[i].user_name,r: '<t>'+item.attendee_data[i].user_name+'</t>',z: "General"};
                ws_3[`E${((i+7)-moinor)+length_3}`] = {s: style_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};

                if(item.attendee_data[i].user_sign){
                  ws_3['!images'].push({
                    "!pos": { r: ((i+7)-moinor)+length_3-1, c: 4, x: 0, y: 0, w: 88, h: 45},
                    "!datatype": "remote",
                    "!link": item.attendee_data[i].user_sign
                  });
                }
              } else {
                console.log('test 6');
                // 홀수
                ws_3[`G${((i+6)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].company_name,w: item.attendee_data[i].company_name,h: item.attendee_data[i].company_name,r: '<t>'+item.attendee_data[i].company_name+'</t>',z: "General"};
                ws_3[`H${((i+6)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,w: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,h: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,r: '<t>'+item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc+'</t>',z: "General"};
                ws_3[`I${((i+6)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].user_name,w: item.attendee_data[i].user_name,h: item.attendee_data[i].user_name,r: '<t>'+item.attendee_data[i].user_name+'</t>',z: "General"};
                ws_3[`J${((i+6)-moinor)+length_3}`] = {s: style_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                if(item.attendee_data[i].user_sign){
                  ws_3['!images'].push({
                    "!pos": { r: ((i+6)-moinor)+length_3-1, c: 9, x: 0, y: 0, w: 88, h: 45},
                    "!datatype": "remote",
                    "!link": item.attendee_data[i].user_sign
                  });
                }
                moinor++;
              }
            }
          }
        }


        let theme_arr:any = [];
        for(let i = 0; i < 11; i++){
          if(i == 0) theme_arr.push({width: 2.5});
          else if(i == 5) theme_arr.push({width: 2.5});
          else if(i == 10) theme_arr.push({width: 2.5});
          else theme_arr.push({width: 9});
        }
        ws_3['!cols'] = theme_arr;

        let theme_arr_2:any = [];
        for(let i = 0; i < 24; i++){
          if(i == 0) theme_arr_2.push({width: 2});
          else if(i == 23) theme_arr_2.push({width: 3});
          else theme_arr_2.push({width: 3.5});
        }
        ws['!cols'] = theme_arr_2;

        let theme_arr_3:any = [];
        for(let i = 0; i < 6; i++){
          if(i == 0) theme_arr_3.push({width: 4});
          else if(i == 5) theme_arr_3.push({width: 4});
          else theme_arr_3.push({width: 18});
        }
        ws_2['!cols'] = theme_arr_3;

        // // 셀 범위지정
        // const ref = `B1:K5000`;
        // ws_3['!ref'] = ref;
        console.log(ws_3['!images']);


        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});
        
        // res(ws_3['!images'][0]['!data']);
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 특별안전교육 엑셀 다운로드
  ex_download_7(url, filename, item) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        // console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        // console.log(workbook);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        
        // 대상자
        ws.E4.t = 's';
        ws.E4.v = item.edu_special_target;
        ws.E4.r = '<t>'+item.edu_special_target+'</t>';
        ws.E4.h = item.edu_special_target;
        ws.E4.w = item.edu_special_target;
        ws.E4.s.sz = 11;

        // 제목
        ws.E3.t = 's';
        ws.E3.v = item.edu_special_title;
        ws.E3.r = '<t>'+item.edu_special_title+'</t>';
        ws.E3.h = item.edu_special_title;
        ws.E3.w = item.edu_special_title;
        ws.E3.s.sz = 11;
        

        // 교육시간
        ws.E5.t = 's';
        ws.E5.v = item.edu_special_time;
        ws.E5.r = '<t>'+item.edu_special_time+'</t>';
        ws.E5.h = item.edu_special_time;
        ws.E5.w = item.edu_special_time;
        ws.E5.s.sz = 11;

        // 강사 성명
        ws.E6.t = 's';
        ws.E6.v = item.educator_name;
        ws.E6.r = '<t>'+item.educator_name+'</t>';
        ws.E6.h = item.educator_name;
        ws.E6.w = item.educator_name;
        ws.E6.s.sz = 11;

        // 해당법규
        ws.E7.t = 's';
        ws.E7.v = item.edu_special_regulations;
        ws.E7.r = '<t>'+item.edu_special_regulations+'</t>';
        ws.E7.h = item.edu_special_regulations;
        ws.E7.w = item.edu_special_regulations;
        ws.E7.s.sz = 11;

        // 위반시
        ws.E8.t = 's';
        ws.E8.v = item.edu_special_violation;
        ws.E8.r = '<t>'+item.edu_special_violation+'</t>';
        ws.E8.h = item.edu_special_violation;
        ws.E8.w = item.edu_special_violation;
        ws.E8.s.sz = 11;

        // 교육결과
        ws.M8.t = 's';
        ws.M8.v = item.edu_special_result;
        ws.M8.r = '<t>'+item.edu_special_result+'</t>';
        ws.M8.h = item.edu_special_result;
        ws.M8.w = item.edu_special_result;
        ws.M8.s.sz = 11;

        // 교육수강 대상작업
        ws.B10.t = 's';
        ws.B10.v = item.edu_special_work;
        ws.B10.r = '<t>'+item.edu_special_work+'</t>';
        ws.B10.h = item.edu_special_work;
        ws.B10.w = item.edu_special_work;
        ws.B10.s.sz = 11;

        // 교육 실시내용
        ws.J10.t = 's';
        ws.J10.v = item.edu_special_text;
        ws.J10.r = '<t>'+item.edu_special_text+'</t>';
        ws.J10.h = item.edu_special_text;
        ws.J10.w = item.edu_special_text;
        ws.J10.s.sz = 11;

        // 너비지정
        let theme_arr:any = [];
        for(let i = 0; i < 18; i++){
          if(i == 0) theme_arr.push({width: 4});
          else if(i == 17) theme_arr.push({width: 4});
          else theme_arr.push({width: 5});
        }
        ws['!cols'] = theme_arr;

        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 정기교육 엑셀 다운로드
  ex_download_8(url, filename, item) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        // console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        // console.log(workbook);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        const ws_2 = workbook.Sheets[workbook.SheetNames[1]];
        const ws_3 = workbook.Sheets[workbook.SheetNames[2]];

        // 현장명 1
        ws_2.B3.t = 's';
        ws_2.B3.v = '현장명 : '+this.connect.area;
        ws_2.B3.r = '<t>현장명 : '+this.connect.area+'</t>';
        ws_2.B3.h = '현장명 : '+this.connect.area;
        ws_2.B3.w = '현장명 : '+this.connect.area;
        ws_2.B3.s.sz = 12;

        // 제목
        ws_2.B4.t = 's';
        ws_2.B4.v = '제목 : '+item.edu_regular_title;
        ws_2.B4.r = '<t>제목 : '+item.edu_regular_title+'</t>';
        ws_2.B4.h = '제목 : '+item.edu_regular_title;
        ws_2.B4.w = '제목 : '+item.edu_regular_title;
        ws_2.B4.s.sz = 11;

        // 현장명 2
        ws_3.C4.t = 's';
        ws_3.C4.v = this.connect.area;
        ws_3.C4.r = '<t>'+this.connect.area+'</t>';
        ws_3.C4.h = this.connect.area;
        ws_3.C4.w = this.connect.area;
        ws_3.C4.s.sz = 11;
        
        // 일자
        ws.O6.t = 's';
        ws.O6.v = this.date.koreanText_week(item.edu_regular_date);
        ws.O6.r = '<t>'+this.date.koreanText_week(item.edu_regular_date)+'</t>';
        ws.O6.h = this.date.koreanText_week(item.edu_regular_date);
        ws.O6.w = this.date.koreanText_week(item.edu_regular_date);
        ws.O6.s.sz = 11;

        // 교육대상 근로자 남자
        ws.L11.t = 'n';
        ws.L11.v = item.target_man_count;
        ws.L11.r = '<t>'+item.target_man_count+'</t>';
        ws.L11.h = item.target_man_count;
        ws.L11.w = item.target_man_count;
        ws.L11.s.sz = 11;

        // 교육대상 근로자 여자
        ws.N11.t = 'n';
        ws.N11.v = item.target_woman_count;
        ws.N11.r = '<t>'+item.target_woman_count+'</t>';
        ws.N11.h = item.target_woman_count;
        ws.N11.w = item.target_woman_count;
        ws.N11.s.sz = 11;

        // 교육실시 근로자 남자
        ws.L12.t = 'n';
        ws.L12.v = item.practice_man_count;
        ws.L12.r = '<t>'+item.practice_man_count+'</t>';
        ws.L12.h = item.practice_man_count;
        ws.L12.w = item.practice_man_count;
        ws.L12.s.sz = 11;

        // 교육실시 근로자 여자
        ws.N12.t = 'n';
        ws.N12.v = item.practice_woman_count;
        ws.N12.r = '<t>'+item.practice_woman_count+'</t>';
        ws.N12.h = item.practice_woman_count;
        ws.N12.w = item.practice_woman_count;
        ws.N12.s.sz = 11;

        // 교육미실시 근로자 남자
        ws.L13.t = 'n';
        ws.L13.v = item.not_man_count;
        ws.L13.r = '<t>'+item.not_man_count+'</t>';
        ws.L13.h = item.not_man_count;
        ws.L13.w = item.not_man_count;
        ws.L13.s.sz = 11;

        // 교육미실시 근로자 여자
        ws.N13.t = 'n';
        ws.N13.v = item.not_woman_count;
        ws.N13.r = '<t>'+item.not_woman_count+'</t>';
        ws.N13.h = item.not_woman_count;
        ws.N13.w = item.not_woman_count;
        ws.N13.s.sz = 11;

        // 교육대상
        ws.P11.t = 's';
        ws.P11.v = item.edu_regular_target;
        ws.P11.r = '<t>'+item.edu_regular_target+'</t>';
        ws.P11.h = item.edu_regular_target;
        ws.P11.w = item.edu_regular_target;
        ws.P11.s.sz = 11;

        // 과목 또는 사항
        ws.D15.t = 's';
        ws.D15.v = item.edu_regular_subject;
        ws.D15.r = '<t>'+item.edu_regular_subject+'</t>';
        ws.D15.h = item.edu_regular_subject;
        ws.D15.w = item.edu_regular_subject;
        ws.D15.s.sz = 11;

        // 교육방법
        ws.I15.t = 's';
        ws.I15.v = item.edu_regular_method;
        ws.I15.r = '<t>'+item.edu_regular_method+'</t>';
        ws.I15.h = item.edu_regular_method;
        ws.I15.w = item.edu_regular_method;
        ws.I15.s.sz = 11;

        // 교육내용의 개요
        ws.L15.t = 's';
        ws.L15.v = item.edu_regular_summary;
        ws.L15.r = '<t>'+item.edu_regular_summary+'</t>';
        ws.L15.h = item.edu_regular_summary;
        ws.L15.w = item.edu_regular_summary;
        ws.L15.s.sz = 11;

        // 교육시간
        ws.S15.t = 's';
        ws.S15.v = item.edu_regular_time;
        ws.S15.r = '<t>'+item.edu_regular_time+'</t>';
        ws.S15.h = item.edu_regular_time;
        ws.S15.w = item.edu_regular_time;
        ws.S15.s.sz = 11;
        
        // 사용교재
        ws.U15.t = 's';
        ws.U15.v = item.edu_regular_book;
        ws.U15.r = '<t>'+item.edu_regular_book+'</t>';
        ws.U15.h = item.edu_regular_book;
        ws.U15.w = item.edu_regular_book;
        ws.U15.s.sz = 11;

        // 직급
        ws.D26.t = 's';
        ws.D26.v = item.educator_rank;
        ws.D26.r = '<t>'+item.educator_rank+'</t>';
        ws.D26.h = item.educator_rank;
        ws.D26.w = item.educator_rank;
        ws.D26.s.sz = 11;

        // 성명
        ws.H26.t = 's';
        ws.H26.v = item.educator_name;
        ws.H26.r = '<t>'+item.educator_name+'</t>';
        ws.H26.h = item.educator_name;
        ws.H26.w = item.educator_name;
        ws.H26.s.sz = 11;

        // 교육장소
        ws.K26.t = 's';
        ws.K26.v = item.educator_area;
        ws.K26.r = '<t>'+item.educator_area+'</t>';
        ws.K26.h = item.educator_area;
        ws.K26.w = item.educator_area;
        ws.K26.s.sz = 11;

        // 비고
        ws.P26.t = 's';
        ws.P26.v = item.educator_etc;
        ws.P26.r = '<t>'+item.educator_etc+'</t>';
        ws.P26.h = item.educator_etc;
        ws.P26.w = item.educator_etc;
        ws.P26.s.sz = 11;

        // 페이지 2 - 일자
        ws_2.D3.t = 's';
        ws_2.D3.v = this.date.koreanText(item.edu_regular_date);
        ws_2.D3.r = '<t>'+this.date.koreanText(item.edu_regular_date)+'</t>';
        ws_2.D3.h = this.date.koreanText(item.edu_regular_date);
        ws_2.D3.w = this.date.koreanText(item.edu_regular_date);
        ws_2.D3.s.sz = 11;

        // 페이지 3 - 일자
        ws_3.G4.t = 's';
        ws_3.G4.v = this.date.koreanText(item.edu_regular_date);
        ws_3.G4.r = '<t>'+this.date.koreanText(item.edu_regular_date)+'</t>';
        ws_3.G4.h = this.date.koreanText(item.edu_regular_date);
        ws_3.G4.w = this.date.koreanText(item.edu_regular_date);
        ws_3.G4.s.sz = 11;

        // 교육구분
        ws_3.C3.t = 's';
        ws_3.C3.v = item.educator_etc;
        ws_3.C3.r = '<t>'+item.educator_etc+'</t>';
        ws_3.C3.h = item.educator_etc;
        ws_3.C3.w = item.educator_etc;
        ws_3.C3.s.sz = 11;

        console.log(ws_3);

        let length_1:number = 36;
        let length_2:number = 25;
        let length_3:number = 0;
        let moinor:number = 0;
        let degi_num:number = 0;
        let style_default:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 12,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }
        ws_3['!images'] = [];
        console.log('---------- aa');
        console.log(item);
        console.log('---------- aa');
        if(item.attendee_data){
          for(let i = 0; i < item.attendee_data.length; i++){
            if(i < 36){
              console.log('test 1');
              if (i%2 == 0){
                console.log('test 2');
                // 짝수
                ws_3[`B${i+7-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].company_name,w: item.attendee_data[i].company_name,h: item.attendee_data[i].company_name,r: '<t>'+item.attendee_data[i].company_name+'</t>',z: "General"};
                ws_3[`C${i+7-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,w: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,h: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,r: '<t>'+item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc+'</t>',z: "General"};
                ws_3[`D${i+7-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].user_name,w: item.attendee_data[i].user_name,h: item.attendee_data[i].user_name,r: '<t>'+item.attendee_data[i].user_name+'</t>',z: "General"};
                ws_3[`E${i+7-moinor}`] = {s: style_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                if(item.attendee_data[i].user_sign){
                  ws_3['!images'].push({
                    "!pos": { r: i+7-moinor-1, c: 4, x: 0, y: 0, w: 88, h: 45},
                    "!datatype": "remote",
                    "!link": item.attendee_data[i].user_sign
                  });
                }
              } else {
                console.log('test 3');
                // 홀수
                ws_3[`G${i+6-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].company_name,w: item.attendee_data[i].company_name,h: item.attendee_data[i].company_name,r: '<t>'+item.attendee_data[i].company_name+'</t>',z: "General"};
                ws_3[`H${i+6-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,w: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,h: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,r: '<t>'+item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc+'</t>',z: "General"};
                ws_3[`I${i+6-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].user_name,w: item.attendee_data[i].user_name,h: item.attendee_data[i].user_name,r: '<t>'+item.attendee_data[i].user_name+'</t>',z: "General"};
                ws_3[`J${i+6-moinor}`] = {s: style_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                if(item.attendee_data[i].user_sign){
                  ws_3['!images'].push({
                    "!pos": { r: i+6-moinor-1, c: 9, x: 0, y: 0, w: 88, h: 45},
                    "!datatype": "remote",
                    "!link": item.attendee_data[i].user_sign
                  });
                }
                moinor++;
              }
              
              // length_1 = length_1+3;
            } else {
              if(i > 36) degi_num = 1;
              if(i == length_1){
                console.log('test 4');
                length_1 = length_1+44;
                length_3 = length_3+2;
  
                // 열 높이 맞추기
                ws_3['!rows'].push({hpt:53, hpx:73});
                for(let i = 0; i < 22; i++) ws_3['!rows'].push({hpt:33, hpx:44});
                ws_3['!rows'].push({hpt:53, hpx:73});
  
                // A4크기로 영역 지정하기
                length_2 = length_2+23+degi_num;
                ws_3['!print'].area = {s:{r:0,c:0},e:{r:length_2,c:10}};
              }
  
              if (i%2 == 0){
                console.log('test 5');
                // 짝수
                ws_3[`B${((i+7)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].company_name,w: item.attendee_data[i].company_name,h: item.attendee_data[i].company_name,r: '<t>'+item.attendee_data[i].company_name+'</t>',z: "General"};
                ws_3[`C${((i+7)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,w: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,h: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,r: '<t>'+item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc+'</t>',z: "General"};
                ws_3[`D${((i+7)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].user_name,w: item.attendee_data[i].user_name,h: item.attendee_data[i].user_name,r: '<t>'+item.attendee_data[i].user_name+'</t>',z: "General"};
                ws_3[`E${((i+7)-moinor)+length_3}`] = {s: style_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                if(item.attendee_data[i].user_sign){
                  ws_3['!images'].push({
                    "!pos": { r: ((i+7)-moinor)+length_3-1, c: 4, x: 0, y: 0, w: 88, h: 45},
                    "!datatype": "remote",
                    "!link": item.attendee_data[i].user_sign
                  });
                }
              } else {
                console.log('test 6');
                // 홀수
                ws_3[`G${((i+6)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].company_name,w: item.attendee_data[i].company_name,h: item.attendee_data[i].company_name,r: '<t>'+item.attendee_data[i].company_name+'</t>',z: "General"};
                ws_3[`H${((i+6)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,w: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,h: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,r: '<t>'+item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc+'</t>',z: "General"};
                ws_3[`I${((i+6)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].user_name,w: item.attendee_data[i].user_name,h: item.attendee_data[i].user_name,r: '<t>'+item.attendee_data[i].user_name+'</t>',z: "General"};
                ws_3[`J${((i+6)-moinor)+length_3}`] = {s: style_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                if(item.attendee_data[i].user_sign){
                  ws_3['!images'].push({
                    "!pos": { r: ((i+6)-moinor)+length_3-1, c: 9, x: 0, y: 0, w: 88, h: 45},
                    "!datatype": "remote",
                    "!link": item.attendee_data[i].user_sign
                  });
                }
                moinor++;
              }
            }
          }
        }


        let theme_arr:any = [];
        for(let i = 0; i < 11; i++){
          if(i == 0) theme_arr.push({width: 2.5});
          else if(i == 5) theme_arr.push({width: 2.5});
          else if(i == 10) theme_arr.push({width: 2.5});
          else theme_arr.push({width: 9});
        }
        ws_3['!cols'] = theme_arr;

        let theme_arr_2:any = [];
        for(let i = 0; i < 24; i++){
          if(i == 0) theme_arr_2.push({width: 2});
          else if(i == 23) theme_arr_2.push({width: 3});
          else theme_arr_2.push({width: 3.5});
        }
        ws['!cols'] = theme_arr_2;

        let theme_arr_3:any = [];
        for(let i = 0; i < 6; i++){
          if(i == 0) theme_arr_3.push({width: 4});
          else if(i == 5) theme_arr_3.push({width: 4});
          else theme_arr_3.push({width: 18});
        }
        ws_2['!cols'] = theme_arr_3;

        // 셀 범위지정
        const ref = `B1:K5000`;
        ws_3['!ref'] = ref;



        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 공사일보 엑셀 다운로드
  ex_download_9(
    url, 
    filename, 
    item_1, // 공사일보 상세
    item_2, // 공사일보 출역현황 // 장비현황으로 바뀜
    // item_3 // 공사일보 장비현황
  ) {
    // console.log(item);
    
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer"; 
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        // console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        // console.log(workbook);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        // console.log(ws['!images']);
        // console.log(ws);

        // 공사일보 상세
        let res_1:any = {
          dr_id: 0, // dr_id			
          user_id: 0, // user_id			
          dr_scene: this.connect.area, // 현장			
          dr_special_note: '', // 특이사항			
          reg_date: '', // 작성일			
          dr_data: '',
          user_name: '', // 작성자			
          approval_user_data: { // 결재선
            daily_report_approval: []
          }, // {"daily_report_approval": [{"user_id": 2147483647, "order_no": 1, "user_name": "고한솔", "company_id": 1, "company_name": "업체명", "approval_file": null, "approval_state": "승인", "approval_comment": "ㅋㅋ", "company_duty_name": "직책"}, {"user_id": 3415721263, "order_no": 2, "user_name": "고한솔", "company_id": 2, "company_name": "ㅇㅅㅇ", "approval_file": null, "approval_state": "승인", "approval_comment": "ㅋㅋ", "company_duty_name": "직책"}, {"user_id": 5278146613, "order_no": 3, "user_name": "고한솔", "company_id": 1, "company_name": "업체명", "approval_file": null, "approval_state": "승인", "approval_comment": "ㅋㅋ", "company_duty_name": "직책"}]}			
          today_data: { // 금일
            daily_report_today: []
          }, // {"daily_report_today": [{"seq_no": 1, "today_text": "내용입력", "occupation_name": "직종ㅋ", "occupation_name_etc": "직종기타입력"}, {"seq_no": 2, "today_text": "내용입력", "occupation_name": "직종", "occupation_name_etc": "직종기타입력"}]}			
          tomorrow_data: { // 명일
            daily_report_tomorrow: []
          }, // {"daily_report_tomorrow": [{"seq_no": 1, "tomorrow_text": "내용입력", "occupation_name": "직종ㅋ", "occupation_name_etc": "직종기타입력"}, {"seq_no": 2, "tomorrow_text": "내용입력", "occupation_name": "직종", "occupation_name_etc": "직종기타입력"}]}			
          bebar_data: { // 철근자재
            daily_report_bebar: []
          }, // {"daily_report_bebar": [{"seq_no": 1, "material_name": "자재ㅋ", "material_standard": "규격", "material_today_count": 1, "material_total_count": 3}, {"seq_no": 2, "material_name": "자재", "material_standard": "규격", "material_today_count": 1, "material_total_count": 3}]}			
          rmc_data: { // 레미콘자재
            daily_report_rmc: []
          }, // {"daily_report_rmc": [{"seq_no": 1, "material_name": "자재ㅋ", "material_standard": "규격", "material_today_count": 1, "material_total_count": 3}, {"seq_no": 2, "material_name": "자재", "material_standard": "규격", "material_today_count": 1, "material_total_count": 3}]}			
          quantity_data: { // 물량산출
            daily_report_quantity: []
          }, // {"daily_report_quantity": [{"unit": "단위", "seq_no": 1, "work_name": "작업내용", "today_count": 3, "total_count": 3, "worker_count": 3, "occupation_name": "직종ㅋ", "yesterday_count": 3, "occupation_name_etc": "직종 기타"}, {"unit": "단위", "seq_no": 2, "work_name": "작업내용", "today_count": 3, "total_count": 3, "worker_count": 3, "occupation_name": "직종", "yesterday_count": 3, "occupation_name_etc": "직종 기타"}]}			
          img_data: { // 사진대지
            daily_report_img: []
          }, // {"daily_report_img": [{"dr_img_id": 3, "work_next_img": "http://54.180.53.189/kunyoung/Data/Daily/Report//4KakaoTalk_20201109_150222728.jpg", "work_prev_img": "http://54.180.53.189/kunyoung/Data/Daily/Report//4KakaoTalk_20201109_150212082.jpg", "occupation_name": "직종3", "occupation_name_etc": "직종 기타3"}, {"dr_img_id": 7, "work_next_img": "http://54.180.53.189/kunyoung/Data/Daily/Report//4KakaoTalk_20201109_150326932.jpg", "work_prev_img": "http://54.180.53.189/kunyoung/Data/Daily/Report//4다운로드.jpg", "occupation_name": "직종ㅋ", "occupation_name_etc": "직종 기타"}, {"dr_img_id": 8, "work_next_img": "http://54.180.53.189/kunyoung/Data/Daily/Report//4KakaoTalk_20201109_150316756.jpg", "work_prev_img": "http://54.180.53.189/kunyoung/Data/Daily/Report//4KakaoTalk_20201109_150323843.jpg", "occupation_name": "직종2", "occupation_name_etc": "직종 기타2"}, {"dr_img_id": 9, "work_next_img": "http://54.180.53.189/kunyoung/Data/Daily/Report//4KakaoTalk_20201109_150258019.jpg", "work_prev_img": "http://54.180.53.189/kunyoung/Data/Daily/Report//4KakaoTalk_20201109_150301812.jpg", "occupation_name": "직종3", "occupation_name_etc": "직종 기타3"}]}			
        }
        res_1 = JSON.parse(JSON.stringify(item_1));

        // 공사일보 출역현황
        let res_2:Array<{
          company_id, // company_id			
          company_name, // 업체			
          construction_name, // 공종			
          occupation_name, // 직종			
          yesterday_count, // 전일			
          today_count, // 금일			
          total_count, // 누계			
        }> = [];
        res_2 = JSON.parse(JSON.stringify(item_2));

        // 공사일보 장비현황
        let res_3:Array<{
          equip_id, // equip_id			
          equip_type, // 장비명			
          equip_area_standard, // 규격			
          yesterday_count, // 전일			
          today_count, // 금일			
          total_count // 누계				
        }> = [];
        if(item_1.equip_data) if(item_1.equip_data.daily_report_equip) res_3 = JSON.parse(JSON.stringify(item_1.equip_data.daily_report_equip));
        

        // let image_data:any = res_1.img_data.daily_report_img;
        // for(let i = 0; i < image_data.length; i++){

        // } 

        
        // 작성일
        ws.F7.t = 's';
        ws.F7.v = this.connect.area;
        ws.F7.r = '<t>'+this.connect.area+'</t>';
        ws.F7.h = this.connect.area;
        ws.F7.w = this.connect.area;
        ws.F7.s.sz = 11;

        // 작성일
        ws.R7.t = 's';
        ws.R7.v = this.date.koreanText_week(res_1.reg_date);
        ws.R7.r = '<t>'+res_1.reg_date+'</t>';
        ws.R7.h = this.date.koreanText_week(res_1.reg_date);
        ws.R7.w = this.date.koreanText_week(res_1.reg_date);
        ws.R7.s.sz = 11;


        // 도트 - 출역현황용(Left - border)
        let option_default_left_1:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "dotted", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 8,
          top: {style: "dotted", color: {rgb: "000000"}},
          right: {style: "dotted", color: {rgb: "000000"}}
        }
        // 도트 - 출역현황용(non-border)
        let option_default_normal_1:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "dotted", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "dotted", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 8,
          top: {style: "dotted", color: {rgb: "000000"}},
          right: {style: "dotted", color: {rgb: "000000"}}
        }
        // 도트 - 출역현황용(right - border)
        let option_default_right_1:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "dotted", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "dotted", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 8,
          top: {style: "dotted", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        // 작업사항 - 금일 and 명일용(non-border)
        let option_default_nonborder_1:any = {
          alignment: {vertical: "center", horizontal: "left", wrapText: true},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          name: "굴림",
          patternType: "none",
          sz: 10,
          right: {style: "medium", color: {rgb: "000000"}},
          left: {style: "thin", color: {rgb: "000000"}}
        }

        // 작업사항 - 금일 and 명일 텍스트넣기(background-color)
        let option_default_backgroundcolor_1:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          name: "굴림",
          patternType: "solid",
          sz: 8,
          right: {style: "medium", color: {rgb: "000000"}},
          left: {style: "thin", color: {rgb: "000000"}},
          top: {style: "double", color: {rgb: "000000"}},
          fgColor: { rgb: "DDDDDD"}
        }

        // 작업사항 - 금일 and 명일 텍스트넣기(default-border)
        let option_default_bordertop:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          name: "굴림",
          patternType: "solid",
          sz: 8,
          top: {style: "double", color: {rgb: "000000"}},
          fgColor: { rgb: "DDDDDD"}
        }

        let length_flag_1:number = 12; // 줄바꿈 - 출역현황
        let length_flag_2:number = 12; // 줄바꿈 - 금일
        let length_flag_3:number = 116; // 줄바꿈 - 명일

        // 출역현황
        // res_2
        // 도트 - 장비현황(Left - border)
        let option_default_left_2:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "dotted", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "medium", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 8,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "dotted", color: {rgb: "000000"}}
        }
        // 도트 - 장비현황(non-border)
        let option_default_normal_2:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "dotted", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "dotted", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 8,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "dotted", color: {rgb: "000000"}}
        }
        // 도트 - 장비현황(right - border)
        let option_default_right_2:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "dotted", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "dotted", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 8,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "medium", color: {rgb: "000000"}}
        }

        let option_default_left_2_1:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "dotted", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "medium", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 8,
          top: {style: "dotted", color: {rgb: "000000"}},
          right: {style: "dotted", color: {rgb: "000000"}}
        }
        // 도트 - 장비현황(non-border)
        let option_default_normal_2_1:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "dotted", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "dotted", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 8,
          top: {style: "dotted", color: {rgb: "000000"}},
          right: {style: "dotted", color: {rgb: "000000"}}
        }
        // 도트 - 장비현황(right - border)
        let option_default_right_2_1:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "dotted", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "dotted", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 8,
          top: {style: "dotted", color: {rgb: "000000"}},
          right: {style: "medium", color: {rgb: "000000"}}
        }

        // 물량
        let option_product_left_1:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "dotted", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "medium", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 8,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "dotted", color: {rgb: "000000"}}
        }
        let option_product_normal_1:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "dotted", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "dotted", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 8,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "dotted", color: {rgb: "000000"}}
        }
        let option_product_right_1:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "dotted", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "dotted", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 8,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "medium", color: {rgb: "000000"}}
        }
        let option_product_left_1_1:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "dotted", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "medium", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 8,
          top: {style: "dotted", color: {rgb: "000000"}},
          right: {style: "dotted", color: {rgb: "000000"}}
        }
        let option_product_normal_1_1:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "dotted", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "dotted", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 8,
          top: {style: "dotted", color: {rgb: "000000"}},
          right: {style: "dotted", color: {rgb: "000000"}}
        }
        let option_product_right_1_1:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "dotted", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "dotted", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 8,
          top: {style: "dotted", color: {rgb: "000000"}},
          right: {style: "medium", color: {rgb: "000000"}}
        }


        let length_flag_4:number = 134; // 줄바꿈 - 장비현황
        let length_flag_5:number = 134; // 줄바꿈 - 철근자제
        let length_flag_6:number = 134; // 줄바꿈 - 레미콘자제
        let length_flag_7:number = 160; // 줄바꿈 - 물량산출
        let number_format:string = '#,##0';

        // 아이템 넣기 - 출역현황
        console.log(res_2);
        let total_1_1:number = 0;
        let total_1_2:number = 0;
        let total_1_3:number = 0;
        for(let i = 0; i < res_2.length; i++){
          ws[`B${length_flag_1+1}`] = {s: option_default_left_1,t: "s",v: res_2[i].construction_name,w: res_2[i].construction_name,h: res_2[i].construction_name,r: '<t>'+res_2[i].construction_name+'</t>',z: "General"};
          ws[`F${length_flag_1+1}`] = {s: option_default_normal_1,t: "s",v: res_2[i].company_name,w: res_2[i].company_name,h: res_2[i].company_name,r: '<t>'+res_2[i].company_name+'</t>',z: "General"};
          ws[`I${length_flag_1+1}`] = {s: option_default_normal_1,t: "s",v: res_2[i].occupation_name,w: res_2[i].occupation_name,h: res_2[i].occupation_name,r: '<t>'+res_2[i].occupation_name+'</t>',z: "General"};
          ws[`L${length_flag_1+1}`] = {z: number_format, s: option_default_normal_1,t: "n",v: res_2[i].yesterday_count,w: res_2[i].yesterday_count,h: res_2[i].yesterday_count,r: '<t>'+res_2[i].yesterday_count+'</t>'};
          ws[`O${length_flag_1+1}`] = {z: number_format, s: option_default_normal_1,t: "n",v: res_2[i].today_count,w: res_2[i].today_count,h: res_2[i].today_count,r: '<t>'+res_2[i].today_count+'</t>'};
          ws[`R${length_flag_1+1}`] = {z: number_format, s: option_default_right_1,t: "n",v: res_2[i].total_count,w: res_2[i].total_count,h: res_2[i].total_count,r: '<t>'+res_2[i].total_count+'</t>'};
          if(i === 66) length_flag_1 = length_flag_1+9;
          else length_flag_1++;
        }

        let theme_res_2:any = res_1.today_data.daily_report_today;
        // 아이템 넣기 - 금일
        for(let i = 0; i < theme_res_2.length; i++){
          if(!theme_res_2.today_text_etc) theme_res_2.today_text_etc = '';
          ws[`U${length_flag_2+1}`] = {s: option_default_nonborder_1,t: "s",v: (theme_res_2[i].occupation_name+'   '+theme_res_2[i].today_text+'   '+theme_res_2.today_text_etc),w: (theme_res_2[i].occupation_name+'   '+theme_res_2[i].today_text+'   '+theme_res_2.today_text_etc),h: (theme_res_2[i].occupation_name+'   '+theme_res_2[i].today_text+'   '+theme_res_2.today_text_etc),r: '<t>'+(theme_res_2[i].occupation_name+'   '+theme_res_2[i].today_text+'   '+theme_res_2.today_text_etc)+'</t>',z: "General"};

          length_flag_2++;

          if(length_flag_2 == 79) length_flag_2 = 88;
        }

        // 아이템 넣기 - 명일 텍스트 넣기
        let theme_res_3:any = res_1.tomorrow_data.daily_report_tomorrow;

        // 아이템 넣기 - 명일
        for(let i = 0; i < theme_res_3.length; i++){
          ws[`U${length_flag_3}`] = {s: option_default_nonborder_1,t: "s",v: (theme_res_3[i].occupation_name+'   '+theme_res_3[i].tomorrow_text),w: (theme_res_3[i].occupation_name+'   '+theme_res_3[i].tomorrow_text),h: (theme_res_3[i].occupation_name+'   '+theme_res_3[i].tomorrow_text),r: '<t>'+(theme_res_3[i].occupation_name+'   '+theme_res_3[i].tomorrow_text)+'</t>',z: "General"};

          length_flag_3++;
        }

        // 장비현황
        for(let i = 0; i < res_3.length; i++){
          if(i == 0){
            ws[`B${length_flag_4}`] = {s: option_default_left_2,t: "s",v: res_3[i].equip_type,w: res_3[i].equip_type,h: res_3[i].equip_type,r: '<t>'+res_3[i].equip_type+'</t>',z: "General"};
            ws[`E${length_flag_4}`] = {s: option_default_normal_2,t: "s",v: res_3[i].equip_area_standard,w: res_3[i].equip_area_standard,h: res_3[i].equip_area_standard,r: '<t>'+res_3[i].equip_area_standard+'</t>',z: "General"};
            ws[`I${length_flag_4}`] = {z: number_format, s: option_default_normal_2,t: "n",v: res_3[i].yesterday_count,w: res_3[i].yesterday_count,h: res_3[i].yesterday_count,r: '<t>'+res_3[i].yesterday_count+'</t>'};
            ws[`K${length_flag_4}`] = {z: number_format, s: option_default_normal_2,t: "n",v: res_3[i].today_count,w: res_3[i].today_count,h: res_3[i].today_count,r: '<t>'+res_3[i].today_count+'</t>'};
            // ws[`N${length_flag_4+1}`] = {z: number_format, s: option_default_normal_2,t: "n",v: res_3[i].total_count,w: res_3[i].total_count,h: res_3[i].total_count,r: '<t>'+res_3[i].total_count+'</t>'};
          } else {
            ws[`B${length_flag_4}`] = {s: option_default_left_2_1,t: "s",v: res_3[i].equip_type,w: res_3[i].equip_type,h: res_3[i].equip_type,r: '<t>'+res_3[i].equip_type+'</t>',z: "General"};
            ws[`E${length_flag_4}`] = {s: option_default_normal_2_1,t: "s",v: res_3[i].equip_area_standard,w: res_3[i].equip_area_standard,h: res_3[i].equip_area_standard,r: '<t>'+res_3[i].equip_area_standard+'</t>',z: "General"};
            ws[`I${length_flag_4}`] = {z: number_format, s: option_default_normal_2_1,t: "n",v: res_3[i].yesterday_count,w: res_3[i].yesterday_count,h: res_3[i].yesterday_count,r: '<t>'+res_3[i].yesterday_count+'</t>'};
            ws[`K${length_flag_4}`] = {z: number_format, s: option_default_normal_2_1,t: "n",v: res_3[i].today_count,w: res_3[i].today_count,h: res_3[i].today_count,r: '<t>'+res_3[i].today_count+'</t>'};
            // ws[`N${length_flag_4+1}`] = {z: number_format, s: option_default_normal_2_1,t: "n",v: res_3[i].total_count,w: res_3[i].total_count,h: res_3[i].total_count,r: '<t>'+res_3[i].total_count+'</t>'};
          }

          length_flag_4++;
        }

        // 철근자제
        let theme_res_4:any = res_1.bebar_data.daily_report_bebar;
        for(let i = 0; i < theme_res_4.length; i++){
          if(i == 0){
            ws[`R${length_flag_5}`] = {s: option_default_normal_2,t: "s",v: theme_res_4[i].material_name,w: theme_res_4[i].material_name,h: theme_res_4[i].material_name,r: '<t>'+theme_res_4[i].material_name+'</t>',z: "General"};
            ws[`W${length_flag_5}`] = {s: option_default_normal_2,t: "s",v: theme_res_4[i].material_standard,w: theme_res_4[i].material_standard,h: theme_res_4[i].material_standard,r: '<t>'+theme_res_4[i].material_standard+'</t>',z: "General"};
            ws[`AA${length_flag_5}`] = {z: number_format, s: option_default_normal_2,t: "n",v: theme_res_4[i].material_today_count,w: theme_res_4[i].material_today_count,h: theme_res_4[i].material_today_count,r: '<t>'+theme_res_4[i].material_today_count+'</t>'};
            ws[`AD${length_flag_5}`] = {z: number_format,s: option_default_normal_2,t: "n",v: theme_res_4[i].material_total_count,w: theme_res_4[i].material_total_count,h: theme_res_4[i].material_total_count,r: '<t>'+theme_res_4[i].material_total_count+'</t>'};
          } else {
            ws[`R${length_flag_5}`] = {s: option_default_normal_2_1,t: "s",v: theme_res_4[i].material_name,w: theme_res_4[i].material_name,h: theme_res_4[i].material_name,r: '<t>'+theme_res_4[i].material_name+'</t>',z: "General"};
            ws[`W${length_flag_5}`] = {s: option_default_normal_2_1,t: "s",v: theme_res_4[i].material_standard,w: theme_res_4[i].material_standard,h: theme_res_4[i].material_standard,r: '<t>'+theme_res_4[i].material_standard+'</t>',z: "General"};
            ws[`AA${length_flag_5}`] = {z: number_format,s: option_default_normal_2_1,t: "n",v: theme_res_4[i].material_today_count,w: theme_res_4[i].material_today_count,h: theme_res_4[i].material_today_count,r: '<t>'+theme_res_4[i].material_today_count+'</t>'};
            ws[`AD${length_flag_5}`] = {z: number_format,s: option_default_normal_2_1,t: "n",v: theme_res_4[i].material_total_count,w: theme_res_4[i].material_total_count,h: theme_res_4[i].material_total_count,r: '<t>'+theme_res_4[i].material_total_count+'</t>'};
          }

          length_flag_5++;
        }

        // 레미콘자제
        let theme_res_5:any = res_1.rmc_data.daily_report_rmc;
        for(let i = 0; i < theme_res_5.length; i++){
          if(i == 0){
            ws[`AG${length_flag_6}`] = {s: option_default_normal_2,t: "s",v: theme_res_5[i].material_name,w: theme_res_5[i].material_name,h: theme_res_5[i].material_name,r: '<t>'+theme_res_5[i].material_name+'</t>',z: "General"};
            ws[`AL${length_flag_6}`] = {s: option_default_normal_2,t: "s",v: theme_res_5[i].material_standard,w: theme_res_5[i].material_standard,h: theme_res_5[i].material_standard,r: '<t>'+theme_res_5[i].material_standard+'</t>',z: "General"};
            ws[`AO${length_flag_6}`] = {z: number_format, s: option_default_normal_2,t: "n",v: theme_res_5[i].material_today_count,w: theme_res_5[i].material_today_count,h: theme_res_5[i].material_today_count,r: '<t>'+theme_res_5[i].material_today_count+'</t>'};
            ws[`AR${length_flag_6}`] = {z: number_format, s: option_default_right_2,t: "n",v: theme_res_5[i].material_total_count,w: theme_res_5[i].material_total_count,h: theme_res_5[i].material_total_count,r: '<t>'+theme_res_5[i].material_total_count+'</t>'};
          } else {
            ws[`AG${length_flag_6}`] = {s: option_default_normal_2_1,t: "s",v: theme_res_5[i].material_name,w: theme_res_5[i].material_name,h: theme_res_5[i].material_name,r: '<t>'+theme_res_5[i].material_name+'</t>',z: "General"};
            ws[`AL${length_flag_6}`] = {s: option_default_normal_2_1,t: "s",v: theme_res_5[i].material_standard,w: theme_res_5[i].material_standard,h: theme_res_5[i].material_standard,r: '<t>'+theme_res_5[i].material_standard+'</t>',z: "General"};
            ws[`AO${length_flag_6}`] = {z: number_format, s: option_default_normal_2_1,t: "n",v: theme_res_5[i].material_today_count,w: theme_res_5[i].material_today_count,h: theme_res_5[i].material_today_count,r: '<t>'+theme_res_5[i].material_today_count+'</t>'};
            ws[`AR${length_flag_6}`] = {z: number_format, s: option_default_right_2_1,t: "n",v: theme_res_5[i].material_total_count,w: theme_res_5[i].material_total_count,h: theme_res_5[i].material_total_count,r: '<t>'+theme_res_5[i].material_total_count+'</t>'};
          }


          length_flag_6++;
        }

        // 특이사항
        let option_special:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "medium", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 8,
          top: {style: "medium", color: {rgb: "000000"}},
          right: {style: "medium", color: {rgb: "000000"}}
        }
        ws['F155'] = {s: option_special,t: "s",v: res_1.dr_special_note,w: res_1.dr_special_note,h: res_1.dr_special_note,r: '<t>'+res_1.dr_special_note+'</t>',z: "General"};

        // 물량산출
        let theme_res_6:any = res_1.quantity_data.daily_report_quantity;
        for(let i = 0; i < theme_res_6.length; i++){
          if(i == 0){
            ws[`B${length_flag_7}`] = {s: option_product_left_1,t: "s",v: (theme_res_6[i].occupation_name ? theme_res_6[i].occupation_name : theme_res_6[i].occupation_name_etc),w: (theme_res_6[i].occupation_name ? theme_res_6[i].occupation_name : theme_res_6[i].occupation_name_etc),h: (theme_res_6[i].occupation_name ? theme_res_6[i].occupation_name : theme_res_6[i].occupation_name_etc),r: '<t>'+(theme_res_6[i].occupation_name ? theme_res_6[i].occupation_name : theme_res_6[i].occupation_name_etc)+'</t>',z: "General"};
            ws[`H${length_flag_7}`] = {s: option_product_normal_1,t: "s",v: theme_res_6[i].work_name,w: theme_res_6[i].work_name,h: theme_res_6[i].work_name,r: '<t>'+theme_res_6[i].work_name+'</t>',z: "General"};
            ws[`AG${length_flag_7}`] = {z: number_format, s: option_product_normal_1,t: "n",v: theme_res_6[i].worker_count,w: theme_res_6[i].worker_count,h: theme_res_6[i].worker_count,r: '<t>'+theme_res_6[i].worker_count+'</t>'};
            ws[`AJ${length_flag_7}`] = {z: number_format, s: option_product_normal_1,t: "n",v: theme_res_6[i].yesterday_count,w: theme_res_6[i].yesterday_count,h: theme_res_6[i].yesterday_count,r: '<t>'+theme_res_6[i].yesterday_count+'</t>'};
            ws[`AM${length_flag_7}`] = {z: number_format, s: option_product_normal_1,t: "n",v: theme_res_6[i].today_count,w: theme_res_6[i].today_count,h: theme_res_6[i].today_count,r: '<t>'+theme_res_6[i].today_count+'</t>'};
            ws[`AP${length_flag_7}`] = {z: number_format, s: option_product_normal_1,t: "n",v: theme_res_6[i].today_count,w: theme_res_6[i].today_count,h: theme_res_6[i].today_count,r: '<t>'+theme_res_6[i].today_count+'</t>'};
            ws[`AS${length_flag_7}`] = {s: option_product_right_1,t: "s",v: theme_res_6[i].unit,w: theme_res_6[i].unit,h: theme_res_6[i].unit,r: '<t>'+theme_res_6[i].unit+'</t>',z: "General"};
          } else {
            ws[`B${length_flag_7}`] = {s: option_product_left_1_1,t: "s",v: (theme_res_6[i].occupation_name ? theme_res_6[i].occupation_name : theme_res_6[i].occupation_name_etc),w: (theme_res_6[i].occupation_name ? theme_res_6[i].occupation_name : theme_res_6[i].occupation_name_etc),h: (theme_res_6[i].occupation_name ? theme_res_6[i].occupation_name : theme_res_6[i].occupation_name_etc),r: '<t>'+(theme_res_6[i].occupation_name ? theme_res_6[i].occupation_name : theme_res_6[i].occupation_name_etc)+'</t>',z: "General"};
            ws[`H${length_flag_7}`] = {s: option_product_normal_1_1,t: "s",v: theme_res_6[i].work_name,w: theme_res_6[i].work_name,h: theme_res_6[i].work_name,r: '<t>'+theme_res_6[i].work_name+'</t>',z: "General"};
            ws[`AG${length_flag_7}`] = {z: number_format, s: option_product_normal_1_1,t: "n",v: theme_res_6[i].worker_count,w: theme_res_6[i].worker_count,h: theme_res_6[i].worker_count,r: '<t>'+theme_res_6[i].worker_count+'</t>'};
            ws[`AJ${length_flag_7}`] = {z: number_format, s: option_product_normal_1_1,t: "n",v: theme_res_6[i].yesterday_count,w: theme_res_6[i].yesterday_count,h: theme_res_6[i].yesterday_count,r: '<t>'+theme_res_6[i].yesterday_count+'</t>'};
            ws[`AM${length_flag_7}`] = {z: number_format, s: option_product_normal_1_1,t: "n",v: theme_res_6[i].today_count,w: theme_res_6[i].today_count,h: theme_res_6[i].today_count,r: '<t>'+theme_res_6[i].today_count+'</t>'};
            ws[`AP${length_flag_7}`] = {z: number_format, s: option_product_normal_1_1,t: "n",v: theme_res_6[i].today_count,w: theme_res_6[i].today_count,h: theme_res_6[i].today_count,r: '<t>'+theme_res_6[i].today_count+'</t>'};
            ws[`AS${length_flag_7}`] = {s: option_product_right_1_1,t: "s",v: theme_res_6[i].unit,w: theme_res_6[i].unit,h: theme_res_6[i].unit,r: '<t>'+theme_res_6[i].unit+'</t>',z: "General"};
          }


          length_flag_7++;
        }

        // for(let i = 0; i < obj.length; i++){
          
        //   // 아이템 - merge: s(시작),e(끝) r(세로), c(가로)
        //   // if(obj.length > 1){
        //   //   merge_obj.push({s: {r: length_flag, c: 0}, e: {r: length_flag+obj[i].risk_assessment_factor.length-1, c: 0}});
        //   //   merge_obj.push({s: {r: length_flag, c: 1}, e: {r: length_flag+obj[i].risk_assessment_factor.length-1, c: 1}});
        //   //   merge_obj.push({s: {r: length_flag, c: 2}, e: {r: length_flag+obj[i].risk_assessment_factor.length-1, c: 2}});
        //   //   merge_obj.push({s: {r: length_flag, c: 3}, e: {r: length_flag+obj[i].risk_assessment_factor.length-1, c: 3}});
        //   //   merge_obj.push({s: {r: length_flag, c: 4}, e: {r: length_flag+obj[i].risk_assessment_factor.length-1, c: 4}});
        //   //   merge_obj.push({s: {r: length_flag, c: 5}, e: {r: length_flag+obj[i].risk_assessment_factor.length-1, c: 5}});
        //   // }

        //   // console.log(res.item_list[i]);
        //   for(let x = 0; x < obj[i].risk_assessment_factor.length; x++){
        //     // 데이터 7 

        //     // 앞에 병합되는 데이터
        //     ws[`A${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].ra_construction_name,w: obj[i].ra_construction_name,h: obj[i].ra_construction_name,r: '<t>'+obj[i].ra_construction_name+'</t>',z: "General"};
        //     ws[`B${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].ra_workstep,w: obj[i].ra_workstep,h: obj[i].ra_workstep,r: '<t>'+obj[i].ra_workstep+'</t>',z: "General"};
        //     ws[`C${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].ra_equipment,w: obj[i].ra_equipment,h: obj[i].ra_equipment,r: '<t>'+obj[i].ra_equipment+'</t>',z: "General"};
        //     ws[`D${length_flag+1}`] = {s: option_default,t: "s",v: (obj[i].ra_work_start_date+'~'+obj[i].ra_work_end_date),w: (obj[i].ra_work_start_date+'~'+obj[i].ra_work_end_date),h: (obj[i].ra_work_start_date+'~'+obj[i].ra_work_end_date),r: '<t>'+(obj[i].ra_work_start_date+'~'+obj[i].ra_work_end_date)+'</t>',z: "General"};
        //     ws[`E${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].ra_work_area ? obj[i].ra_work_area : obj[i].ra_work_area_detail,w: obj[i].ra_work_area ? obj[i].ra_work_area : obj[i].ra_work_area_detail,h: obj[i].ra_work_area ? obj[i].ra_work_area : obj[i].ra_work_area_detail,r: '<t>'+obj[i].ra_work_area ? obj[i].ra_work_area : obj[i].ra_work_area_detail+'</t>',z: "General"};
        //     ws[`F${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].ra_worker_no,w: obj[i].ra_worker_no,h: obj[i].ra_worker_no,r: '<t>'+obj[i].ra_worker_no+'</t>',z: "General"};

        //     // 데이터 안에 데이터
        //     ws[`G${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].risk_assessment_factor[x].ra_risk_factor,w: obj[i].risk_assessment_factor[x].ra_risk_factor,h: obj[i].risk_assessment_factor[x].ra_risk_factor,r: '<t>'+obj[i].risk_assessment_factor[x].ra_risk_factor+'</t>',z: "General"};
        //     ws[`H${length_flag+1}`] = {s: option_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
        //     ws[`I${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].risk_assessment_factor[x].ra_damage_form,w: obj[i].risk_assessment_factor[x].ra_damage_form,h: obj[i].risk_assessment_factor[x].ra_damage_form,r: '<t>'+obj[i].risk_assessment_factor[x].ra_damage_form+'</t>',z: "General"};
        //     ws[`J${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].risk_assessment_factor[x].ra_frequency,w: obj[i].risk_assessment_factor[x].ra_frequency,h: obj[i].risk_assessment_factor[x].ra_frequency,r: '<t>'+obj[i].risk_assessment_factor[x].ra_frequency+'</t>',z: "General"};
        //     ws[`K${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].risk_assessment_factor[x].ra_strength,w: obj[i].risk_assessment_factor[x].ra_strength,h: obj[i].risk_assessment_factor[x].ra_strength,r: '<t>'+obj[i].risk_assessment_factor[x].ra_strength+'</t>',z: "General"};
        //     ws[`L${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].risk_assessment_factor[x].ra_rating,w: obj[i].risk_assessment_factor[x].ra_rating,h: obj[i].risk_assessment_factor[x].ra_rating,r: '<t>'+obj[i].risk_assessment_factor[x].ra_rating+'</t>',z: "General"};

        //     ws[`M${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].risk_assessment_factor[x].ra_admin_comment,w: obj[i].risk_assessment_factor[x].ra_admin_comment,h: obj[i].risk_assessment_factor[x].ra_admin_comment,r: '<t>'+obj[i].risk_assessment_factor[x].ra_admin_comment+'</t>',z: "General"};
        //     ws[`N${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].risk_assessment_factor[x].ra_admin_comment,w: obj[i].risk_assessment_factor[x].ra_admin_comment,h: obj[i].risk_assessment_factor[x].ra_admin_comment,r: '<t>'+obj[i].risk_assessment_factor[x].ra_admin_comment+'</t>',z: "General"};
        //     ws[`O${length_flag+1}`] = {s: option_default,t: "s",v: obj[i].risk_assessment_factor[x].ra_admin_comment,w: obj[i].risk_assessment_factor[x].ra_admin_comment,h: obj[i].risk_assessment_factor[x].ra_admin_comment,r: '<t>'+obj[i].risk_assessment_factor[x].ra_admin_comment+'</t>',z: "General"};

        //     // 맨뒤에칸 병합 M ~ O
        //     // merge: s(시작),e(끝) r(세로), c(가로)
        //     merge_obj.push({s: {r: length_flag+1, c: 12}, e: {r: length_flag+1, c: 14}});

        //     length_flag++;
        //   }
        // }

        // 셀 범위지정
        // const ref = `A1:AV${length_flag}`;
        const ref = `A1:AV5000`;
        ws['!ref'] = ref;
        ws['!images'] = [];
        let merge_obj:any = [];
        // ws['!merges'] = ws['!merges'].concat(merge_obj);
        
        // let theme_col:any = [
        //   {width: 11.1640625, customwidth: "1", wpx: 78, wch: 10.43, MDW: 7},
        //   {width: 14.5, customwidth: "1", wpx: 101, wch: 13.71, MDW: 7},
        //   {width: 10.1640625, customwidth: "1", wpx: 71, wch: 9.43, MDW: 7},
        //   {width: 11.5, customwidth: "1", wpx: 80, wch: 10.71, MDW: 7},
        //   {width: 8.6640625, customwidth: "1", wpx: 61, wch: 8, MDW: 7},
        //   {width: 5.6640625, customwidth: "1", wpx: 40, wch: 5, MDW: 7},
        //   {width: 26, customwidth: "1", wpx: 182, wch: 25.29, MDW: 7},
        //   {width: 6.5, customwidth: "1", wpx: 45, wch: 5.71, MDW: 7},
        //   {width: 5.1640625, customwidth: "1", wpx: 36, wch: 4.43, MDW: 7},
        //   {width: 4.33203125, customwidth: "1", wpx: 30, wch: 3.57, MDW: 7},
        //   {width: 4.33203125, customwidth: "1", wpx: 30, wch: 3.57, MDW: 7},
        //   {width: 4.33203125, customwidth: "1", wpx: 30, wch: 3.57, MDW: 7},
        //   {width: 11.1640625, customwidth: "1", wpx: 78, wch: 10.43, MDW: 7},
        //   {width: 11.1640625, customwidth: "1", wpx: 78, wch: 10.43, MDW: 7},
        //   {width: 11.1640625, customwidth: "1", wpx: 78, wch: 10.43, MDW: 7},
        //   {width: 8.83203125, customwidth: "1", wpx: 62, wch: 8.14, MDW: 7}
        // ]
        // let theme_row:any = [];

        // for(let i = 0; i < (length_flag-7); i++){
        //   theme_row.push({hpt: 42.5, hpx: 56.666666666666664});
        // }

        // 넓이지정
        // ws['!cols'] = theme_col;

        // 높이지정
        // ws['!rows'] = ws['!rows'].concat(theme_row);

        // 이미지 크기 및 위치
        // r: 2, c: 9, x: 0, y: 0, w: 104, h: 44 - 현장소장
        // r: 2, c: 12, x: 0, y: 0, w: 90, h: 44 - 공사 책임자
        // r: 2, c: 13, x: 0, y: 0, w: 90, h: 44 - 현장소장
        // r: 2, c: 13, x: 0, y: 0, w: 90, h: 44 - 안전관리자

        // let img_data:any = this.createObjectURL('');
        // let img_data:any = this.UrlBuffer();
        
        // 이미지 넣기 - assets/img/theme-sign.png
        let option_image_title:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "double", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "medium", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 12,
          top: {style: "double", color: {rgb: "000000"}},
          right: {style: "medium", color: {rgb: "000000"}}
        }

        let option_image_title_border:any = {
          bottom: {style: "double", color: {rgb: "000000"}},
          top: {style: "double", color: {rgb: "000000"}}
        }

        let option_image_title_border_2:any = {
          left: {style: "medium", color: {rgb: "000000"}},
          right: {style: "medium", color: {rgb: "000000"}}
        }

        let length_flag_8:number = 203;
        let length_flag_9:number = 205;
        let flag_num:number = 0;
        // let length_flag_8:number = 205;
        const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU'];

        if(res_1.img_data){
          let image_data:any = res_1.img_data.daily_report_img;
          for(let i = 0; i < image_data.length; i++){
            // 전
            ws['!images'].push({
              // "!pos": { r: 208, c: 223, x: 0, y: 0, w: 100, h: 120},
              "!pos": { r: length_flag_9+flag_num, c: 2, x: 0, y: 0, w: 450, h: 320},
              "!datatype": "remote",
              "!link": image_data[i].work_prev_img
            });
  
            // 후
            ws['!images'].push({
              // "!pos": { r: 208, c: 223, x: 0, y: 0, w: 100, h: 120},
              "!pos": { r: length_flag_9+flag_num, c: 26, x: 0, y: 0, w: 450, h: 320},
              "!datatype": "remote",
              "!link": image_data[i].work_next_img
            });
  
            // merge: s(시작),e(끝) r(세로), c(가로)
            merge_obj.push({s: {r: length_flag_8+flag_num, c: 1}, e: {r: length_flag_8+flag_num, c: 24}}); // 전
            merge_obj.push({s: {r: length_flag_8+flag_num, c: 25}, e: {r: length_flag_8+flag_num, c: 46}}); // 후
  
            merge_obj.push({s: {r: length_flag_8+1+flag_num, c: 1}, e: {r: length_flag_8+15+flag_num, c: 24}}); // 전
            merge_obj.push({s: {r: length_flag_8+1+flag_num, c: 25}, e: {r: length_flag_8+15+flag_num, c: 46}}); // 후
  
            // 타이틀 중간 보더
            for(let g = 2; g < 25; g++) ws[`${alphabet[g]}${length_flag_8+1+flag_num}`] = {s:option_image_title,v:'',w: '',h: '',r: '<t></t>',z: "General"};
            for(let g = 26; g < 47; g++) ws[`${alphabet[g]}${length_flag_8+1+flag_num}`] = {s:option_image_title,v:'',w: '',h: '',r: '<t></t>',z: "General"};
  
            // 이미지 중간보더
            for(let g = 1; g < 25; g++){
              for(let j = 2; j < 17; j++) ws[`${alphabet[g]}${length_flag_8+j+flag_num}`] = {s:option_image_title_border_2,v:'',w: '',h: '',r: '<t></t>',z: "General"};
            }
            for(let g = 26; g < 47; g++){
              for(let j = 2; j < 17; j++) ws[`${alphabet[g]}${length_flag_8+j+flag_num}`] = {s:option_image_title_border_2,v:'',w: '',h: '',r: '<t></t>',z: "General"};
            }
  
            ws[`B${length_flag_8+1+flag_num}`] = {s: option_image_title,t: "s",v: (image_data[i].occupation_name ? image_data[i].occupation_name : image_data[i].occupation_name_etc),w: (image_data[i].occupation_name ? image_data[i].occupation_name : image_data[i].occupation_name_etc),h: (image_data[i].occupation_name ? image_data[i].occupation_name : image_data[i].occupation_name_etc),r: '<t>'+(image_data[i].occupation_name ? image_data[i].occupation_name : image_data[i].occupation_name_etc)+'</t>',z: "General"};
            ws[`Z${length_flag_8+1+flag_num}`] = {s: option_image_title,t: "s",v: (image_data[i].occupation_name ? image_data[i].occupation_name : image_data[i].occupation_name_etc),w: (image_data[i].occupation_name ? image_data[i].occupation_name : image_data[i].occupation_name_etc),h: (image_data[i].occupation_name ? image_data[i].occupation_name : image_data[i].occupation_name_etc),r: '<t>'+(image_data[i].occupation_name ? image_data[i].occupation_name : image_data[i].occupation_name_etc)+'</t>',z: "General"};
  
            length_flag_8 = length_flag_8+15;
            length_flag_9 = length_flag_9+15;
            flag_num++;
          } 
  
          // A4크기로 영역 지정하기
          ws['!print'].area = {s:{r:0,c:0},e:{r:length_flag_9,c:47}};
          ws['!merges'] = ws['!merges'].concat(merge_obj);
        }


        // ws['!images'].push({
        //   "!pos": { r: 7, c: 34, x: 0, y: 0, w: 85, h: 67},
        //   "!datatype": "remote",
        //   "!link": "https://w7.pngwing.com/pngs/959/325/png-transparent-autograph-encapsulated-postscript-signature-miscellaneous-angle-text.png"
        // });
        
        let option_duty:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 12,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }
        let length_flag_10:number = 34;
        let length_flag_11:number = 34;

        let duty_arr:any = []; 
        if(res_1.approval_user_data){
          duty_arr = res_1.approval_user_data.daily_report_approval;

          // duty_arr.length
          for(let i = 0; i < duty_arr.length; i++){
            if(i < 4){
            // ws[`${alphabet[length_flag_10]}3`] = {s: option_duty,t: "s",v: 'test_'+i,w: 'test_'+i,h: 'test_'+i,r: '<t>'+'test_'+i+'</t>',z: "General"};
            // ws['!images'].push({
            //   "!pos": { r: 3, c: length_flag_10, x: 0, y: 0, w: 88, h: 60},
            //   "!datatype": "remote",
            //   "!link": 'https://w7.pngwing.com/pngs/959/325/png-transparent-autograph-encapsulated-postscript-signature-miscellaneous-angle-text.png'
            // });

              ws[`${alphabet[length_flag_10]}3`] = {s: option_duty,t: "s",v: duty_arr[i].company_duty_name,w: duty_arr[i].company_duty_name,h: duty_arr[i].company_duty_name,r: '<t>'+duty_arr[i].company_duty_name+'</t>',z: "General"};
              if(duty_arr[i].approval_file){
                if(duty_arr[i].approval_state == '승인'){
                  ws['!images'].push({
                    "!pos": { r: 3, c: length_flag_10, x: 0, y: 0, w: 75, h: 60},
                    "!datatype": "remote",
                    "!link": duty_arr[i].approval_file
                  });
                }
              }
              length_flag_10 = length_flag_10+3;
            } else {
              // ws[`${alphabet[length_flag_11]}7`] = {s: option_duty,t: "s",v: 'test_'+i,w: 'test_'+i,h: 'test_'+i,r: '<t>'+'test_'+i+'</t>',z: "General"};
              // ws['!images'].push({
              //   "!pos": { r: 7, c: length_flag_11, x: 0, y: 0, w: 88, h: 60},
              //   "!datatype": "remote",
              //   "!link": 'https://w7.pngwing.com/pngs/959/325/png-transparent-autograph-encapsulated-postscript-signature-miscellaneous-angle-text.png'
              // });

              ws[`${alphabet[length_flag_11]}7`] = {s: option_duty,t: "s",v: duty_arr[i].company_duty_name,w: duty_arr[i].company_duty_name,h: duty_arr[i].company_duty_name,r: '<t>'+duty_arr[i].company_duty_name+'</t>',z: "General"};
              if(duty_arr[i].approval_file){
                if(duty_arr[i].approval_state == '승인'){
                  ws['!images'].push({
                    "!pos": { r: 7, c: length_flag_11, x: 0, y: 0, w: 75, h: 60},
                    "!datatype": "remote",
                    "!link": duty_arr[i].approval_file
                  });
                }
              }
              length_flag_11 = length_flag_11+3;
            }
          }
        }

        let theme_arr:any = [];
        for(let i = 0; i < 48; i++){
          if(i == 0) theme_arr.push({width: 1});
          else if(i == 5) theme_arr.push({width: 6.5});
          else if(i == 6) theme_arr.push({width: 6.5});
          else if(i == 7) theme_arr.push({width: 6.5});
          else if(i == 8) theme_arr.push({width: 6.5});
          else if(i == 9) theme_arr.push({width: 6.5});
          else if(i == 10) theme_arr.push({width: 6.5});
          else if(i == 11) theme_arr.push({width: 3});
          else if(i == 12) theme_arr.push({width: 3});
          else if(i == 13) theme_arr.push({width: 3});
          else if(i == 17) theme_arr.push({width: 3});
          else if(i == 18) theme_arr.push({width: 3});
          else if(i == 19) theme_arr.push({width: 3});
          else if(i == 47) theme_arr.push({width: 1});
          else theme_arr.push({width: 3});
        }
        ws['!cols'] = theme_arr;
        
        console.log('최종 - ', ws);
        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});

        // XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true, bookSST: true});
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

    // 건강문진표 엑셀 다운로드
  ex_download_10(url, filename, item, state) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        console.log(workbook);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        // console.log(ws['!images']);
        console.log(ws);

        // merge: s(시작),e(끝) r(세로), c(가로)
        // let merge_obj:any = [];
        // merge_obj.push({s: {r: 13, c: 1}, e: {r: 18, c: 4}});
        // ws['!merges'] = ws['!merges'].concat(merge_obj);

        if(state){
          // 고혈압
          ws.C5.t = 's';
          ws.C5.v = item.high_blood_drugs ? 'O' : 'X';
          ws.C5.r = '<t>'+item.high_blood_drugs ? 'O' : 'X'+'</t>';
          ws.C5.h = item.high_blood_drugs ? 'O' : 'X';
          ws.C5.w = item.high_blood_drugs ? 'O' : 'X';
          ws.C5.s.sz = 22;

          if(item.high_blood_state){
            ws.C6.t = 's';
            ws.C6.v = item.high_blood_date;
            ws.C6.r = '<t>'+item.high_blood_date+'</t>';
            ws.C6.h = item.high_blood_date;
            ws.C6.w = item.high_blood_date;
            ws.C6.s.sz = 22;
          }

          if(item.high_blood_drugs){
            ws.C7.t = 's';
            ws.C7.v = item.high_blood_start_date+' ~ '+item.high_blood_end_date;
            ws.C7.r = '<t>'+item.high_blood_start_date+' ~ '+item.high_blood_end_date+'</t>';
            ws.C7.h = item.high_blood_start_date+' ~ '+item.high_blood_end_date;
            ws.C7.w = item.high_blood_start_date+' ~ '+item.high_blood_end_date;
            ws.C7.s.sz = 22;
          }

          // 당뇨
          ws.D5.t = 's';
          ws.D5.v = item.diabetes_drugs ? 'O' : 'X';
          ws.D5.r = '<t>'+item.diabetes_drugs ? 'O' : 'X'+'</t>';
          ws.D5.h = item.diabetes_drugs ? 'O' : 'X';
          ws.D5.w = item.diabetes_drugs ? 'O' : 'X';
          ws.D5.s.sz = 22;

          if(item.diabetes_diagnosis){
            ws.D6.t = 's';
            ws.D6.v = item.diabetes_date;
            ws.D6.r = '<t>'+item.diabetes_date+'</t>';
            ws.D6.h = item.diabetes_date;
            ws.D6.w = item.diabetes_date;
            ws.D6.s.sz = 22;
          }

          if(item.diabetes_drugs){
            ws.D7.t = 's';
            ws.D7.v = item.diabetes_start_date+' ~ '+item.diabetes_end_date;
            ws.D7.r = '<t>'+item.diabetes_start_date+' ~ '+item.diabetes_end_date+'</t>';
            ws.D7.h = item.diabetes_start_date+' ~ '+item.diabetes_end_date;
            ws.D7.w = item.diabetes_start_date+' ~ '+item.diabetes_end_date;
            ws.D7.s.sz = 22;
          }

          // 뇌졸증
          ws.E5.t = 's';
          ws.E5.v = item.stroke_drugs ? 'O' : 'X';
          ws.E5.r = '<t>'+item.stroke_drugs ? 'O' : 'X'+'</t>';
          ws.E5.h = item.stroke_drugs ? 'O' : 'X';
          ws.E5.w = item.stroke_drugs ? 'O' : 'X';
          ws.E5.s.sz = 22;

          if(item.stroke_diagnosis){
            ws.E6.t = 's';
            ws.E6.v = item.stroke_date;
            ws.E6.r = '<t>'+item.stroke_date+'</t>';
            ws.E6.h = item.stroke_date;
            ws.E6.w = item.stroke_date;
            ws.E6.s.sz = 22;
          }

          if(item.stroke_drugs){
            ws.E7.t = 's';
            ws.E7.v = item.stroke_start_date+' ~ '+item.stroke_end_date;
            ws.E7.r = '<t>'+item.stroke_start_date+' ~ '+item.stroke_end_date+'</t>';
            ws.E7.h = item.stroke_start_date+' ~ '+item.stroke_end_date;
            ws.E7.w = item.stroke_start_date+' ~ '+item.stroke_end_date;
            ws.E7.s.sz = 22;
          }

          // 심장병
          ws.F5.t = 's';
          ws.F5.v = item.heart_drugs ? 'O' : 'X';
          ws.F5.r = '<t>'+item.heart_drugs ? 'O' : 'X'+'</t>';
          ws.F5.h = item.heart_drugs ? 'O' : 'X';
          ws.F5.w = item.heart_drugs ? 'O' : 'X';
          ws.F5.s.sz = 22;

          if(item.heart_diagnosis){
            ws.F6.t = 's';
            ws.F6.v = item.heart_date;
            ws.F6.r = '<t>'+item.heart_date+'</t>';
            ws.F6.h = item.heart_date;
            ws.F6.w = item.heart_date;
            ws.F6.s.sz = 22;
          }

          if(item.heart_drugs){
            ws.F7.t = 's';
            ws.F7.v = item.heart_start_date+' ~ '+item.heart_end_date;
            ws.F7.r = '<t>'+item.heart_start_date+' ~ '+item.heart_end_date+'</t>';
            ws.F7.h = item.heart_start_date+' ~ '+item.heart_end_date;
            ws.F7.w = item.heart_start_date+' ~ '+item.heart_end_date;
            ws.F7.s.sz = 22;
          }

          // 기타
          ws.G4.t = 's';
          ws.G4.v = `기타( ${item.etc_nm} )`;
          ws.G4.r = '<t>기타( '+item.etc_nm+' )</t>';
          ws.G4.h = `기타( ${item.etc_nm} )`;
          ws.G4.w = `기타( ${item.etc_nm} )`;
          ws.G4.s.sz = 22;

          ws.G5.t = 's';
          ws.G5.v = item.etc_drugs ? 'O' : 'X';
          ws.G5.r = '<t>'+item.etc_drugs ? 'O' : 'X'+'</t>';
          ws.G5.h = item.etc_drugs ? 'O' : 'X';
          ws.G5.w = item.etc_drugs ? 'O' : 'X';
          ws.G5.s.sz = 22;

          if(item.etc_diagnosis){
            ws.G6.t = 's';
            ws.G6.v = item.heart_date;
            ws.G6.r = '<t>'+item.heart_date+'</t>';
            ws.G6.h = item.heart_date;
            ws.G6.w = item.heart_date;
            ws.G6.s.sz = 22;
          }

          if(item.etc_drugs){
            ws.G7.t = 's';
            ws.G7.v = item.etc_start_date+' ~ '+item.etc_end_date;
            ws.G7.r = '<t>'+item.etc_start_date+' ~ '+item.etc_end_date+'</t>';
            ws.G7.h = item.etc_start_date+' ~ '+item.etc_end_date;
            ws.G7.w = item.etc_start_date+' ~ '+item.etc_end_date;
            ws.G7.s.sz = 22;
          }

          // 특이사항
          ws.C8.t = 's';
          ws.C8.v = item.special_text;
          ws.C8.r = '<t>'+item.special_text+'</t>';
          ws.C8.h = item.special_text;
          ws.C8.w = item.special_text;
          ws.C8.s.sz = 22;

          // 년 월 일
          ws.D29.t = 's';
          ws.D29.v = this.date.koreanText_week(item.reg_date);
          ws.D29.r = '<t>'+this.date.koreanText_week(item.reg_date)+'</t>';
          ws.D29.h = this.date.koreanText_week(item.reg_date);
          ws.D29.w = this.date.koreanText_week(item.reg_date);
          ws.D29.s.sz = 26;

          // 협력사명
          ws.G31.t = 's';
          ws.G31.v = item.company_name;
          ws.G31.r = '<t>'+item.company_name+'</t>';
          ws.G31.h = item.company_name;
          ws.G31.w = item.company_name;
          ws.G31.s.sz = 24;

          // 서명
          ws['!images'] = [];
          ws['!images'].push({
            "!pos": { r: 31, c: 6, x: 0, y: 0, w: 240, h: 120},
            "!datatype": "remote",
            "!link": item.user_sign_file
          });
        } else {
          // 협력사명
          ws.G31.t = 's';
          ws.G31.v = item.company_name;
          ws.G31.r = '<t>'+item.company_name+'</t>';
          ws.G31.h = item.company_name;
          ws.G31.w = item.company_name;
          ws.G31.s.sz = 24;
        }


        let theme_arr:any = [];
        for(let i = 0; i < 8; i++){
          if(i == 0) theme_arr.push({width: 8.5});
          else if(i == 7) theme_arr.push({width: 8.5});
          else theme_arr.push({width: 35});
        }
        ws['!cols'] = theme_arr;

        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true, bookSST: true});
        res(ws['!images'][0]['!data']);
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 반입전 장비 엑셀 다운로드
  ex_download_11(url, filename, item) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        console.log(workbook);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        // console.log(ws['!images']);
        console.log(ws);

        // merge: s(시작),e(끝) r(세로), c(가로)
        // let merge_obj:any = [];
        // merge_obj.push({s: {r: 13, c: 1}, e: {r: 18, c: 4}});
        // ws['!merges'] = ws['!merges'].concat(merge_obj);

        // 업체
        ws.C4.t = 's';
        ws.C4.v = item.company_name;
        ws.C4.r = '<t>'+item.company_name+'</t>';
        ws.C4.h = item.company_name;
        ws.C4.w = item.company_name;
        ws.C4.s.sz = 22;

        // 장비분류(대분류)
        ws.C5.t = 's';
        ws.C5.v = item.equip_type;
        ws.C5.r = '<t>'+item.equip_type+'</t>';
        ws.C5.h = item.equip_type;
        ws.C5.w = item.equip_type;
        ws.C5.s.sz = 22;

        // 장비등록번호
        ws.C6.t = 's';
        ws.C6.v = item.equip_regist_no;
        ws.C6.r = '<t>'+item.equip_regist_no+'</t>';
        ws.C6.h = item.equip_regist_no;
        ws.C6.w = item.equip_regist_no;
        ws.C6.s.sz = 22;

        // 장비 검사일
        ws.C7.t = 's';
        ws.C7.v = item.equip_start_date+' ~ '+item.equip_end_date;
        ws.C7.r = '<t>'+item.equip_start_date+' ~ '+item.equip_end_date+'</t>';
        ws.C7.h = item.equip_start_date+' ~ '+item.equip_end_date;
        ws.C7.w = item.equip_start_date+' ~ '+item.equip_end_date;
        ws.C7.s.sz = 22;

        // 운전원
        ws.C8.t = 's';
        ws.C8.v = item.equip_user_name;
        ws.C8.r = '<t>'+item.equip_user_name+'</t>';
        ws.C8.h = item.equip_user_name;
        ws.C8.w = item.equip_user_name;
        ws.C8.s.sz = 22;

        // 운전원 면허번호
        ws.C9.t = 's';
        ws.C9.v = item.equip_license_no;
        ws.C9.r = '<t>'+item.equip_license_no+'</t>';
        ws.C9.h = item.equip_license_no;
        ws.C9.w = item.equip_license_no;
        ws.C9.s.sz = 22;

        // NFC
        ws.C10.t = 's';
        ws.C10.v = item.nfc_uq_id;
        ws.C10.r = '<t>'+item.nfc_uq_id+'</t>';
        ws.C10.h = item.nfc_uq_id;
        ws.C10.w = item.nfc_uq_id;
        ws.C10.s.sz = 22;

        // 임대업체
        ws.F4.t = 's';
        ws.F4.v = item.rental_company_name;
        ws.F4.r = '<t>'+item.rental_company_name+'</t>';
        ws.F4.h = item.rental_company_name;
        ws.F4.w = item.rental_company_name;
        ws.F4.s.sz = 22;

        // 반입장비(소분류)
        ws.F5.t = 's';
        ws.F5.v = item.equip_sub_type;
        ws.F5.r = '<t>'+item.equip_sub_type+'</t>';
        ws.F5.h = item.equip_sub_type;
        ws.F5.w = item.equip_sub_type;
        ws.F5.s.sz = 22;

        // 장비실 규격
        ws.F6.t = 's';
        ws.F6.v = item.equip_area_standard;
        ws.F6.r = '<t>'+item.equip_area_standard+'</t>';
        ws.F6.h = item.equip_area_standard;
        ws.F6.w = item.equip_area_standard;
        ws.F6.s.sz = 22;

        // 보험 검사일
        ws.F7.t = 's';
        ws.F7.v = item.insur_start_date+' ~ '+item.insur_end_date;
        ws.F7.r = '<t>'+item.insur_start_date+' ~ '+item.insur_end_date+'</t>';
        ws.F7.h = item.insur_start_date+' ~ '+item.insur_end_date;
        ws.F7.w = item.insur_start_date+' ~ '+item.insur_end_date;
        ws.F7.s.sz = 22;

        // 운전원 연락처
        ws.F8.t = 's';
        ws.F8.v = item.equip_user_phone;
        ws.F8.r = '<t>'+item.equip_user_phone+'</t>';
        ws.F8.h = item.equip_user_phone;
        ws.F8.w = item.equip_user_phone;
        ws.F8.s.sz = 22;    
        
        // 장비교대 운전원
        ws.F9.t = 's';
        ws.F9.v = item.equip_sub_user_name;
        ws.F9.r = '<t>'+item.equip_sub_user_name+'</t>';
        ws.F9.h = item.equip_sub_user_name;
        ws.F9.w = item.equip_sub_user_name;
        ws.F9.s.sz = 22;

        // 질문
        if(item.equip_question_data){
          const q_text:any = item.equip_question_data.ctgo_question_data;
          q_text.map((q_item,i) => {
            q_item.index = i + 1;
  
            ws[`B${i+20}`].t = 's';
            ws[`B${i+20}`].v = q_item.index+'. '+q_item.ctgo_equip_question_name;
            ws[`B${i+20}`].r = '<t>'+q_item.index+'. '+q_item.ctgo_equip_question_name+'</t>';
            ws[`B${i+20}`].h = q_item.index+'. '+q_item.ctgo_equip_question_name;
            ws[`B${i+20}`].w = q_item.index+'. '+q_item.ctgo_equip_question_name;
            ws[`B${i+20}`].s.sz = 22;
          });
        }


        // 서명
        if(item.equip_file_data){
          ws['!images'] = [];
          item.equip_file_data.map((item_image,i) => {
            ws['!images'].push({
              "!pos": { r: 12, c: i+1, x: 0, y: 0, w: 300, h: 300},
              "!datatype": "remote",
              "!link": item_image.equip_file
            });
          });
        }



        let theme_arr:any = [];
        for(let i = 0; i < 8; i++){
          if(i == 0) theme_arr.push({width: 8.5});
          else if(i == 7) theme_arr.push({width: 8.5});
          else theme_arr.push({width: 35});
        }
        ws['!cols'] = theme_arr;

        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true, bookSST: true});
        res(ws['!images'][0]['!data']);
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 사용전 장비 엑셀 다운로드
  ex_download_12(url, filename, item) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        console.log(workbook);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        // console.log(ws['!images']);
        console.log(ws);

        // merge: s(시작),e(끝) r(세로), c(가로)
        // let merge_obj:any = [];
        // merge_obj.push({s: {r: 13, c: 1}, e: {r: 18, c: 4}});
        // ws['!merges'] = ws['!merges'].concat(merge_obj);

        // 업체
        ws.C4.t = 's';
        ws.C4.v = item.company_name;
        ws.C4.r = '<t>'+item.company_name+'</t>';
        ws.C4.h = item.company_name;
        ws.C4.w = item.company_name;
        ws.C4.s.sz = 22;

        // 장비분류(대분류)
        ws.C5.t = 's';
        ws.C5.v = item.equip_type;
        ws.C5.r = '<t>'+item.equip_type+'</t>';
        ws.C5.h = item.equip_type;
        ws.C5.w = item.equip_type;
        ws.C5.s.sz = 22;

        // 장비등록번호
        ws.C6.t = 's';
        ws.C6.v = item.equip_regist_no;
        ws.C6.r = '<t>'+item.equip_regist_no+'</t>';
        ws.C6.h = item.equip_regist_no;
        ws.C6.w = item.equip_regist_no;
        ws.C6.s.sz = 22;

        // 장비 검사일
        ws.C7.t = 's';
        ws.C7.v = item.equip_start_date+' ~ '+item.equip_end_date;
        ws.C7.r = '<t>'+item.equip_start_date+' ~ '+item.equip_end_date+'</t>';
        ws.C7.h = item.equip_start_date+' ~ '+item.equip_end_date;
        ws.C7.w = item.equip_start_date+' ~ '+item.equip_end_date;
        ws.C7.s.sz = 22;

        // 운전원
        ws.C8.t = 's';
        ws.C8.v = item.equip_user_name;
        ws.C8.r = '<t>'+item.equip_user_name+'</t>';
        ws.C8.h = item.equip_user_name;
        ws.C8.w = item.equip_user_name;
        ws.C8.s.sz = 22;

        // 운전원 면허번호
        ws.C9.t = 's';
        ws.C9.v = item.equip_license_no;
        ws.C9.r = '<t>'+item.equip_license_no+'</t>';
        ws.C9.h = item.equip_license_no;
        ws.C9.w = item.equip_license_no;
        ws.C9.s.sz = 22;

        // NFC
        ws.C10.t = 's';
        ws.C10.v = item.nfc_uq_id;
        ws.C10.r = '<t>'+item.nfc_uq_id+'</t>';
        ws.C10.h = item.nfc_uq_id;
        ws.C10.w = item.nfc_uq_id;
        ws.C10.s.sz = 22;

        // 점검일자
        ws.F4.t = 's';
        ws.F4.v = item.reg_date;
        ws.F4.r = '<t>'+item.reg_date+'</t>';
        ws.F4.h = item.reg_date;
        ws.F4.w = item.reg_date;
        ws.F4.s.sz = 22;

        // 임대업체
        ws.F5.t = 's';
        ws.F5.v = item.rental_company_name;
        ws.F5.r = '<t>'+item.rental_company_name+'</t>';
        ws.F5.h = item.rental_company_name;
        ws.F5.w = item.rental_company_name;
        ws.F5.s.sz = 22;

        // 반입장비(소분류)
        ws.F6.t = 's';
        ws.F6.v = item.equip_sub_type;
        ws.F6.r = '<t>'+item.equip_sub_type+'</t>';
        ws.F6.h = item.equip_sub_type;
        ws.F6.w = item.equip_sub_type;
        ws.F6.s.sz = 22;

        // 장비실 규격
        ws.F7.t = 's';
        ws.F7.v = item.equip_area_standard;
        ws.F7.r = '<t>'+item.equip_area_standard+'</t>';
        ws.F7.h = item.equip_area_standard;
        ws.F7.w = item.equip_area_standard;
        ws.F7.s.sz = 22;

        // 보험 검사일
        ws.F8.t = 's';
        ws.F8.v = item.insur_start_date+' ~ '+item.insur_end_date;
        ws.F8.r = '<t>'+item.insur_start_date+' ~ '+item.insur_end_date+'</t>';
        ws.F8.h = item.insur_start_date+' ~ '+item.insur_end_date;
        ws.F8.w = item.insur_start_date+' ~ '+item.insur_end_date;
        ws.F8.s.sz = 22;

        // 운전원 연락처
        ws.F9.t = 's';
        ws.F9.v = item.equip_user_phone;
        ws.F9.r = '<t>'+item.equip_user_phone+'</t>';
        ws.F9.h = item.equip_user_phone;
        ws.F9.w = item.equip_user_phone;
        ws.F9.s.sz = 22;    
        
        // 장비교대 운전원
        ws.F10.t = 's';
        ws.F10.v = item.equip_sub_user_name;
        ws.F10.r = '<t>'+item.equip_sub_user_name+'</t>';
        ws.F10.h = item.equip_sub_user_name;
        ws.F10.w = item.equip_sub_user_name;
        ws.F10.s.sz = 22;

        // 질문
        const q_text:any = item.inspection_question_list;
        q_text.map((q_item,i) => {
          q_item.index = i + 1;

          // 질문내용
          ws[`B${i+20}`].t = 's';
          ws[`B${i+20}`].v = q_item.index+'. '+q_item.inspection_question_list.ctgo_equip_question_name;
          ws[`B${i+20}`].r = '<t>'+q_item.index+'. '+q_item.inspection_question_list.ctgo_equip_question_name+'</t>';
          ws[`B${i+20}`].h = q_item.index+'. '+q_item.inspection_question_list.ctgo_equip_question_name;
          ws[`B${i+20}`].w = q_item.index+'. '+q_item.inspection_question_list.ctgo_equip_question_name;
          ws[`B${i+20}`].s.sz = 22;

          // 상태
          ws[`F${i+20}`].t = 's';
          ws[`F${i+20}`].v = q_item.inspection_state;
          ws[`F${i+20}`].r = '<t>'+q_item.inspection_state+'</t>';
          ws[`F${i+20}`].h = q_item.inspection_state;
          ws[`F${i+20}`].w = q_item.inspection_state;
          ws[`F${i+20}`].s.sz = 22;

          // 비고
          ws[`G${i+20}`].t = 's';
          ws[`G${i+20}`].v = q_item.inspection_comment;
          ws[`G${i+20}`].r = '<t>'+q_item.inspection_comment+'</t>';
          ws[`G${i+20}`].h = q_item.inspection_comment;
          ws[`G${i+20}`].w = q_item.inspection_comment;
          ws[`G${i+20}`].s.sz = 22;
        });

        // 서명
        ws['!images'] = [];
        if(item.equip_file_data){
          item.equip_file_data.map((item_image,i) => {
            ws['!images'].push({
              "!pos": { r: 12, c: i+1, x: 0, y: 0, w: 300, h: 300},
              "!datatype": "remote",
              "!link": item_image.equip_file
            });
          });
        }



        let theme_arr:any = [];
        for(let i = 0; i < 8; i++){
          if(i == 0) theme_arr.push({width: 8.5});
          else if(i == 7) theme_arr.push({width: 8.5});
          else theme_arr.push({width: 35});
        }
        ws['!cols'] = theme_arr;

        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true, bookSST: true});
        res(ws['!images'][0]['!data']);
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 상세인적사항 엑셀 다운로드 
  ex_download_13(url, filename, item, item_1) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        // console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        // console.log('----------');
        // console.log(item_1);
        // console.log('----------');
        // console.log('----------');
        // console.log(item);
        // console.log('----------');
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        // console.log(ws['!images']);
        console.log(ws);

        // merge: s(시작),e(끝) r(세로), c(가로)
        // let merge_obj:any = [];
        // merge_obj.push({s: {r: 13, c: 1}, e: {r: 18, c: 4}});
        // ws['!merges'] = ws['!merges'].concat(merge_obj);

        // 성명
        ws.D4.t = 's';
        ws.D4.v = item.user_name ? item.user_name : '';
        ws.D4.r = '<t>'+(item.user_name ? item.user_name : '')+'</t>';
        ws.D4.h = item.user_name ? item.user_name : '';
        ws.D4.w = item.user_name ? item.user_name : '';
        ws.D4.s.sz = 22;

        // 생년월일
        ws.D5.t = 's';
        ws.D5.v = item.user_birthday ? item.user_birthday : '';
        ws.D5.r = '<t>'+(item.user_birthday ? item.user_birthday : '')+'</t>';
        ws.D5.h = item.user_birthday ? item.user_birthday : '';
        ws.D5.w = item.user_birthday ? item.user_birthday : '';
        ws.D5.s.sz = 22;

        // 주소
        ws.D6.t = 's';
        ws.D6.v = item.user_address ? item.user_address : '';
        ws.D6.r = '<t>'+(item.user_address ? item.user_address : '')+'</t>';
        ws.D6.h = item.user_address ? item.user_address : '';
        ws.D6.w = item.user_address ? item.user_address : '';
        ws.D6.s.sz = 22;

        // 업체
        ws.D7.t = 's';
        ws.D7.v = item.company_name ? item.company_name : '';
        ws.D7.r = '<t>'+(item.company_name ? item.company_name : '')+'</t>';
        ws.D7.h = item.company_name ? item.company_name : '';
        ws.D7.w = item.company_name ? item.company_name : '';
        ws.D7.s.sz = 22;

        // 공종
        ws.D8.t = 's';
        ws.D8.v = item.construction_name ? item.construction_name : item.construction_name_etc;
        ws.D8.r = '<t>'+item.construction_name ? item.construction_name : item.construction_name_etc+'</t>';
        ws.D8.h = item.construction_name ? item.construction_name : item.construction_name_etc;
        ws.D8.w = item.construction_name ? item.construction_name : item.construction_name_etc;
        ws.D8.s.sz = 22;

        // 경력
        ws.D9.t = 's';
        ws.D9.v = item.user_career ? item.user_career : '';
        ws.D9.r = '<t>'+(item.user_career ? item.user_career : '')+'</t>';
        ws.D9.h = item.user_career ? item.user_career : '';
        ws.D9.w = item.user_career ? item.user_career : '';
        ws.D9.s.sz = 22;

        // 연락처
        ws.I4.t = 's';
        ws.I4.v = item.user_phone ? item.user_phone : '';
        ws.I4.r = '<t>'+(item.user_phone ? item.user_phone : '')+'</t>';
        ws.I4.h = item.user_phone ? item.user_phone : '';
        ws.I4.w = item.user_phone ? item.user_phone : '';
        ws.I4.s.sz = 22;

        // 연령
        ws.I5.t = 's';
        ws.I5.v = item.user_birthday ? this.ageTrans(item.user_birthday) : '';
        ws.I5.r = '<t>'+(item.user_birthday ? this.ageTrans(item.user_birthday) : '')+'</t>';
        ws.I5.h = item.user_birthday ? this.ageTrans(item.user_birthday) : '';
        ws.I5.w = item.user_birthday ? this.ageTrans(item.user_birthday) : '';
        ws.I5.s.sz = 22;

        // 상세주소
        ws.I6.t = 's';
        ws.I6.v = item.user_address_detail ? item.user_address_detail : '';
        ws.I6.r = '<t>'+(item.user_address_detail ? item.user_address_detail : '')+'</t>';
        ws.I6.h = item.user_address_detail ? item.user_address_detail : '';
        ws.I6.w = item.user_address_detail ? item.user_address_detail : '';
        ws.I6.s.sz = 22;

        // 건설업 기초안전 보건교육 이수일자
        ws.I7.t = 's';
        ws.I7.v = item.edu_date ? item.edu_date : '';
        ws.I7.r = '<t>'+(item.edu_date ? item.edu_date : '')+'</t>';
        ws.I7.h = item.edu_date ? item.edu_date : '';
        ws.I7.w = item.edu_date ? item.edu_date : '';
        ws.I7.s.sz = 22;

        // 직종
        ws.I8.t = 's';
        ws.I8.v = item.occupation_name ? item.occupation_name : item.occupation_name_etc;
        ws.I8.r = '<t>'+item.occupation_name ? item.occupation_name : item.occupation_name_etc+'</t>';
        ws.I8.h = item.occupation_name ? item.occupation_name : item.occupation_name_etc;
        ws.I8.w = item.occupation_name ? item.occupation_name : item.occupation_name_etc;
        ws.I8.s.sz = 22;

        // 국적
        ws.I9.t = 's';
        ws.I9.v = item.user_nationality ? item.user_nationality : '';
        ws.I9.r = '<t>'+(item.user_nationality ? item.user_nationality : '')+'</t>';
        ws.I9.h = item.user_nationality ? item.user_nationality : '';
        ws.I9.w = item.user_nationality ? item.user_nationality : '';
        ws.I9.s.sz = 22;    

        // 성명
        ws.D13.t = 's';
        ws.D13.v = item.user_sos_name ? item.user_sos_name : '';
        ws.D13.r = '<t>'+(item.user_sos_name ? item.user_sos_name : '')+'</t>';
        ws.D13.h = item.user_sos_name ? item.user_sos_name : '';
        ws.D13.w = item.user_sos_name ? item.user_sos_name : '';
        ws.D13.s.sz = 22;    
        
        // 가족관계
        ws.D14.t = 's';
        ws.D14.v = item.user_sos_family ? item.user_sos_family : '';
        ws.D14.r = '<t>'+item.user_sos_family ? item.user_sos_family : ''+'</t>';
        ws.D14.h = item.user_sos_family ? item.user_sos_family : '';
        ws.D14.w = item.user_sos_family ? item.user_sos_family : '';
        ws.D14.s.sz = 22;

        // 연락처
        ws.D15.t = 's';
        ws.D15.v = item.user_sos_phone ? item.user_sos_phone : '';
        ws.D15.r = '<t>'+(item.user_sos_phone ? item.user_sos_phone : '')+'</t>';
        ws.D15.h = item.user_sos_phone ? item.user_sos_phone : '';
        ws.D15.w = item.user_sos_phone ? item.user_sos_phone : '';
        ws.D15.s.sz = 22;

        if(item_1){
          // 안전모
          ws.D28.t = 's';
          ws.D28.v = item_1.helmet ? 'O' : 'X';
          ws.D28.r = '<t>'+item_1.helmet ? 'O' : 'X'+'</t>';
          ws.D28.h = item_1.helmet ? 'O' : 'X';
          ws.D28.w = item_1.helmet ? 'O' : 'X';
          ws.D28.s.sz = 22;

          // 안전화
          ws.D29.t = 's';
          ws.D29.v = item_1.shoes ? 'O' : 'X';
          ws.D29.r = '<t>'+item_1.shoes ? 'O' : 'X'+'</t>';
          ws.D29.h = item_1.shoes ? 'O' : 'X';
          ws.D29.w = item_1.shoes ? 'O' : 'X';
          ws.D29.s.sz = 22;

          // 안전대
          ws.D30.t = 's';
          ws.D30.v = item_1.belt ? 'O' : 'X';
          ws.D30.r = '<t>'+item_1.belt ? 'O' : 'X'+'</t>';
          ws.D30.h = item_1.belt ? 'O' : 'X';
          ws.D30.w = item_1.belt ? 'O' : 'X';
          ws.D30.s.sz = 22;

          // 각반
          ws.D31.t = 's';
          ws.D31.v = item_1.gaiters ? 'O' : 'X';
          ws.D31.r = '<t>'+item_1.gaiters ? 'O' : 'X'+'</t>';
          ws.D31.h = item_1.gaiters ? 'O' : 'X';
          ws.D31.w = item_1.gaiters ? 'O' : 'X';
          ws.D31.s.sz = 22;

          // 조끼
          ws.D32.t = 's';
          ws.D32.v = item_1.vest ? 'O' : 'X';
          ws.D32.r = '<t>'+item_1.vest ? 'O' : 'X'+'</t>';
          ws.D32.h = item_1.vest ? 'O' : 'X';
          ws.D32.w = item_1.vest ? 'O' : 'X';
          ws.D32.s.sz = 22;
        }

        

        // 보호구 지급 내역
        // ws.F10.ㅇ= 's';
        // ws.F10.v = item.equip_sub_user_name;
        // ws.F10.r = '<t>'+item.equip_sub_user_name+'</t>';
        // ws.F10.h = item.equip_sub_user_name;
        // ws.F10.w = item.equip_sub_user_name;
        // ws.F10.s.sz = 22;

        // 서명
        // ws['!images'] = [];
        // item.equip_file_data.map((item_image,i) => {
        //   ws['!images'].push({
        //     "!pos": { r: 12, c: i+1, x: 0, y: 0, w: 300, h: 300},
        //     "!datatype": "remote",
        //     "!link": item_image.equip_file
        //   });
        // });

        // 사진
        ws['!images'] = [];
        ws['!images'].push({
          "!pos": { r: 18, c: 1, x: 0, y: 0, w: 600, h: 600},
          "!datatype": "remote",
          "!link": item.user_profile_file
        });


        let theme_arr:any = [];
        for(let i = 0; i < 12; i++){
          if(i == 0) theme_arr.push({width: 8});
          else if(i == 11) theme_arr.push({width: 8});
          else theme_arr.push({width: 21});
        }
        ws['!cols'] = theme_arr;

        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});
        res(ws['!images'][0]['!data']);
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 상세인적사항 엑셀 다운로드 
  ex_download_14(url, filename, item, item_1, item_2) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";

      // console.log('----------');
      // console.log(item_1);
      // console.log('----------');
      // console.log('----------');
      // console.log(company_name);
      // console.log('----------');
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});

        const ws = workbook.Sheets[workbook.SheetNames[0]];
        // console.log(ws['!images']);
        console.log(ws);

        // merge: s(시작),e(끝) r(세로), c(가로)
        // let merge_obj:any = [];
        // merge_obj.push({s: {r: 13, c: 1}, e: {r: 18, c: 4}});
        // ws['!merges'] = ws['!merges'].concat(merge_obj);

        // 업체명
        ws.C4.t = 's';
        ws.C4.v = item_2.company_name;
        ws.C4.r = '<t>'+item_2.company_name+'</t>';
        ws.C4.h = item_2.company_name;
        ws.C4.w = item_2.company_name;
        ws.C4.s.sz = 26;

        // 세부공종
        ws.C5.t = 's';
        ws.C5.v = item.ra_construction_name;
        ws.C5.r = '<t>'+item.ra_construction_name+'</t>';
        ws.C5.h = item.ra_construction_name;
        ws.C5.w = item.ra_construction_name;
        ws.C5.s.sz = 26;

        // 작업단계
        ws.C6.t = 's';
        ws.C6.v = item.ra_workstep;
        ws.C6.r = '<t>'+item.ra_workstep+'</t>';
        ws.C6.h = item.ra_workstep;
        ws.C6.w = item.ra_workstep;
        ws.C6.s.sz = 26;

        // -------------------------------------------------------

        // 평가기간
        ws.C7.t = 's';
        ws.C7.v = item_1.ra_work_start_date+' ~ '+item_1.ra_work_end_date;
        ws.C7.r = '<t>'+item_1.ra_work_start_date+' ~ '+item_1.ra_work_end_date+'</t>';
        ws.C7.h = item_1.ra_work_start_date+' ~ '+item_1.ra_work_end_date;
        ws.C7.w = item_1.ra_work_start_date+' ~ '+item_1.ra_work_end_date;
        ws.C7.s.sz = 26;

        // 점검자
        if(item_1.checker_data){
          ws.G4.t = 's';
          ws.G4.v = item_1.checker_data.user_name;
          ws.G4.r = '<t>'+item_1.checker_data.user_name+'</t>';
          ws.G4.h = item_1.checker_data.user_name;
          ws.G4.w = item_1.checker_data.user_name;
          ws.G4.s.sz = 26;
        }


        // 사용장비
        ws.G5.t = 's';
        ws.G5.v = item_1.ra_equipment;
        ws.G5.r = '<t>'+item_1.ra_equipment+'</t>';
        ws.G5.h = item_1.ra_equipment;
        ws.G5.w = item_1.ra_equipment;
        ws.G5.s.sz = 26;

        // 작업위치
        ws.G6.t = 's';
        ws.G6.v = item_1.ra_work_area+(item.ra_work_area_detail ? '('+ item.ra_work_area_detail +')' : '');
        ws.G6.r = '<t>'+item_1.ra_work_area+(item.ra_work_area_detail ? '('+ item.ra_work_area_detail +')' : '')+'</t>';
        ws.G6.h = item_1.ra_work_area+(item.ra_work_area_detail ? '('+ item.ra_work_area_detail +')' : '');
        ws.G6.w = item_1.ra_work_area+(item.ra_work_area_detail ? '('+ item.ra_work_area_detail +')' : '');
        ws.G6.s.sz = 26;

        // 인원
        ws.G7.t = 's';
        ws.G7.v = item_1.ra_worker_no;
        ws.G7.r = '<t>'+item_1.ra_worker_no+'</t>';
        ws.G7.h = item_1.ra_worker_no;
        ws.G7.w = item_1.ra_worker_no;
        ws.G7.s.sz = 26;

        // 점검일자
        ws.I2.t = 's';
        ws.I2.v = item_2.reg_date;
        ws.I2.r = '<t>'+item_2.reg_date+'</t>';
        ws.I2.h = item_2.reg_date;
        ws.I2.w = item_2.reg_date;
        ws.I2.s.sz = 26;


        let length_1:number = 10; // 아이템 길이
        let length_2:number = 0; // 간격길이
        let merge_obj:any = [];

        // let option_default:any = {
        //   alignment: {vertical: "center", horizontal: "center", wrapText: true},
        //   bottom: {style: "thin", color: {rgb: "000000"}},
        //   color: {theme: 1, rgb: "000000"},
        //   family: 2,
        //   left: {style: "thin", color: {rgb: "000000"}},
        //   name: "굴림",
        //   patternType: "none",
        //   sz: 22,
        //   top: {style: "thin", color: {rgb: "000000"}},
        //   right: {style: "thin", color: {rgb: "000000"}}
        // }

        let option_default_1:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 26,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}},
          bold: true
        }

        let option_default_2:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          fgColor: { rgb: "#CCFFFF" },
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 26,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}},
          bold: true
        }

        let option_default_3:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 26,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        let option_default_4:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          name: "굴림",
          patternType: "none",
          sz: 26,
        }
        // let test_theme:any = [];
        // test_theme.push(item_1.factor_data[0]);
        // test_theme.push(item_1.factor_data[0]);
        // test_theme.push(item_1.factor_data[0]);
        // test_theme.push(item_1.factor_data[0]);
        // item_1.factor_data
        item_1.factor_data.map((item, i) => {
          merge_obj.push({s: {r: length_1+length_2, c: 2}, e: {r: length_1+length_2, c: 4}});
          merge_obj.push({s: {r: length_1+1+length_2, c: 2}, e: {r: length_1+1+length_2, c: 4}});
          merge_obj.push({s: {r: length_1+2+length_2, c: 2}, e: {r: length_1+2+length_2, c: 4}});
          merge_obj.push({s: {r: length_1+2+length_2, c: 6}, e: {r: length_1+2+length_2, c: 8}});

          console.log('-------- ggggg');
          console.log(item.ra_frequency);
          console.log('-------- ggggg');
          ws[`B${length_1+1+length_2}`] = {s: option_default_1,t: "s",v: '위험요인',w: '위험요인',h: '위험요인',r: '<t>위험요인</t>',z: "General"};
          ws[`B${length_1+2+length_2}`] = {s: option_default_1,t: "s",v: '관리방안',w: '관리방안',h: '관리방안',r: '<t>관리방안</t>',z: "General"};
          ws[`B${length_1+3+length_2}`] = {s: option_default_1,t: "s",v: '상태',w: '상태',h: '상태',r: '<t>상태</t>',z: "General"};
          ws[`F${length_1+1+length_2}`] = {s: option_default_2,t: "s",v: '피해형태',w: '피해형태',h: '피해형태',r: '<t>피해형태</t>',z: "General"};
          ws[`F${length_1+2+length_2}`] = {s: option_default_3,t: "s",v: item.ra_damage_form,w: item.ra_damage_form,h: item.ra_damage_form,r: '<t>'+item.ra_damage_form+'</t>',z: "General"};
          ws[`F${length_1+3+length_2}`] = {s: option_default_1,t: "s",v: '비고(불량 사유)',w: '비고(불량 사유)',h: '비고(불량 사유)',r: '<t>비고(불량 사유)</t>',z: "General"};
          ws[`G${length_1+1+length_2}`] = {s: option_default_2,t: "s",v: '빈도',w: '빈도',h: '빈도',r: '<t>빈도</t>',z: "General"};
          ws[`G${length_1+2+length_2}`] = {s: option_default_3,t: "s",v: item.ra_frequency,w: item.ra_frequency,h: item.ra_frequency,r: '<t>'+item.ra_frequency+'</t>',z: "General"};
          ws[`H${length_1+1+length_2}`] = {s: option_default_2,t: "s",v: '강도',w: '강도',h: '강도',r: '<t>강도</t>',z: "General"};
          ws[`H${length_1+2+length_2}`] = {s: option_default_3,t: "s",v: item.ra_strength,w: item.ra_strength,h: item.ra_strength,r: '<t>'+item.ra_strength+'</t>',z: "General"};
          ws[`I${length_1+1+length_2}`] = {s: option_default_2,t: "s",v: '등급',w: '등급',h: '등급',r: '<t>등급</t>',z: "General"};
          ws[`I${length_1+2+length_2}`] = {s: option_default_3,t: "s",v: item.ra_rating,w: item.ra_rating,h: item.ra_rating,r: '<t>'+item.ra_rating+'</t>',z: "General"};
          XLSX.utils.sheet_set_range_style(
            ws, // worksheet
            `C${length_1+1+length_2}:E${length_1+1+length_2}`, // range
            { // style object
              fgColor: { rgb: 0xFFFFFF }, // blue solid background
              color: { rgb: 0x000000 }, // white text
              top: { style: "thin", color: { rgb: 0x000000 } }, // thick yellow border
              bottom: { style: "thin", color: { rgb: 0x000000 } }, // red thick border
              left: { style: "thin", color: { rgb: 0x000000 } }, // green dashed border
              right: { style: "thin", color: { rgb: 0x000000 } } // green dashed border
            }
          );

          XLSX.utils.sheet_set_range_style(
            ws, // worksheet
            `C${length_1+2+length_2}:E${length_1+2+length_2}`, // range
            { // style object
              fgColor: { rgb: 0xFFFFFF }, // blue solid background
              color: { rgb: 0x000000 }, // white text
              top: { style: "thin", color: { rgb: 0x000000 } }, // thick yellow border
              bottom: { style: "thin", color: { rgb: 0x000000 } }, // red thick border
              left: { style: "thin", color: { rgb: 0x000000 } }, // green dashed border
              right: { style: "thin", color: { rgb: 0x000000 } } // green dashed border
            }
          );

          XLSX.utils.sheet_set_range_style(
            ws, // worksheet
            `C${length_1+3+length_2}:E${length_1+3+length_2}`, // range
            { // style object
              fgColor: { rgb: 0xFFFFFF }, // blue solid background
              color: { rgb: 0x000000 }, // white text
              top: { style: "thin", color: { rgb: 0x000000 } }, // thick yellow border
              bottom: { style: "thin", color: { rgb: 0x000000 } }, // red thick border
              left: { style: "thin", color: { rgb: 0x000000 } }, // green dashed border
              right: { style: "thin", color: { rgb: 0x000000 } } // green dashed border
            }
          );

          XLSX.utils.sheet_set_range_style(
            ws, // worksheet
            `G${length_1+3+length_2}:I${length_1+3+length_2}`, // range
            { // style object
              fgColor: { rgb: 0xFFFFFF }, // blue solid background
              color: { rgb: 0x000000 }, // white text
              top: { style: "thin", color: { rgb: 0x000000 } }, // thick yellow border
              bottom: { style: "thin", color: { rgb: 0x000000 } }, // red thick border
              left: { style: "thin", color: { rgb: 0x000000 } }, // green dashed border
              right: { style: "thin", color: { rgb: 0x000000 } } // green dashed border
            }
          );

          // XLSX.utils.sheet_set_range_style(
          //   ws, // worksheet
          //   `B${length_1+4}:I${length_1+4}`, // range
          //   { // style object
          //     bottom: { style: "thin", color: { rgb: 0x000000 } }, // red thick border
          //   }
          // );

          // // 위험요인
          ws[`C${length_1+1+length_2}`].t = 's';
          ws[`C${length_1+1+length_2}`].v = item.ra_risk_factor;
          ws[`C${length_1+1+length_2}`].r = '<t>'+item.ra_risk_factor+'</t>';
          ws[`C${length_1+1+length_2}`].h = item.ra_risk_factor;
          ws[`C${length_1+1+length_2}`].w = item.ra_risk_factor;
          ws[`C${length_1+1+length_2}`].s.sz = 26;
          // ws[`C${length_1+1}`] = {s: option_default,t: "s",v: item.ra_risk_factor,w: item.ra_risk_factor,h: item.ra_risk_factor,r: '<t>'+item.ra_risk_factor+'</t>',z: "General"};

          // // 관리방안
          ws[`C${length_1+2+length_2}`].t = 's';
          ws[`C${length_1+2+length_2}`].v = item.ra_management_method;
          ws[`C${length_1+2+length_2}`].r = '<t>'+item.ra_management_method+'</t>';
          ws[`C${length_1+2+length_2}`].h = item.ra_management_method;
          ws[`C${length_1+2+length_2}`].w = item.ra_management_method;
          ws[`C${length_1+2+length_2}`].s.sz = 26;
          // ws[`C${length_1+2}`] = {s: option_default,t: "s",v: item.ra_management_method,w: item.ra_management_method,h: item.ra_management_method,r: '<t>'+item.ra_management_method+'</t>',z: "General"};

          // // 상태
          ws[`C${length_1+3+length_2}`].t = 's';
          ws[`C${length_1+3+length_2}`].v = item.inspection_state;
          ws[`C${length_1+3+length_2}`].r = '<t>'+item.inspection_state+'</t>';
          ws[`C${length_1+3+length_2}`].h = item.inspection_state;
          ws[`C${length_1+3+length_2}`].w = item.inspection_state;
          ws[`C${length_1+3+length_2}`].s.sz = 26;
          // ws[`C${length_1+3}`] = {s: option_default,t: "s",v: item.inspection_state,w: item.inspection_state,h: item.inspection_state,r: '<t>'+item.inspection_state+'</t>',z: "General"};

          // // 비고
          ws[`G${length_1+3+length_2}`].t = 's';
          ws[`G${length_1+3+length_2}`].v = item.ra_admin_comment;
          ws[`G${length_1+3+length_2}`].r = '<t>'+item.ra_admin_comment+'</t>';
          ws[`G${length_1+3+length_2}`].h = item.ra_admin_comment;
          ws[`G${length_1+3+length_2}`].w = item.ra_admin_comment;
          ws[`G${length_1+3+length_2}`].s.sz = 26;
          // ws[`G${length_1+3}`] = {s: option_default,t: "s",v: item.ra_admin_comment,w: item.ra_admin_comment,h: item.ra_admin_comment,r: '<t>'+item.ra_admin_comment+'</t>',z: "General"};

          length_2 = length_2+4;
        });
        const ref = `A1:J3000`;
        ws['!merges'] = ws['!merges'].concat(merge_obj);
        ws['!ref'] = ref;
        


        let theme_arr:any = [];
        for(let i = 0; i < 10; i++){
          if(i == 0) theme_arr.push({width: 8});
          else if(i == 9) theme_arr.push({width: 8});
          else theme_arr.push({width: 32});
        }
        ws['!cols'] = theme_arr;

        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});
        // res(ws['!images'][0]['!data']);
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 상세인적사항 엑셀 다운로드 
  ex_download_15(url, filename, item, item_1) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";

      // console.log('----------');
      // console.log(item_1);
      // console.log('----------');
      // console.log('----------');
      // console.log(company_name);
      // console.log('----------');
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});

        const ws = workbook.Sheets[workbook.SheetNames[0]];
        // console.log(ws['!images']);
        console.log(ws);

        // merge: s(시작),e(끝) r(세로), c(가로)
        // let merge_obj:any = [];
        // merge_obj.push({s: {r: 13, c: 1}, e: {r: 18, c: 4}});
        // ws['!merges'] = ws['!merges'].concat(merge_obj);

        let h_length:number = 7; // 프린트 열 수

        // 출역일자
        ws.H2.t = 's';
        ws.H2.v = item.nfc_date;
        ws.H2.r = '<t>'+item.nfc_date+'</t>';
        ws.H2.h = item.nfc_date;
        ws.H2.w = item.nfc_date;
        ws.H2.s.sz = 22;

        // 관리자 인원
        ws.C3.t = 's';
        ws.C3.v = item.admin_count;
        ws.C3.r = '<t>'+item.admin_count+'</t>';
        ws.C3.h = item.admin_count;
        ws.C3.w = item.admin_count;
        ws.C3.s.sz = 28;

        // 근로자 인원
        ws.C4.t = 's';
        ws.C4.v = item.user_count;
        ws.C4.r = '<t>'+item.user_count+'</t>';
        ws.C4.h = item.user_count;
        ws.C4.w = item.user_count;
        ws.C4.s.sz = 28;

        let option_default:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 22,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        // let moinor:number = -1;
        // for(let i = 1; i < 200; i++){
        //   if(i == 28) h_length = h_length+6;
        //   else if(i%28 == 0 && i != 28) h_length = h_length+5;
        //   ws[`B${h_length}`] = {s: option_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
        //   ws[`C${h_length}`] = {s: option_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
        //   ws[`D${h_length}`] = {s: option_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
        //   ws[`E${h_length}`] = {s: option_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
        //   ws[`F${h_length}`] = {s: option_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
        //   ws[`G${h_length}`] = {s: option_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
        //   ws[`H${h_length}`] = {s: option_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
        //   ws[`I${h_length}`] = {s: option_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
        //   h_length++;
        // }
        console.log('item - ', item_1);
        item_1.map((item_d) => {
          // if(item_1.index == 25) h_length = h_length+3;

          // if(item.index == 28) h_length = h_length+6;
          // else if(item.index%28 == 0 && item.index != 28) h_length = h_length+5;

          ws[`B${h_length}`] = {s: option_default,t: "s",v: item_d.index,w: item_d.index,h: item_d.index,r: '<t>'+item_d.index+'</t>',z: "General"};
          ws[`C${h_length}`] = {s: option_default,t: "s",v: item_d.user_name ? item_d.user_name : '',w: item_d.user_name ? item_d.user_name : '',h: item_d.user_name ? item_d.user_name : '',r: '<t>'+item_d.user_name ? item_d.user_name : ''+'</t>',z: "General"};
          ws[`D${h_length}`] = {s: option_default,t: "s",v: item_d.company_name ? item_d.company_name : '',w: item_d.company_name ? item_d.company_name : '',h: item_d.company_name ? item_d.company_name : '',r: '<t>'+item_d.company_name ? item_d.company_name : ''+'</t>',z: "General"};
          ws[`E${h_length}`] = {s: option_default,t: "s",v: item_d.construction_name ? item_d.construction_name : '',w: item_d.construction_name ? item_d.construction_name : '',h: item_d.construction_name ? item_d.construction_name : '',r: '<t>'+item_d.construction_name ? item_d.construction_name : ''+'</t>',z: "General"};
          ws[`F${h_length}`] = {s: option_default,t: "s",v: item_d.occupation_name ? item_d.occupation_name : '',w: item_d.occupation_name ? item_d.occupation_name : '',h: item_d.occupation_name ? item_d.occupation_name : '',r: '<t>'+item_d.occupation_name ? item_d.occupation_name : ''+'</t>',z: "General"};
          ws[`G${h_length}`] = {s: option_default,t: "s",v: item_d.enter_datetime ? item_d.enter_datetime : '',w: item_d.enter_datetime ? item_d.enter_datetime : '',h: item_d.enter_datetime ? item_d.enter_datetime : '',r: '<t>'+item_d.enter_datetime ? item_d.enter_datetime : ''+'</t>',z: "General"};
          ws[`H${h_length}`] = {s: option_default,t: "s",v: item_d.out_datetime ? item_d.out_datetime : '',w: item_d.out_datetime ? item_d.out_datetime : '',h: item_d.out_datetime ? item_d.out_datetime : '',r: '<t>'+item_d.out_datetime ? item_d.out_datetime : ''+'</t>',z: "General"};

          h_length++;
        });




        // length_1 = length_1+44;
        // length_3 = length_3+2;

        // 열 높이 맞추기
        
        for(let i = 35; i < h_length; i++) ws['!rows'].push({hpt:80, hpx:100});
        ws['!rows'][33] = {hpt:80, hpx:100};
        ws['!rows'][34] = {hpt:80, hpx:100};

        // A4크기로 영역 지정하기
        // length_2 = length_2+23+degi_num;
        ws['!print'].area = {s:{r:0,c:0},e:{r:h_length > 29 ? h_length : 29,c:8}};

        let arr_langth = 10+h_length;
        const ref = `A1:I${arr_langth}`;
        ws['!ref'] = ref;
        


        let theme_arr:any = [];
        for(let i = 0; i < 10; i++){
          if(i == 0) theme_arr.push({width: 8});
          else if(i == 6) theme_arr.push({width: 46});
          else if(i == 7) theme_arr.push({width: 46});
          else if(i == 8) theme_arr.push({width: 8});
          else theme_arr.push({width: 27});
        }
        ws['!cols'] = theme_arr;

        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});
        // res(ws['!images'][0]['!data']);
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 작업내용변경시 교육 엑셀 다운로드
  ex_download_16(url, filename, item) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        // console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        // console.log(workbook);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        const ws_2 = workbook.Sheets[workbook.SheetNames[1]];
        const ws_3 = workbook.Sheets[workbook.SheetNames[2]];
        
        // 현장명 1
        ws_2.B3.t = 's';
        ws_2.B3.v = '현장명 : '+this.connect.area;
        ws_2.B3.r = '<t>현장명 : '+this.connect.area+'</t>';
        ws_2.B3.h = '현장명 : '+this.connect.area;
        ws_2.B3.w = '현장명 : '+this.connect.area;
        ws_2.B3.s.sz = 12;

        // 제목
        ws_2.B3.t = 's';
        ws_2.B3.v = '제목 : '+item.edu_workedit_title;
        ws_2.B3.r = '<t>제목 : '+item.edu_workedit_title+'</t>';
        ws_2.B3.h = '제목 : '+item.edu_workedit_title;
        ws_2.B3.w = '제목 : '+item.edu_workedit_title;
        ws_2.B3.s.sz = 11;

        
        // 현장명 2
        ws_3.C4.t = 's';
        ws_3.C4.v = this.connect.area;
        ws_3.C4.r = '<t>'+this.connect.area+'</t>';
        ws_3.C4.h = this.connect.area;
        ws_3.C4.w = this.connect.area;
        ws_3.C4.s.sz = 11;
        
        // 일자
        ws.O6.t = 's';
        ws.O6.v = this.date.koreanText_week(item.edu_workedit_date);
        ws.O6.r = '<t>'+this.date.koreanText_week(item.edu_workedit_date)+'</t>';
        ws.O6.h = this.date.koreanText_week(item.edu_workedit_date);
        ws.O6.w = this.date.koreanText_week(item.edu_workedit_date);
        ws.O6.s.sz = 11;

        // 교육대상 근로자 남자
        ws.L11.t = 'n';
        ws.L11.v = item.target_man_count;
        ws.L11.r = '<t>'+item.target_man_count+'</t>';
        ws.L11.h = item.target_man_count;
        ws.L11.w = item.target_man_count;
        ws.L11.s.sz = 11;

        // 교육대상 근로자 여자
        ws.N11.t = 'n';
        ws.N11.v = item.target_woman_count;
        ws.N11.r = '<t>'+item.target_woman_count+'</t>';
        ws.N11.h = item.target_woman_count;
        ws.N11.w = item.target_woman_count;
        ws.N11.s.sz = 11;

        // 교육실시 근로자 남자
        ws.L12.t = 'n';
        ws.L12.v = item.practice_man_count;
        ws.L12.r = '<t>'+item.practice_man_count+'</t>';
        ws.L12.h = item.practice_man_count;
        ws.L12.w = item.practice_man_count;
        ws.L12.s.sz = 11;

        // 교육실시 근로자 여자
        ws.N12.t = 'n';
        ws.N12.v = item.practice_woman_count;
        ws.N12.r = '<t>'+item.practice_woman_count+'</t>';
        ws.N12.h = item.practice_woman_count;
        ws.N12.w = item.practice_woman_count;
        ws.N12.s.sz = 11;

        // 교육미실시 근로자 남자
        ws.L13.t = 'n';
        ws.L13.v = item.not_man_count;
        ws.L13.r = '<t>'+item.not_man_count+'</t>';
        ws.L13.h = item.not_man_count;
        ws.L13.w = item.not_man_count;
        ws.L13.s.sz = 11;

        // 교육미실시 근로자 여자
        ws.N13.t = 'n';
        ws.N13.v = item.not_woman_count;
        ws.N13.r = '<t>'+item.not_woman_count+'</t>';
        ws.N13.h = item.not_woman_count;
        ws.N13.w = item.not_woman_count;
        ws.N13.s.sz = 11;

        // 교육대상
        ws.P11.t = 's';
        ws.P11.v = item.edu_workedit_target;
        ws.P11.r = '<t>'+item.edu_workedit_target+'</t>';
        ws.P11.h = item.edu_workedit_target;
        ws.P11.w = item.edu_workedit_target;
        ws.P11.s.sz = 11;

        // 과목 또는 사항
        ws.D15.t = 's';
        ws.D15.v = item.edu_workedit_subject;
        ws.D15.r = '<t>'+item.edu_workedit_subject+'</t>';
        ws.D15.h = item.edu_workedit_subject;
        ws.D15.w = item.edu_workedit_subject;
        ws.D15.s.sz = 11;

        // 교육방법
        ws.I15.t = 's';
        ws.I15.v = item.edu_workedit_method;
        ws.I15.r = '<t>'+item.edu_workedit_method+'</t>';
        ws.I15.h = item.edu_workedit_method;
        ws.I15.w = item.edu_workedit_method;
        ws.I15.s.sz = 11;

        // 교육내용의 개요
        ws.L15.t = 's';
        ws.L15.v = item.edu_workedit_summary;
        ws.L15.r = '<t>'+item.edu_workedit_summary+'</t>';
        ws.L15.h = item.edu_workedit_summary;
        ws.L15.w = item.edu_workedit_summary;
        ws.L15.s.sz = 11;

        // 교육시간
        ws.S15.t = 's';
        ws.S15.v = item.edu_workedit_time;
        ws.S15.r = '<t>'+item.edu_workedit_time+'</t>';
        ws.S15.h = item.edu_workedit_time;
        ws.S15.w = item.edu_workedit_time;
        ws.S15.s.sz = 11;
        
        // 사용교재
        ws.U15.t = 's';
        ws.U15.v = item.edu_workedit_book;
        ws.U15.r = '<t>'+item.edu_workedit_book+'</t>';
        ws.U15.h = item.edu_workedit_book;
        ws.U15.w = item.edu_workedit_book;
        ws.U15.s.sz = 11;

        // 직급
        ws.D26.t = 's';
        ws.D26.v = item.educator_rank;
        ws.D26.r = '<t>'+item.educator_rank+'</t>';
        ws.D26.h = item.educator_rank;
        ws.D26.w = item.educator_rank;
        ws.D26.s.sz = 11;

        // 성명
        ws.H26.t = 's';
        ws.H26.v = item.educator_name;
        ws.H26.r = '<t>'+item.educator_name+'</t>';
        ws.H26.h = item.educator_name;
        ws.H26.w = item.educator_name;
        ws.H26.s.sz = 11;

        // 교육장소
        ws.K26.t = 's';
        ws.K26.v = item.educator_area;
        ws.K26.r = '<t>'+item.educator_area+'</t>';
        ws.K26.h = item.educator_area;
        ws.K26.w = item.educator_area;
        ws.K26.s.sz = 11;

        // 비고
        ws.P26.t = 's';
        ws.P26.v = item.educator_etc;
        ws.P26.r = '<t>'+item.educator_etc+'</t>';
        ws.P26.h = item.educator_etc;
        ws.P26.w = item.educator_etc;
        ws.P26.s.sz = 11;

        // 페이지 2 - 일자
        ws_2.D3.t = 's';
        ws_2.D3.v = this.date.koreanText(item.edu_workedit_date);
        ws_2.D3.r = '<t>'+this.date.koreanText(item.edu_workedit_date)+'</t>';
        ws_2.D3.h = this.date.koreanText(item.edu_workedit_date);
        ws_2.D3.w = this.date.koreanText(item.edu_workedit_date);
        ws_2.D3.s.sz = 11;

        // 페이지 3 - 일자
        ws_3.G4.t = 's';
        ws_3.G4.v = this.date.koreanText(item.edu_workedit_date);
        ws_3.G4.r = '<t>'+this.date.koreanText(item.edu_workedit_date)+'</t>';
        ws_3.G4.h = this.date.koreanText(item.edu_workedit_date);
        ws_3.G4.w = this.date.koreanText(item.edu_workedit_date);
        ws_3.G4.s.sz = 11;

        // 교육구분
        ws_3.C3.t = 's';
        ws_3.C3.v = item.educator_etc;
        ws_3.C3.r = '<t>'+item.educator_etc+'</t>';
        ws_3.C3.h = item.educator_etc;
        ws_3.C3.w = item.educator_etc;
        ws_3.C3.s.sz = 11;

        console.log(ws_3);

        let length_1:number = 36;
        let length_2:number = 25;
        let length_3:number = 0;
        let moinor:number = 0;
        let degi_num:number = 0;
        let style_default:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 12,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        ws_3['!images'] = [];
        console.log('---------- aa');
        console.log(item);
        console.log('---------- aa');
        if(item.attendee_data){
          for(let i = 0; i < item.attendee_data.length; i++){
            if(i < 36){
              console.log('test 1');
              if (i < 19){
                console.log('test 2');
                // 짝수
                ws_3[`B${i+7-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].company_name,w: item.attendee_data[i].company_name,h: item.attendee_data[i].company_name,r: '<t>'+item.attendee_data[i].company_name+'</t>',z: "General"};
                ws_3[`C${i+7-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,w: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,h: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,r: '<t>'+item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc+'</t>',z: "General"};
                ws_3[`D${i+7-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].user_name,w: item.attendee_data[i].user_name,h: item.attendee_data[i].user_name,r: '<t>'+item.attendee_data[i].user_name+'</t>',z: "General"};
                ws_3[`E${i+7-moinor}`] = {s: style_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                if(item.attendee_data[i].user_sign){
                  ws_3['!images'].push({
                    "!pos": { r: i+7-moinor-1, c: 4, x: 0, y: 0, w: 88, h: 45},
                    "!datatype": "remote",
                    "!link": item.attendee_data[i].user_sign
                  });
                }
              } else {
                console.log('test 3');
                // 홀수
                ws_3[`G${i+6-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].company_name,w: item.attendee_data[i].company_name,h: item.attendee_data[i].company_name,r: '<t>'+item.attendee_data[i].company_name+'</t>',z: "General"};
                ws_3[`H${i+6-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,w: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,h: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,r: '<t>'+item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc+'</t>',z: "General"};
                ws_3[`I${i+6-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].user_name,w: item.attendee_data[i].user_name,h: item.attendee_data[i].user_name,r: '<t>'+item.attendee_data[i].user_name+'</t>',z: "General"};
                ws_3[`J${i+6-moinor}`] = {s: style_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                if(item.attendee_data[i].user_sign){
                  ws_3['!images'].push({
                    "!pos": { r: i+6-moinor-1, c: 9, x: 0, y: 0, w: 88, h: 45},
                    "!datatype": "remote",
                    "!link": item.attendee_data[i].user_sign
                  });
                }
                moinor++;
              }
              
              // length_1 = length_1+3;
            } else {
              if(i > 36) degi_num = 1;
              if(i == length_1){
                console.log('test 4');
                length_1 = length_1+44;
                length_3 = length_3+2;
  
                // 열 높이 맞추기
                ws_3['!rows'].push({hpt:53, hpx:73});
                for(let i = 0; i < 22; i++) ws_3['!rows'].push({hpt:33, hpx:44});
                ws_3['!rows'].push({hpt:53, hpx:73});
  
                // A4크기로 영역 지정하기
                length_2 = length_2+23+degi_num;
                ws_3['!print'].area = {s:{r:0,c:0},e:{r:length_2,c:10}};
              }
  
              if (i%2 == 0){
                console.log('test 5');
                // 짝수
                ws_3[`B${((i+7)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].company_name,w: item.attendee_data[i].company_name,h: item.attendee_data[i].company_name,r: '<t>'+item.attendee_data[i].company_name+'</t>',z: "General"};
                ws_3[`C${((i+7)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,w: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,h: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,r: '<t>'+item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc+'</t>',z: "General"};
                ws_3[`D${((i+7)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].user_name,w: item.attendee_data[i].user_name,h: item.attendee_data[i].user_name,r: '<t>'+item.attendee_data[i].user_name+'</t>',z: "General"};
                ws_3[`E${((i+7)-moinor)+length_3}`] = {s: style_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                if(item.attendee_data[i].user_sign){
                  ws_3['!images'].push({
                    "!pos": { r: ((i+7)-moinor)+length_3-1, c: 4, x: 0, y: 0, w: 88, h: 45},
                    "!datatype": "remote",
                    "!link": item.attendee_data[i].user_sign
                  });
                }
              } else {
                console.log('test 6');
                // 홀수
                ws_3[`G${((i+6)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].company_name,w: item.attendee_data[i].company_name,h: item.attendee_data[i].company_name,r: '<t>'+item.attendee_data[i].company_name+'</t>',z: "General"};
                ws_3[`H${((i+6)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,w: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,h: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,r: '<t>'+item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc+'</t>',z: "General"};
                ws_3[`I${((i+6)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].user_name,w: item.attendee_data[i].user_name,h: item.attendee_data[i].user_name,r: '<t>'+item.attendee_data[i].user_name+'</t>',z: "General"};
                ws_3[`J${((i+6)-moinor)+length_3}`] = {s: style_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                if(item.attendee_data[i].user_sign){
                  ws_3['!images'].push({
                    "!pos": { r: ((i+6)-moinor)+length_3-1, c: 9, x: 0, y: 0, w: 88, h: 45},
                    "!datatype": "remote",
                    "!link": item.attendee_data[i].user_sign
                  });
                }
                moinor++;
              }
            }
          }
        }


        let theme_arr:any = [];
        for(let i = 0; i < 11; i++){
          if(i == 0) theme_arr.push({width: 2.5});
          else if(i == 5) theme_arr.push({width: 2.5});
          else if(i == 10) theme_arr.push({width: 2.5});
          else theme_arr.push({width: 9});
        }
        ws_3['!cols'] = theme_arr;

        let theme_arr_2:any = [];
        for(let i = 0; i < 24; i++){
          if(i == 0) theme_arr_2.push({width: 2});
          else if(i == 23) theme_arr_2.push({width: 3});
          else theme_arr_2.push({width: 3.5});
        }
        ws['!cols'] = theme_arr_2;

        let theme_arr_3:any = [];
        for(let i = 0; i < 6; i++){
          if(i == 0) theme_arr_3.push({width: 4});
          else if(i == 5) theme_arr_3.push({width: 4});
          else theme_arr_3.push({width: 18});
        }
        ws_2['!cols'] = theme_arr_3;

        // 셀 범위지정
        const ref = `B1:K5000`;
        ws_3['!ref'] = ref;



        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 관리감독자 교육 엑셀 다운로드
  ex_download_17(url, filename, item) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        // console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        // console.log(workbook);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        const ws_2 = workbook.Sheets[workbook.SheetNames[1]];
        const ws_3 = workbook.Sheets[workbook.SheetNames[2]];
        
        // 현장명 1
        ws_2.B3.t = 's';
        ws_2.B3.v = '현장명 : '+this.connect.area;
        ws_2.B3.r = '<t>현장명 : '+this.connect.area+'</t>';
        ws_2.B3.h = '현장명 : '+this.connect.area;
        ws_2.B3.w = '현장명 : '+this.connect.area;
        ws_2.B3.s.sz = 12;

        // 제목
        ws_2.B4.t = 's';
        ws_2.B4.v = '제목 : '+item.edu_admin_title;
        ws_2.B4.r = '<t>제목 : '+item.edu_admin_title+'</t>';
        ws_2.B4.h = '제목 : '+item.edu_admin_title;
        ws_2.B4.w = '제목 : '+item.edu_admin_title;
        ws_2.B4.s.sz = 11;

        // 현장명 2
        ws_3.C4.t = 's';
        ws_3.C4.v = this.connect.area;
        ws_3.C4.r = '<t>'+this.connect.area+'</t>';
        ws_3.C4.h = this.connect.area;
        ws_3.C4.w = this.connect.area;
        ws_3.C4.s.sz = 11;

        // 일자
        ws.O6.t = 's';
        ws.O6.v = this.date.koreanText_week(item.edu_admin_date);
        ws.O6.r = '<t>'+this.date.koreanText_week(item.edu_admin_date)+'</t>';
        ws.O6.h = this.date.koreanText_week(item.edu_admin_date);
        ws.O6.w = this.date.koreanText_week(item.edu_admin_date);
        ws.O6.s.sz = 11;

        // 교육대상 근로자 남자
        ws.L11.t = 'n';
        ws.L11.v = item.target_man_count;
        ws.L11.r = '<t>'+item.target_man_count+'</t>';
        ws.L11.h = item.target_man_count;
        ws.L11.w = item.target_man_count;
        ws.L11.s.sz = 11;

        // 교육대상 근로자 여자
        ws.N11.t = 'n';
        ws.N11.v = item.target_woman_count;
        ws.N11.r = '<t>'+item.target_woman_count+'</t>';
        ws.N11.h = item.target_woman_count;
        ws.N11.w = item.target_woman_count;
        ws.N11.s.sz = 11;

        // 교육실시 근로자 남자
        ws.L12.t = 'n';
        ws.L12.v = item.practice_man_count;
        ws.L12.r = '<t>'+item.practice_man_count+'</t>';
        ws.L12.h = item.practice_man_count;
        ws.L12.w = item.practice_man_count;
        ws.L12.s.sz = 11;

        // 교육실시 근로자 여자
        ws.N12.t = 'n';
        ws.N12.v = item.practice_woman_count;
        ws.N12.r = '<t>'+item.practice_woman_count+'</t>';
        ws.N12.h = item.practice_woman_count;
        ws.N12.w = item.practice_woman_count;
        ws.N12.s.sz = 11;

        // 교육미실시 근로자 남자
        ws.L13.t = 'n';
        ws.L13.v = item.not_man_count;
        ws.L13.r = '<t>'+item.not_man_count+'</t>';
        ws.L13.h = item.not_man_count;
        ws.L13.w = item.not_man_count;
        ws.L13.s.sz = 11;

        // 교육미실시 근로자 여자
        ws.N13.t = 'n';
        ws.N13.v = item.not_woman_count;
        ws.N13.r = '<t>'+item.not_woman_count+'</t>';
        ws.N13.h = item.not_woman_count;
        ws.N13.w = item.not_woman_count;
        ws.N13.s.sz = 11;

        // 교육대상
        ws.P11.t = 's';
        ws.P11.v = item.edu_admin_target;
        ws.P11.r = '<t>'+item.edu_admin_target+'</t>';
        ws.P11.h = item.edu_admin_target;
        ws.P11.w = item.edu_admin_target;
        ws.P11.s.sz = 11;

        // 과목 또는 사항
        ws.D15.t = 's';
        ws.D15.v = item.edu_admin_subject;
        ws.D15.r = '<t>'+item.edu_admin_subject+'</t>';
        ws.D15.h = item.edu_admin_subject;
        ws.D15.w = item.edu_admin_subject;
        ws.D15.s.sz = 11;

        // 교육방법
        ws.I15.t = 's';
        ws.I15.v = item.edu_admin_method;
        ws.I15.r = '<t>'+item.edu_admin_method+'</t>';
        ws.I15.h = item.edu_admin_method;
        ws.I15.w = item.edu_admin_method;
        ws.I15.s.sz = 11;

        // 교육내용의 개요
        ws.L15.t = 's';
        ws.L15.v = item.edu_admin_summary;
        ws.L15.r = '<t>'+item.edu_admin_summary+'</t>';
        ws.L15.h = item.edu_admin_summary;
        ws.L15.w = item.edu_admin_summary;
        ws.L15.s.sz = 11;

        // 교육시간
        ws.S15.t = 's';
        ws.S15.v = item.edu_admin_time;
        ws.S15.r = '<t>'+item.edu_admin_time+'</t>';
        ws.S15.h = item.edu_admin_time;
        ws.S15.w = item.edu_admin_time;
        ws.S15.s.sz = 11;
        
        // 사용교재
        ws.U15.t = 's';
        ws.U15.v = item.edu_admin_book;
        ws.U15.r = '<t>'+item.edu_admin_book+'</t>';
        ws.U15.h = item.edu_admin_book;
        ws.U15.w = item.edu_admin_book;
        ws.U15.s.sz = 11;

        // 직급
        ws.D26.t = 's';
        ws.D26.v = item.educator_rank;
        ws.D26.r = '<t>'+item.educator_rank+'</t>';
        ws.D26.h = item.educator_rank;
        ws.D26.w = item.educator_rank;
        ws.D26.s.sz = 11;

        // 성명
        ws.H26.t = 's';
        ws.H26.v = item.educator_name;
        ws.H26.r = '<t>'+item.educator_name+'</t>';
        ws.H26.h = item.educator_name;
        ws.H26.w = item.educator_name;
        ws.H26.s.sz = 11;

        // 교육장소
        ws.K26.t = 's';
        ws.K26.v = item.educator_area;
        ws.K26.r = '<t>'+item.educator_area+'</t>';
        ws.K26.h = item.educator_area;
        ws.K26.w = item.educator_area;
        ws.K26.s.sz = 11;

        // 비고
        ws.P26.t = 's';
        ws.P26.v = item.educator_etc;
        ws.P26.r = '<t>'+item.educator_etc+'</t>';
        ws.P26.h = item.educator_etc;
        ws.P26.w = item.educator_etc;
        ws.P26.s.sz = 11;

        // 페이지 2 - 일자
        ws_2.D3.t = 's';
        ws_2.D3.v = this.date.koreanText(item.edu_admin_date);
        ws_2.D3.r = '<t>'+this.date.koreanText(item.edu_admin_date)+'</t>';
        ws_2.D3.h = this.date.koreanText(item.edu_admin_date);
        ws_2.D3.w = this.date.koreanText(item.edu_admin_date);
        ws_2.D3.s.sz = 11;

        // 페이지 3 - 일자
        ws_3.G4.t = 's';
        ws_3.G4.v = this.date.koreanText(item.edu_admin_date);
        ws_3.G4.r = '<t>'+this.date.koreanText(item.edu_admin_date)+'</t>';
        ws_3.G4.h = this.date.koreanText(item.edu_admin_date);
        ws_3.G4.w = this.date.koreanText(item.edu_admin_date);
        ws_3.G4.s.sz = 11;

        // 교육구분
        ws_3.C3.t = 's';
        ws_3.C3.v = item.educator_etc;
        ws_3.C3.r = '<t>'+item.educator_etc+'</t>';
        ws_3.C3.h = item.educator_etc;
        ws_3.C3.w = item.educator_etc;
        ws_3.C3.s.sz = 11;

        console.log(ws_3);

        let length_1:number = 36;
        let length_2:number = 25;
        let length_3:number = 0;
        let moinor:number = 0;
        let degi_num:number = 0;
        let style_default:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 12,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        console.log('---------- aa');
        console.log(item);
        console.log('---------- aa');
        ws_3['!images'] = [];
        if(item.attendee_data){
          for(let i = 0; i < item.attendee_data.length; i++){
            if(i < 36){
              console.log('test 1');
              if (i < 19){
                console.log('test 2');
                // 짝수
                ws_3[`B${i+7-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].company_name,w: item.attendee_data[i].company_name,h: item.attendee_data[i].company_name,r: '<t>'+item.attendee_data[i].company_name+'</t>',z: "General"};
                ws_3[`C${i+7-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,w: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,h: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,r: '<t>'+item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc+'</t>',z: "General"};
                ws_3[`D${i+7-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].user_name,w: item.attendee_data[i].user_name,h: item.attendee_data[i].user_name,r: '<t>'+item.attendee_data[i].user_name+'</t>',z: "General"};
                ws_3[`E${i+7-moinor}`] = {s: style_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                if(item.attendee_data[i].user_sign){
                  ws_3['!images'].push({
                    "!pos": { r: i+7-moinor-1, c: 4, x: 0, y: 0, w: 88, h: 45},
                    "!datatype": "remote",
                    "!link": item.attendee_data[i].user_sign
                  });
                }
              } else {
                console.log('test 3');
                // 홀수
                ws_3[`G${i+6-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].company_name,w: item.attendee_data[i].company_name,h: item.attendee_data[i].company_name,r: '<t>'+item.attendee_data[i].company_name+'</t>',z: "General"};
                ws_3[`H${i+6-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,w: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,h: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,r: '<t>'+item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc+'</t>',z: "General"};
                ws_3[`I${i+6-moinor}`] = {s: style_default,t: "s",v: item.attendee_data[i].user_name,w: item.attendee_data[i].user_name,h: item.attendee_data[i].user_name,r: '<t>'+item.attendee_data[i].user_name+'</t>',z: "General"};
                ws_3[`J${i+6-moinor}`] = {s: style_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                if(item.attendee_data[i].user_sign){
                  ws_3['!images'].push({
                    "!pos": { r: i+6-moinor-1, c: 9, x: 0, y: 0, w: 88, h: 45},
                    "!datatype": "remote",
                    "!link": item.attendee_data[i].user_sign
                  });
                }
                moinor++;
              }
              
              // length_1 = length_1+3;
            } else {
              if(i > 36) degi_num = 1;
              if(i == length_1){
                console.log('test 4');
                length_1 = length_1+44;
                length_3 = length_3+2;
  
                // 열 높이 맞추기
                ws_3['!rows'].push({hpt:53, hpx:73});
                for(let i = 0; i < 22; i++) ws_3['!rows'].push({hpt:33, hpx:44});
                ws_3['!rows'].push({hpt:53, hpx:73});
  
                // A4크기로 영역 지정하기
                length_2 = length_2+23+degi_num;
                ws_3['!print'].area = {s:{r:0,c:0},e:{r:length_2,c:10}};
              }
  
              if (i%2 == 0){
                console.log('test 5');
                // 짝수
                ws_3[`B${((i+7)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].company_name,w: item.attendee_data[i].company_name,h: item.attendee_data[i].company_name,r: '<t>'+item.attendee_data[i].company_name+'</t>',z: "General"};
                ws_3[`C${((i+7)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,w: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,h: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,r: '<t>'+item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc+'</t>',z: "General"};
                ws_3[`D${((i+7)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].user_name,w: item.attendee_data[i].user_name,h: item.attendee_data[i].user_name,r: '<t>'+item.attendee_data[i].user_name+'</t>',z: "General"};
                ws_3[`E${((i+7)-moinor)+length_3}`] = {s: style_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                if(item.attendee_data[i].user_sign){
                  ws_3['!images'].push({
                    "!pos": { r: ((i+7)-moinor)+length_3-1, c: 4, x: 0, y: 0, w: 88, h: 45},
                    "!datatype": "remote",
                    "!link": item.attendee_data[i].user_sign
                  });
                }
              } else {
                console.log('test 6');
                // 홀수
                ws_3[`G${((i+6)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].company_name,w: item.attendee_data[i].company_name,h: item.attendee_data[i].company_name,r: '<t>'+item.attendee_data[i].company_name+'</t>',z: "General"};
                ws_3[`H${((i+6)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,w: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,h: item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc,r: '<t>'+item.attendee_data[i].occupation_name ? item.attendee_data[i].occupation_name : item.attendee_data[i].occupation_name_etc+'</t>',z: "General"};
                ws_3[`I${((i+6)-moinor)+length_3}`] = {s: style_default,t: "s",v: item.attendee_data[i].user_name,w: item.attendee_data[i].user_name,h: item.attendee_data[i].user_name,r: '<t>'+item.attendee_data[i].user_name+'</t>',z: "General"};
                ws_3[`J${((i+6)-moinor)+length_3}`] = {s: style_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
                if(item.attendee_data[i].user_sign){
                  ws_3['!images'].push({
                    "!pos": { r: ((i+6)-moinor)+length_3-1, c: 4, x: 0, y: 0, w: 88, h: 45},
                    "!datatype": "remote",
                    "!link": item.attendee_data[i].user_sign
                  });
                }
                moinor++;
              }
            }
          }
        }


        let theme_arr:any = [];
        for(let i = 0; i < 11; i++){
          if(i == 0) theme_arr.push({width: 2.5});
          else if(i == 5) theme_arr.push({width: 2.5});
          else if(i == 10) theme_arr.push({width: 2.5});
          else theme_arr.push({width: 9});
        }
        ws_3['!cols'] = theme_arr;

        let theme_arr_2:any = [];
        for(let i = 0; i < 24; i++){
          if(i == 0) theme_arr_2.push({width: 2});
          else if(i == 23) theme_arr_2.push({width: 3});
          else theme_arr_2.push({width: 3.5});
        }
        ws['!cols'] = theme_arr_2;

        let theme_arr_3:any = [];
        for(let i = 0; i < 6; i++){
          if(i == 0) theme_arr_3.push({width: 4});
          else if(i == 5) theme_arr_3.push({width: 4});
          else theme_arr_3.push({width: 18});
        }
        ws_2['!cols'] = theme_arr_3;

        // 셀 범위지정
        const ref = `B1:K5000`;
        ws_3['!ref'] = ref;



        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 관리감독자 교육 엑셀 다운로드
  ex_download_18(url, filename, item) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        // console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        // console.log(workbook);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        
        let style_default:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 22,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        let theme = [];
        for(let i = 0; i < 200; i++){
          theme.push({
            index: i+1
          });
        }

        let length_1:number = 29; // 페이징 넘버링
        let length_2:number = 0; // 건너뛰기 넘버링
        let moinor:number = 0; // 빼기 넘버링

        for(let i = 0; i < item.length; i++){
          ws[`B${i+6+length_2+moinor}`] = {s: style_default,t: "s",v: item[i].index,w: item[i].index,h: item[i].index,r: '<t>'+item[i].index+'</t>',z: "General"};
          ws[`C${i+6+length_2+moinor}`] = {s: style_default,t: "s",v: item[i].company_name,w: item[i].company_name,h: item[i].company_name,r: '<t>'+item[i].company_name+'</t>',z: "General"};
          ws[`D${i+6+length_2+moinor}`] = {s: style_default,t: "s",v: item[i].construction_name,w: item[i].construction_name,h: item[i].construction_name,r: '<t>'+item[i].construction_name+'</t>',z: "General"};
          ws[`E${i+6+length_2+moinor}`] = {s: style_default,t: "s",v: item[i].user_name,w: item[i].user_name,h: item[i].user_name,r: '<t>'+item[i].user_name+'</t>',z: "General"};
          ws[`F${i+6+length_2+moinor}`] = {s: style_default,t: "s",v: item[i].helmet ? 'O' : '',w: item[i].helmet ? 'O' : '',h: item[i].helmet ? 'O' : '',r: '<t>'+item[i].helmet ? 'O' : ''+'</t>',z: "General"};
          ws[`G${i+6+length_2+moinor}`] = {s: style_default,t: "s",v: item[i].shoes ? 'O' : '',w: item[i].shoes ? 'O' : '',h: item[i].shoes ? 'O' : '',r: '<t>'+item[i].shoes ? 'O' : ''+'</t>',z: "General"};
          ws[`H${i+6+length_2+moinor}`] = {s: style_default,t: "s",v: item[i].belt ? 'O' : '',w: item[i].belt ? 'O' : '',h: item[i].belt ? 'O' : '',r: '<t>'+item[i].belt ? 'O' : ''+'</t>',z: "General"};
          ws[`I${i+6+length_2+moinor}`] = {s: style_default,t: "s",v: item[i].gaiters ? 'O' : '',w: item[i].gaiters ? 'O' : '',h: item[i].gaiters ? 'O' : '',r: '<t>'+item[i].gaiters ? 'O' : ''+'</t>',z: "General"};
          ws[`J${i+6+length_2+moinor}`] = {s: style_default,t: "s",v: item[i].vest ? 'O' : '',w: item[i].vest ? 'O' : '',h: item[i].vest ? 'O' : '',r: '<t>'+item[i].vest ? 'O' : ''+'</t>',z: "General"};
          ws[`K${i+6+length_2+moinor}`] = {s: style_default,t: "s",v: item[i].terms_state ? 'O' : '',w: item[i].terms_state ? 'O' : '',h: item[i].terms_state ? 'O' : '',r: '<t>'+item[i].terms_state ? 'O' : ''+'</t>',z: "General"};

          // 처음
          if(i == 29){
            length_1 = length_1+34;
            length_2 = length_2+2;
            // 열 높이 맞추기
            for(let i = 0; i < 33; i++) ws['!rows'].push({hpt:80, hpx:100});

            // // A4크기로 영역 지정하기
            // ws['!print'].area = {s:{r:0,c:0},e:{r:length_1,c:11}};
          }

          // 처음이 아님
          if(i == length_1 && i != 29){
            length_1 = length_1+34;
            length_2 = length_2+2;
            // 열 높이 맞추기
            for(let i = 0; i < 36; i++) ws['!rows'].push({hpt:80, hpx:100});

            // // A4크기로 영역 지정하기
            // ws['!print'].area = {s:{r:0,c:0},e:{r:length_1,c:11}};

            moinor++;
          }
        }

        // A4크기로 영역 지정하기
        ws['!print'].area = {s:{r:0,c:0},e:{r:length_1+length_2,c:11}};

        // 열 높이 맞추기
        // ws['!rows'].push({hpt:53, hpx:73});
        // for(let i = 0; i < 22; i++) ws['!rows'].push({hpt:33, hpx:44});
        // ws['!rows'].push({hpt:53, hpx:73});

        // A4크기로 영역 지정하기
        // length_2 = length_2+23+degi_num;
        // ws['!print'].area = {s:{r:0,c:0},e:{r:length_2,c:10}};

        let theme_arr:any = [];
        for(let i = 0; i < 12; i++){
          if(i == 0) theme_arr.push({width: 7});
          else if(i == 1) theme_arr.push({width: 15});
          else if(i == 5) theme_arr.push({width: 24});
          else if(i == 6) theme_arr.push({width: 24});
          else if(i == 7) theme_arr.push({width: 24});
          else if(i == 8) theme_arr.push({width: 24});
          else if(i == 9) theme_arr.push({width: 24});
          else if(i == 11) theme_arr.push({width: 7});
          else theme_arr.push({width: 30});
        }
        ws['!cols'] = theme_arr;

        // 셀 범위지정
        const ref = `B1:K5000`;
        ws['!ref'] = ref;

        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 일용 노무비 지급 명세서
  ex_download_20(url, filename, item, form_item, total_theme) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        // console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});
        // console.log(workbook);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        console.log(form_item);
        console.log(item);
        let day_state = new Date(form_item.search_year,form_item.search_month,0).getDate()


        let names_list = {
          company_names: '업체: 전체', // 업체
          construction_names: '공종: 전체', // 공종
          occupation_names: '직종: 전체', // 직종
          company_duty_names: '직책: 전체' // 직책
        }

        if(form_item.company_names.length){
          let theme_item:string = '';
          for(let i = 0; i < form_item.company_names.length; i++){
            if((i+1) == form_item.company_names.length) theme_item += form_item.company_names[i];
            else theme_item += form_item.company_names[i]+',';
          }
          names_list.company_names = '업체: '+theme_item;
        }

        if(form_item.construction_names.length){
          let theme_item:string = '';
          for(let i = 0; i < form_item.construction_names.length; i++){
            if((i+1) == form_item.construction_names.length) theme_item += form_item.construction_names[i];
            else theme_item += form_item.construction_names[i]+',';
          }
          names_list.construction_names = '공종: '+theme_item;
        }

        if(form_item.occupation_names.length){
          let theme_item:string = '';
          for(let i = 0; i < form_item.occupation_names.length; i++){
            if((i+1) == form_item.occupation_names.length) theme_item += form_item.occupation_names[i];
            else theme_item += form_item.occupation_names[i]+',';
          }
          names_list.occupation_names = '직종: '+theme_item;
        }

        if(form_item.company_duty_names.length){
          let theme_item:string = '';
          for(let i = 0; i < form_item.company_duty_names.length; i++){
            if((i+1) == form_item.company_duty_names.length) theme_item += form_item.company_duty_names[i];
            else theme_item += form_item.company_duty_names[i]+',';
          }
          names_list.company_duty_names = '직책: '+theme_item;
        }


        // 제목
        ws.A1.t = 's';
        ws.A1.v = `( ${form_item.search_month}월) 일 용 노 무 비 지 급 명 세 서`;
        ws.A1.r = `<t>( ${form_item.search_month}월) 일 용 노 무 비 지 급 명 세 서</t>`;
        ws.A1.h = `( ${form_item.search_month}월) 일 용 노 무 비 지 급 명 세 서`;
        ws.A1.w = `( ${form_item.search_month}월) 일 용 노 무 비 지 급 명 세 서`;
        ws.A1.s.sz = 18;

        // 시작기간
        ws.Y7.t = 's';
        ws.Y7.v = `${form_item.search_year}년 ${form_item.search_month}월 01일`;
        ws.Y7.r = `<t>${form_item.search_year}년 ${form_item.search_month}월 01일</t>`;
        ws.Y7.h = `${form_item.search_year}년 ${form_item.search_month}월 01일`;
        ws.Y7.w = `${form_item.search_year}년 ${form_item.search_month}월 01일`;
        ws.Y7.s.sz = 9;

        // 시작기간
        ws.Y8.t = 's';
        ws.Y8.v = `${form_item.search_year}년 ${form_item.search_month}월 ${day_state}일`;
        ws.Y8.r = `<t>${form_item.search_year}년 ${form_item.search_month}월 ${day_state}일</t>`;
        ws.Y8.h = `${form_item.search_year}년 ${form_item.search_month}월 ${day_state}일`;
        ws.Y8.w = `${form_item.search_year}년 ${form_item.search_month}월 ${day_state}일`;
        ws.Y8.s.sz = 9;


        // 현장명
        ws.A4.t = 's';
        ws.A4.v = '현장명 : '+this.connect.area;
        ws.A4.r = '<t>'+'현장명 : '+this.connect.area+'</t>';
        ws.A4.h = '현장명 : '+this.connect.area;
        ws.A4.w = '현장명 : '+this.connect.area;
        ws.A4.s.sz = 11;

        // 업체
        ws.A5.t = 's';
        ws.A5.v = names_list.company_names;
        ws.A5.r = `<t>${names_list.company_names}</t>`;
        ws.A5.h = names_list.company_names;
        ws.A5.w = names_list.company_names;
        ws.A5.s.sz = 10;

        // 공종
        ws.A6.t = 's';
        ws.A6.v = names_list.construction_names;
        ws.A6.r = `<t>${names_list.construction_names}</t>`;
        ws.A6.h = names_list.construction_names;
        ws.A6.w = names_list.construction_names;
        ws.A6.s.sz = 10;

        // 직종
        ws.A7.t = 's';
        ws.A7.v = names_list.occupation_names;
        ws.A7.r = `<t>${names_list.occupation_names}</t>`;
        ws.A7.h = names_list.occupation_names;
        ws.A7.w = names_list.occupation_names;
        ws.A7.s.sz = 10;

        // 직책
        /* ws.A8.t = 's';
        ws.A8.v = names_list.company_duty_names;
        ws.A8.r = `<t>${names_list.company_duty_names}</t>`;
        ws.A8.h = names_list.company_duty_names;
        ws.A8.w = names_list.company_duty_names;
        ws.A8.s.sz = 10; */


        // 디폴트 아이템 스타일
        let style_left:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "medium", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 9,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        let style_right:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 9,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "medium", color: {rgb: "000000"}}
        }

        let style_default_8:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 8,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        let style_default_9:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 9,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        let style_default_11:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {rgb: "ff0000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 11,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        let style_default_12:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          bold:true,
          sz: 12,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        let style_default_color:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          fgColor: { rgb: 'CCFFCC' },
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "solid",
          bold: true,
          sz: 12,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }
        //////////////////////////////////////////////////////////////////////////////
        
        // 아래쪽 합계 아이템 스타일
        let style_left_bottom:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "medium", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "medium", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 9,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        let style_right_bottom:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "medium", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 9,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "medium", color: {rgb: "000000"}}
        }

        let style_default_9_bottom:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "medium", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "none",
          sz: 9,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }

        let style_default_color_bottom:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "medium", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          fgColor: { rgb: 'CCFFCC' },
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "Malgun Gothic (본문)",
          patternType: "solid",
          bold: true,
          sz: 12,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }
        //////////////////////////////////////////////////////////////////////////////


        // 맨뒤에칸 병합 M ~ O
        // merge: s(시작),e(끝) r(세로), c(가로)
        let merge_obj = [];

        // 병합
        let merge_index = 10;
        item.map(() => {
          merge_obj.push({s: {r: merge_index, c: 0}, e: {r: merge_index+1, c: 0}});
          merge_obj.push({s: {r: merge_index, c: 1}, e: {r: merge_index+1, c: 1}});
          merge_obj.push({s: {r: merge_index, c: 2}, e: {r: merge_index+1, c: 2}});
          merge_obj.push({s: {r: merge_index, c: 3}, e: {r: merge_index+1, c: 3}});
          merge_obj.push({s: {r: merge_index, c: 20}, e: {r: merge_index+1, c: 20}});
          merge_obj.push({s: {r: merge_index, c: 21}, e: {r: merge_index+1, c: 21}});
          merge_obj.push({s: {r: merge_index, c: 22}, e: {r: merge_index+1, c: 22}});
          merge_obj.push({s: {r: merge_index, c: 23}, e: {r: merge_index+1, c: 23}});
          // merge_obj.push({s: {r: merge_index, c: 26}, e: {r: merge_index+1, c: 26}});
          merge_obj.push({s: {r: merge_index, c: 27}, e: {r: merge_index+1, c: 27}});
          merge_index+=2;
        });

        // 마지막 아래쪽 합계 병합
        merge_obj.push({s: {r: merge_index, c: 0}, e: {r: merge_index+1, c: 0}});
        merge_obj.push({s: {r: merge_index, c: 1}, e: {r: merge_index+1, c: 1}});
        merge_obj.push({s: {r: merge_index, c: 2}, e: {r: merge_index+1, c: 2}});
        merge_obj.push({s: {r: merge_index, c: 3}, e: {r: merge_index+1, c: 3}});
        merge_obj.push({s: {r: merge_index, c: 20}, e: {r: merge_index+1, c: 20}});
        merge_obj.push({s: {r: merge_index, c: 21}, e: {r: merge_index+1, c: 21}});
        merge_obj.push({s: {r: merge_index, c: 22}, e: {r: merge_index+1, c: 22}});
        merge_obj.push({s: {r: merge_index, c: 23}, e: {r: merge_index+1, c: 23}});
        // merge_obj.push({s: {r: merge_index, c: 26}, e: {r: merge_index+1, c: 26}});
        merge_obj.push({s: {r: merge_index, c: 27}, e: {r: merge_index+1, c: 27}});        
        ws['!merges'] = ws['!merges'].concat(merge_obj);

        // 스타일링
        // 1. 높이 맞추기
        ws['!rows'].splice(10,22); // 원래 있던 높이 제거
        item.map(() => { // 두칸씩 쓰기때문에 한번당 2개씩 넣어줘야 맞는다
          ws['!rows'].push({hpt:27, hpx:27});
          ws['!rows'].push({hpt:27, hpx:27});
        });

        // 마지막 아래쪽 한줄더 높이지정
        ws['!rows'].push({hpt:27, hpx:27});
        ws['!rows'].push({hpt:27, hpx:27});

        // 2. 열 너비 맞추기
        let theme_arr:any = [];
        for(let i = 0; i < 24; i++){
          if(i == 0) theme_arr.push({width: 4});
          if(i == 1) theme_arr.push({width: 8});
          if(i == 2) theme_arr.push({width: 10});
          if(i == 3) theme_arr.push({width: 20}); // 17
          if(i > 3 && i < 21) theme_arr.push({width: 5});
          if(i == 21) theme_arr.push({width: 8});
          if(i == 22) theme_arr.push({width: 8});
          if(i == 23) theme_arr.push({width: 9});
          if(i == 24) theme_arr.push({width: 7});
          if(i == 25) theme_arr.push({width: 7});
          if(i == 26) theme_arr.push({width: 9});
          if(i == 27) theme_arr.push({width: 5});
        }
        ws['!cols'] = theme_arr;

        // 3. 테두리와 색상 그리고 데이터형식 넣기
        let item_index = 11;
        let item_index_color = 12;
        const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB','AC'];
        item.map(() => {
          alphabet.map((alphpa) => {
            if(alphpa == 'A') ws[`${alphpa}${item_index}`] = {s: style_left,t: "s",v: '',w: '',h: '',r: '',z: "General"};
            else if(alphpa == 'AB') ws[`${alphpa}${item_index}`] = {s: style_right,t: "s",v: '',w: '',h: '',r: '',z: "General"};
            else ws[`${alphpa}${item_index}`] = {s: style_default_9,t: "s",v: '',w: '',h: '',r: '',z: "General"};

            if(alphpa == 'A') ws[`${alphpa}${item_index_color}`] = {s: style_left,t: "s",v: '',w: '',h: '',r: '',z: "General"};
            else if(alphpa == 'AB') ws[`${alphpa}${item_index_color}`] = {s: style_right,t: "s",v: '',w: '',h: '',r: '',z: "General"};
            else ws[`${alphpa}${item_index_color}`] = {s: style_default_9,t: "s",v: '',w: '',h: '',r: '',z: "General"};

            // 글자크기 조정
            if(alphpa == 'B' || alphpa == 'C' || alphpa == 'D') ws[`${alphpa}${item_index}`] = {s: style_default_11,t: "s",v: '',w: '',h: '',r: '',z: "General"};
            if(alphpa == 'U' || alphpa == 'V' || alphpa == 'W' || alphpa == 'X' || alphpa == 'Y' || alphpa == 'Z') ws[`${alphpa}${item_index}`] = {s: style_default_8,t: "s",v: '',w: '',h: '',r: '',z: "General"};
            if(alphpa == 'E' || alphpa == 'F' || alphpa == 'G' || alphpa == 'H' || alphpa == 'I' || alphpa == 'J' || alphpa == 'K' || alphpa == 'L' || alphpa == 'M' || alphpa == 'N' || alphpa == 'O' || alphpa == 'P' || alphpa == 'Q' || alphpa == 'R' || alphpa == 'S' || alphpa == 'T') ws[`${alphpa}${item_index}`] = {s: style_default_12,t: "s",v: '',w: '',h: '',r: '',z: "General"};

            // 컬러 채우기
            if(alphpa == 'E' || alphpa == 'F' || alphpa == 'G' || alphpa == 'H' || alphpa == 'I' || alphpa == 'J' || alphpa == 'K' || alphpa == 'L' || alphpa == 'M' || alphpa == 'N' || alphpa == 'O' || alphpa == 'P' || alphpa == 'Q' || alphpa == 'R' || alphpa == 'S' || alphpa == 'T') ws[`${alphpa}${item_index_color}`] = {s: style_default_color,t: "s",v: '',w: '',h: '',r: '',z: "General"};
          });
          item_index+=2;
          item_index_color+=2;
        });

        // 마지막 한줄 더 테두리랑 스타일넣기
        alphabet.map((alphpa) => {
          if(alphpa == 'A') ws[`${alphpa}${item_index}`] = {s: style_left,t: "s",v: '',w: '',h: '',r: '',z: "General"};
          else if(alphpa == 'AB') ws[`${alphpa}${item_index}`] = {s: style_right,t: "s",v: '',w: '',h: '',r: '',z: "General"};
          else ws[`${alphpa}${item_index}`] = {s: style_default_9,t: "s",v: '',w: '',h: '',r: '',z: "General"};

          if(alphpa == 'A') ws[`${alphpa}${item_index_color}`] = {s: style_left_bottom,t: "s",v: '',w: '',h: '',r: '',z: "General"};
          else if(alphpa == 'AB') ws[`${alphpa}${item_index_color}`] = {s: style_right_bottom,t: "s",v: '',w: '',h: '',r: '',z: "General"};
          else ws[`${alphpa}${item_index_color}`] = {s: style_default_9_bottom,t: "s",v: '',w: '',h: '',r: '',z: "General"};

          // 글자크기 조정
          if(alphpa == 'B' || alphpa == 'C' || alphpa == 'D') ws[`${alphpa}${item_index}`] = {s: style_default_11,t: "s",v: '',w: '',h: '',r: '',z: "General"};
          if(alphpa == 'U' || alphpa == 'V' || alphpa == 'W' || alphpa == 'X' || alphpa == 'Y' || alphpa == 'Z') ws[`${alphpa}${item_index}`] = {s: style_default_8,t: "s",v: '',w: '',h: '',r: '',z: "General"};
          if(alphpa == 'E' || alphpa == 'F' || alphpa == 'G' || alphpa == 'H' || alphpa == 'I' || alphpa == 'J' || alphpa == 'K' || alphpa == 'L' || alphpa == 'M' || alphpa == 'N' || alphpa == 'O' || alphpa == 'P' || alphpa == 'Q' || alphpa == 'R' || alphpa == 'S' || alphpa == 'T') ws[`${alphpa}${item_index}`] = {s: style_default_12,t: "s",v: '',w: '',h: '',r: '',z: "General"};

          // 컬러 채우기
          if(alphpa == 'E' || alphpa == 'F' || alphpa == 'G' || alphpa == 'H' || alphpa == 'I' || alphpa == 'J' || alphpa == 'K' || alphpa == 'L' || alphpa == 'M' || alphpa == 'N' || alphpa == 'O' || alphpa == 'P' || alphpa == 'Q' || alphpa == 'R' || alphpa == 'S' || alphpa == 'T') ws[`${alphpa}${item_index_color}`] = {s: style_default_color_bottom,t: "s",v: '',w: '',h: '',r: '',z: "General"};
        });


        // 4. A4 크기로 영역 지정하기 - s(시작),e(끝) r(세로), c(가로)
        // 가로 0 ~ 27까지, 세로 0 ~ 갯수만큼 - 위에있는 merge_index를 사용하면 됨
        ws['!print'].area = {s:{r:0,c:0},e:{r:merge_index+2,c:27}};

        // 5. 데이터 집어넣기
        let image_index = 10;
        let item_data_1 = 11;
        let item_data_2 = 12;
        const alphabet_1 = ['E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T'];
        ws['!images'] = [];
        item.map((res) => {
          // index
          ws[`A${item_data_1}`].v = res.index;
          ws[`A${item_data_1}`].w = res.index;
          ws[`A${item_data_1}`].h = res.index;
          ws[`A${item_data_1}`].r = res.index;

          // 성명
          ws[`B${item_data_1}`].v = res.user_name ? res.user_name : '';
          ws[`B${item_data_1}`].w = res.user_name ? res.user_name : '';
          ws[`B${item_data_1}`].h = res.user_name ? res.user_name : '';
          ws[`B${item_data_1}`].r = res.user_name ? res.user_name : '';

          // 주민번호
          ws[`C${item_data_1}`].v = res.worker_resident_no ? res.worker_resident_no : '';
          ws[`C${item_data_1}`].w = res.worker_resident_no ? res.worker_resident_no : '';
          ws[`C${item_data_1}`].h = res.worker_resident_no ? res.worker_resident_no : '';
          ws[`C${item_data_1}`].r = res.worker_resident_no ? res.worker_resident_no : '';

          // 주소
          ws[`D${item_data_1}`].v = (res.user_address ? res.user_address : '')+' '+(res.user_address_detail ? res.user_address_detail : '');
          ws[`D${item_data_1}`].w = (res.user_address ? res.user_address : '')+' '+(res.user_address_detail ? res.user_address_detail : '');
          ws[`D${item_data_1}`].h = (res.user_address ? res.user_address : '')+' '+(res.user_address_detail ? res.user_address_detail : '');
          ws[`D${item_data_1}`].r = (res.user_address ? res.user_address : '')+' '+(res.user_address_detail ? res.user_address_detail : '');

          // 합계
          ws[`U${item_data_1}`].v = res.total_row ? res.total_row : '0';
          ws[`U${item_data_1}`].w = res.total_row ? res.total_row : '0';
          ws[`U${item_data_1}`].h = res.total_row ? res.total_row : '0';
          ws[`U${item_data_1}`].r = res.total_row ? res.total_row : '0';

          // 노무비 단가
          ws[`V${item_data_1}`].v = res.basic_daily_wage ? this.number.price(res.basic_daily_wage) : '0';
          ws[`V${item_data_1}`].w = res.basic_daily_wage ? this.number.price(res.basic_daily_wage) : '0';
          ws[`V${item_data_1}`].h = res.basic_daily_wage ? this.number.price(res.basic_daily_wage) : '0';
          ws[`V${item_data_1}`].r = res.basic_daily_wage ? this.number.price(res.basic_daily_wage) : '0';

          // 예외 단가
          ws[`W${item_data_1}`].v = res.exception_daily_wage ? this.number.price(res.exception_daily_wage) : '0';
          ws[`W${item_data_1}`].w = res.exception_daily_wage ? this.number.price(res.exception_daily_wage) : '0';
          ws[`W${item_data_1}`].h = res.exception_daily_wage ? this.number.price(res.exception_daily_wage) : '0';
          ws[`W${item_data_1}`].r = res.exception_daily_wage ? this.number.price(res.exception_daily_wage) : '0';

          // 노무비 총액
          ws[`X${item_data_1}`].v = res.total_wage ? this.number.price(res.total_wage) : '0';
          ws[`X${item_data_1}`].w = res.total_wage ? this.number.price(res.total_wage) : '0';
          ws[`X${item_data_1}`].h = res.total_wage ? this.number.price(res.total_wage) : '0';
          ws[`X${item_data_1}`].r = res.total_wage ? this.number.price(res.total_wage) : '0';

          // 건강보험
          ws[`Y${item_data_1}`].v = res.tax_health ? this.number.price(res.tax_health) : '0';
          ws[`Y${item_data_1}`].w = res.tax_health ? this.number.price(res.tax_health) : '0';
          ws[`Y${item_data_1}`].h = res.tax_health ? this.number.price(res.tax_health) : '0';
          ws[`Y${item_data_1}`].r = res.tax_health ? this.number.price(res.tax_health) : '0';

          // 소득세
          ws[`Z${item_data_1}`].v = res.total_income_tax ? this.number.price(res.total_income_tax) : '0';
          ws[`Z${item_data_1}`].w = res.total_income_tax ? this.number.price(res.total_income_tax) : '0';
          ws[`Z${item_data_1}`].h = res.total_income_tax ? this.number.price(res.total_income_tax) : '0';
          ws[`Z${item_data_1}`].r = res.total_income_tax ? this.number.price(res.total_income_tax) : '0';

          // 고용보험
          ws[`AA${item_data_1}`].v = res.total_employment_tax ? this.number.price(res.total_employment_tax) : '0';
          ws[`AA${item_data_1}`].w = res.total_employment_tax ? this.number.price(res.total_employment_tax) : '0';
          ws[`AA${item_data_1}`].h = res.total_employment_tax ? this.number.price(res.total_employment_tax) : '0';
          ws[`AA${item_data_1}`].r = res.total_employment_tax ? this.number.price(res.total_employment_tax) : '0';

          // 국민연금
          ws[`Y${item_data_2}`].v = res.tax_national_pension ? this.number.price(res.tax_national_pension) : '0';
          ws[`Y${item_data_2}`].w = res.tax_national_pension ? this.number.price(res.tax_national_pension) : '0';
          ws[`Y${item_data_2}`].h = res.tax_national_pension ? this.number.price(res.tax_national_pension) : '0';
          ws[`Y${item_data_2}`].r = res.tax_national_pension ? this.number.price(res.tax_national_pension) : '0';

          // 주민세
          ws[`Z${item_data_2}`].v = res.total_resident_tax ? this.number.price(res.total_resident_tax) : '0';
          ws[`Z${item_data_2}`].w = res.total_resident_tax ? this.number.price(res.total_resident_tax) : '0';
          ws[`Z${item_data_2}`].h = res.total_resident_tax ? this.number.price(res.total_resident_tax) : '0';
          ws[`Z${item_data_2}`].r = res.total_resident_tax ? this.number.price(res.total_resident_tax) : '0';

          // 차감계
          ws[`AA${item_data_2}`].v = res.total_sub_wage ? this.number.price(res.total_sub_wage) : '0';
          ws[`AA${item_data_2}`].w = res.total_sub_wage ? this.number.price(res.total_sub_wage) : '0';
          ws[`AA${item_data_2}`].h = res.total_sub_wage ? this.number.price(res.total_sub_wage) : '0';
          ws[`AA${item_data_2}`].r = res.total_sub_wage ? this.number.price(res.total_sub_wage) : '0';

          // 차감지급액
          ws[`AB${item_data_1}`].v = res.total_received_wage ? this.number.price(res.total_received_wage) : '0';
          ws[`AB${item_data_1}`].w = res.total_received_wage ? this.number.price(res.total_received_wage) : '0';
          ws[`AB${item_data_1}`].h = res.total_received_wage ? this.number.price(res.total_received_wage) : '0';
          ws[`AB${item_data_1}`].r = res.total_received_wage ? this.number.price(res.total_received_wage) : '0';

          // 일자별 출역 현황
          console.log(res);
          for(let i = 0; i < 15; i++){
            ws[`${alphabet_1[i]}${item_data_1}`].v = res.wage_data[i].work_count ? res.wage_data[i].work_count : '';
            ws[`${alphabet_1[i]}${item_data_1}`].w = res.wage_data[i].work_count ? res.wage_data[i].work_count : '';
            ws[`${alphabet_1[i]}${item_data_1}`].h = res.wage_data[i].work_count ? res.wage_data[i].work_count : '';
            ws[`${alphabet_1[i]}${item_data_1}`].r = res.wage_data[i].work_count ? res.wage_data[i].work_count : '';
          }
          for(let i = 0; i < 16; i++){
            // console.log('length: ',res.wage_data.length);
            // console.log(i+' --------------------');
            // console.log(item_data_1);
            
            // if(res.wage_data.length <= i+12){
            if(res.wage_data[i+15]){
              // console.log(`${alphabet_1[i]}${item_data_2}-${res.wage_data[i+15].work_count}`);
              ws[`${alphabet_1[i]}${item_data_2}`].v = res.wage_data[i+15].work_count ? res.wage_data[i+15].work_count : '';
              ws[`${alphabet_1[i]}${item_data_2}`].w = res.wage_data[i+15].work_count ? res.wage_data[i+15].work_count : '';
              ws[`${alphabet_1[i]}${item_data_2}`].h = res.wage_data[i+15].work_count ? res.wage_data[i+15].work_count : '';
              ws[`${alphabet_1[i]}${item_data_2}`].r = res.wage_data[i+15].work_count ? res.wage_data[i+15].work_count : '';
            }
          }

          // 영수인
          // if(res.user_sign_file){
          //   ws['!images'].push({
          //     "!pos": { r: image_index, c: 27, x: 0, y: 0, w: 80, h: 50},
          //     "!datatype": "remote",
          //     "!link": res.user_sign_file
          //   });
          // }

          image_index+=2;
          item_data_1+=2;
          item_data_2+=2;
        });

        // 마지막 아래쪽 합계데이터 한줄 넣기
        // total_theme

        // 일별 출역 총수
        console.log(total_theme);
        for(let i = 0; i < 15; i++){
          ws[`${alphabet_1[i]}${item_data_1}`].v = total_theme.total_bottom_row[i] ? total_theme.total_bottom_row[i] : '';
          ws[`${alphabet_1[i]}${item_data_1}`].w = total_theme.total_bottom_row[i] ? total_theme.total_bottom_row[i] : '';
          ws[`${alphabet_1[i]}${item_data_1}`].h = total_theme.total_bottom_row[i] ? total_theme.total_bottom_row[i] : '';
          ws[`${alphabet_1[i]}${item_data_1}`].r = total_theme.total_bottom_row[i] ? total_theme.total_bottom_row[i] : '';
        }
        for(let i = 0; i < 16; i++){
          ws[`${alphabet_1[i]}${item_data_2}`].v = total_theme.total_bottom_row[i+15] ? total_theme.total_bottom_row[i+15] : '';
          ws[`${alphabet_1[i]}${item_data_2}`].w = total_theme.total_bottom_row[i+15] ? total_theme.total_bottom_row[i+15] : '';
          ws[`${alphabet_1[i]}${item_data_2}`].h = total_theme.total_bottom_row[i+15] ? total_theme.total_bottom_row[i+15] : '';
          ws[`${alphabet_1[i]}${item_data_2}`].r = total_theme.total_bottom_row[i+15] ? total_theme.total_bottom_row[i+15] : '';
        }

        // 계
        ws[`A${item_data_1}`].v = '계';
        ws[`A${item_data_1}`].w = '계';
        ws[`A${item_data_1}`].h = '계';
        ws[`A${item_data_1}`].r = '계';

        // 일별 출역 합계 총수
        ws[`U${item_data_1}`].v = total_theme.total_bottom_row_join ? total_theme.total_bottom_row_join : '0';
        ws[`U${item_data_1}`].w = total_theme.total_bottom_row_join ? total_theme.total_bottom_row_join : '0';
        ws[`U${item_data_1}`].h = total_theme.total_bottom_row_join ? total_theme.total_bottom_row_join : '0';
        ws[`U${item_data_1}`].r = total_theme.total_bottom_row_join ? total_theme.total_bottom_row_join : '0';

        // 노무비 총액
        ws[`X${item_data_1}`].v = total_theme.total_1 ? this.number.price(total_theme.total_1) : '0';
        ws[`X${item_data_1}`].w = total_theme.total_1 ? this.number.price(total_theme.total_1) : '0';
        ws[`X${item_data_1}`].h = total_theme.total_1 ? this.number.price(total_theme.total_1) : '0';
        ws[`X${item_data_1}`].r = total_theme.total_1 ? this.number.price(total_theme.total_1) : '0';

        // 소득세
        ws[`Z${item_data_1}`].v = total_theme.total_2 ? this.number.price(total_theme.total_2) : '0';
        ws[`Z${item_data_1}`].w = total_theme.total_2 ? this.number.price(total_theme.total_2) : '0';
        ws[`Z${item_data_1}`].h = total_theme.total_2 ? this.number.price(total_theme.total_2) : '0';
        ws[`Z${item_data_1}`].r = total_theme.total_2 ? this.number.price(total_theme.total_2) : '0';

        // 고용보험
        ws[`AA${item_data_1}`].v = total_theme.total_3 ? this.number.price(total_theme.total_3) : '0';
        ws[`AA${item_data_1}`].w = total_theme.total_3 ? this.number.price(total_theme.total_3) : '0';
        ws[`AA${item_data_1}`].h = total_theme.total_3 ? this.number.price(total_theme.total_3) : '0';
        ws[`AA${item_data_1}`].r = total_theme.total_3 ? this.number.price(total_theme.total_3) : '0';

        // 주민세
        ws[`Z${item_data_2}`].v = total_theme.total_4 ? this.number.price(total_theme.total_4) : '0';
        ws[`Z${item_data_2}`].w = total_theme.total_4 ? this.number.price(total_theme.total_4) : '0';
        ws[`Z${item_data_2}`].h = total_theme.total_4 ? this.number.price(total_theme.total_4) : '0';
        ws[`Z${item_data_2}`].r = total_theme.total_4 ? this.number.price(total_theme.total_4) : '0';

        // 차감계
        ws[`AA${item_data_2}`].v = total_theme.total_5 ? this.number.price(total_theme.total_5) : '0';
        ws[`AA${item_data_2}`].w = total_theme.total_5 ? this.number.price(total_theme.total_5) : '0';
        ws[`AA${item_data_2}`].h = total_theme.total_5 ? this.number.price(total_theme.total_5) : '0';
        ws[`AA${item_data_2}`].r = total_theme.total_5 ? this.number.price(total_theme.total_5) : '0';

        // 차감지급액
        ws[`AB${item_data_1}`].v = total_theme.total_6 ? this.number.price(total_theme.total_6) : '0';
        ws[`AB${item_data_1}`].w = total_theme.total_6 ? this.number.price(total_theme.total_6) : '0';
        ws[`AB${item_data_1}`].h = total_theme.total_6 ? this.number.price(total_theme.total_6) : '0';
        ws[`AB${item_data_1}`].r = total_theme.total_6 ? this.number.price(total_theme.total_6) : '0';

        // 건강보함
        ws[`Y${item_data_1}`].v = total_theme.total_7 ? this.number.price(total_theme.total_7) : '0';
        ws[`Y${item_data_1}`].w = total_theme.total_7 ? this.number.price(total_theme.total_7) : '0';
        ws[`Y${item_data_1}`].h = total_theme.total_7 ? this.number.price(total_theme.total_7) : '0';
        ws[`Y${item_data_1}`].r = total_theme.total_7 ? this.number.price(total_theme.total_7) : '0';

        // 국민연금
        ws[`Y${item_data_2}`].v = total_theme.total_8 ? this.number.price(total_theme.total_8) : '0';
        ws[`Y${item_data_2}`].w = total_theme.total_8 ? this.number.price(total_theme.total_8) : '0';
        ws[`Y${item_data_2}`].h = total_theme.total_8 ? this.number.price(total_theme.total_8) : '0';
        ws[`Y${item_data_2}`].r = total_theme.total_8 ? this.number.price(total_theme.total_8) : '0';

        // 셀 범위지정
        const ref = `A1:AB${merge_index+2}`;
        ws['!ref'] = ref;


        console.log(ws);
        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 장비 출역현황 엑셀 다운로드 
  ex_download_21(url, filename, item, item_1) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";

      // console.log('----------');
      // console.log(item_1);
      // console.log('----------');
      // console.log('----------');
      // console.log(company_name);
      // console.log('----------');
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});

        const ws = workbook.Sheets[workbook.SheetNames[0]];
        // console.log(ws['!images']);
        console.log(ws);

        // merge: s(시작),e(끝) r(세로), c(가로)
        // let merge_obj:any = [];
        // merge_obj.push({s: {r: 13, c: 1}, e: {r: 18, c: 4}});
        // ws['!merges'] = ws['!merges'].concat(merge_obj);

        let h_length:number = 5; // 프린트 열 수

        // 출역일자
        ws.I2.t = 's';
        ws.I2.v = item.nfc_date;
        ws.I2.r = '<t>'+item.nfc_date+'</t>';
        ws.I2.h = item.nfc_date;
        ws.I2.w = item.nfc_date;
        ws.I2.s.sz = 22;

        let option_default:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 22,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }			

        item_1.map((item) => {
          // if(item_1.index == 25) h_length = h_length+3;

          // if(item.index == 28) h_length = h_length+6;
          // else if(item.index%28 == 0 && item.index != 28) h_length = h_length+5;

          ws[`B${h_length}`] = {s: option_default,t: "s",v: item.index,w: item.index,h: item.index,r: '<t>'+item.index+'</t>',z: "General"};
          ws[`C${h_length}`] = {s: option_default,t: "s",v: item.company_name ? item.company_name : '',w: item.company_name ? item.company_name : '',h: item.company_name ? item.company_name : '',r: '<t>'+item.company_name ? item.company_name : ''+'</t>',z: "General"};
          ws[`D${h_length}`] = {s: option_default,t: "s",v: item.equip_type ? item.equip_type : '',w: item.equip_type ? item.equip_type : '',h: item.equip_type ? item.equip_type : '',r: '<t>'+item.equip_type ? item.equip_type : ''+'</t>',z: "General"};
          // ws[`E${h_length}`] = {s: option_default,t: "s",v: item.construction_name ? item.construction_name : '',w: item.construction_name ? item.construction_name : '',h: item.construction_name ? item.construction_name : '',r: '<t>'+item.construction_name ? item.construction_name : ''+'</t>',z: "General"};
          ws[`E${h_length}`] = {s: option_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
          ws[`F${h_length}`] = {s: option_default,t: "s",v: item.equip_sub_type ? item.equip_sub_type : '',w: item.equip_sub_type ? item.equip_sub_type : '',h: item.equip_sub_type ? item.equip_sub_type : '',r: '<t>'+item.equip_sub_type ? item.equip_sub_type : ''+'</t>',z: "General"};
          ws[`G${h_length}`] = {s: option_default,t: "s",v: item.equip_area_standard ? item.equip_area_standard : '',w: item.equip_area_standard ? item.equip_area_standard : '',h: item.equip_area_standard ? item.equip_area_standard : '',r: '<t>'+item.equip_area_standard ? item.equip_area_standard : ''+'</t>',z: "General"};
          // ws[`H${h_length}`] = {s: option_default,t: "s",v: '',w: '',h: '',r: '<t></t>',z: "General"};
          ws[`H${h_length}`] = {s: option_default,t: "s",v: item.enter_datetime ? item.enter_datetime : '',w: item.enter_datetime ? item.enter_datetime : '',h: item.enter_datetime ? item.enter_datetime : '',r: '<t>'+item.enter_datetime ? item.enter_datetime : ''+'</t>',z: "General"};
          ws[`I${h_length}`] = {s: option_default,t: "s",v: item.out_datetime ? item.out_datetime : '',w: item.out_datetime ? item.out_datetime : '',h: item.out_datetime ? item.out_datetime : '',r: '<t>'+item.out_datetime ? item.out_datetime : ''+'</t>',z: "General"};

          h_length++;
        });




        // length_1 = length_1+44;
        // length_3 = length_3+2;

        // 열 높이 맞추기
        
        for(let i = 33; i < h_length; i++) ws['!rows'].push({hpt:80, hpx:100});
        ws['!rows'][31] = {hpt:80, hpx:100};
        ws['!rows'][32] = {hpt:80, hpx:100};

        // A4크기로 영역 지정하기
        // length_2 = length_2+23+degi_num;
        if(item_1.length < 24) ws['!print'].area = {s:{r:0,c:0},e:{r:29,c:9}};
        else ws['!print'].area = {s:{r:0,c:0},e:{r:h_length,c:9}};
        

        const ref = `A1:J3000`;
        ws['!ref'] = ref;
        


        let theme_arr:any = [];
        for(let i = 0; i < 10; i++){
          if(i == 0) theme_arr.push({width: 8});
          else if(i == 9) theme_arr.push({width: 8});
          else theme_arr.push({width: 27});
        }
        ws['!cols'] = theme_arr;

        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});
        // res(ws['!images'][0]['!data']);
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 작업구간 출역현황 엑셀 다운로드 
  ex_download_22(url, filename, item, item_1) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer"; 

      // console.log('----------');
      // console.log(item_1);
      // console.log('----------');
      // console.log('----------');
      // console.log(company_name);
      // console.log('----------');
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});

        const ws = workbook.Sheets[workbook.SheetNames[0]];
        // console.log(ws['!images']);
        console.log(ws);

        // merge: s(시작),e(끝) r(세로), c(가로)
        // let merge_obj:any = [];
        // merge_obj.push({s: {r: 13, c: 1}, e: {r: 18, c: 4}});
        // ws['!merges'] = ws['!merges'].concat(merge_obj);

        let h_length:number = 7; // 프린트 열 수

        // 출역일자
        ws.I2.t = 's';
        ws.I2.v = item.bicon_date;
        ws.I2.r = '<t>'+item.bicon_date+'</t>';
        ws.I2.h = item.bicon_date;
        ws.I2.w = item.bicon_date;
        ws.I2.s.sz = 22;

        // 관리자 인원
        ws.C3.t = 's';
        ws.C3.v = item.admin_count;
        ws.C3.r = '<t>'+item.admin_count+'</t>';
        ws.C3.h = item.admin_count;
        ws.C3.w = item.admin_count;
        ws.C3.s.sz = 28;

        // 근로자 인원
        ws.C4.t = 's';
        ws.C4.v = item.user_count;
        ws.C4.r = '<t>'+item.user_count+'</t>';
        ws.C4.h = item.user_count;
        ws.C4.w = item.user_count;
        ws.C4.s.sz = 28;

        let total_c = item.admin_count+item.user_count;
        let total_r = item.admin_count+item.user_count;

        let option_default:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 22,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }
        let merge_obj = [];
        let merger_num_1 = 6;
        let merger_num_2 = 6;
        item_1.map((data_1) => {
          // data_1.search_date 날짜
          let t_c = data_1.user_count+data_1.admin_count; // 총 출역수

          // merge - 병합
          // merge: s(시작),e(끝) r(세로), c(가로)
          console.log('merger_num_1 - ',merger_num_1);
          merge_obj.push({s: {r: merger_num_1, c: 1}, e: {r: merger_num_1+t_c-1, c: 1}});
          merger_num_1+=t_c;
          

          data_1.total_list.map((data_2) => {
            // merge - 병합
            // merge: s(시작),e(끝) r(세로), c(가로)
            console.log('merger_num_2 - ',merger_num_2);
            merge_obj.push({s: {r: merger_num_2, c: 2}, e: {r: merger_num_2+data_2.area_list.length-1, c: 2}});
            merger_num_2+=data_2.area_list.length

            data_2.area_list.map((item_d) => {
              ws[`B${h_length}`] = {s: option_default,t: "s",v: data_1.search_date,w: data_1.search_date,h: data_1.search_date,r: '<t>'+data_1.search_date+'</t>',z: "General"};
              ws[`C${h_length}`] = {s: option_default,t: "s",v: `${data_2.area_name}(${data_2.area_dong})`,w: `${data_2.area_name}(${data_2.area_dong})`,h: `${data_2.area_name}(${data_2.area_dong})`,r: `<t>${data_2.area_name}(${data_2.area_dong})</t>`,z: "General"};
              ws[`D${h_length}`] = {s: option_default,t: "s",v: total_r,w: total_r,h: total_r,r: '<t>'+total_r+'</t>',z: "General"};
              ws[`E${h_length}`] = {s: option_default,t: "s",v: item_d.user_name ? item_d.user_name : '',w: item_d.user_name ? item_d.user_name : '',h: item_d.user_name ? item_d.user_name : '',r: '<t>'+item_d.user_name ? item_d.user_name : ''+'</t>',z: "General"};
              ws[`F${h_length}`] = {s: option_default,t: "s",v: item_d.company_name ? item_d.company_name : '',w: item_d.company_name ? item_d.company_name : '',h: item_d.company_name ? item_d.company_name : '',r: '<t>'+item_d.company_name ? item_d.company_name : ''+'</t>',z: "General"};
              ws[`G${h_length}`] = {s: option_default,t: "s",v: item_d.construction_name ? item_d.construction_name : '',w: item_d.construction_name ? item_d.construction_name : '',h: item_d.construction_name ? item_d.construction_name : '',r: '<t>'+item_d.construction_name ? item_d.construction_name : ''+'</t>',z: "General"};
              ws[`H${h_length}`] = {s: option_default,t: "s",v: item_d.occupation_name ? item_d.occupation_name : '',w: item_d.occupation_name ? item_d.occupation_name : '',h: item_d.occupation_name ? item_d.occupation_name : '',r: '<t>'+item_d.occupation_name ? item_d.occupation_name : ''+'</t>',z: "General"};
              ws[`I${h_length}`] = {s: option_default,t: "s",v: item_d.enter_datetime ? item_d.enter_datetime : '',w: item_d.enter_datetime ? item_d.enter_datetime : '',h: item_d.enter_datetime ? item_d.enter_datetime : '',r: '<t>'+item_d.enter_datetime ? item_d.enter_datetime : ''+'</t>',z: "General"};
              ws[`J${h_length}`] = {s: option_default,t: "s",v: item_d.out_datetime ? item_d.out_datetime : '',w: item_d.out_datetime ? item_d.out_datetime : '',h: item_d.out_datetime ? item_d.out_datetime : '',r: '<t>'+item_d.out_datetime ? item_d.out_datetime : ''+'</t>',z: "General"};

              total_r--;
              h_length++;
            });
          });
        });


        // 병합한거 합치기
        ws['!merges'] = [];
        ws['!merges'] = ws['!merges'].concat(merge_obj);

        // length_1 = length_1+44;
        // length_3 = length_3+2;

        // 열 높이 맞추기
        
        for(let i = 26; i < h_length; i++){
          if(!ws['!rows'][i]) ws['!rows'].push({hpt:80, hpx:100});
          else if(ws['!rows'][i].hpx !== 80) ws['!rows'][i] = {hpt:80, hpx:100};
        }
        // ws['!rows'][24] = {hpt:80, hpx:100};
        // ws['!rows'][25] = {hpt:80, hpx:100};

        // A4크기로 영역 지정하기
        // length_2 = length_2+23+degi_num;
        ws['!print'].area = {s:{r:0,c:0},e:{r:h_length > 26 ? h_length : 26,c:10}};

        const ref = `A1:K3000`;
        ws['!ref'] = ref;
        


        let theme_arr:any = [];
        for(let i = 0; i < 11; i++){
          if(i == 0) theme_arr.push({width: 4});
          else if(i == 1) theme_arr.push({width: 22});
          else if(i == 2) theme_arr.push({width: 22});
          else if(i == 10) theme_arr.push({width: 4});
          else theme_arr.push({width: 26});
        }
        ws['!cols'] = theme_arr;

        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});
        // res(ws['!images'][0]['!data']);
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }

  // 모니터링용 출역현인원
  ex_download_23(url, filename, item, item_1) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});

        const ws = workbook.Sheets[workbook.SheetNames[0]];
        console.log(ws);

        let h_length:number = 7; // 프린트 열 수

        // 출역일자
        ws.G2.t = 's';
        ws.G2.v = item.nfc_date;
        ws.G2.r = '<t>'+item.nfc_date+'</t>';
        ws.G2.h = item.nfc_date;
        ws.G2.w = item.nfc_date;
        ws.G2.s.sz = 22;

        // 관리자 인원
        ws.C3.t = 's';
        ws.C3.v = item.admin_count;
        ws.C3.r = '<t>'+item.admin_count+'</t>';
        ws.C3.h = item.admin_count;
        ws.C3.w = item.admin_count;
        ws.C3.s.sz = 28;

        // 근로자 인원
        ws.C4.t = 's';
        ws.C4.v = item.user_count;
        ws.C4.r = '<t>'+item.user_count+'</t>';
        ws.C4.h = item.user_count;
        ws.C4.w = item.user_count;
        ws.C4.s.sz = 28;

        let option_default:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 22,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }
        console.log('item - ', item_1);

        // merge: s(시작),e(끝) r(세로), c(가로)
        let merge_obj = [];
        // merge_obj.push({s: {r: 6, c: 1}, e: {r: 7, c: 24}}); // 전
        // merge_obj.push({s: {r: length_flag_8+flag_num, c: 25}, e: {r: length_flag_8+flag_num, c: 46}}); // 후
        item_1.map((item_d) => {
          ws[`B${h_length}`] = {s: option_default,t: "s",v: item_d.index,w: item_d.index,h: item_d.index,r: '<t>'+item_d.index+'</t>',z: "General"};
          ws[`C${h_length}`] = {s: option_default,t: "s",v: item_d.user_name ? item_d.user_name : '',w: item_d.user_name ? item_d.user_name : '',h: item_d.user_name ? item_d.user_name : '',r: '<t>'+item_d.user_name ? item_d.user_name : ''+'</t>',z: "General"};
          ws[`D${h_length}`] = {s: option_default,t: "s",v: item_d.company_name ? item_d.company_name : '',w: item_d.company_name ? item_d.company_name : '',h: item_d.company_name ? item_d.company_name : '',r: '<t>'+item_d.company_name ? item_d.company_name : ''+'</t>',z: "General"};
          ws[`E${h_length}`] = {s: option_default,t: "s",v: item_d.construction_name ? item_d.construction_name : '',w: item_d.construction_name ? item_d.construction_name : '',h: item_d.construction_name ? item_d.construction_name : '',r: '<t>'+item_d.construction_name ? item_d.construction_name : ''+'</t>',z: "General"};
          ws[`F${h_length}`] = {s: option_default,t: "s",v: item_d.occupation_name ? item_d.occupation_name : '',w: item_d.occupation_name ? item_d.occupation_name : '',h: item_d.occupation_name ? item_d.occupation_name : '',r: '<t>'+item_d.occupation_name ? item_d.occupation_name : ''+'</t>',z: "General"};
          ws[`G${h_length}`] = {s: option_default,t: "s",v: item_d.area_name ? item_d.area_name : '',w: item_d.area_name ? item_d.area_name : '',h: item_d.area_name ? item_d.area_name : '',r: '<t>'+item_d.area_name ? item_d.area_name : ''+'</t>',z: "General"};
          ws[`H${h_length}`] = {s: option_default,t: "s",v: item_d.area_name ? item_d.area_name : '',w: item_d.area_name ? item_d.area_name : '',h: item_d.area_name ? item_d.area_name : '',r: '<t>'+item_d.area_name ? item_d.area_name : ''+'</t>',z: "General"};
          merge_obj.push({s: {r: h_length, c: 6}, e: {r: h_length, c: 7}}); // 전

          h_length++;
        });

        // 열 높이 맞추기
        
        for(let i = 35; i < h_length; i++) ws['!rows'].push({hpt:80, hpx:100});
        ws['!rows'][33] = {hpt:80, hpx:100};
        ws['!rows'][34] = {hpt:80, hpx:100};

        // merge
        ws['!merges'] = ws['!merges'].concat(merge_obj);

        // A4크기로 영역 지정하기
        ws['!print'].area = {s:{r:0,c:0},e:{r:h_length > 29 ? h_length : 29,c:8}};

        let arr_langth = 10+h_length;
        const ref = `A1:I${arr_langth}`;
        ws['!ref'] = ref;
        


        let theme_arr:any = [];
        for(let i = 0; i < 10; i++){
          if(i == 0) theme_arr.push({width: 8});
          else if(i == 3) theme_arr.push({width: 88});
          else if(i == 6) theme_arr.push({width: 15});
          else if(i == 7) theme_arr.push({width: 15});
          else if(i == 8) theme_arr.push({width: 8});
          else theme_arr.push({width: 27});
        }
        ws['!cols'] = theme_arr;

        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});
        // res(ws['!images'][0]['!data']);
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }
  // 작업구간 출역현황 엑셀 다운로드 
  ex_download_24(url, filename, item, item_1) {
    return new Promise(async(res) => {
      const req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "arraybuffer"; 

      // console.log('----------');
      // console.log(item_1);
      // console.log('----------');
      // console.log('----------');
      // console.log(company_name);
      // console.log('----------');
  
      req.onload = (e) => {
        console.log(req.response);
        const data = new Uint8Array(req.response);
        console.log(data);
        const workbook = XLSX.read(req.response, {type:"buffer", cellStyles:true, bookImages: true});

        const ws = workbook.Sheets[workbook.SheetNames[0]];
        // console.log(ws['!images']);
        console.log(ws);

        // merge: s(시작),e(끝) r(세로), c(가로)
        // let merge_obj:any = [];
        // merge_obj.push({s: {r: 13, c: 1}, e: {r: 18, c: 4}});
        // ws['!merges'] = ws['!merges'].concat(merge_obj);

        let h_length:number = 7; // 프린트 열 수

        // 출역일자
        ws.H2.t = 's';
        ws.H2.v = item.nfc_date;
        ws.H2.r = '<t>'+item.nfc_date+'</t>';
        ws.H2.h = item.nfc_date;
        ws.H2.w = item.nfc_date;
        ws.H2.s.sz = 22;

        // 관리자 인원
        ws.C3.t = 's';
        ws.C3.v = item.admin_count;
        ws.C3.r = '<t>'+item.admin_count+'</t>';
        ws.C3.h = item.admin_count;
        ws.C3.w = item.admin_count;
        ws.C3.s.sz = 28;

        // 근로자 인원
        ws.C4.t = 's';
        ws.C4.v = item.user_count;
        ws.C4.r = '<t>'+item.user_count+'</t>';
        ws.C4.h = item.user_count;
        ws.C4.w = item.user_count;
        ws.C4.s.sz = 28;

        let total_c = item.admin_count+item.user_count;
        let total_r = item.admin_count+item.user_count;

        let option_default:any = {
          alignment: {vertical: "center", horizontal: "center", wrapText: true},
          bottom: {style: "thin", color: {rgb: "000000"}},
          color: {theme: 1, rgb: "000000"},
          family: 2,
          left: {style: "thin", color: {rgb: "000000"}},
          name: "굴림",
          patternType: "none",
          sz: 22,
          top: {style: "thin", color: {rgb: "000000"}},
          right: {style: "thin", color: {rgb: "000000"}}
        }
        let merge_obj = [];
        ws['!merges'] = [];
        let merger_num_1 = 6;
        let merger_num_2 = 6;
        merge_obj.push({s: {r: 1, c: 7}, e: {r: 1, c: 8}});
        item_1.map((data_1) => {
          // data_1.search_date 날짜
          let t_c = data_1.user_count+data_1.admin_count; // 총 출역수

          // merge - 병합
          // merge: s(시작),e(끝) r(세로), c(가로)
          console.log('merger_num_1 - ',merger_num_1);
          merge_obj.push({s: {r: merger_num_1, c: 1}, e: {r: merger_num_1+t_c-1, c: 1}});
          merger_num_1+=t_c;
          

          // data_1.data.map((data_2) => {
            // merge - 병합
            // merge: s(시작),e(끝) r(세로), c(가로)
            // console.log('merger_num_2 - ',merger_num_2);
            // merge_obj.push({s: {r: merger_num_2, c: 2}, e: {r: merger_num_2+data_2.area_list.length-1, c: 2}});
            // merger_num_2+=data_2.area_list.length

            data_1.data.map((item_d) => {
              ws[`B${h_length}`] = {s: option_default,t: "s",v: data_1.search_date,w: data_1.search_date,h: data_1.search_date,r: '<t>'+data_1.search_date+'</t>',z: "General"};
              ws[`C${h_length}`] = {s: option_default,t: "s",v: total_r,w: total_r,h: total_r,r: '<t>'+total_r+'</t>',z: "General"};
              ws[`D${h_length}`] = {s: option_default,t: "s",v: item_d.user_name ? item_d.user_name : '',w: item_d.user_name ? item_d.user_name : '',h: item_d.user_name ? item_d.user_name : '',r: '<t>'+item_d.user_name ? item_d.user_name : ''+'</t>',z: "General"};
              ws[`E${h_length}`] = {s: option_default,t: "s",v: item_d.company_name ? item_d.company_name : '',w: item_d.company_name ? item_d.company_name : '',h: item_d.company_name ? item_d.company_name : '',r: '<t>'+item_d.company_name ? item_d.company_name : ''+'</t>',z: "General"};
              ws[`F${h_length}`] = {s: option_default,t: "s",v: item_d.construction_name ? item_d.construction_name : '',w: item_d.construction_name ? item_d.construction_name : '',h: item_d.construction_name ? item_d.construction_name : '',r: '<t>'+item_d.construction_name ? item_d.construction_name : ''+'</t>',z: "General"};
              ws[`G${h_length}`] = {s: option_default,t: "s",v: item_d.occupation_name ? item_d.occupation_name : '',w: item_d.occupation_name ? item_d.occupation_name : '',h: item_d.occupation_name ? item_d.occupation_name : '',r: '<t>'+item_d.occupation_name ? item_d.occupation_name : ''+'</t>',z: "General"};
              ws[`H${h_length}`] = {s: option_default,t: "s",v: item_d.enter_datetime ? item_d.enter_datetime : '',w: item_d.enter_datetime ? item_d.enter_datetime : '',h: item_d.enter_datetime ? item_d.enter_datetime : '',r: '<t>'+item_d.enter_datetime ? item_d.enter_datetime : ''+'</t>',z: "General"};
              ws[`I${h_length}`] = {s: option_default,t: "s",v: item_d.out_datetime ? item_d.out_datetime : '',w: item_d.out_datetime ? item_d.out_datetime : '',h: item_d.out_datetime ? item_d.out_datetime : '',r: '<t>'+item_d.out_datetime ? item_d.out_datetime : ''+'</t>',z: "General"};

              total_r--;
              h_length++;
            });
          // });
        });


        // 병합한거 합치기
        
        ws['!merges'] = ws['!merges'].concat(merge_obj);

        // length_1 = length_1+44;
        // length_3 = length_3+2;

        // 열 높이 맞추기
        
        for(let i = 26; i < h_length; i++){
          if(!ws['!rows'][i]) ws['!rows'].push({hpt:80, hpx:100});
          else if(ws['!rows'][i].hpx !== 80) ws['!rows'][i] = {hpt:80, hpx:100};
        }
        // ws['!rows'][24] = {hpt:80, hpx:100};
        // ws['!rows'][25] = {hpt:80, hpx:100};

        // A4크기로 영역 지정하기
        // length_2 = length_2+23+degi_num;
        ws['!print'].area = {s:{r:0,c:0},e:{r:h_length > 26 ? h_length : 26,c:10}};

        const ref = `A1:K3000`;
        ws['!ref'] = ref;
        


        let theme_arr:any = [];
        for(let i = 0; i < 11; i++){
          if(i == 0) theme_arr.push({width: 4});
          else if(i == 1) theme_arr.push({width: 22});
          else if(i == 2) theme_arr.push({width: 22});
          else if(i == 10) theme_arr.push({width: 4});
          else theme_arr.push({width: 26});
        }
        ws['!cols'] = theme_arr;

        XLSX.writeFile(workbook, filename, {type:"buffer", cellStyles:true, bookImages: true});
        // res(ws['!images'][0]['!data']);
      }
      req.onerror = (e) => {
        res(e);
      }
      req.send();
    });
  }


  // 나이
  ageTrans(item){if(item) return this.date.age(item) ? this.date.age(item) : ''; else '';}
}

export class interfaceClass {
  setDefault(data) {
    if(data) {
      for(let key in this) {
        if(key === 'origin') continue;
        if(typeof data[key] === 'undefined') {
          this.origin[key] = this[key];
        }
        else if(typeof data[key] === 'object') {
          if(this[key].constructor.name === 'Array') {
            this[key] = data[key] === null ? this[key] : JSON.parse(JSON.stringify(data[key]));
            this.origin[key] = data[key] === null ? this[key] : JSON.parse(JSON.stringify(data[key]));
          } else {
            this[key] = data[key] === null ? this[key] : {...data[key]};
            this.origin[key] = data[key] === null ? this[key] : {...data[key]};
          }
        }
        else {
          this[key] = data[key];
          this.origin[key] = data[key];
        }
      }
      const proto = Object.getPrototypeOf(this);
      for(let key of Object.getOwnPropertyNames(proto)) {
        const prop:any = this[key];
        switch(key) {
          case 'constructor': case 'hasOwnProperty': case 'isPrototypeOf': case 'propertyIsEnumerable': case 'toLocaleString': case 'valueOf': case 'toString': case '__defineGetter__': case '__defineSetter__': case '__lookupGetter__': case '__lookupSetter__':
            break;
          default:
            this.origin[key] = prop;
            break;
        }
      }
    } else {
      for(let key in this) {
        if(key === 'origin') continue;
        if(typeof this[key] === 'object') {
          if(this[key].constructor.name === 'Array') {
            this.origin[key] = JSON.parse(JSON.stringify(this[key]));
          } else {
            this.origin[key] = {...this[key]};
          }
        } else {
          this.origin[key] = this[key];
        }
      }
    }
  }
  checkChange() {
    const _self:any = this;
    for(let key in _self) {
      if(key !== 'origin' && _self[key] !== _self.origin[key]) {
        if(typeof _self[key] === 'object') {
          if(JSON.stringify(_self[key]) !== JSON.stringify(_self.origin[key])) {
            return true;
          }
        } else {
          return true;
        }
      }
    }
    return false;
  }
  toJSON() {
    let clone:any = {};
    const proto = Object.getPrototypeOf(this);
    for(let key of Object.getOwnPropertyNames(proto)) {
      const prop:any = this[key];
      switch(key) {
        case 'constructor': case 'hasOwnProperty': case 'isPrototypeOf': case 'propertyIsEnumerable': case 'toLocaleString': case 'valueOf': case 'toString': case '__defineGetter__': case '__defineSetter__': case '__lookupGetter__': case '__lookupSetter__':
          break;
        default:
          clone[key] = prop;
          break;
      }
    }
    clone = {
      ...clone,
      ...this
    }
    if(clone.origin) delete clone.origin;
    return clone;
  }
  origin:any = {};
}