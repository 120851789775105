import { Injectable } from '@angular/core';
// import { InAppBrowser, InAppBrowserEventType } from '@ionic-native/in-app-browser/ngx';
// import { Platform } from '@ionic/angular';
// import { DeviceService } from './device.service';
// import { StorageService } from './storage.service';
// import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
// import { ConnectService } from './connect.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WebService {

  constructor(
    // private iab: InAppBrowser,
    // private storage: StorageService,
    // private device: DeviceService,
    // private platform: Platform,
    // private screenOrientation: ScreenOrientation,
    // private connect: ConnectService,
    private router: Router
  ) { }

  // open(url = '/main', menuOpen: 'yes' | 'no' = 'no') {
  //   const code = `
  //     const data = ${JSON.stringify(this.storage.user.data)};
  //     localStorage.clear();
  //     for(let key in data) {
  //       localStorage.setItem('kunyoung-web@' + key, String(data[key]));
  //     }
  //     localStorage.setItem('platform_type', "${this.device.platform_type}");
  //     localStorage.setItem('platform_key', "${this.device.platform_key}");
  //     localStorage.setItem('inappbrowser', 'yes');
  //     localStorage.setItem('menuOpen', '${menuOpen}');
  //     localStorage.setItem('kunyoung-app@area_type', '${this.connect.area_type}');
  //     location.href = '${url}';
  //   `;

  //   let monitor_flag:string  = '';
  //   let platform_state:InAppBrowserEventType = 'loadstart';
  //   if(this.platform.is('ios')){
  //     platform_state = 'loadstop';
  //     monitor_flag = ',presentationstyle=pagesheet';
  //   }
    
  //   const browser = this.iab.create(`${this.connect.web_url}/blank`, 'inapp', `location=no,footer=no,enableViewportScale=yes${monitor_flag}`);
  //   browser.on(platform_state).subscribe(event => {
  //     console.log(event);
  //     if(event.url.indexOf('/blank') > -1) {
  //       console.log('inner blank');
  //       browser.executeScript({
  //         code
  //       });
  //     } else if(event.url.indexOf('/monitor') > -1){
  //       this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
  //     } else if(event.url.indexOf('/exit') > -1) {
  //       console.log('inner exit');
  //       browser.close();
  //     } else {
  //       this.screenOrientation.unlock();
  //     }
  //   });
  //   browser.on('exit').subscribe(event => {
  //     this.screenOrientation.unlock();
  //   });

  //   return browser;
  // }

  open(url = '/main', menuOpen: 'yes' | 'no' = 'no') {
    this.router.navigate(['/admin-web'+url]);
    // if(url == '/monitor') this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
  }
}
