import { Injectable } from '@angular/core';
import { ConnectService } from './connect.service';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  HapticsImpactStyle,
  HapticsNotificationType } from '@capacitor/core';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { WorkerService } from './worker.service';

const { PushNotifications, Haptics } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class PushService {

  activated:boolean = false;

  audio = new Audio();

  constructor(
    private connect: ConnectService,
    private alert: AlertController,
    private toast: ToastController,
    private worker: WorkerService,
    private plt: Platform
  ) {
    this.audio.src = 'assets/sound/alarm.mp3';
  }

  initPush() {
    return new Promise(async(res) => {
      console.log('INIT PUSH');
  
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermission().then( async(result) => {
        if (result.granted) {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
          const alert = await this.alert.create({
            header: '푸시서비스 동의',
            message: '이 어플리케이션을 사용하기 위해서는 푸시서비스 동의가 필요합니다. 푸시서비스에 동의해주세요.',
            buttons: [
              {text: '확인'}
            ]
          });
          alert.present();
          await alert.onDidDismiss();
          res(true);
        }
      });

      if(this.plt.is('android')) {
        //비상알람벨만 소리바뀌고, 중장비는 기본음으로
        PushNotifications.createChannel({
          id: 'kunyoung_app_3',
          name: 'kunyoung_app_3',
          description: 'kunyoung_app_3',
          sound: "alarm.mp3",
          importance: 5,
          vibration: true,
          visibility: 1,
          lights: true
        })
        .catch(async(e) => {
          const toast = await this.toast.create({
            message: '긴급 푸시 채널(진동)을 생성할 수 없습니다!\안드로이드 8.0 이상 버전에서만 동작 가능합니다.',
            duration: 5000,
            buttons: [
              {
                side: 'end',
                icon: 'close'
              }
            ]
          });
          toast.present();
          res(true);
        });
        PushNotifications.createChannel({
          id: 'kunyoung_app_2',
          name: 'kunyoung_app_2',
          description: 'kunyoung_app_2',
          importance: 5,
          vibration: true,
          visibility: 1
        })
        .catch(async(e) => {
          const toast = await this.toast.create({
            message: '중장비 푸시 채널(진동)을 생성할 수 없습니다!\안드로이드 8.0 이상 버전에서만 동작 가능합니다.',
            duration: 5000,
            buttons: [
              {
                side: 'end',
                icon: 'close'
              }
            ]
          });
          toast.present();
          res(true);
        });
        PushNotifications.createChannel({
          id: 'kunyoung_app_1',
          name: 'kunyoung_app_1',
          description: 'kunyoung_app_1',
          importance: 3,
          vibration: false,
          visibility: 1,
          sound: 'disabled'
        })
        .catch(async(e) => {
          const toast = await this.toast.create({
            message: '푸시 채널(무음)을 생성할 수 없습니다!\안드로이드 8.0 이상 버전에서만 동작 가능합니다.',
            duration: 5000,
            buttons: [
              {
                side: 'end',
                icon: 'close'
              }
            ]
          });
          toast.present();
          res(true);
        });
      }

  
      console.log('Push registration start - 1');
      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        async(token: PushNotificationToken) => {
          console.log('Push registration success, token: ');
          console.log(JSON.stringify(token));
          const resp = await this.connect.run('Insert_UserPush', {
            register_id: token.value
          });
          switch(resp.code) {
            case 0:
              console.log('PUSH REGISTERED');
              if(this.plt.is('android')) {
                PushNotifications.listChannels().then(list => {
                  console.log('PUSH CHANNEL LIST', list);
                });
              }
              this.activated = true;
              res(true);
              break;
            default:
              this.connect.error('푸시등록 실패', resp);
              res(true);
              break;
          }
        }
      );
      console.log('Push registration end - 1');
  
      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        async(error: any) => {
          const alert = await this.alert.create({
            header: '푸시등록 실패',
            subHeader: '푸시 등록에 실패하였습니다.',
            message: JSON.stringify(error),
            buttons: [
              {text: '확인'}
            ]
          });
          alert.present();
          res(true);
        }
      );
  
      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        async(notification: PushNotification) => {
          console.log(notification);
          
          if(this.plt.is('android') && notification.title) {
            this.sosHaptics(notification);
            if(notification.title === 'SOS 응급구조 요청' || notification.title === '특이사항 근로자 알림') this.sosAlert(notification);
            else this.sosToast(notification);
          }
        }
      );
  
      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: PushNotificationActionPerformed) => {
          alert('Push action performed: ' + JSON.stringify(notification));
        }
      );
    });
  }

  async sosAlert(notification) {
    const alert = await this.alert.create({
      header: notification.title,
      message: notification.body,
      buttons: [
        {
          text: '확인',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    await alert.present();
  }

  async sosToast(notification) {
    const toast = await this.toast.create({
      header: notification.title,
      message: notification.body,
      position: 'bottom',
      color: 'danger',
      duration: 10000,
      buttons: [
        {
          side: 'start',
          icon: 'fitness'
        }, {
          text: '확인',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
            this.toast.dismiss();
          }
        }
      ]
    });
    await toast.present();
  }
  sosHaptics(notification) {
    if(this.worker.worker.push_state === 'ON') {
      if(notification.title === '비상상황이 발생하였습니다.') this.audio.play();
      
      this.hapticsImpact();
      this.hapticVibrate();
      // const interval = setInterval(() => {
      //   this.hapticVibrate();
      // }, 300);
      // setTimeout(() => {
      //   clearInterval(interval);
      // }, 2000);
    }
  }

  hapticsImpact(style = HapticsImpactStyle.Heavy) {
    console.log('HAPTIC IMPACT');
    Haptics.impact({
      style: style
    });
  }
  hapticVibrate(type = HapticsNotificationType.ERROR) {
    console.log('HAPTIC VIBRATE');
    Haptics.vibrate();
  }
}
