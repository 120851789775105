import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

  constructor(
    private alert: AlertController,
    private modal: ModalController,
    private popover: PopoverController
  ) {

  }

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.checkPage(component);
  }
  
  async checkPage(component) {

    const controls = await Promise.all([
      this.alert.getTop(),
      this.modal.getTop(),
      this.popover.getTop()
    ]);
    
    for(let i = 0; i < controls.length; i++) {
      const control = controls[i];
      if(control) {
        await control.dismiss();
        history.pushState(null,null);
        return false;
      }
    }
    return component ? (component.canDeactivate ? component.canDeactivate() : true) : true;
  }
}
