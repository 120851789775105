import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from './guard/can-deactive.guard';
import { NonOrientGuardGuard } from './guard/non-orient-guard.guard';
import { NotLoginGuard } from './guard/not-login.guard';
import { OnlyAdminGuard } from './guard/only-admin.guard';
import { OrientGuardGuard } from './guard/orient-guard.guard';
import { AppRoutingPreloaderService } from './service/app-routing-preloader-service.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'admin-web',
    pathMatch: 'full'
  },
  {
    path: 'admin-web',
    // loadChildren: './page/admin-web/admin-routing.module',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        loadChildren: () => import('./page/admin-web/login/login.module').then( m => m.LoginPageModule),
        // canActivate: [NotLoginGuard]
      },
      {
        path: 'notice',
        loadChildren: () => import('./page/admin-web/notice/notice.module').then( m => m.NoticePageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'theme-page',
        loadChildren: () => import('./page/admin-web/theme-page/theme-page.module').then( m => m.ThemePagePageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'alarm-inbox',
        loadChildren: () => import('./page/admin-web/alarm-inbox/alarm-inbox.module').then( m => m.AlarmInboxPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'worker-manage',
        loadChildren: () => import('./page/admin-web/worker-manage/worker-manage.module').then( m => m.WorkerManagePageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'daily-safty-check-list',
        loadChildren: () => import('./page/admin-web/daily-safty-check-list/daily-safty-check-list.module').then( m => m.DailySaftyCheckListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'ra-list',
        loadChildren: () => import('./page/admin-web/ra-list/ra-list.module').then( m => m.RaListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'daily-report-list',
        loadChildren: () => import('./page/admin-web/daily-report-list/daily-report-list.module').then( m => m.DailyReportListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'manager-list',
        loadChildren: () => import('./page/admin-web/manager-list/manager-list.module').then( m => m.ManagerListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'company-list',
        loadChildren: () => import('./page/admin-web/company-list/company-list.module').then( m => m.CompanyListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'manager-setup',
        loadChildren: () => import('./page/admin-web/manager-setup/manager-setup.module').then( m => m.ManagerSetupPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'incongruity-list',
        loadChildren: () => import('./page/admin-web/incongruity-list/incongruity-list.module').then( m => m.IncongruityListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'daily-plan-list',
        loadChildren: () => import('./page/admin-web/daily-plan-list/daily-plan-list.module').then( m => m.DailyPlanListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'record-list',
        loadChildren: () => import('./page/admin-web/record-list/record-list.module').then( m => m.RecordListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'labor-attend-list',
        loadChildren: () => import('./page/admin-web/labor-attend-list/labor-attend-list.module').then( m => m.LaborAttendListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'spot-list',
        loadChildren: () => import('./page/admin-web/spot-list/spot-list.module').then( m => m.SpotListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'spot-check-list',
        loadChildren: () => import('./page/admin-web/spot-check-list/spot-check-list.module').then( m => m.SpotCheckListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'new-edu-list',
        loadChildren: () => import('./page/admin-web/new-edu-list/new-edu-list.module').then( m => m.NewEduListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'special-edu-list',
        loadChildren: () => import('./page/admin-web/special-edu-list/special-edu-list.module').then( m => m.SpecialEduListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'regular-edu-list',
        loadChildren: () => import('./page/admin-web/regular-edu-list/regular-edu-list.module').then( m => m.RegularEduListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'equip-list',
        loadChildren: () => import('./page/admin-web/equip-list/equip-list.module').then( m => m.EquipListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'equip-check-list',
        loadChildren: () => import('./page/admin-web/equip-check-list/equip-check-list.module').then( m => m.EquipCheckListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'spot-check-incongruity-list',
        loadChildren: () => import('./page/admin-web/spot-check-incongruity-list/spot-check-incongruity-list.module').then( m => m.SpotCheckIncongruityListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'main',
        loadChildren: () => import('./page/admin-web/main/main.module').then( m => m.MainPageModule),
        canActivate: [OnlyAdminGuard,OrientGuardGuard],
        canDeactivate: [NonOrientGuardGuard]
      },
      {
        path: 'find-id',
        loadChildren: () => import('./page/admin-web/find-id/find-id.module').then( m => m.FindIdPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'equip-check-incongruity-list',
        loadChildren: () => import('./page/admin-web/equip-check-incongruity-list/equip-check-incongruity-list.module').then( m => m.EquipCheckIncongruityListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'ra-check-incongruity-list',
        loadChildren: () => import('./page/admin-web/ra-check-incongruity-list/ra-check-incongruity-list.module').then( m => m.RaCheckIncongruityListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'nfc-list',
        loadChildren: () => import('./page/admin-web/nfc-list/nfc-list.module').then( m => m.NfcListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'work-edu-list',
        loadChildren: () => import('./page/admin-web/work-edu-list/work-edu-list.module').then( m => m.WorkEduListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'admin-edu-list',
        loadChildren: () => import('./page/admin-web/admin-edu-list/admin-edu-list.module').then( m => m.AdminEduListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'blank',
        loadChildren: () => import('./page/admin-web/blank/blank.module').then( m => m.BlankPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'exit',
        loadChildren: () => import('./page/admin-web/blank/blank.module').then( m => m.BlankPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'ra-day-check',
        loadChildren: () => import('./page/admin-web/ra-day-check/ra-day-check.module').then( m => m.RaDayCheckPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'bicon-manage-in',
        loadChildren: () => import('./page/admin-web/bicon-manage-in/bicon-manage-in.module').then( m => m.BiconManageInPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'bicon-manage-out',
        loadChildren: () => import('./page/admin-web/bicon-manage-out/bicon-manage-out.module').then( m => m.BiconManageOutPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'bicon-manage-equip',
        loadChildren: () => import('./page/admin-web/bicon-manage-equip/bicon-manage-equip.module').then( m => m.BiconManageEquipPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'work-space-attend-list',
        loadChildren: () => import('./page/admin-web/work-space-attend-list/work-space-attend-list.module').then( m => m.WorkSpaceAttendListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'work-space-attend-zone',
        loadChildren: () => import('./page/admin-web/work-space-attend-zone/work-space-attend-zone.module').then( m => m.WorkSpaceAttendZonePageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'hr-payment-contract',
        loadChildren: () => import('./page/admin-web/hr-payment-contract/hr-payment-contract.module').then( m => m.HrPaymentContractPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'hr-payment-list',
        loadChildren: () => import('./page/admin-web/hr-payment-list/hr-payment-list.module').then( m => m.HrPaymentListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'hr-db',
        loadChildren: () => import('./page/admin-web/hr-db/hr-db.module').then( m => m.HrDbPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'equip-today-list',
        loadChildren: () => import('./page/admin-web/equip-today-list/equip-today-list.module').then( m => m.EquipTodayListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'hr-contract-list',
        loadChildren: () => import('./page/admin-web/hr-contract-list/hr-contract-list.module').then( m => m.HrContractListPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'android-term-info',
        loadChildren: () => import('./page/admin-web/android-term-info/android-term-info.module').then( m => m.AndroidTermInfoPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'edu-nfc-management',
        loadChildren: () => import('./page/admin-web/edu-nfc-management/edu-nfc-management.module').then( m => m.EduNfcManagementPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'project-outline',
        loadChildren: () => import('./page/admin-web/project-outline/project-outline.module').then( m => m.ProjectOutlinePageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'project-outline-input',
        loadChildren: () => import('./page/admin-web/project-outline-input/project-outline-input.module').then( m => m.ProjectOutlineInputPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'organization-chart',
        loadChildren: () => import('./page/admin-web/organization-chart/organization-chart.module').then( m => m.OrganizationChartPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'monthly-photo',
        loadChildren: () => import('./page/admin-web/monthly-photo/monthly-photo.module').then( m => m.MonthlyPhotoPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'bleuprint-folder',
        loadChildren: () => import('./page/admin-web/bleuprint-folder/bleuprint-folder.module').then( m => m.BleuprintFolderPageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'bleuprint-file',
        loadChildren: () => import('./page/admin-web/bleuprint-file/bleuprint-file.module').then( m => m.BleuprintFilePageModule),
        canActivate: [OnlyAdminGuard]
      },
      {
        path: 'process-rate',
        loadChildren: () => import('./page/admin-web/process-rate/process-rate.module').then( m => m.ProcessRatePageModule),
        canActivate: [OnlyAdminGuard,OrientGuardGuard],
        canDeactivate: [NonOrientGuardGuard]
      },
      {
        path: 'weather-info',
        loadChildren: () => import('./page/admin-web/weather-info/weather-info.module').then( m => m.WeatherInfoPageModule),
        canActivate: [OnlyAdminGuard,OrientGuardGuard],
        canDeactivate: [NonOrientGuardGuard]
      },
      {
        path: 'hr-contract-list-detail',
        loadChildren: () => import('./page/admin-web/hr-contract-list-detail/hr-contract-list-detail.module').then( m => m.HrContractListDetailPageModule)
      },
      {
        path: 'sosmanage',
        loadChildren: () => import('./page/admin-web/sosmanage/sosmanage.module').then( m => m.SOSmanagePageModule)
      },
      {
        path: 'labour-place-log',
        loadChildren: () => import('./page/admin-web/labour-place-log/labour-place-log.module').then( m => m.LabourPlaceLogPageModule)
      }
    ]
  },
  {
    path: 'employee-app',
    // loadChildren: './page/employee-app/employee-routing.module',
    children: [
      {
        path: 'main',
        loadChildren: () => import('./page/employee-app/main/main.module').then( m => m.MainPageModule),
        // canActivate: [NotLoginGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {preload: true}
      },
      {
        path: 'find-password',
        loadChildren: () => import('./page/employee-app/find-password/find-password.module').then( m => m.FindPasswordPageModule),
        //canActivate: [LoginGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'find-password-result',
        loadChildren: () => import('./page/employee-app/find-password-result/find-password-result.module').then( m => m.FindPasswordResultPageModule),
        //canActivate: [LoginGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'signup',
        loadChildren: () => import('./page/employee-app/signup/signup.module').then( m => m.SignupPageModule),
        //canActivate: [LoginGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'info-add-safe',
        loadChildren: () => import('./page/employee-app/info-add-safe/info-add-safe.module').then( m => m.InfoAddSafePageModule),
        //canActivate: [LoginGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'info-add-health',
        loadChildren: () => import('./page/employee-app/info-add-health/info-add-health.module').then( m => m.InfoAddHealthPageModule),
        //canActivate: [LoginGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'info-add-gard',
        loadChildren: () => import('./page/employee-app/info-add-gard/info-add-gard.module').then( m => m.InfoAddGardPageModule),
        //canActivate: [LoginGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'info-add-smoke',
        loadChildren: () => import('./page/employee-app/info-add-smoke/info-add-smoke.module').then( m => m.InfoAddSmokePageModule),
        //canActivate: [LoginGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'info-detail',
        loadChildren: () => import('./page/employee-app/info-detail/info-detail.module').then( m => m.InfoDetailPageModule),
        //canActivate: [LoginGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'request-work',
        loadChildren: () => import('./page/employee-app/request-work/request-work.module').then( m => m.RequestWorkPageModule),
        //canActivate: [LoginGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'safe-study',
        loadChildren: () => import('./page/employee-app/safe-study/safe-study.module').then( m => m.SafeStudyPageModule),
        //canActivate: [LoginGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'sos',
        loadChildren: () => import('./page/employee-app/sos/sos.module').then( m => m.SosPageModule),
        //canActivate: [LoginGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'info-list',
        loadChildren: () => import('./page/employee-app/info-list/info-list.module').then( m => m.InfoListPageModule),
        //canActivate: [LoginGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'safe-study-detail',
        loadChildren: () => import('./page/employee-app/safe-study-detail/safe-study-detail.module').then( m => m.SafeStudyDetailPageModule),
        //canActivate: [LoginGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'qr-scanner',
        loadChildren: () => import('./page/employee-app/qr-scanner/qr-scanner.module').then( m => m.QrScannerPageModule),
      },
      {
        path: 'notice',
        loadChildren: () => import('./page/employee-app/notice/notice.module').then( m => m.NoticePageModule),
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'notice-detail',
        loadChildren: () => import('./page/employee-app/notice-detail/notice-detail.module').then( m => m.NoticeDetailPageModule),
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'equip-list-user',
        loadChildren: () => import('./page/employee-app/equip-list-user/equip-list-user.module').then( m => m.EquipListUserPageModule),
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'equip-pre-check-nfc',
        loadChildren: () => import('./page/employee-app/equip-pre-check-nfc/equip-pre-check-nfc.module').then( m => m.EquipPreCheckNfcPageModule),
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'lock-screen',
        loadChildren: () => import('./page/employee-app/lock-screen/lock-screen.module').then( m => m.LockScreenPageModule),
      },
      {
        path: 'find-id',
        loadChildren: () => import('./page/employee-app/find-id/find-id.module').then( m => m.FindIdPageModule),
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'worker-modify',
        loadChildren: () => import('./page/employee-app/worker-modify/worker-modify.module').then( m => m.WorkerModifyPageModule),
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'check-daywork',
        loadChildren: () => import('./page/employee-app/check-daywork/check-daywork.module').then( m => m.CheckDayworkPageModule),
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'main-admin',
        loadChildren: () => import('./page/employee-app/main-admin/main-admin.module').then( m => m.MainAdminPageModule),
        canDeactivate: [CanDeactivateGuard],
        data: {preload: true}
      },
      {
        path: 'check-daily1',
        loadChildren: () => import('./page/employee-app/check-daily1/check-daily1.module').then( m => m.CheckDaily1PageModule),
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'check-daily2',
        loadChildren: () => import('./page/employee-app/check-daily2/check-daily2.module').then( m => m.CheckDaily2PageModule),
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'check-daily3',
        loadChildren: () => import('./page/employee-app/check-daily3/check-daily3.module').then( m => m.CheckDaily3PageModule),
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'edu-list-admin',
        loadChildren: () => import('./page/employee-app/edu-list-admin/edu-list-admin.module').then( m => m.EduListAdminPageModule),
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'equip-list-admin',
        loadChildren: () => import('./page/employee-app/equip-list-admin/equip-list-admin.module').then( m => m.EquipListAdminPageModule),
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'admin-modify',
        loadChildren: () => import('./page/employee-app/admin-modify/admin-modify.module').then( m => m.AdminModifyPageModule),
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'ra-list-admin',
        loadChildren: () => import('./page/employee-app/ra-list-admin/ra-list-admin.module').then( m => m.RaListAdminPageModule)
      },
      {
        path: 'labour-person-detail',
        loadChildren: () => import('./page/employee-app/labour-person-detail/labour-person-detail.module').then( m => m.LabourPersonDetailPageModule)
      },
      {
        path: 'hr-payment-check',
        loadChildren: () => import('./page/employee-app/hr-payment-check/hr-payment-check.module').then( m => m.HrPaymentCheckPageModule)
      },
      {
        path: 'hr-labour-contract',
        loadChildren: () => import('./page/employee-app/hr-labour-contract/hr-labour-contract.module').then( m => m.HrLabourContractPageModule)
      },
      {
        path: 'mangement-person-detail',
        loadChildren: () => import('./page/employee-app/mangement-person-detail/mangement-person-detail.module').then( m => m.MangementPersonDetailPageModule)
      },
      {
        path: 'today-work-people',
        loadChildren: () => import('./page/employee-app/today-work-people/today-work-people.module').then( m => m.TodayWorkPeoplePageModule)
      },
      {
        path: 'nfc-edu1',
        loadChildren: () => import('./page/employee-app/nfc-edu1/nfc-edu1.module').then( m => m.NfcEdu1PageModule)
      },
      {
        path: 'info-modify-health',
        loadChildren: () => import('./page/employee-app/info-modify-health/info-modify-health.module').then( m => m.InfoModifyHealthPageModule)
      },
      {
        path: 'info-modify-gard',
        loadChildren: () => import('./page/employee-app/info-modify-gard/info-modify-gard.module').then( m => m.InfoModifyGardPageModule)
      }
    ]
  },
];

@NgModule({
  imports: [
    // , {preloadingStrategy: AppRoutingPreloaderService}
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
