import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-img-preview',
  templateUrl: './img-preview.component.html',
  styleUrls: ['./img-preview.component.scss'],
})
export class ImgPreviewComponent implements OnInit {

  @Input('item') item = [];

  constructor(
    private modal: ModalController
  ) { }

  ngOnInit() {
    console.log(this.item);
  }

  dismiss() {
    this.modal.dismiss();
  }
}
