import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { DeviceService } from './device.service';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { StorageService } from './storage.service';
import { isPlatformServer } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import * as FormDataServer from 'form-data';

type CONNECT_RESULT = {code:number, data:any, message:string, url:string};

@Injectable({
  providedIn: 'root'
})
export class ConnectService {

  // url = 'https://keco.gsil.me/keko/userservice.asmx/';

  // 실사용
  // url = 'https://www.kunyoungcms.com/KUNYOUNG/managementservice.asmx/';
  // 테스트
  // url = 'http://3.35.5.135/KUNYOUNG/managementservice.asmx/';
  

  // 테스트
  test_mode:boolean = false;

  area_type:'세종' | '서현' = '세종';
  url = '';
  web_url = '';
  area:'세종시 블록형 단독주택공사' | '분당 서현 라포르테 블랑 현장' = '세종시 블록형 단독주택공사';
  drawing = 'dom1.png'; // dom_saehyun.png
 
  

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private alertController: AlertController,
    private http: HttpClient,
    private device: DeviceService,
    private storage: StorageService,
    private loadingController: LoadingController,
    private router: Router,
    private platform: Platform
  ) {
    const area_type = window.localStorage.getItem('kunyoung-app@area_type') || this.area_type;
    if(area_type) {
      this.changeServer(area_type);
    }
  }
  
  changeServer(area_type) {
    this.area_type = area_type;
    window.localStorage.setItem('kunyoung-app@area_type', this.area_type);
    switch(area_type) {
      case '세종':
        this.url = `${this.test_mode ? 'http://13.124.86.135' : 'https://kunyoungcms.gsil.shop'}/KUNYOUNG/managementservice.asmx/`;
        this.web_url = this.test_mode ? 'http://13.124.86.135' : 'https://kunyoungcms.gsil.shop';
        this.area = '세종시 블록형 단독주택공사';
        this.drawing = 'dom1.png';
        break;
      case '서현':
        // this.url = `${this.test_mode ? 'http://3.35.5.135' : 'https://kunyoungcms.gsil.shop'}/kunyoung/managementservice.asmx/`;
        // this.web_url = this.test_mode ? 'http://3.35.5.135' : 'https://kunyoungcms.com';
        // this.area = '분당 서현 라푸르테 블랑 현장';
        // this.drawing = 'dom_saehyun.png';

        this.url = 'https://kunyoungcms.gsil.shop/KUNYOUNG/managementservice.asmx/';
        this.web_url = this.test_mode ? 'http://13.124.86.135' : 'https://kunyoungcms.gsil.shop';
        this.area = '분당 서현 라포르테 블랑 현장';
        this.drawing = 'dom_saehyun.png';
        break;
    }
  }

  /** 서버 접속. 기본데이터: platform_type, platform_key, user_id, user_session, user_type */
  async run(method, data = {}, options:{loading?:string, test_img?:boolean} = {loading: '', test_img: false}) {
    const url = this.url + method;
    const { platform_type, platform_key } = await this.device.get();
    const { user_id, user_session } = this.storage.user.data;
    if(!environment.production) {
      if(method !== 'Insert_EnterBicon') {
        console.log({
          method: url,
          ...data,
          platform_type, platform_key, user_id, user_session
        });
      }
    }
    let body = this.jsonToForm({
      ...data,
      platform_type, platform_key, user_id, user_session
    });
    let headers = new HttpHeaders();
    let result:CONNECT_RESULT;

    let loading:HTMLIonLoadingElement;
    if(!isPlatformServer(this.platformId)) {
      if(options.loading) {
        loading = await this.loadingController.create({
          message: options.loading
        });

        await loading.present();
      }
    }

    try {
      const http = await this.http.post(url, body, { headers })
      .pipe(timeout(60000))
      .toPromise() as CONNECT_RESULT;
      result = http;
    } catch(error) {
      result  = (() => {
        switch(error.status) {
          case 0:
            return {code: 1, data: error.message, message: '인터넷 연결을 확인해주세요.', url: ''};
          default:
            return {code: error.status, data: error.error, message: error.message, url: ''};
        }
      })()
    }
    if(options.test_img && !environment.production) result.data = await this.changeTestModeUrl(result.data);

    result.url = method;
    if(!isPlatformServer(this.platformId)) {
      if(options.loading) {
        loading.dismiss();
      }
    }
    if(method !== 'Insert_EnterBicon') {
      console.log(result);
    }
    if(!environment.production) {
    }
    return result;
  }
  async error(title, res) {
    res.code ? null : res.code = '';
    switch(res.code) {
      case 1002:
      case 2002:
        //ip교체로 인한 세션 마감
        this.storage.user.clear();
        // 퍼블리싱 끝나면 밑에 첫째줄 풀어줘야함
        this.router.navigate(['/admin-web/login'], {replaceUrl: true});
        break;
      default:
        const error = await this.alertController.create({
          // mode: 'ios',
          header: `${title}${res.code && !environment.production ? '['+res.code+']' : ''}`,
          message: res.message,
          buttons: [{
            text: '확인'
          }]
        });
        error.present();
        break;
    }
  }
  jsonToForm(json) {
    let form = new FormData();
    for (let prompt in json) {
      if (typeof json[prompt] == 'object') {
        if (!json[prompt]) {
          form.append(prompt, json[prompt]);
        } else if (json[prompt].constructor.name == 'Array') {
          if (typeof json[prompt][0] == 'object') {
            if (json[prompt][0].constructor.name == 'File') {
              for (let i = 0; i < json[prompt].length; i++) {
                form.append(prompt, json[prompt][i]);
              }
            } else if (json[prompt][0].constructor.name == 'Blob') {
              for (let i = 0; i < json[prompt].length; i++) {
                switch(json[prompt][i].type) {
                  case 'audio/mpeg':
                    form.append(prompt, json[prompt][i], 'attaches' + new Date().getTime() + '.mp3');
                    break;
                  case 'audio/wav':
                    form.append(prompt, json[prompt][i], 'attaches' + new Date().getTime() + '.wav');
                    break;
                  default:
                    form.append(prompt, json[prompt][i], 'attaches' + new Date().getTime() + '.jpeg');
                    break;
                }
              }
            } else {
              form.append(prompt, JSON.stringify(json[prompt]));
            }
          } else {
            form.append(prompt, JSON.stringify(json[prompt]));
          }
        } else {
          if (json[prompt].constructor.name == 'File') {
            form.append(prompt, json[prompt]);
          } else if (json[prompt].constructor.name == 'Blob') {
            switch(json[prompt].type) {
              case 'audio/mpeg':
                form.append(prompt, json[prompt], 'attaches' + new Date().getTime() + '.mp3');
                break;
              case 'audio/wav':
                form.append(prompt, json[prompt], 'attaches' + new Date().getTime() + '.wav');
                break;
              default:
                form.append(prompt, json[prompt], 'attaches' + new Date().getTime() + '.jpeg');
                break;
            }
          } else {
            form.append(prompt, JSON.stringify(json[prompt]));
          }
        }
      } else {
        if (json[prompt] == 'true') json[prompt] = true;
        if (json[prompt] == 'false') json[prompt] = false;
        form.append(prompt, json[prompt]);
      }
    }
    return form;
  }

  async changeTestModeUrl(json) {
    let str = JSON.stringify(json);
    const imgUrls = str.match(/(https:\/\/www\.kunyoungcms\.com[\/\w\-\.\:\[\]ㄱ-하-ㅣ가-힣]+)(.jpg|.jpeg|.png|.gif)/g) || [];
    const imgUrlPromise = imgUrls.map((imgUrl) => this.changeTestImgUrl(imgUrl));
    const newImageUrls = await Promise.all(imgUrlPromise); 

    for(let i = 0; i < imgUrls.length; i++) {
      const imgUrl = imgUrls[i];
      const newImgUrl = newImageUrls[i];
      if(newImgUrl) str = str.replace(imgUrl, newImgUrl);
    }

    const newJson = JSON.parse(str);

    return newJson;
  }
  private changeTestImgUrl(imgUrl):Promise<string> {
    return new Promise(res => {
      let img = document.createElement('img');
      img.onload = () => {
        img = null;
        res(null);
      }
      img.onerror = () => {
        img = null;
        const reg = /https:\/\/www\.kunyoungcms\.com/;
        if(reg.test(imgUrl)) res(imgUrl.replace(reg, 'http://3.35.5.135'));
        else res(null);
      }
      img.src = imgUrl;
    });
  }
  toArrayBuffer(buf) {
    var ab = new ArrayBuffer(buf.length);
    var view = new Uint8Array(ab);
    for (var i = 0; i < buf.length; ++i) {
      view[i] = buf[i];
    }
    return ab;
  }
  isApp(){
    if((this.platform.is('android') && !this.platform.is('mobileweb')) || (this.platform.is('ios') && !this.platform.is('mobileweb'))) return true;
    else return false;
  }
  isiOS(){
    if((this.platform.is('ios') && !this.platform.is('mobileweb'))) return true;
    else return false;
  }
  isAndroid(){
    if((this.platform.is('android') && !this.platform.is('mobileweb'))) return true;
    else return false;
  }
}