import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumberService {

  constructor() { }

  number(number:string | number) {
    return String(number).replace(/[^\d]/g, "");
  }
  XX(number:string | number) {
    let sNumber = String(number);
    if (sNumber.length < 2) sNumber = "0" + sNumber;
    return sNumber;
  }
  price(number) {
    let sNumber = String(number);
    let sNumber_2 = [];
    if(sNumber.indexOf('.') != -1){
      sNumber_2 = sNumber.split('.');
      let str = sNumber_2[0].replace(/[^0-9]/g, '');
      let price = str.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      return sNumber.startsWith('-') ? '-' + price + '.' + sNumber_2[1] : price + '.' + sNumber_2[1];
    } else {
      let str = sNumber.replace(/[^0-9]/g, '');
      let price = str.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      return sNumber.startsWith('-') ? '-' + price : price;
    }
  }
  phone(number) {
    console.log(number);
    var tmp = '';
    var str = String(number).replace(/[^0-9]/g, '');
    console.log(str);
    switch (str.length) {
      case 10: case 9: case 8: case 7:
        tmp = str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 3);
        tmp += '-';
        tmp += str.substr(6);
        break;
      case 6: case 5: case 4:
        tmp = str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3);
        break;
      case 3: case 2: case 1: case 0:
        tmp = str;
        break;
      default:
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 4);
        tmp += '-';
        tmp += str.substr(7, 4);
        break;
    }
    return tmp;
  }
  date(number) {
    console.log(number);
    var tmp = '';
    var str = String(number).replace(/[^0-9]/g, '');
    console.log(str);
    switch (str.length) {
      case 8: case 7:
        tmp = str.substr(0, 4);
        tmp += '-';
        tmp += str.substr(4, 2);
        tmp += '-';
        tmp += str.substr(6);
        break;
      case 6: case 5:
        tmp = str.substr(0, 4);
        tmp += '-';
        tmp += str.substr(4);
        break;
      case 4: case 3: case 2: case 1: case 0:
        tmp = str;
        break;
      default:
        tmp += str.substr(0, 4);
        tmp += '-';
        tmp += str.substr(4, 2);
        tmp += '-';
        tmp += str.substr(6, 2);
        break;
    }
    return tmp;
  }
  register_no(number){
    var tmp = '';
    var str = String(number).replace(/[^0-9]/g, '');
    switch (str.length) {
      case 12: case 11: case 10: case 9: case 8: case 7:
        tmp += str.substr(0, 6);
        tmp += '-';
        tmp += str.substr(6);
        console.log('1');
        break;
      case 6: case 5: case 4: case 3: case 2: case 1: case 0:
        tmp = str;
        break;
      default:
        tmp += str.substr(0, 6);
        tmp += '-';
        tmp += str.substr(6,7);
        console.log(str.substr(6,12));
        console.log('2');
        break;
    }
    return tmp;
  }
}
