import { Component, ViewChild } from '@angular/core';
import { Plugins, StatusBarStyle } from '@capacitor/core';
const { StatusBar, SplashScreen } = Plugins;
import { AlertController, IonRouterOutlet, MenuController, ModalController, Platform, ToastController } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import { WorkerService } from './service/worker.service';
import { StorageService } from './service/storage.service';
import { ConnectService } from './service/connect.service';
import { NativeListComponent } from './app-component/native-list/native-list.component';
import { BiconRssiComponent } from './app-component/bicon-rssi/bicon-rssi.component';
import { WebService } from './service/web.service';
import { Subscription } from 'rxjs';
import { DeviceService } from './service/device.service';

declare const firebase;

// 날씨
interface Weather_Item {
  weather_humidity:string;
  weather_icon:string;
  weather_main:string;
  weather_speed:string;
  weather_temp:number;
  weather_vec_icon:string,
  weather_vec:string
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  @ViewChild(IonRouterOutlet, { static : true }) routerOutlet: IonRouterOutlet;
  
  url:string = '';
  splitPaneDisabled:boolean = true;
  isInappbrowser;
  weather_data:Weather_Item = null;



  menuLogin = [
    {
      text: '모니터링', open: false, view_state : true,
      children: [
        //{text: '통합관제모니터링', routerLink: undefined, click: this.navMoniter}
        {text: '통합관제모니터링', routerLink: '/main'}
      ]
    },
    {
      text: '공지사항/알림사항', open: true, view_state : true,
      children: [
        {text: '공지사항', routerLink: '/notice', badge: 0},
        {text: '알림수신함', routerLink: '/alarm-inbox', badge: 0},
        {text: 'SOS 요청 이력', routerLink: '/sosmanage'},
        {text: '기상정보 관리', routerLink: '/weather-info'},
      ]
    },
    {
      text: '프로젝트 관리', open: false, view_state : true,
      children: [
        {text: '프로젝트 개요', routerLink: '/project-outline'},
        {text: '인원배치표', routerLink: '/organization-chart'},
        {text: '월별 공정사진', routerLink: '/monthly-photo'},
        {text: '프로젝트 도면 등록', routerLink: '/bleuprint-folder'}
      ]
    },
    {
      text: '공정률', open: false, view_state : true,
      children: [
        {text: '공정률 관리', routerLink: '/process-rate'}
      ]
    },
    {
      text: '인력관리', open: false, view_state : true,
      children: [
        {text: '근로자관리', routerLink: '/worker-manage'},
        {text: '계약서작성', routerLink: '/hr-payment-contract'},
        {text: '계약서관리', routerLink: '/hr-contract-list'},
        {text: '노무관리', routerLink: '/hr-payment-list'},
        {text: '노무설정', routerLink: '/hr-db'}
      ]
    },
    {
      text: '작업정보관리', open: false, view_state : true,
      children: [
        {text: '안전일일점검', routerLink: '/daily-safty-check-list'},
        {text: '작업허가서', routerLink: '/daily-plan-list'},
        {text: '공사일보', routerLink: '/daily-report-list'},
        {text: '안전 회의록', routerLink: '/record-list'},
        {text: '근로자 출역현황', routerLink: '/labor-attend-list'},
        {text: '작업구간 출역현황', routerLink: '/work-space-attend-list'},
        {text: '근로자 이동기록 현황', routerLink: '/labour-place-log'}
      ]
    },
    {
      text: '위험성평가', open: false, view_state : true,
      children: [
        {text: '위험성평가서 작성', routerLink: '/ra-list'},
        {text: '위험성평가 부적합관리', routerLink: '/ra-check-incongruity-list'},
      ]
    },
    {
      text: '중장비', open: false, view_state : true,
      children: [
        //{text: '중장비관리 설정', routerLink: '/monitoring'},
        {text: '반입전 등록', routerLink: '/equip-list'},
        {text: '장비현황', routerLink: '/equip-today-list'},
        {text: '사용전 장비점검 확인', routerLink: '/equip-check-list'},
        {text: '사용전 장비점검 부적합', routerLink: '/equip-check-incongruity-list'},
        //{text: '장비투입 및 작업현황(2단계)', routerLink: '/monitoring'}
      ]
    },
    {
      text: '교육정보관리', open: false, view_state : true,
      children: [
        {text: '신규채용자 교육', routerLink: '/new-edu-list'},
        {text: '특별안전 교육', routerLink: '/special-edu-list'},
        {text: '정기안전 교육', routerLink: '/regular-edu-list'},
        {text: '작업내용변경시 교육', routerLink: '/work-edu-list'},
        {text: '관리감독자 교육', routerLink: '/admin-edu-list'},
        {text: '교육NFC 관리', routerLink: '/edu-nfc-management'}    
      ]
    },
    // {
    //   text: '취약개소', open: false,
    //   children: [
    //     {text: '취약개소 등록/관리', routerLink: '/spot-list'},
    //     {text: '취약개소 일일점검 목록', routerLink: '/spot-check-list'},
    //     {text: '부적합관리 목록', routerLink: '/spot-check-incongruity-list'}
    //   ]
    // },
    {
      text: '부적합', open: false, view_state : true,
      children: [
        {text: '부적합 등록/관리', routerLink: '/incongruity-list'}
      ]
    },
    {
      text: '관리자 메뉴', open: false, view_state : true,
      children: [
        {text: '관리자 등록', routerLink: '/manager-list'},
        {text: '업체관리', routerLink: '/company-list'},
        {text: '관리자 설정', routerLink: '/manager-setup'},
        {text: 'NFC 관리', routerLink: '/nfc-list'}
      ]
    },
    {
      text: '비콘 관리', open: false, view_state : true,
      children: [
        {text: '작업구간 IN 비콘', routerLink: '/bicon-manage-in'},
        {text: '작업구간 OUT 비콘', routerLink: '/bicon-manage-out'},
        {text: '중장비 비콘', routerLink: '/bicon-manage-equip'}
      ]
    }
  ]

  alram_interval = null;
  onMessageEvent = null;
  is_monitoring = true;
  back_clicked = 0;

  constructor(
    public storage: StorageService,
    public worker: WorkerService,
    private platform: Platform,
    private alert: AlertController,
    private menu: MenuController,
    private modal: ModalController,
    private router: Router,
    private connect: ConnectService,
    public device: DeviceService,
    public web: WebService,
    private toast: ToastController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then((res) => {
      
      setTimeout(() => {
        SplashScreen.hide({
          fadeOutDuration: 500
        });
      }, 500)
      
      /**
       * 실행 메서드 분기 나누기
       */
      if(this.device.platform_type <= 2) this.appMethod();
      else this.adminMethod();

      //공통 이벤트
      this.events_router();
    });
  }

  back_sub:Subscription
  ionViewWillEnter() {
    // this.back_sub = this.platform.backButton.subscribeWithPriority(-1, () => {
    //   if((location.href.indexOf('employee/login') != -1)){
    //     if(!this.routerOutlet.canGoBack()) App.exitApp();
    //     else history.back();
    //   }
    //   else history.back();
    // });
  }

  appMethod(){
    StatusBar.setStyle({
      style: StatusBarStyle.Light
    });
    StatusBar.setBackgroundColor({
      color: '#fdfdfd'
    });

    this.events_biccon();

    this.startSettings();
    this.changeMenu();
  }
  adminMethod(){
    this.events();
    this.startSettings();
    this.changeMenu();
  }
  events_biccon() {
    //비콘 이벤트
    window.addEventListener('app:biconRssi', async(ev:CustomEvent) => {
      const editable:boolean = Boolean(ev.detail?.editable);
      const modal = await this.modal.create({
        //component: BiconStrengthComponent
        component: BiconRssiComponent,
        componentProps: { editable }
      });
      await modal.present();
    });
  }
  events_router() {
    //라우터 이벤트
    this.router.events.subscribe(async(event) => {
      if(event instanceof NavigationEnd) {
        console.log('router url', event.urlAfterRedirects);
        this.url = event.urlAfterRedirects;
      }
    });
  }
  async modify() {
    if(this.storage.user.data.user_type == 'USER'){
      this.router.navigate(['/employee-app/worker-modify']).then(() =>{
        this.menu.close();
      });
    } else {
      this.router.navigate(['/employee-app/admin-modify']).then(() =>{
        this.menu.close();
      });
    }
  }

  async openAlarm() {
    console.log(this.worker.worker);
    const alert = await this.alert.create({
      header: '푸시알림',
      inputs: [
        {
          name: 'radio1',
          type: 'radio',
          label: '소리+진동',
          value: 'ON',
          checked: this.worker.worker.push_state === 'ON'
        },
        {
          name: 'radio2',
          type: 'radio',
          label: '무음',
          value: 'SILENT',
          checked: this.worker.worker.push_state === 'SILENT'
        },
        {
          name: 'radio3',
          type: 'radio',
          label: '푸시알림 끄기',
          value: 'OFF',
          checked: this.worker.worker.push_state === 'OFF' || !this.worker.worker.push_state
        }
      ],
      buttons: [
        {text: '취소'},
        {text: '설정', handler: async(data) => {
          console.log('push change', data);
          const res = await this.connect.run('Update_PushState', {
            push_state: data
          }, {loading: '알람설정 중'});
          switch(res.code) {
            case 0:
              this.worker.getWorker(true);
              this.connect.error('알람변경됨', {message: '알람설정이 변경되었습니다.'});
              break;
            default:
              this.connect.error('알람설정 실패', res);
              break;
          }
        }}
      ]
    });
    alert.present();
  }
  async openNative() {
    console.log('openNative() 들어옴 1 ----------');
    const modal = await this.modal.create({
      component: NativeListComponent
    });
    modal.present();
    console.log('openNative() 들어옴 2 ----------');
  }
  async openBiconRssi() {
    window.dispatchEvent(new CustomEvent('app:biconRssi', { detail: { editable: true } }));
  }
  async openLogout() {
    // window.dispatchEvent(new CustomEvent('app:logout'));
    const alert = await this.alert.create({
      header: '로그아웃',
      message: '로그아웃 하시겠습니까?',
      buttons: [
        {text: '취소'},
        {text: '로그아웃', handler:async() => {
          this.menu.close('main-menu').then(async() => {
            const res = await this.connect.run('Update_UserLoginState', {login_state:false}, {
              loading: '로그아웃'
            });
            switch(res.code) {
              case 0:
                this.storage.user.clear();
                this.worker.clearWorker();
                setTimeout(() => {
                  // this.router.navigate(['/admin-web/login', {replaceUrl: true}]);
                  this.router.navigate(['/admin-web/login']);
                }, 500);
                break;
              default:
                this.connect.error('로그아웃 실패', res);
                break;
            }
          });

        }}
      ]
    });
    alert.present();
  }

  openMonitor() {
    this.menu.close().then(() => {
      this.web.open('/main', 'no');
    });
  }

  goToTerm(){
    window.open('https://kunyoungcms.com/admin-web/android-term-info', '_system');
  }

  /**
   * 여기서부터는 관리자 웹
   */

   async changeMenu(){
    if(this.storage.user.data.user_level == 0 && this.storage.user.data.company_type_name != '협력업체'){
      this.menuLogin[4].children = [
        {text: '근로자관리', routerLink: '/worker-manage'},
        {text: '계약서작성', routerLink: '/hr-payment-contract'},
        {text: '계약서관리', routerLink: '/hr-contract-list'},
        {text: '노무관리', routerLink: '/hr-payment-list'},
        {text: '노무설정', routerLink: '/hr-db'}
      ];
      this.menuLogin[2].children = [
        {text: '프로젝트 개요', routerLink: '/project-outline'},
        {text: '인원배치표', routerLink: '/organization-chart'},
        {text: '월별 공정사진', routerLink: '/monthly-photo'},
        {text: '프로젝트 도면 등록', routerLink: '/bleuprint-folder'}
      ]
      this.menuLogin[3].children =  [
        {text: '공정률 관리', routerLink: '/process-rate'},
      ];
      this.menuLogin[2].view_state = true;
      this.menuLogin[3].view_state = true;
    } else {
      this.menuLogin[4].children = [{text: '근로자관리', routerLink: '/worker-manage'}];
      this.menuLogin[2].view_state = false;
      this.menuLogin[3].view_state = false;

    }

    window.addEventListener('app:changeMenu', (event:CustomEvent) => {
      if(this.storage.user.data.user_level == 0 && this.storage.user.data.company_type_name != '협력업체'){
        this.menuLogin[4].children = [
          {text: '근로자관리', routerLink: '/worker-manage'},
          {text: '계약서작성', routerLink: '/hr-payment-contract'},
          {text: '계약서관리', routerLink: '/hr-contract-list'},
          {text: '노무관리', routerLink: '/hr-payment-list'},
          {text: '노무설정', routerLink: '/hr-db'}
        ];
        this.menuLogin[2].children = [
          {text: '프로젝트 개요', routerLink: '/project-outline'},
          {text: '인원배치표', routerLink: '/organization-chart'},
          {text: '월별 공정사진', routerLink: '/monthly-photo'},
          {text: '프로젝트 도면 등록', routerLink: '/bleuprint-folder'}
        ]
        this.menuLogin[3].children =  [
          {text: '공정률 관리', routerLink: '/process-rate'}
        ];
        this.menuLogin[2].view_state = true;
        this.menuLogin[3].view_state = true;
      } else {
        this.menuLogin[4].children = [{text: '근로자관리', routerLink: '/worker-manage'}];
        this.menuLogin[2].view_state = false;
        this.menuLogin[3].view_state = false;
      }
    });
  }

  async startSettings() {
    window.addEventListener('web:setting_roll', async() => {
      await this.Get_Weather();
      await this.getAlarm();
    });
    await this.Get_Weather();
    await this.getAlarm();
  }
  stopWeather() {
    this.is_monitoring = false;
  }

  async Get_Weather() {
    const res = await this.connect.run('Get_MonitoringData');
    switch(res.code) {
      case 0:
        // 날씨
        this.weather_data = res.data.weather_data || null;
        if(this.weather_data) this.weather_data.weather_temp = Math.floor(this.weather_data.weather_temp);
        break;
    }
  }
  stopAlarm() {
    clearInterval(this.alram_interval);
  }

  async getAlarm(){
    if(this.storage.user.data.user_id){
      const res = await this.connect.run('Get_CountData');
      // console.log(res);
      switch(res.code) {
        case 0:
          this.menuLogin[1].children[0]['badge'] = res.data.notice_count;
          this.menuLogin[1].children[1]['badge'] = res.data.alarm_count;
          break;
        default:
          // this.connect.error('근로자 불러오기 실패', res);
          break;
      }
    }
  }

  events() {
    this.router.events.subscribe(async(event) => {
      if(event instanceof NavigationEnd) {
        this.url = event.url;
        for(let i = 0; i < this.menuLogin.length; i++) {
          const item = this.menuLogin[i];
          for(let j = 0; j < item.children.length; j++) {
            const child = item.children[j];
            if(child.routerLink === event.url) {
              item.open = true;
              break;
            }
          }
        }
      }
    });
    window.addEventListener('app:logout', () => {
      this.logout();
    });
    window.addEventListener('app:print', async(event:CustomEvent) => {
      const { type, params } = event.detail;
      // const modal = await this.modal.create({
      //   component: PrintComponent,
      //   componentProps: {
      //     type,
      //     params
      //   },
      //   cssClass: 'print-modal'
      // });
      // modal.present();
    });
    window.addEventListener('app:splitPaneToggle', (event:CustomEvent) => {
      this.splitPaneDisabled = event.detail;
    });
    window.addEventListener('app:startPush', () => {
      if(this.storage.user.data.user_id) this.startPush();
    });
  }
  async logout(ev = null) {
    if(ev) ev.stopPropagation();
    const alert = await this.alert.create({
      header: '로그아웃',
      message: '로그아웃 하시겠습니까?',
      buttons: [
        {text: '취소'},
        {text: '로그아웃', handler:async() => {
          this.storage.user.clear();
          this.worker.clearWorker();
          setTimeout(() =>{
            this.router.navigate(['/admin-web/login']);
          }, 800);
        }}
      ]
    });
    alert.present();
  }
  async checkSession() {
    if(this.storage.user.data.user_id) {
      const res = await this.connect.run('Check_AdminSession_Data');
      switch(res.code) {
        case 0:
          // this.storage.user.set(res.data, this.storage.user.auto_login);
          break;
        default:
          this.router.navigate(['/admin-web/login'], {replaceUrl: true});
          break;
      }
    }
  }

  navMoniter() {
    window.open('admin-web/main', '_blank');
  }

  push_event = null;
  async startPush() {
    if(!this.platform.is('mobileweb')){
      try {
        const messaging = firebase.messaging();
        
        // Get Instance ID token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        messaging.getToken({vapidKey: 'BKLZJVVhYa0RHwTgFBrA-FCIMR2A_iKd5-WI-bgf4IWx898VkvhymdP302jTTVVSXZLWtEG3pmTV-7LV9Gb6j-g'}).then(async(currentToken) => {
          if (currentToken) {
            console.log(currentToken);
            //sendTokenToServer(currentToken);
            //updateUIForPushEnabled(currentToken);
            const resp = await this.connect.run('Insert_UserPush', {
              register_id: currentToken
            });
            switch(resp.code) {
              case 0:
                console.log('PUSH REGISTERED');
                break;
              default:
                //this.connect.error('푸시등록 실패', resp);
                break;
            }
          } else {
            // Show permission request.
            console.log('No Instance ID token available. Request permission to generate one.');
            // const alert = await this.alert.create({
            //   header: '푸시 알림 설정 실패',
            //   message: 'No Instance ID token available. Request permission to generate one.'
            // });
            // alert.present();
            // Show permission UI.
            //updateUIForPushPermissionRequired();
            //setTokenSentToServer(false);
          }
        }).catch(async(err) => {
          console.log('An error occurred while retrieving token. ', err);
          // const alert = await this.alert.create({
          //   header: '푸시 알림 설정 실패',
          //   message: typeof err === 'string' ? err : JSON.stringify(err)
          // });
          // alert.present();
        });
    
        this.onMessageEvent = messaging.onMessage(async(payload) => {
          console.log('Message received. ', payload);
          if(payload.notification) {
            window.dispatchEvent(new CustomEvent('monitor:toast', {detail:payload.notification}));
          }
        });
        const channel = new BroadcastChannel('sw-messages');
        channel.addEventListener('message', event => {
          window.dispatchEvent(new CustomEvent('monitor:toast', {detail:event.data}));
        });
      } catch(err) {
        console.error(err);
        // const alert = await this.alert.create({
        //   header: '푸시 알림 설정 실패',
        //   message: typeof err === 'string' ? err : JSON.stringify(err)
        // });
        // alert.present();
      }
      this.push_event = this.openToast.bind(this);
      window.addEventListener('monitor:toast', this.push_event);
    }
  }
  stopPush() {
    if(!this.platform.is('mobileweb')){
      this.onMessageEvent();
      window.removeEventListener('monitor:toast', this.push_event);
    }
  }
  async openToast(e) {
    console.info('%cI GOT IT', "color: blue; font-size: x-large");
    const notification = e.detail;
    console.log(notification);
    const toast = await this.toast.create({
      header: `${notification.title} ${notification.body}`,
      cssClass: 'full-toast',
      color: 'danger',
      duration: 3000,
      buttons: [
        {
          side: 'start',
          icon: 'fitness'
        }, {
          text: '확인',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }


  //일단 테스트로 사용하지 않고 해봄. 문제 생기면 살려야 함
  /**
   * @function menuChange(): 웹과 앱의 사이드메뉴를 나누는 메서드
   */
  /* menuChange(){
    
    let res = false;
    // 관리자
    if(this.storage.user.data.user_type == 'ADMIN'){
      if(this.connect.isApp()){
        if(location.href.indexOf('admin-web') != -1) res = true;
      } else res = true;
    } else if(this.storage.user.data.user_type == 'USER'){
      res = false;
    } else res = true;

    return res;
  } */
}
